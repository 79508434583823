import {
  DatePickerRound,
  isComplHangul,
  setDateFormat,
  setNumberComma,
  showToast,
  showToastError,
} from '@common/common';
import { CalendarMonth, Search } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ko } from 'date-fns/locale/ko';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { delPaymentProcess, getPaymentCompDtl, getPaymentList } from '@store/actions/paymentAction';
import PagiNation from 'component/pagination';
import ConfirmPopUp from 'component/ConfirmPopUp';
import _ from 'lodash';
import { SEARCH_TIME } from '@common/constants';
import PagePaymentComplteDetailModal from './PagePaymentComplteDetailModal';

const PagePaymentComplete = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  const [checkedList, setCheckedList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [delProcessPop, setDelProcessPop] = useState(false);
  const [isSuceess, setIsSuccess] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const [search, setSearch] = useState({
    pageNum: 1,
    pageSize: 20,
    keyword: '',
    searchType: 1,
    paymentStts: 5,
  });

  const [searchDay, setSearchDay] = useState({
    payEndDt: '',
    payStDt: '',
  });

  // useEffect(() => {
  //   getPayments();
  // }, [isSuceess]);

  // const getPayments = () => {
  //   dispatch(
  //     getPaymentList({
  //       ...search,
  //       userSn: user.userSn,
  //       userId: user.userId,
  //       callback: handleGetPaymentListCallback,
  //     })
  //   );
  // };

  const debouncedDispatch = useCallback(
    _.debounce((newSearch, searchDay) => {
      if (isComplHangul(newSearch.keyword)) {
        if (searchDay.payStDt !== '' && searchDay.payEndDt === '') return;
        dispatch(
          getPaymentList({
            ...newSearch,
            userSn: user.userSn,
            userId: user.userId,
            stDt: setDateFormat(searchDay.payStDt, 'YYYY-MM-DD'),
            endDt: setDateFormat(searchDay.payEndDt, 'YYYY-MM-DD'),
            callback: handleGetPaymentListCallback,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  useEffect(() => {
    debouncedDispatch(search, searchDay);
  }, [debouncedDispatch, search, searchDay.payEndDt, isSuceess]);

  const handleGetPaymentListCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      setPaymentList(data);
    } else {
      showToastError(data);
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    console.log(start);
    setSearchDay({
      payStDt: start ? start : '',
      payEndDt: end ? end : '',
    });
  };

  const handleAllCheck = () => {
    setAllChecked(!allChecked);

    if (!allChecked) {
      paymentList.data.map((item) => {
        setCheckedList((prev) => [...prev, item.paymentDtlSn]);
      });
    } else {
      setCheckedList([]);
    }
  };

  const checkedItemHandler = (value, isChecked) => {
    setAllChecked(false);

    if (isChecked) {
      setCheckedList((prev) => [...prev, value]);
      return;
    }

    if (!isChecked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));
      return;
    }
    return;
  };

  const checkHandler = (e, value) => {
    checkedItemHandler(value, e.target.checked);
  };

  const returnPage = (event) => {
    setSearch((prev) => ({
      ...prev,
      pageNum: event,
    }));
  };

  const handleDelProcessConfirm = () => {
    if (checkedList.length <= 0) {
      return showToast('삭제할 지급 목록을 선택해주세요.');
    }

    setDelProcessPop(true);
  };

  const handleDelPaymentProcess = () => {
    dispatch(
      delPaymentProcess({
        userSn: user.userSn,
        userId: user.userId,
        paymentDtlSnList: checkedList,
        callback: handleDelPaymentProcessCallback,
      })
    );
  };

  const handleDelPaymentProcessCallback = (data) => {
    if (data.resultCode === 0) {
      setDelProcessPop(false);
      setIsSuccess(!isSuceess);
      showToast(checkedList.length + '건의 지급 목록이 삭제되었습니다.');
    } else {
      showToast(data);
    }
  };

  const handleDtlClick = (paymentSn, tgtSn, tgtType) => {
    setIsDetailOpen(true);
    setPaymentDetail({
      paymentSn: paymentSn,
      tgtSn: tgtSn,
      tgtType: tgtType,
    });
  };

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} pb={2} spacing={1}>
        <Grid item md={6} display={'flex'} alignItems={'center'}>
          <Grid item mr={1}>
            <FormControl size="small" fullWidth>
              <Select
                variant="outlined"
                name="searchType"
                sx={{ width: '120px' }}
                defaultValue={1}
                onChange={handleSearchChange}
              >
                <MenuItem value={1}>지급대상</MenuItem>
                <MenuItem value={2}>회원코드(회사코드)</MenuItem>
                <MenuItem value={3}>프로젝트명</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item mr={1}>
            <TextField
              className="inp-round srh"
              required
              placeholder="내용을 검색해주세요"
              autoComplete="off"
              size="small"
              fullWidth
              name="keyword"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={5}>
            <DatePicker
              locale={ko}
              selected={searchDay.payStDt}
              onChange={handleDateChange}
              startDate={searchDay.payStDt}
              endDate={searchDay.payEndDt}
              dateFormat={'YYYY-MM-dd'}
              selectsRange
              customInput={<DatePickerRound placehol="YYYY-MM-DD ~ YYYY-MM-DD" />}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <Grid item mr={1}>
            <Button className="btn">지급 대상별 내역 다운로드</Button>
          </Grid>
          <Grid item mr={1}>
            <Button className="btn-red" onClick={handleDelProcessConfirm}>
              삭제
            </Button>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <Select
                variant="outlined"
                displayEmpty
                defaultValue="20"
                sx={{ width: '120px' }}
                name="pageSize"
                onChange={handleSearchChange}
              >
                <MenuItem value={20}>20건</MenuItem>
                <MenuItem value={50}>50건</MenuItem>
                <MenuItem value={100}>100건</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 1, width: '5%' }}>
                <Checkbox checked={allChecked} onChange={handleAllCheck} />
              </TableCell>
              <TableCell sx={{ padding: 1, width: '20%' }}>프로젝트</TableCell>
              <TableCell sx={{ padding: 1, width: '20%' }}>지급 대상</TableCell>
              <TableCell sx={{ padding: 1, width: '10%' }}>고용 형태</TableCell>
              <TableCell sx={{ padding: 1, width: '10%' }}>지급 종류</TableCell>
              <TableCell sx={{ padding: 1, width: '10%' }}>지급 처리일</TableCell>
              <TableCell sx={{ padding: 1, width: '10%', textAlign: 'end' }}>지급액</TableCell>
              <TableCell sx={{ padding: 1, width: '10%', textAlign: 'end' }}>실지급액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentList.data && paymentList.data.length > 0 ? (
              paymentList.data.map((item, index) => (
                <TableRow
                  key={item.paymentDtlSn}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleDtlClick(item.paymentSn, item.tgtSn, item.tgtType)}
                >
                  <TableCell sx={{ padding: 1 }} onClick={(event) => event.stopPropagation()}>
                    <Checkbox
                      checked={checkedList.includes(item.paymentDtlSn)}
                      onChange={(e) => checkHandler(e, item.paymentDtlSn)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.prjcNm}</TableCell>
                  <TableCell sx={{ padding: 1 }}>
                    <Typography variant="body2">{item.tgtNm}</Typography>
                    <Typography variant="body2">{item.coCd}</Typography>
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.emplTypeNm}</TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.categoryNm}</TableCell>
                  <TableCell sx={{ padding: 1 }}>{setDateFormat(item.paymentRealDttm, 'YYYY-MM-DD')}</TableCell>
                  <TableCell sx={{ padding: 1, textAlign: 'end' }}>
                    {setNumberComma(String(item.cost))}&nbsp;원
                  </TableCell>
                  <TableCell sx={{ padding: 1, textAlign: 'end' }}>
                    {setNumberComma(String(item.realCost))}&nbsp;원
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  검색결과가 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PagiNation
        totalItems={paymentList && paymentList.totalCount}
        itemCountPerPage={search.pageSize}
        currentPage={search.pageNum}
        returnPage={returnPage}
      />
      {delProcessPop && (
        <ConfirmPopUp
          open={delProcessPop}
          close={setDelProcessPop}
          title={'선택한 지급 목록을 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDelPaymentProcess}
        />
      )}
      {isDetailOpen && (
        <PagePaymentComplteDetailModal isOpen={isDetailOpen} setIsOpen={setIsDetailOpen} paymentDtl={paymentDetail} />
      )}
    </>
  );
};

export default PagePaymentComplete;
