import { showToast } from '@common/common';
import { Close, Search } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getCntrNmList, getPrjcMainNmList, postClntPrjc } from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageProjectRequestRegModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);
  const [projectList, setProjectList] = useState([]);
  const [requestPop, setRequestPop] = useState(false);

  const [prjcNm, setPrjcNm] = useState('');
  const searchProject = () => {
    dispatch(
      getPrjcMainNmList({
        prjcNm: prjcNm,
        callback: fn_result,
      })
    );
  };

  const fn_result = (data) => {
    if (data.resultCode === 0) {
      setProjectList(data.data);
    }
  };

  const handlEnter = (event) => {
    if (event.key === 'Enter') {
      searchProject();
    }
  };

  const [sendData, setSendData] = useState({
    userSn: userInfo.userSn,
    userId: userInfo.userId,
    prjcMainSn: 0,
    tgtUserSn: 0,
    coNm: '',
  });

  const handelApproval = (item) => {
    setSendData((prev) => ({
      ...prev,
      prjcMainSn: item.prjcMainSn,
      tgtUserSn: item.tgtUserSn,
      coNm: item.coNm,
    }));

    setRequestPop(true);
  };

  const handleSendApproval = () => {
    dispatch(
      postClntPrjc({
        ...sendData,
        callback: fn_approval,
      })
    );
  };

  const fn_approval = (data) => {
    if (data.resultCode === 0) {
      showToast('프로젝트 접근 요청이 완료되었습니다.');
      setRequestPop(false);
      setIsOpen(false);
    } else if (data.resultCode === 2) {
      showToast(data.resultMsg);
    } else {
      showToast(data.resultMsg, 'error');
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          프로젝트 등록
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          {/* 프로젝트 코드는 각 개발/관리사에게 확인 가능합니다. */}
          프로젝트 접근 요청을 개발/관리사에게 보냅니다.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={9.5}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="프로젝트를 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                value={prjcNm}
                onKeyUp={(e) => handlEnter(e)}
                onChange={(e) => setPrjcNm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <Button className="btn" fullWidth onClick={searchProject}>
              검색
            </Button>
          </Grid>
        </Grid>
        <Grid item className="list-type">
          {projectList.length > 0 ? (
            projectList.map((item) => (
              <Grid container key={item.prjcMainSn}>
                <Grid item>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.prjcNm}
                  </Typography>
                  <Typography variant="body2">{item.coNm}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handelApproval(item)}>
                    요청
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {requestPop && (
        <ConfirmPopUp
          open={requestPop}
          close={setRequestPop}
          title={'해당 프로젝트에 대한\n접근 권한을 요청 하시겠습니까?'}
          comment={'승인 주체: ' + sendData.coNm}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handleSendApproval}
        />
      )}
    </Dialog>
  );
};

export default PageProjectRequestRegModal;
