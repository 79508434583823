import React, { useState, useEffect, useRef } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import 'rc-tree/assets/index.css';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material';
import { keys } from 'lodash';
import { useDispatch } from 'react-redux';
import { getPrjcTaskNmList, putPrjcTaskList } from '@store/actions/projectAction';
import { useSelector } from 'react-redux';
import { showToast, showToastError } from '@common/common';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { Add, AddBox, IndeterminateCheckBox, PlusOne, Remove } from '@mui/icons-material';
const getSvgIcon = (IconComponent, iStyle = {}) => (
  <IconButton style={iStyle}>
    <SvgIcon component={IconComponent} style={{ verticalAlign: '-.125em' }} />
  </IconButton>
);

// const treeData = [
//   {
//     key: '0-0',
//     title: 'parent 1',
//     children: [
//       {
//         key: '0-0-0',
//         title: 'parent 1-1',
//         children: [{ key: '0-0-0-0', title: 'parent 1-1-0' }],
//       },
//       {
//         key: '0-0-1',
//         title: 'parent 1-2',
//         children: [
//           { key: '0-0-1-0', title: 'parent 1-2-0', disableCheckbox: true },
//           { key: '0-0-1-1', title: 'parent 1-2-1' },
//           { key: '0-0-1-2', title: 'parent 1-2-2' },
//           { key: '0-0-1-3', title: 'parent 1-2-3' },
//           { key: '0-0-1-4', title: 'parent 1-2-4' },
//           { key: '0-0-1-5', title: 'parent 1-2-5' },
//           { key: '0-0-1-6', title: 'parent 1-2-6' },
//           { key: '0-0-1-7', title: 'parent 1-2-7' },
//           { key: '0-0-1-8', title: 'parent 1-2-8' },
//           { key: '0-0-1-9', title: 'parent 1-2-9' },
//           { key: 1128, title: 1128 },
//         ],
//       },
//     ],
//   },
// ];

const getSwitcherIcon = (expanded, iStyle = {}) => {
  return expanded ? (
    <IconButton style={iStyle}>
      <SvgIcon component={IndeterminateCheckBox} style={{ fontSize: 20 }} />
    </IconButton>
  ) : (
    <IconButton style={iStyle}>
      <SvgIcon component={AddBox} style={{ fontSize: 20 }} />
    </IconButton>
  );
};

const PageProjectTaskDelModal = ({ isOpen, setIsOpen, isSuccess, setIsSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);
  const pUser = useSelector((state) => state.projectReducer.project);

  const [treeData, setTreeData] = useState([]);
  const groupMap = {};

  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState(keys);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(keys);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState(keys);
  const treeRef = useRef(null);
  const selKeyRef = useRef(null);
  const [checkedKeysInfo, setCheckedKeysInfo] = useState([]);
  const [deletePop, setDeletePop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);

  useEffect(() => {
    dispatch(
      getPrjcTaskNmList({
        userSn: user.userSn,
        userId: user.userId,
        puserSn: pUser.puserSn,
        prjcMainSn: id,
        callback: handleGetPrjcTaskNmListCallback,
      })
    );
  }, []);

  const handleGetPrjcTaskNmListCallback = (data) => {
    if (data.resultCode === 0) {
      const newTreeData = [...treeData];
      const newGroupMap = { ...groupMap };

      data.data.forEach((item) => {
        const groupKey = `group-${item.prjcGrpSn}`;

        if (item.type === 0) {
          if (!groupMap[groupKey]) {
            console.log(newTreeData);
            groupMap[groupKey] = {
              key: `0-${newTreeData.length}`,
              title: item.taskNm,
              prjcGrpSn: item.prjcGrpSn,
              type: item.type,
              prjcTaskSn: item.prjcTaskSn,
              disabled: item.modiYn === 'N',
              isLeaf: true,
              children: [],
            };
            newTreeData.push(groupMap[groupKey]);
          }
        }

        const group = groupMap[groupKey];
        if (item.type === 1) {
          const parentNode = {
            key: `${item.prjcTaskSn}`,
            title: item.taskNm,
            prjcGrpSn: item.prjcGrpSn,
            type: item.type,
            prjcTaskSn: item.prjcTaskSn,
            disabled: item.modiYn === 'N',
            children: [],
          };
          group.children.push(parentNode);
          if (item.modiYn === 'N') {
            group.disabled = true; // Disable the group if any child is modiYn 'N'
          }
          group.isLeaf = false;
        } else if (item.type === 2) {
          console.log(group);
          const parentIndex = group.children.findIndex((child) => child.key.endsWith(item.prntTaskSn));
          console.log(parentIndex);
          if (parentIndex !== -1) {
            const parentNode = group.children[parentIndex];
            const childNode = {
              key: `${parentNode.key}-${parentNode.children.length}`,
              title: item.taskNm,
              prjcGrpSn: item.prjcGrpSn,
              type: item.type,
              prjcTaskSn: item.prjcTaskSn,
              disabled: item.modiYn === 'N',
            };
            parentNode.children.push(childNode);
            if (item.modiYn === 'N') {
              group.disabled = true; // Disable the group if any child is modiYn 'N'
            }
            group.isLeaf = false;
          }
        }
      });

      setTreeData(newTreeData);
    } else {
      showToastError(data);
    }
  };
  const [expandedKeys, setExpandedKeys] = useState([]);
  const onExpand = (expandedKeys) => {
    console.log('onExpand', expandedKeys);
    setExpandedKeys(expandedKeys);
  };

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    selKeyRef.current = info.node.props.eventKey;
  };

  const onCheck = (checkedKeys, info) => {
    console.log('onCheck', info);
    const checkedNodesInfo = info.checkedNodes.map((node) => ({
      type: node.type,
      prjcTaskSn: node.prjcTaskSn,
      prjcGrpSn: node.prjcGrpSn, // Extract custom property
    }));

    setCheckedKeysInfo(checkedNodesInfo);
    console.log('Checked Nodes Info:', checkedNodesInfo);
  };

  const onEdit = () => {
    setTimeout(() => {
      console.log('current key: ', selKeyRef.current);
    }, 0);
  };

  const onDel = (e) => {
    if (!window.confirm('sure to delete?')) {
      return;
    }
    e.stopPropagation();
  };

  const handleConfirmDeletePrjcTask = () => {
    if (checkedKeysInfo.length <= 0) {
      return showToast('삭제할 업무를 선택해주세요.');
    } else {
      setDeletePop(true);
    }
  };

  const handleDeletePrjcTask = () => {
    dispatch(
      putPrjcTaskList({
        userSn: user.userSn,
        userId: user.userId,
        puserSn: pUser.puserSn,
        prjcMainSn: id,
        delPrjcTaskList: checkedKeysInfo,
        callback: handlePutPrjcTaskListCallback,
      })
    );
  };

  const handlePutPrjcTaskListCallback = (data) => {
    if (data.resultCode === 0) {
      showToast(`업무 ${checkedKeysInfo.length}건이 삭제되었습니다.`);
      setIsOpen(false);
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };

  // const switcherIcon = (obj) => {
  //   console.log(obj);
  //   if (obj.data.type === 2) {
  //     return false;
  //   }
  //   if (obj.isLeaf) {
  //     return getSvgIcon(IndeterminateCheckBox, { cursor: 'pointer', backgroundColor: 'white' });
  //   }
  //   return getSvgIcon(AddBox, { cursor: 'pointer', backgroundColor: 'white' });
  // };

  const switcherIcon = (obj) => {
    const { expanded, data, isLeaf } = obj;

    // type이 2인 경우 아이콘을 표시하지 않음
    if (data.type === 2) {
      return null;
    }

    // 자식 노드가 없는 경우
    if (isLeaf) {
      return (
        <IconButton style={{ cursor: 'pointer', backgroundColor: 'white', margin: ' -15px', top: '-7px' }}>
          <SvgIcon component={IndeterminateCheckBox} style={{ fontSize: 20 }} />
        </IconButton>
      );
    }

    // 자식 노드가 있는 경우
    return getSwitcherIcon(expanded, { cursor: 'pointer', backgroundColor: 'white', margin: '-15px', top: '-7px' });
  };
  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
        <DialogTitle>
          <Typography className="modal-tit">업무 삭제</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            그룹 또는 상위 업무 삭제 시 하위에 속한 업무도 함께 삭제됩니다.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Tree
            className="myCls"
            showLine
            checkable
            selectable={false}
            defaultExpandAll
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            defaultCheckedKeys={defaultCheckedKeys}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={treeData}
            switcherIcon={switcherIcon}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={handleConfirmDeletePrjcTask}>
            {checkedKeysInfo.length}건 삭제
          </Button>
        </DialogActions>
      </Dialog>
      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'선택한 업무를 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없의며, 하위 업무가 있는 경우 함께 삭제됩니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDeletePrjcTask}
        />
      )}
    </>
  );
};

export default PageProjectTaskDelModal;
