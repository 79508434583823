import { isNumber, showToast, showToastError } from '@common/common';
import { Add, Close, DeleteForever, DragHandle } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getPrjcGrpList, postPrjcGrp } from '@store/actions/projectAction';

import ConfirmPopUp from 'component/ConfirmPopUp';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sortable from 'sortablejs';

const PageProjectTaskUpdGroupModal = ({ isOpen, setIsOpen, isSuccess, setIsSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);
  const pUser = useSelector((state) => state.projectReducer.project);
  const sortableContainerRef = useRef(null);

  const [plusButton, setPlusButton] = useState(true);
  const [savePop, setSavePop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [grpList, setGrpList] = useState([]);
  const [prrtLength, setPrrtLength] = useState();
  const [sendGrpList, setSendGrpList] = useState([]);

  //const [sendData, setSendData] = useState([]);

  const sortableRef = useRef(null); // Sortable instance reference

  useEffect(() => {
    if (sortableContainerRef.current) {
      // Destroy existing Sortable instance if it exists
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }

      // Create a new Sortable instance
      sortableRef.current = new Sortable(sortableContainerRef.current, {
        handle: '.handle',
        animation: 150,
        //draggable: '.draggable-item:not(.fixed)',
        filter: '.fixed', // Class name of elements that should not be sortable
        preventOnFilter: false,
        onEnd: (event) => {
          const { oldIndex, newIndex } = event;
          console.log(grpList);
          if (oldIndex !== newIndex) {
            const updatedItems = [...grpList];
            console.log(updatedItems);
            const [movedItem] = updatedItems.splice(oldIndex, 1);
            updatedItems.splice(newIndex, 0, movedItem);
            setGrpList(updatedItems);
            setSendGrpList(updatedItems);
          }
        },
        onMove: (event) => {
          const { related, dragged } = event;
          const relatedIndex = Array.from(related.parentNode.children).indexOf(related);
          // Prevent drop to the first position

          return true;
        },
      });
    }

    if (prrtLength > 50) {
      setPlusButton(false);
    } else {
      setPlusButton(true);
    }
  }, [prrtLength, grpList, sortableContainerRef.current]);

  useEffect(() => {
    dispatch(
      getPrjcGrpList({
        userId: user.userId,
        userSn: user.userSn,
        puserSn: pUser.puserSn,
        prjcMainSn: id,
        callback: handleGetPrjcGrpListCallback,
      })
    );
  }, []);

  const handleGetPrjcGrpListCallback = (data) => {
    if (data.resultCode === 0) {
      setGrpList(data.data);
      setSendGrpList(data.data);
      setPrrtLength(data.data.length);
    } else {
      showToastError(data);
    }
  };

  const handlAddContent = () => {
    console.log('prrtLength', prrtLength);
    setPrrtLength(prrtLength + 1);
    const data = {
      prjcGrpSn: Math.random().toString(36).substring(2, 11),
      grpNm: '',
    };

    setGrpList((prev) => [...prev, data]);

    setSendGrpList((prev) => [...prev, data]);
    //setData(data);
  };

  const changePrrt = (item, e) => {
    item.grpNm = e.target.value;
    //setData(item);
  };

  const deleteContent = (value) => {
    console.log(value);
    if (value.linkCnt > 0) {
      showToast('해당 그룹에 속한 업무가 존재합니다. 업무 삭제 후 다시 진행해주세요.');
    } else {
      setGrpList(grpList.filter((item) => item.prjcGrpSn !== value.prjcGrpSn));
      setPrrtLength(prrtLength - 1);
    }
  };

  const handleConfirmPrjcGrp = () => {
    const isGroupNameEmpty = grpList.some((grp) => grp.grpNm === '');

    if (isGroupNameEmpty) {
      return showToast('그룹 이름을 입력해주세요.');
    }
    setSavePop(true);
  };

  const handleRegPrjcGrp = () => {
    // const grpListIds = new Set(grpList.map((item) => item.prjcGrpSn)); // Assuming items have an 'id' property
    // console.log(grpListIds);
    // // Update items in sendGrpList that are not in grpList
    // const updatedSendGrpList = grpList
    //   .map((item) => {
    //     if (!grpListIds.has(item.prjcGrpSn) && isNumber(item.prjcGrpSn)) {
    //       // Update delYn to 'Y'
    //       return { ...item, delYn: 'Y' };
    //     } else if (!grpListIds.has(item.prjcGrpSn) && !isNumber(item.prjcGrpSn)) {
    //       // Mark for deletion
    //       return null;
    //     }
    //     // Return unchanged item if it is in grpList
    //     return item;
    //   })
    //   .filter((item) => item !== null);

    const newGrpList = grpList.map((item) => {
      if (typeof item.prjcGrpSn === 'string') {
        return { ...item, prjcGrpSn: 0 };
      }
      return item;
    });

    // Optionally, update state if needed

    dispatch(
      postPrjcGrp({
        userSn: user.userSn,
        userId: user.userId,
        prjcMainSn: id,
        grpList: newGrpList,
        callback: handlepostPrjcGrpCallback,
      })
    );
  };

  const handlepostPrjcGrpCallback = (data) => {
    if (data.resultCode === 0) {
      setIsSuccess(!isSuccess);
      setIsOpen(false);
      setSavePop(false);
      showToast('그룹이 수정되었습니다.');
    } else {
      showToastError(data);
    }
  };
  return (
    <>
      <Dialog open={isOpen} fullWidth scroll="paper" disableEnforceFocus>
        <DialogTitle>
          <Button className="btn-close">
            <Close onClick={() => setIsOpen(false)} />
          </Button>
          <Typography className="modal-tit" fontWeight={'bold'}>
            그룹 편집
          </Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            그룹 위치 이동 시 그룹에 속한 업무도 함께 이동되며,
            <br />
            속한 업무가 있는 그룹은 삭제할 수 없습니다.(최대 50개로 구성 가능)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid ref={sortableContainerRef}>
            {grpList &&
              grpList.map((item, index) => (
                <Grid container className="inp-list" key={item.prjcGrpSn}>
                  <Grid container>
                    <Grid item>
                      <TextField
                        defaultValue={item.grpNm}
                        placeholder="그룹 이름을 입력해주세요"
                        size="small"
                        autoComplete="off"
                        onChange={(e) => changePrrt(item, e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="handle">
                              <DragHandle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <DeleteForever onClick={() => deleteContent(item)} />
                  </Grid>
                </Grid>
              ))}
          </Grid>
          {plusButton && <Button className="add" fullWidth onClick={handlAddContent} startIcon={<Add />} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelPop(true)} className="btn-white" fullWidth>
            취소
          </Button>
          <Button className="btn-navy" fullWidth onClick={handleConfirmPrjcGrp}>
            저장
          </Button>
        </DialogActions>
      </Dialog>

      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setIsOpen}
        />
      )}
      {savePop && (
        <ConfirmPopUp
          open={savePop}
          close={setSavePop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleRegPrjcGrp}
        />
      )}
    </>
  );
};

export default PageProjectTaskUpdGroupModal;
