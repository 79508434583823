import { setNumberComma } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getClctDtl } from '@store/actions/calculationAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import PageCalculationListUpdate from './PageCalculationListUpdate';

const PageCalculationListDetail = ({ isDetailOpen, setIsDetailOpen, clctSn, updateProcess }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [resultData, setResultData] = useState({});

  const fn_resultData = (data) => {
    if (data.resultCode === 0) {
      setResultData(data.data);
    }
  };
  useEffect(() => {
    dispatch(
      getClctDtl({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        clctSn,
        callback: fn_resultData,
      })
    );
  }, []);

  const [updatePopup, setUpdatePopup] = useState(false);

  const handleUpdate = () => {
    setIsDetailOpen(false);
    updateProcess();
  };

  return (
    <>
      <Dialog open={isDetailOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
        <DialogTitle sx={{ padding: '30px 40px !important', pb: '0px !important' }}>
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h5" fontWeight={'bold'}>
                  {resultData.prjcNm}
                </Typography>
                <Typography color={'grey'} variant="body2" mt={1}>
                  {resultData.coNm} / {resultData.cntrStDt} ~ {resultData.cntrEndDt}
                </Typography>
              </Box>
              <Close onClick={() => setIsDetailOpen(false)} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'end'}>
              <IconButton
                disableRipple
                sx={{
                  color: 'black',
                  padding: 0,
                }}
                onClick={() => setUpdatePopup(true)}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '15%' }}>구분</TableCell>
                  <TableCell sx={{ width: '27%' }}>정산 종류</TableCell>
                  <TableCell sx={{ width: '26%' }}>정산 예정일</TableCell>
                  <TableCell sx={{ width: '32%', textAlign: 'end' }}>지급액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(resultData).length > 0 && resultData.clctDtlList.length > 0 ? (
                  resultData.clctDtlList.map((item) => (
                    <TableRow key={item.clctDtlSn}>
                      <TableCell>{item.clctSttsNm}</TableCell>
                      <TableCell>{item.categoryNm}</TableCell>
                      <TableCell>{item.clctDt}</TableCell>
                      <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.cost))} 원</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      검색결과가 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      {updatePopup && (
        <PageCalculationListUpdate
          updatePopup={updatePopup}
          setUpdatePopup={setUpdatePopup}
          resultData={resultData}
          updateProcess={handleUpdate}
        />
      )}
    </>
  );
};

export default PageCalculationListDetail;
