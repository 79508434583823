import { Close, Percent } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const PageProjectTaskUpdProgressModal = ({ isOpen, setIsOpen, oriPrgr, setOriPrgr, onClick }) => {
  const handleChange = (e) => {
    console.log(e.target.value);
    setOriPrgr(e.target.value > 100 ? 100 : e.target.value);
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Typography className="modal-tit" fontWeight={'bold'}>
          진행률 수정
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            fullWidth
            autoComplete="off"
            variant="standard"
            size="small"
            value={oriPrgr}
            onChange={handleChange}
            name="oriPrgr"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Percent fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpen(false)} className="btn-white">
          취소
        </Button>
        <Button className="btn-navy" onClick={onClick}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PageProjectTaskUpdProgressModal;
