import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Search } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale/ko';
import {
  DatePickerRound,
  ExampleCustomInput,
  isComplHangul,
  setDateFormat,
  setNumberComma,
  showToast,
} from '@common/common';
import { SEARCH_TIME } from '@common/constants';
import PagiNation from 'component/pagination';
import { delClctProcess, getClctList } from '@store/actions/calculationAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import PageCalculationCompleteDetail from './PageCalculationCompleteDetail';
import PageCalculationDownload from './PageCalculationDownload';

const PageCalculationComplete = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [sendData, setSendData] = useState({
    userSn: userInfo.userSn,
    userId: userInfo.userId,
    pageSize: 20,
    pageNum: 1,
    filterType: 5,
  });

  const [searchValues, setSearchValues] = useState({
    searchType: 'projectNm',
    searchValue: '',
  });

  const [searchDay, setSearchDay] = useState({
    clctEndDt: '',
    clctStDt: '',
  });

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    console.log('동작? ', start, '   aaaa    ', end);

    setSearchDay({
      clctStDt: start ? start : '',
      clctEndDt: end ? end : '',
    });
  };

  const [allChecked, setAllChecked] = useState(false);

  const [resultData, setResultData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const returnPage = (event) => {
    setSendData((prev) => ({
      ...prev,
      pageNum: event,
    }));
  };

  useEffect(() => {
    getClcts();
  }, [searchDay.clctEndDt, sendData]);

  const getClcts = () => {
    setCheckedList([]);
    if (searchDay.clctStDt !== '' && searchDay.clctEndDt === '') return;

    dispatch(
      getClctList({
        ...sendData,
        ...searchValues,
        clctStDt: setDateFormat(searchDay.clctStDt, 'YYYY-MM-DD'),
        clctEndDt: setDateFormat(searchDay.clctEndDt, 'YYYY-MM-DD'),
        callback: fn_resultData,
      })
    );
  };

  const fn_resultData = (data) => {
    if (data.resultCode === 0) {
      setResultData(data.data);
      setTotalCount(data.totalCount);
    }
  };

  const [checkedList, setCheckedList] = useState([]);

  const handleAllCheck = () => {
    setAllChecked(!allChecked);

    if (!allChecked) {
      const clctDtlSnList = resultData.map((item) => item.clctDtlSn);
      setCheckedList(clctDtlSnList);
    } else {
      setCheckedList([]);
    }
  };

  const checkedItemHandler = (value, isChecked) => {
    setAllChecked(false);
    if (isChecked) {
      setCheckedList((prev) => [...prev, value]);

      return;
    }

    if (!isChecked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));

      return;
    }

    return;
  };

  const checkHandler = (e, value) => {
    checkedItemHandler(value, e.target.checked);
  };

  const setDefaultValue = () => {
    setCheckedList([]);
    setAllChecked(false);
    setSearchValues({
      searchType: 'projectNm',
      searchValue: '',
    });

    setSearchDay({
      clctEndDt: '',
      clctStDt: '',
    });

    setSendData({
      userSn: userInfo.userSn,
      userId: userInfo.userId,
      pageSize: 20,
      pageNum: 1,
      filterType: 5,
    });
  };

  const handleClctDelete = () => {
    if (checkedList.length > 0) {
      setDelPopup(true);
    } else {
      showToast('삭제할 정산 목록을 선택해주세요.');
    }
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  const debouncedDispatch = useCallback(
    _.debounce((newSearch, sendData, searchDay) => {
      if (isComplHangul(newSearch.searchValue)) {
        dispatch(
          getClctList({
            ...sendData,
            ...newSearch,
            clctStDt: setDateFormat(searchDay.clctStDt, 'YYYY-MM-DD'),
            clctEndDt: setDateFormat(searchDay.clctEndDt, 'YYYY-MM-DD'),
            callback: fn_resultData,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    debouncedDispatch(searchValues, sendData, searchDay);
  }, [debouncedDispatch, searchValues.searchValue]);

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setSearchValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [delPopup, setDelPopup] = useState(false);

  const handleDelete = () => {
    dispatch(
      delClctProcess({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        clctDtlSnList: checkedList,
        callback: fn_delProcess,
      })
    );
  };

  const fn_delProcess = (data) => {
    if (data.resultCode === 0) {
      showToast(checkedList.length + '건의 정산 목록이 삭제되었습니다.');
      setDelPopup(false);
      setDefaultValue();
    }
  };
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailSn, setDetailSn] = useState('');
  const handleDetailPopup = (value) => {
    setIsDetailOpen(true);
    setDetailSn(value);
  };

  const [downloadPopup, setDownloadPopup] = useState(false);

  return (
    <>
      <Grid container mb={5}>
        <Grid item xs={12}>
          <Container maxWidth="100%">
            <Toolbar
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                justifyContent: 'space-between',
                paddingLeft: '0 !important',
                paddingRight: '0 !important',
              }}
            >
              <Grid item xs={8} display={'flex'} alignItems={'center'}>
                <Box sx={{ minWidth: 150, mr: 1 }}>
                  <FormControl size="small" fullWidth>
                    <Select
                      name="searchType"
                      displayEmpty
                      value={searchValues.searchType}
                      onChange={(e) => handleOnChange(e)}
                    >
                      <MenuItem value="projectNm">프로젝트</MenuItem>
                      <MenuItem value="coNm">계약사</MenuItem>
                      {/* <MenuItem value="coCd">회사코드</MenuItem> */}
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  sx={{ minWidth: '330px', mr: 1 }}
                  placeholder={'내용을 검색해주세요'}
                  autoComplete="off"
                  size="small"
                  name="searchValue"
                  className="inp-round"
                  onKeyUp={(e) => handleOnChange(e)}
                  disabled={searchValues.searchType === ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <DatePicker
                    locale={ko}
                    selected={searchDay.clctStDt}
                    onChange={handleDateChange}
                    startDate={searchDay.clctStDt}
                    endDate={searchDay.clctEndDt}
                    dateFormat={'YYYY-MM-dd'}
                    selectsRange
                    customInput={<DatePickerRound placehol="YYYY-MM-DD ~ YYYY-MM-DD" />}
                    isClearable={searchDay.clctStDt !== ''}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Grid item xs={8} display={'flex'} justifyContent={'end'} pr={1}>
                  <Button className="btn" onClick={() => setDownloadPopup(true)}>
                    정산 주체별 내역 다운로드
                  </Button>
                </Grid>

                <Grid item xs={2} display={'flex'} justifyContent={'end'} pr={1}>
                  <Button className="btn-red" onClick={handleClctDelete}>
                    삭제
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <FormControl size="small" fullWidth>
                      <Select
                        displayEmpty
                        defaultValue={20}
                        onChange={(e) => setSendData((prev) => ({ ...prev, pageSize: e.target.value }))}
                        value={sendData.pageSize}
                      >
                        <MenuItem value={20}>20건</MenuItem>
                        <MenuItem value={50}>50건</MenuItem>
                        <MenuItem value={100}>100건</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
            <TableContainer sx={{ marginTop: 2, pb: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: 0.5 }}>
                      <Checkbox checked={allChecked} onChange={handleAllCheck} disabled={!resultData.length > 0} />
                    </TableCell>
                    <TableCell sx={{ padding: 0.5 }}>프로젝트</TableCell>
                    <TableCell sx={{ padding: 0.5 }}>계약사(정산주체)</TableCell>
                    <TableCell sx={{ padding: 0.5 }}>정산 종류</TableCell>
                    <TableCell sx={{ padding: 0.5 }}>정산 처리일</TableCell>
                    <TableCell sx={{ textAlign: 'end', padding: 0.5 }}>정산 금액</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultData.length > 0 ? (
                    resultData.map((item, index) => (
                      <TableRow key={item.clctDtlSn} onClick={() => handleDetailPopup(item.clctSn)}>
                        <TableCell onClick={(event) => event.stopPropagation()} sx={{ padding: 0.5 }}>
                          <Checkbox
                            checked={checkedList.includes(item.clctDtlSn)}
                            onChange={(e) => checkHandler(e, item.clctDtlSn)}
                          />
                        </TableCell>

                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            padding: 0.5,
                          }}
                        >
                          {item.prjcNm}
                        </TableCell>
                        <TableCell sx={{ padding: 0.5 }}>
                          {/* {item.coCd ? item.coNm + ' (' + item.coCd + ')' : item.coNm} */}
                          {item.coNm}
                        </TableCell>
                        <TableCell sx={{ padding: 0.5 }}>{item.categoryNm}</TableCell>
                        <TableCell sx={{ padding: 0.5 }}>{setDateFormat(item.clctRealDttm, 'YYYY-MM-DD')}</TableCell>
                        <TableCell sx={{ textAlign: 'end', padding: 0.5 }}>
                          {setNumberComma(String(item.cost))} 원
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        검색결과가 없습니다.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <PagiNation
              totalItems={totalCount}
              itemCountPerPage={sendData.pageSize}
              currentPage={sendData.pageNum}
              returnPage={returnPage}
            />
          </Container>
        </Grid>
      </Grid>
      {delPopup && (
        <ConfirmPopUp
          open={delPopup}
          close={setDelPopup}
          title={'선택한 목록을 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDelete}
        />
      )}
      {isDetailOpen && (
        <PageCalculationCompleteDetail
          isDetailOpen={isDetailOpen}
          setIsDetailOpen={setIsDetailOpen}
          clctSn={detailSn}
        />
      )}
      {downloadPopup && <PageCalculationDownload downloadPopup={downloadPopup} setDownloadPopup={setDownloadPopup} />}
    </>
  );
};

export default PageCalculationComplete;
