import api from '@api/apiCalculation';
import * as actions from '@actions/calculationAction';
import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

function* getClctStats({ payload }) {
  const { status, data } = yield call(api.getClctStats, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getClctBoard({ payload }) {
  const { status, data } = yield call(api.getClctBoard, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getClctList({ payload }) {
  const { status, data } = yield call(api.getClctList, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* putClctProcess({ payload }) {
  const { status, data } = yield call(api.putClctProcess, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* postClct({ payload }) {
  const { status, data } = yield call(api.postClct, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* delClctProcess({ payload }) {
  const { status, data } = yield call(api.delClctProcess, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getClctDtl({ payload }) {
  const { status, data } = yield call(api.getClctDtl, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* putClctList(action) {
  const { payload, callback } = action.payload;
  const { status, data } = yield call(api.putClctList, { payload: payload });
  if (status === 200) callback(data, status);
}

function* putClctStop({ payload }) {
  const { status, data } = yield call(api.putClctStop, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getClctDownload({ payload }) {
  const { status, data } = yield call(api.getClctDownload, { payload: payload });
  payload.callback(data, status);
}

function* watchUser() {
  yield takeLatest(actions.GET_CLCT_STATS, getClctStats);
  yield takeLatest(actions.GET_CLCT_BOARD, getClctBoard);
  yield takeLatest(actions.GET_CLCT_LIST, getClctList);
  yield takeLatest(actions.PUT_CLCT_PROCESS, putClctProcess);
  yield takeLatest(actions.POST_CLCT, postClct);
  yield takeLatest(actions.DEL_CLCT_PROCESS, delClctProcess);
  yield takeLatest(actions.GET_CLCT_DTL, getClctDtl);
  yield takeLatest(actions.PUT_CLCT_LIST, putClctList);
  yield takeLatest(actions.PUT_CLCT_STOP, putClctStop);
  yield takeLatest(actions.GET_CLCT_DOWNLOAD, getClctDownload);
}

export default function* calculationSaga() {
  yield all([fork(watchUser)]);
}
