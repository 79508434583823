import React, { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography, Box, Card, CardContent, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserJoin } from '@store/actions/userAction';
import PageJoinRequestLayout from './PageJoinRequestLayout';
const defaultTheme = createTheme();

export default function PageJoinTypeMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [flag, setFlag] = useState('');
  const [radioBtn, setRadioBtn] = useState('');

  const handleChange = (flag) => {
    setFlag(flag);
  };

  const handleNext = () => {
    dispatch(
      setUserJoin({
        userFlag: flag,
      })
    );
    navigate('/joinTerms');
  };

  return (
    <PageJoinRequestLayout>
      <Box noValidate>
        <Typography fontWeight={'bold'}>환영합니다</Typography>

        <Typography>원하시는 가입 유형을 선택해주세요</Typography>
        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            textAlign: 'left',
            borderRadius: 4,
            width: '100%',
            mt: 3,
            border: flag === 'D' ? '1px solid #f8c9a8' : '', // Conditional border
            background: flag === 'D' ? '#fef8f4' : 'inherit', // Conditional background
          }}
          className="radio-btn2 orange"
          onClick={() => handleChange('D')}
        >
          <CardContent>
            <Typography color="text.primary" variant="body4">
              관리사/개발사
            </Typography>
            <Typography variant="body2">의뢰사와 개발사 간의 관리를 하는 회사 또는 개발회사</Typography>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            textAlign: 'left',
            borderRadius: 4,
            width: '100%',
            mt: 3,
            border: flag === 'E' ? '1px solid #f8c9a8' : '', // Conditional border
            background: flag === 'E' ? '#fef8f4' : 'inherit', // Conditional background
          }}
          onClick={() => handleChange('E')}
        >
          <CardContent>
            <Typography color="text.primary" variant="body4">
              프리랜서/개발사 직원
            </Typography>
            <Typography variant="body2">PMT를 이미 사용하고 있는 회사에 고용된 프리랜서 또는 직원</Typography>
          </CardContent>
        </Card>

        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            textAlign: 'left',
            borderRadius: 4,
            width: '100%',
            mt: 3,
            border: flag === 'C' ? '1px solid #f8c9a8' : '', // Conditional border
            background: flag === 'C' ? '#fef8f4' : 'inherit', // Conditional background
          }}
          onClick={() => handleChange('C')}
        >
          <CardContent>
            <Typography color="text.primary" variant="body4">
              의뢰사
            </Typography>
            <Typography variant="body2">PMT를 사용하고 있는 회사에 프로젝트를 의뢰한 회사</Typography>
          </CardContent>
        </Card>

        <Button type="button" fullWidth variant="contained" sx={{ mt: 5 }} onClick={handleNext} disabled={!flag}>
          다음
        </Button>
      </Box>
    </PageJoinRequestLayout>
  );
}
