import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AccessTime } from '@mui/icons-material';
import { isValue, isNumberExact, showToastError, isPassword, isId, isMobile, isYmd, showToast } from '@common/common';
import { getMblAuth, getMblCnfr, putTelEdit } from '@store/actions/userAction';

const PageMypageTelEdit = ({ telOpen, setTelOpen, handleTelUpdate }) => {
  const limitTime = 180;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [auth, setAuth] = useState(false);
  const [timer, setTimer] = useState(limitTime);
  const [intervalId, setIntervalId] = useState(null); // interval ID 저장할 상태
  const [buttonText, setButtonText] = useState('인증번호');

  const [errorMsg1, setErrorMsg1] = useState('');
  const [errorMsg2, setErrorMsg2] = useState('');
  const [errorMsg3, setErrorMsg3] = useState('');
  const [inputs, setInputs] = useState({
    userId: '',
    userSn: '',
    mblNo: '',
    isAuth: false,
    authNo: '',
    uuid: '',
    authResNo: '',
    isAuthCompl: false,
  });

  // 타이머
  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleTimer = () => {
    const time = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(time);
          setAuth(false);
          setInputs({
            ...inputs,
            mblNo: '',
            userSn: '',
            userId: '',
          });
        }
        return prevTimer - 1;
      });
    }, 1000);
    setIntervalId(time); // interval ID를 상태에 저장
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let _value = '';

    // if (name === 'opnDt') _value = setOnlyNumber(value);
    // else _value = value;

    setInputs({
      ...inputs,
      isAuthCompl: true,
      [name]: value,
    });
  };
  const successValid = () => {
    if (!isMobile(inputs.mblNo)) {
      setErrorMsg1('휴대폰번호: 입력하신 내용을 확인해주세요.');
      return false;
    } else if (!inputs.isAuthCompl) {
      setErrorMsg1('휴대폰인증: 휴대폰 인증을 확인해주세요.');
      return false;
    }
    return true;
  };

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      //setIntervalId(null); // interval ID 초기화
    }
  };

  const handleAuthRequest = () => {
    if (!isMobile(inputs.mblNo)) {
      setAuth(false);
      return setErrorMsg1('휴대폰번호: 입력하신 내용을 확인해주세요.');
    } else {
      setAuth(true);
      resetInterval();
      const updatedInputs = {
        ...inputs,
        uuid: '',
        authNo: '',
        authResNo: '',
        isAuth: false,
        isAuthCompl: false,
      };
      setInputs(updatedInputs);
      console.log(updatedInputs);
      dispatch(
        getMblAuth({
          mblNo: inputs.mblNo,
          regIp: '192.169.0.72',
          callback: handleAuthCallback,
        })
      );
    }
  };
  console.log(inputs);

  const handleAuthTestCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      console.log(inputs);
    }
  };

  const handleAuthCallback = (data, status) => {
    if (data.resultCode === 0) {
      console.log(inputs.authNo);
      setInputs((prevInputs) => ({
        ...prevInputs,
        uuid: data.data.uuid,
        authResNo: data.data.authNo,
        isAuth: true,
      }));
      handleTimer();
      // setAuth(!auth);
      setAuth(true);
      setTimer(limitTime);
    } else {
      showToastError(data);
    }
  };

  const handleSuccRequest = () => {
    if (successValid()) {
      dispatch(
        putTelEdit({
          mblNo: inputs.mblNo,
          userId: user.userId,
          userSn: user.userSn,
          callback: putTelEditCallback,
        })
      );
    }
  };

  const putTelEditCallback = (data, status) => {
    if (data.resultCode === 0) {
      showToast('변경 되었습니다.');
      handleTelUpdate(inputs.mblNo);
      setTelOpen(false);
    } else {
      showToastError(data, '0.9em');
    }
  };

  const handleComplRequest = () => {
    setErrorMsg1('');
    if (inputs.authNo != inputs.authResNo) {
      setErrorMsg1('인증번호 : 입력하신 내용을 다시 확인해주세요.', 'warning');
    } else {
      dispatch(
        getMblCnfr({
          uuid: inputs.uuid,
          authNo: inputs.authNo,
          callback: handleAuthComplCallback,
        })
      );
    }
  };

  const handleAuthComplCallback = (data, status) => {
    if (data.resultCode === 0) {
      resetInterval();
      setInputs({
        ...inputs,
        uuid: '',
        authResNo: '',
        isAuthCompl: true,
      });
      setAuth(false); // 인증번호 입력란 숨김
    } else {
      showToastError(data);
    }
  };

  //인증번호 버튼
  const handleButtonClick = () => {
    handleAuthRequest();
    setButtonText('재전송');
  };
  return (
    <>
      <Dialog open={telOpen} fullWidth maxWidth="xs" disableEnforceFocus>
        <DialogTitle>
          <Button className="btn-close" onClick={() => setTelOpen(false)}>
            <CloseIcon className="icon" />
          </Button>
          <Typography className="modal-tit">휴대폰 번호 변경</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            변경할 번호를 입력한 후, 휴대폰 인증을 진행해주세요
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container flexDirection={'column'} spacing={2}>
            <Grid container item alignItems={'center'} spacing={2} md={12}>
              <Grid item md={9}>
                <TextField
                  className="inp-round"
                  size="small"
                  fullWidth
                  name="mblNo"
                  value={inputs.mblNo}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Chip
                          className="timer"
                          // icon={<AccessTime />}
                          label={formatTime(timer)}
                          sx={{ mr: 1, display: !inputs.isAuth ? 'none' : '' }}
                        />
                      </>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <Button className="btn" type="button" fullWidth onClick={handleButtonClick}>
                  {buttonText}
                </Button>
              </Grid>
            </Grid>

            {auth && (
              <Grid container item alignItems={'center'} spacing={2} md={12}>
                <Grid item md={9}>
                  <TextField
                    className="inp-round"
                    size="small"
                    fullWidth
                    placeholder="인증번호 숫자 6자리"
                    autoComplete="off"
                    name="authNo"
                    value={inputs.authNo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={3}>
                  <Button
                    className="btn"
                    type="button"
                    fullWidth
                    onClick={handleComplRequest}
                    // disabled={inputs.isAuthCompl}
                    disabled={!inputs.isAuthCompl ? true : false}
                  >
                    인증
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Divider sx={{ borderColor: 'gray', marginTop: '30px' }} />
        </DialogContent>
        <DialogActions>
          <Grid container padding={0} spacing={2}>
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
              {errorMsg1 !== '' && (
                <Typography variant="caption" color={'red'}>
                  {errorMsg1}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} mt={1} paddingTop={0}>
              <Button className="btn-navy" sx={{ display: 'Block', margin: '0 auto' }} onClick={handleSuccRequest}>
                변경
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PageMypageTelEdit;
