import { fileAPI, sendAPI } from '@axios/index';

/* 인력 관리  */
const getWrkfList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-wrkf-list`, params, payload);
};

const getBlngList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-blng-list`, params, payload);
};

const delWrkfList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-wrkf-list`, params, payload);
};

const postBlng = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-blng`, params, payload);
};

const postWrkf = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/api/post-wrkf`, params, payload);
};

const getSchUserInfoByCode = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sch-info-code`, params, payload);
};

const getWrkfDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-wrkf-dtl`, params, payload);
};

const putWrkf = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/api/put-wrkf`, params, payload);
};

const api = {
  getWrkfList,
  getBlngList,
  delWrkfList,
  postBlng,
  postWrkf,
  getSchUserInfoByCode,
  getWrkfDtl,
  putWrkf,
};

export default api;
