import { fileAPI, sendAPI } from '@axios/index';

/* 거래처 관리 */
const postClientList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clnt-co-list`, params, payload);
};

const postClientReg = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-clnt-co`, params, payload);
};

const delClientList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-clnt-co`, params, payload);
};

const getClientDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clnt-co-dtl`, params, payload);
};

const putClntCo = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-clnt-co`, params, payload);
};

const getCoNmList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-co-nm-list`, params, payload);
};

const getCoDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-co-info-dtl`, params, payload);
};

const postDevtList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-dvlp-co-list`, params, payload);
};

const postDevReg = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-dvlp-co`, params, payload);
};

const delDevList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-dvlp-co`, params, payload);
};

const getDevDetail = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-dvlp-co-dtl`, params, payload);
};

const putDvlpCo = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-dvlp-co`, params, payload);
};

const api = {
  postClientList,
  postClientReg,
  postDevReg,
  delClientList,
  getClientDetail,
  getCoNmList,
  postDevtList,
  delDevList,
  getDevDetail,
  getCoDtl,
  putClntCo,
  putDvlpCo,
};

export default api;
