// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Noto Sans KR", sans-serif !important;
}
/* 페이지 네이션 css */
.pagination {
  display: flex;
  justify-content: end;
  margin: 5px 10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  background: #fff;
}

ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}

ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #e2e2e2;
}

ul.pagination li a {
  text-decoration: none;
  color: #0c0482;
  font-size: 1rem;
}

ul.pagination li.active a {
  color: white;
}

ul.pagination li.active {
  background-color: #0c0482;
}

ul.pagination li a.active {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,kDAAkD;AACpD;AACA,gBAAgB;AAChB;EACE,aAAa;EACb,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,gCAAgC;EAChC,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap\");\n\n* {\n  font-family: \"Noto Sans KR\", sans-serif !important;\n}\n/* 페이지 네이션 css */\n.pagination {\n  display: flex;\n  justify-content: end;\n  margin: 5px 10px;\n}\n\nul {\n  list-style: none;\n  padding: 0;\n}\n\nul.pagination li {\n  display: inline-block;\n  width: 36px;\n  height: 36px;\n  border-top: 1px solid #e2e2e2;\n  border-bottom: 1px solid #e2e2e2;\n  border-left: 1px solid #e2e2e2;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1rem;\n  font-weight: bold;\n  background: #fff;\n}\n\nul.pagination li:first-child {\n  border-radius: 5px 0 0 5px;\n}\n\nul.pagination li:last-child {\n  border-radius: 0 5px 5px 0;\n  border-right: 1px solid #e2e2e2;\n}\n\nul.pagination li a {\n  text-decoration: none;\n  color: #0c0482;\n  font-size: 1rem;\n}\n\nul.pagination li.active a {\n  color: white;\n}\n\nul.pagination li.active {\n  background-color: #0c0482;\n}\n\nul.pagination li a.active {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
