import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '@actions/salesAction';
import api from '@api/apiSales';

function* getSalesList({ payload }) {
  const { status, data } = yield call(api.getSalesList, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getStgList({ payload }) {
  const { status, data } = yield call(api.getStgList, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getPrrtList({ payload }) {
  const { status, data } = yield call(api.getPrrtList, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* postSales({ payload }) {
  const { status, data } = yield call(api.postSales, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* delSales({ payload }) {
  const { status, data } = yield call(api.delSales, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* getSalesDtl({ payload }) {
  const { status, data } = yield call(api.getSalesDtl, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* getSalesNmList({ payload }) {
  const { status, data } = yield call(api.getSalesNmList, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* putDnd({ payload }) {
  const { status, data } = yield call(api.putDnd, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* putPrrtList({ payload }) {
  const { status, data } = yield call(api.putPrrtList, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* putSalesDtl({ payload }) {
  const { status, data } = yield call(api.putSalesDtl, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* getChkStgDel({ payload }) {
  const { status, data } = yield call(api.getChkStgDel, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* getChkPrrtDel({ payload }) {
  const { status, data } = yield call(api.getChkPrrtDel, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* putStgList({ payload }) {
  const { status, data } = yield call(api.putStgList, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* getSalesLog({ payload }) {
  const { status, data } = yield call(api.getSalesLog, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* getClntCoNmList({ payload }) {
  const { status, data } = yield call(api.getClntCoNmList, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

function* testPrjcTaskList({ payload }) {
  const { status, data } = yield call(api.testPrjcTaskList, { payload: payload });
  if (status === 200) payload.callback(data, status);
};

// watcher
function* watchUser() {
  yield takeLatest(actions.GET_SALES_LIST, getSalesList);
  yield takeLatest(actions.GET_STG_LIST, getStgList);
  yield takeLatest(actions.GET_PRRT_LIST, getPrrtList);
  yield takeLatest(actions.POST_SALES, postSales);
  yield takeLatest(actions.DEL_SALES, delSales);
  yield takeLatest(actions.GET_SALES_DTL, getSalesDtl);
  yield takeLatest(actions.GET_SALES_NM_LIST, getSalesNmList);
  yield takeLatest(actions.PUT_DND, putDnd);
  yield takeLatest(actions.PUT_PRRT_LIST, putPrrtList);
  yield takeLatest(actions.PUT_SALES_DTL, putSalesDtl);
  yield takeLatest(actions.GET_CHK_STG_DEL, getChkStgDel);
  yield takeLatest(actions.GET_CHK_PRRT_DEL, getChkPrrtDel);
  yield takeLatest(actions.PUT_STG_LIST, putStgList);
  yield takeLatest(actions.GET_SALES_LOG, getSalesLog);
  yield takeLatest(actions.GET_CLNT_CO_NM_LIST, getClntCoNmList);
  yield takeLatest(actions.TEST_PRJC_TASK_LIST, testPrjcTaskList);
}

export default function* salesSaga() {
  yield all([fork(watchUser)]);
}
