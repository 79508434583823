import { strToPriceFormat } from '@common/common';
import { Card, Grid, Toolbar, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';

const MainSalesRecognitionChart = ({ resultData }) => {
  const [thisYearData, setThisYearData] = useState([]);
  const [lastYearData, setLastYearData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Initialize thisYearData and lastYearData if resultData exists
    if (resultData?.thisYearClct && resultData?.lastYearClct) {
      setThisYearData(resultData.thisYearClct);
      setLastYearData(resultData.lastYearClct);

      // Combine data for the chart
      const combinedData = resultData.thisYearClct.map((item, index) => ({
        category: item.yearMonth, // Set the category (e.g., month)
        [new Date().getFullYear().toString()]: item.cost, // Value for this year
        [(new Date().getFullYear() - 1).toString()]: resultData.lastYearClct[index]?.cost || 0, // Value for last year
      }));

      setChartData(combinedData); // Set the chart data
    }
  }, [resultData]);
  return (
    <Grid container minHeight={'500px'} px={2}>
      <Grid
        container
        component={Card}
        variant="outlined"
        sx={{
          mt: 2,
          mr: 1,
          borderRadius: '15px',
        }}
      >
        <Grid item md={12} p={2}>
          <Typography>매출 인식 금액</Typography>
        </Grid>
        <Grid item md={12}>
          <ResponsiveBar
            data={chartData}
            keys={[new Date().getFullYear().toString(), (new Date().getFullYear() - 1).toString()]}
            groupMode="grouped"
            indexBy="category"
            margin={{ top: 50, right: 50, bottom: 100, left: 100 }}
            padding={0}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            enableLabel={false}
            enableGridY={false}
            axisLeft={{
              tickSize: 0,
              format: (value) => strToPriceFormat(value),
            }}
            gridYValues={(value) => console.log(value)}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'row',
                justify: false,
                translateX: 10,
                translateY: 70,
                itemWidth: 69,
                itemHeight: 20,
                itemsSpacing: 10,
                symbolSize: 20,
                itemDirection: 'left-to-right',
              },
            ]}
            tooltip={(item) => {
              console.log(item);
              return (
                <div
                  style={{
                    background: 'rgba(0,0,0,0.5)',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '15px',
                    color: 'white',
                  }}
                >
                  <Typography>{item.indexValue}</Typography>
                  <Typography variant="body2">&#8361;{strToPriceFormat(item.value)}</Typography>
                </div>
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainSalesRecognitionChart;
