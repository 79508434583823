import { ExampleCustomInput, isNumber, setDateFormat, setNumberComma, showToast } from '@common/common';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ko } from 'date-fns/locale/ko';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PageCalculationAgreement from './PageCalculationAgreement';
import PageLoadProjectModal from '../contract/PageLoadProjectModal';
import PageLoadContractModal from '../contract/PageLoadContractModal';
import PageLoadSalesModal from '../contract/PageLoadSalesModal';
import ConfirmPopUp from 'component/ConfirmPopUp';
import PageCalculationMonth from './PageCalculationMonth';
import { postClct } from '@store/actions/calculationAction';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageCalculationReg = ({ regPopup, setRegPopup, saveCal }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [toggleValue, setToggleValue] = useState('S');

  const [sendData, setSendData] = useState({
    cntrStDt: '',
    cntrEndDt: '',
    cntrAmnt: '',
    prjcNm: '',
    coNm: '',
    cntrYN: 'N',
    coCd: '',
    cntrSn: '',
  });

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setSendData({
      ...sendData,
      cntrStDt: start,
      cntrEndDt: end,
    });
  };

  const [settlementAmount, setSettlementAmount] = useState([]);
  const [cntrPopup, setCntrPopup] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);

  const contractData = useSelector((state) => state.contractReducer.contractInfo);
  const [inputs, setInputs] = useState(contractData);

  const [coYn, setCoYn] = useState(false);
  useEffect(() => {
    setSendData((prev) => ({
      ...prev,
      coCd: inputs.coCd ? inputs.coCd : '',
      coNm: inputs.clntCoNm,
    }));

    if (inputs.clntCoNm !== '') {
      setCoYn(true);
    }
  }, [inputs]);

  const [confirmPop, setConfirmPop] = useState(false);

  const handleClctSave = () => {
    const dtlList = [];

    settlementAmount.forEach((e) => {
      const clctStts = new Date() < e.clctDt ? '1' : '2';
      dtlList.push({
        clctDt: setDateFormat(e.clctDt, 'YYYY-MM-DD'),
        category: e.category,
        cost: e.cost,
        clctStts,
      });
    });

    dispatch(
      postClct({
        ...sendData,
        cntrStDt: setDateFormat(sendData.cntrStDt, 'YYYY-MM-DD'),
        cntrEndDt: setDateFormat(sendData.cntrEndDt, 'YYYY-MM-DD'),
        dtlList: dtlList,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: fn_saveResult,
      })
    );
  };

  const fn_saveResult = (data) => {
    if (data.resultCode === 0) {
      setRegPopup(false);
      saveCal();
    }
  };

  const checkSettl = () => {
    let boolenValue = true;

    if (settlementAmount.length <= 0) {
      boolenValue = false;
    }

    settlementAmount.forEach((e) => {
      if (e.clctDt === '' || e.cost === '') {
        boolenValue = false;
        return boolenValue;
      }
    });

    return boolenValue;
  };

  const [saveCheckConfirm, setSaveCheckConfirm] = useState(false);

  const handleSaveBtn = () => {
    if (
      sendData.prjcNm === '' ||
      sendData.cntrAmnt === '' ||
      sendData.coNm === '' ||
      sendData.cntrStDt === '' ||
      sendData.cntrEndDt === '' ||
      !checkSettl()
    ) {
      showToast('계약정보를 모두 입력해주세요.');
    } else if (!checkPays()) {
      setSaveCheckConfirm(true);
    } else {
      setConfirmPop(true);
    }
  };

  const checkPays = () => {
    let booleanValue = true;
    let pay = 0;

    settlementAmount.forEach((e) => {
      pay += Number(e.cost);
    });

    if (pay.toString() !== sendData.cntrAmnt) {
      booleanValue = false;
    }

    return booleanValue;
  };

  return (
    <>
      <Dialog
        open={regPopup}
        fullWidth
        maxWidth={'sm'}
        disableEnforceFocus
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '700px', // sm과 md 사이의 원하는 너비
            width: '100%',
          },
        }}
      >
        <DialogTitle>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
            <Box>
              <Typography className="modal-tit" fontWeight={'bold'}>
                정산 등록
              </Typography>
              <Typography color={'grey'} variant="body2" mt={1}>
                프로젝트의 정산 정보를 입력해주세요
              </Typography>
            </Box>
            <Button type="button" className="btn-orange" onClick={() => setCntrPopup(true)}>
              가져오기
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={
            {
              // overflowY: 'hidden',
            }
          }
        >
          <Box>
            <Typography variant="subtitle2">계약 정보</Typography>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" className="rqd">
                    프로젝트
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={sendData.prjcNm}
                    InputProps={{
                      readOnly: sendData.cntrYN === 'Y' ? true : false,
                    }}
                    onChange={(e) =>
                      setSendData((prev) => ({
                        ...prev,
                        prjcNm: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" className="rqd">
                    계약사
                  </Typography>
                </Grid>
                <Grid item md={5} mr={1}>
                  <TextField
                    variant="standard"
                    fullWidth
                    value={sendData.coCd !== '' ? sendData.coNm + '(' + sendData.coCd + ')' : sendData.coNm}
                    InputProps={{
                      readOnly: sendData.cntrYN === 'Y' || coYn ? true : false,
                    }}
                    onChange={(e) =>
                      setSendData((prev) => ({
                        ...prev,
                        coNm: e.target.value,
                        coCd: '',
                      }))
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <Button
                    type="button"
                    className="btn-orange"
                    disabled={sendData.cntrYN === 'Y' ? true : false}
                    onClick={() => setIsSalesOpen(true)}
                  >
                    정보 가져오기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" className="rqd">
                    계약금
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    variant="standard"
                    fullWidth
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(/^0+/, '').replace(/[^0-9]+/g, '');

                      if (inputValue.length > 20) {
                        inputValue = '99999999999999999999';
                      }

                      setSendData((prev) => ({
                        ...prev,
                        cntrAmnt: inputValue,
                      }));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="subtitle2" mr={2}>
                            원
                          </Typography>
                        </InputAdornment>
                      ),
                      readOnly: sendData.cntrYN === 'Y' ? true : false,
                    }}
                    value={setNumberComma(sendData.cntrAmnt)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" className="rqd">
                    계약기간
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <DatePickerWrapper className="inp-date">
                    <DatePicker
                      locale={ko}
                      selected={sendData.cntrStDt}
                      onChange={handleDateChange}
                      startDate={sendData.cntrStDt}
                      endDate={sendData.cntrEndDt}
                      dateFormat={'YYYY-MM-dd'}
                      selectsRange
                      customInput={<ExampleCustomInput />}
                      readOnly={sendData.cntrYN === 'Y' ? true : false}
                    />
                  </DatePickerWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box mt={5}>
            <Typography variant="subtitle2">정산 정보</Typography>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1" className="rqd">
                    정산방식
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Grid item xs={6}>
                    <ToggleButtonGroup
                      color="primary"
                      value={toggleValue}
                      className="radio-btn"
                      exclusive
                      onChange={(e) => {
                        setSettlementAmount([]);
                        setToggleValue(e.target.value);
                      }}
                    >
                      <ToggleButton
                        value="S"
                        aria-label="S"
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        약정 정산
                      </ToggleButton>
                      <ToggleButton value="M" aria-label="M">
                        월 정산
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} display={'flex'} alignItems={'center'}></Grid>
                <Grid item md={9}>
                  {toggleValue === 'S' && (
                    <PageCalculationAgreement
                      settlementAmount={settlementAmount}
                      setSettlementAmount={setSettlementAmount}
                      sendData={sendData}
                    />
                  )}
                  {toggleValue === 'M' && (
                    <PageCalculationMonth setSettlementAmount={setSettlementAmount} sendData={sendData} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRegPopup(false)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={() => handleSaveBtn()}>
            등록
          </Button>
        </DialogActions>
      </Dialog>

      {cntrPopup && (
        <PageLoadContractModal cntrPopup={cntrPopup} setCntrPopup={setCntrPopup} setSendData={setSendData} />
      )}
      {isSalesOpen && (
        <PageLoadSalesModal
          isOpen={isSalesOpen}
          setIsOpen={setIsSalesOpen}
          inputs={inputs}
          setInputs={setInputs}
          title={'계약사 가져오기'}
          subTitle={'계약사는 정산 주체인 업체를 의미합니다.'}
        />
      )}
      {confirmPop && (
        <ConfirmPopUp
          open={confirmPop}
          close={setConfirmPop}
          title={'등록 하시겠습니까?'}
          btnNm1={'아니오'}
          btnNm2={'예'}
          btnEvent={handleClctSave}
        />
      )}
      {saveCheckConfirm && (
        <ConfirmPopUp
          open={saveCheckConfirm}
          close={setSaveCheckConfirm}
          title={'계약금액과 입력금액이 서로 다릅니다.\n정산 정보를 수정 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handleClctSave}
        />
      )}
    </>
  );
};

export default PageCalculationReg;
