import { Box, Container, createTheme, CssBaseline, Tab, Tabs, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import PageCalculationStatistic from './PageCalculationStatistic';
import PageCalculationList from './PageCalculationList';
import PageCalculationComplete from './PageCalculationComplete';

const theme = createTheme();
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const PageCalculationMain = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={false}>
          <Box>
            <Tabs className="tab" value={value} onChange={handleChange} aria-label="tabs">
              <Tab
                label="정산 통계"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,

                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
              <Tab
                label="정산 목록"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
              <Tab
                label="정산 내역"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <PageCalculationStatistic />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PageCalculationList />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PageCalculationComplete />
            </TabPanel>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default PageCalculationMain;
