import { Backup, Block, Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { delCntrStts, getSalesNmList, postCntr, putCntr } from '@store/actions/contractAction';
import {
  convertFomData,
  isValue,
  setDateFormat,
  setNumberComma,
  setTelFormat,
  showToast,
  showToastError,
  showToastMsg,
  toastTest,
} from '@common/common';
import DaumPostCode from 'component/DaumPostCode';
import { useSelector } from 'react-redux';
import PageLoadProjectModal from './PageLoadProjectModal';
import PageLoadSalesModal from './PageLoadSalesModal';
import PageContractSttsChagneModal from './PageContractSttsChagneModal';
import { MAX_SIZE_BYTES, MAX_SIZE_MB } from '@common/constants';
import ConfirmPopUp from 'component/ConfirmPopUp';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const isFileDeleted = (file, deletedFiles) => {
  return deletedFiles.some((deletedFile) => deletedFile.fileSn === file.fileSn);
};

const PageContractRegModal = ({ type, regOpen, setRegOpen, setIsSuccessYn, isSuccessYn }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const contractData = useSelector((state) => state.contractReducer.contractInfo);
  const [isOpen, setIsOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);
  const [isDeletePop, setIsDeletePop] = useState(false);
  const [deletePop, setDeletePop] = useState(false);
  const [regPop, setRegPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);

  const [inputs, setInputs] = useState(contractData);
  const [dataFiles, setDataFiles] = useState([]);
  const [oriDataFiles, setOriDataFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const [preMemo, setPrevMemo] = useState([]);
  console.log(contractData);

  const [coType, setCoType] = useState('');

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
    />
  ));

  //날짜 change
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    setInputs({
      ...inputs,
      cntrStDt: start,
      cntrEndDt: end,
    });
  };

  //등록input change
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === 'clntMngrTelno' || name === 'dvlpMngrTelno') {
      formattedValue = setTelFormat(value, '-');
    } else if (name === 'cntrAmnt') {
      formattedValue = setNumberComma(value);
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: formattedValue,
    }));
  };

  const handleConfirmPostCntr = () => {
    if (!isValue(inputs.prjcNm)) {
      return showToast('프로젝트명을 입력해주세요.');
    } else if (!isValue(inputs.clntCoNm)) {
      return showToast('계약사 회사명을 입력해주세요.');
    } else if (!isValue(inputs.cntrAmnt)) {
      return showToast('계약금을 입력해주세요.');
    } else if (!isValue(inputs.cntrStDt)) {
      return showToast('계약기간을 입력해주세요.');
    }

    if (type === 'reg') {
      let totalSize = 0;
      for (let i = 0; i < dataFiles.length; i++) {
        totalSize += dataFiles[i].size;
      }
      console.log(totalSize);
      console.log(MAX_SIZE_BYTES);
      if (totalSize > MAX_SIZE_BYTES) {
        return showToast(`파일들의 총 크기는 ${MAX_SIZE_MB}MB를 초과할 수 없습니다.`);
      }

      setRegPop(true);
    } else {
      const totalSize = checkTotalFileSize(dataFiles, oriDataFiles, deletedFiles);
      if (totalSize > MAX_SIZE_BYTES) {
        return showToast(`파일들의 총 크기는 ${MAX_SIZE_MB}MB를 초과할 수 없습니다.`);
      }

      setRegPop(true);
    }
  };

  //등록
  const handlePostCntr = () => {
    if (type === 'reg') {
      dispatch(
        postCntr(
          convertFomData({
            regCntrRequest: {
              userId: user.userId,
              userSn: user.userSn,
              ...inputs,
              cntrStDt: setDateFormat(inputs.cntrStDt, 'YYYYMMDD'),
              cntrEndDt: setDateFormat(inputs.cntrEndDt, 'YYYYMMDD'),
              clntMngrTelno: inputs.clntMngrTelno === '' ? '' : inputs.clntMngrTelno.replace(/[^0-9]/g, ''),
              cntrAmnt: inputs.cntrAmnt === '' ? '' : inputs.cntrAmnt.replace(/[^0-9]/g, ''),
            },
            files: dataFiles,
          }),
          handlePostCntrCallback
        )
      );
    } else {
      const { memoList, fileInfoList, ...otherInputs } = inputs;

      dispatch(
        putCntr(
          convertFomData({
            updCntrRequest: {
              cntrSn: contractData.cntrSn,
              userId: user.userId,
              userSn: user.userSn,
              ...otherInputs,
              cntrStDt: setDateFormat(inputs.cntrStDt, 'YYYYMMDD'),
              cntrEndDt: setDateFormat(inputs.cntrEndDt, 'YYYYMMDD'),
              clntMngrTelno: inputs.clntMngrTelno === '' ? '' : inputs.clntMngrTelno.replace(/[^0-9]/g, ''),
              cntrAmnt: inputs.cntrAmnt === '' ? '' : inputs.cntrAmnt.replace(/[^0-9]/g, ''),
              fileSnList: deletedFiles,
            },
            files: dataFiles,
          }),
          handlePostCntrCallback
        )
      );
    }
  };

  const handlePostCntrCallback = (data) => {
    if (data.resultCode === 0) {
      setRegOpen(false);
      console.log(inputs);
      showToast(type === 'reg' ? '등록되었습니다.' : '계약 정보가 수정되었습니다.');
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError(data);
    }
  };

  const hanldeZipClick = () => {
    setIsOpen(true);
  };

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsOpen(false);
    setInputs({
      ...inputs,
      clntZip: zonecode,
      clntAddr: address,
    });
  };

  // 파일 크기를 사람이 읽을 수 있는 형식으로 변환하는 헬퍼 함수
  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    else if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(1)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setDataFiles([...dataFiles, ...droppedFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setDataFiles([...dataFiles, ...selectedFiles]);
  };

  useEffect(() => {
    if (inputs.fileInfoList) {
      const uploadedFiles = inputs.fileInfoList.map((file) => ({
        ...file,
        isUploaded: true,
      }));
      setOriDataFiles(uploadedFiles);
    }
  }, [inputs.fileInfoList]);

  const checkTotalFileSize = (files, oriFiles, deletedFiles) => {
    let totalSize = 0;

    files.forEach((file) => {
      totalSize += file.size;
    });

    oriFiles.forEach((file) => {
      if (!isFileDeleted(file, deletedFiles)) {
        totalSize += file.fileSize;
      }
    });

    return totalSize;
  };

  const handleFileDelete = (fileIndex, isUploadedFile) => {
    if (isUploadedFile) {
      const fileToDelete = oriDataFiles[fileIndex];
      setDeletedFiles((prevDeleted) => [...prevDeleted, fileToDelete.fileSn]);
      setInputs((prevInputs) => ({
        ...prevInputs,
        fileInfoList: prevInputs.fileInfoList.filter((_, i) => i !== fileIndex),
      }));
    } else {
      const updatedFiles = dataFiles.filter((_, index) => index !== fileIndex);
      setDataFiles(updatedFiles);
    }
  };

  const handleLoadProject = () => {
    setIsProjectOpen(true);
  };

  const handleLoadSales = () => {
    setIsSalesOpen(true);
  };

  const hanldeChangeCntrStts = () => {
    setIsDeletePop(true);
  };

  const sttsChip = (stts) => {
    if (stts === '0') {
      return '계약 준비중';
    } else if (stts === '1') {
      return '계약 진행중';
    } else if (stts === '2') {
      return '계약 종료';
    } else {
      return '계약 중단';
    }
  };
  const prjcData = type === 'reg' ? preMemo : contractData.memoList;
  const [deletedMemoSnList, setDeletedMemoSnList] = useState([]);
  const handleDeleteMemo = (memoSn) => {
    const updatedPrjcData = prjcData.filter((item) =>
      type === 'reg' ? item.salesMemoSn !== memoSn : item.memoSn !== memoSn
    );
    console.log(memoSn);
    if (type === 'reg') {
      setPrevMemo(updatedPrjcData);
      setInputs((prevInputs) => ({
        ...prevInputs,
        memoSnList: updatedPrjcData.map((item) => item.salesMemoSn),
      }));
    } else {
      contractData.memoList = updatedPrjcData;
      // setInputs((prevInputs) => ({
      //   ...prevInputs,
      //   memoSnList: contractData.memoList.filter((sn) => sn.memoSn !== memoSn).map((item) => item.memoSn),
      // }));

      setDeletedMemoSnList((prevDeleted) => [...prevDeleted, memoSn]);

      setInputs((prevInputs) => ({
        ...prevInputs,
        memoSnList: [...deletedMemoSnList, memoSn],
      }));
    }
  };

  //계약 중단
  const handleDeleteCntrStts = () => {
    dispatch(
      delCntrStts({
        userId: user.userId,
        userSn: user.userSn,
        stts: '3',
        cntrSnList: [contractData.cntrSn],
        callback: handleDelCntrSttsCallback,
      })
    );
  };

  const handleDelCntrSttsCallback = (data) => {
    if (data.resultCode === 0) {
      setDeletePop(true);
    } else {
      showToastError(data);
    }
  };

  const handleDelCntrCallback = () => {
    setIsSuccessYn(!isSuccessYn);
    setIsDeletePop(false);
    setDeletePop(false);
    setRegOpen(false);
  };

  console.log(type);
  return (
    <Dialog open={regOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title">
        {/* <Typography variant="h6" component={'div'} fontWeight={'bold'}>
          프로젝트명을 입력해주세요
        </Typography> */}
        {type === 'upd' && <Chip label={sttsChip(contractData.stts)} variant="outlined" />}

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
          <TextField
            name="prjcNm"
            className="modal-tit2 rqd"
            size="medium"
            fullWidth
            placeholder="프로젝트명을 입력해주세요"
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
            value={inputs.prjcNm ? inputs.prjcNm : ''}
            sx={{
              width: '80%',
              border: 'none',
              '& fieldset': { border: 'none' },
              input: {
                '&::placeholder': {
                  fontSize: '1.5rem',
                  color: '#000000',
                  fontWeight: 'bold',
                },
                fontSize: '1.5rem',
              },
            }}
          />
          {type === 'reg' ? (
            <Button type="button" className="btn-orange" onClick={handleLoadProject}>
              가져오기
            </Button>
          ) : type === 'upd' && (contractData.stts === '0' || contractData.stts === '1') ? (
            <>
              <IconButton color="error" onClick={hanldeChangeCntrStts}>
                <Block />
              </IconButton>
            </>
          ) : (
            ''
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Typography variant="subtitle2">계약 정보</Typography>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">계약금</Typography>
                <Typography variant="subtitle1" color={'red'}>
                  *
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="cntrAmnt"
                  variant="standard"
                  size="small"
                  onChange={handleInputChange}
                  value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                  fullWidth
                  inputProps={{
                    readOnly: contractData.stts === '2' || contractData.stts === '3' ? true : false,
                  }}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">계약기간</Typography>
                <Typography variant="subtitle1" color={'red'}>
                  *
                </Typography>
              </Grid>
              <Grid item md={8} className="inp-date">
                <DatePickerWrapper>
                  <DatePicker
                    locale={ko}
                    selected={inputs.cntrStDt}
                    onChange={handleDateChange}
                    startDate={inputs.cntrStDt}
                    endDate={inputs.cntrEndDt}
                    dateFormat={'YYYY-MM-dd'}
                    selectsRange
                    customInput={<ExampleCustomInput />}
                    disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                  />
                </DatePickerWrapper>
                {/* <TextField variant="standard" fullWidth /> */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="subtitle2" mr={1}>
              계약사 정보
            </Typography>
            <Button type="button" className="btn-orange" onClick={() => handleLoadSales()}>
              정보 가져오기
            </Button>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">회사코드</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="clntCoCode"
                  variant="standard"
                  size="small"
                  onChange={handleInputChange}
                  value={inputs.coCd ? inputs.coCd : ''}
                  fullWidth
                  inputProps={{
                    readOnly: contractData.stts === '2' || contractData.stts === '3' ? true : false,
                  }}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">회사명</Typography>
                <Typography variant="subtitle1" color={'red'}>
                  *
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="clntCoNm"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.clntCoNm ? inputs.clntCoNm : ''}
                  fullWidth
                  inputProps={{
                    readOnly: contractData.stts === '2' || contractData.stts === '3' ? true : false,
                  }}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">회사 주소</Typography>
              </Grid>
              <Grid item md={8} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item md={6}>
                  <TextField
                    name="clntZip"
                    size="small"
                    variant="standard"
                    onChange={handleInputChange}
                    value={inputs.clntZip ? inputs.clntZip : ''}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={5}>
                  <Button type="button" className="btn" onClick={hanldeZipClick}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  name="clntAddr"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  placeholder="기본 주소 입력"
                  value={inputs.clntAddr ? inputs.clntAddr : ''}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  name="clntDaddr"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  placeholder="상세 주소 입력"
                  value={inputs.clntDaddr ? inputs.clntDaddr : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">담당자</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="clntMngrNm"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.clntMngrNm ? inputs.clntMngrNm : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">담당자 연락처</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="clntMngrTelno"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.clntMngrTelno ? setTelFormat(inputs.clntMngrTelno, '-') : ''}
                  fullWidth
                  inputProps={{ maxLength: '13' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">담당자 이메일</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="clntMngrEmail"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.clntMngrEmail ? inputs.clntMngrEmail : ''}
                  fullWidth
                  inputProps={{ maxLength: '50' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <Box mt={5}>
          <Grid container alignItems={'center'}>
            <Typography variant="body1" mr={1}>
              개발사 정보
            </Typography>
            <Button type="button" color="warning" variant="outlined" onClick={() => handleLoadSales('dvlp')}>
              가져오기
            </Button>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4} display={"flex"} alignItems={"center"}>
                <Typography variant="subtitle2">회사명</Typography>
                <Typography variant="subtitle2" color={"red"}>
                  *
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpCoNm"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.dvlpCoNm ? inputs.dvlpCoNm : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4}>
                <Typography variant="subtitle2">회사 주소</Typography>
              </Grid>
              <Grid
                item
                md={8}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item md={6}>
                  <TextField
                    name="dvlpZip"
                    variant="standard"
                    onChange={handleInputChange}
                    value={inputs.dvlpZip ? inputs.dvlpZip : ""}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item md={5}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => hanldeZipClick("dvlp")}
                  >
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpAddr"
                  variant="standard"
                  placeholder="기본 주소 입력"
                  onChange={handleInputChange}
                  value={inputs.dvlpAddr ? inputs.dvlpAddr : ""}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpDaddr"
                  variant="standard"
                  placeholder="상세 주소 입력"
                  onChange={handleInputChange}
                  value={inputs.dvlpDaddr ? inputs.dvlpDaddr : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4}>
                <Typography variant="subtitle2">담당자</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpMngrNm"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.dvlpMngrNm ? inputs.dvlpMngrNm : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4}>
                <Typography variant="subtitle2">담당자 연락처</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpMngrTelno"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.dvlpMngrTelno ? inputs.dvlpMngrTelno : ""}
                  fullWidth
                  inputProps={{ maxLength: "13" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={"flex"} alignItems={"center"}>
              <Grid item md={4}>
                <Typography variant="subtitle2">담당자 이메일</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpMngrEmail"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.dvlpMngrEmail ? inputs.dvlpMngrEmail : ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Box> */}

        <Box mb={5}>
          <Typography variant="subtitle2">기타</Typography>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">영업 담당자</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="slsMngrNm"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  value={inputs.slsMngrNm ? inputs.slsMngrNm : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={contractData.stts === '3' ? 'center' : 'flex-start'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">첨부파일(최대50MB)</Typography>
              </Grid>
              <Grid item md={8}>
                {contractData.stts !== '3' && (
                  <Box component="section" className="file-Box" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <Backup />
                    <Typography variant="body2" ml={1}>
                      첨부할 파일을 끌어다 놓거나&nbsp;
                    </Typography>
                    <Button component="label">
                      찾아보기
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                  </Box>
                )}
                {inputs.fileInfoList &&
                  inputs.fileInfoList.map((file, index) => (
                    <Box display={'flex'} alignItems={'center'} key={`uploaded-${index}`}>
                      <Typography variant="body2">
                        {file.oriFileNm} ({formatFileSize(file.fileSize)})
                      </Typography>
                      {contractData.stts !== '3' && (
                        <IconButton onClick={() => handleFileDelete(index, true)}>
                          <Close fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                {dataFiles.map((file, index) => (
                  <Box display={'flex'} alignItems={'center'} key={`new-${index}`}>
                    <Typography variant="body2">
                      {file.name} ({formatFileSize(file.size)})
                    </Typography>
                    <IconButton onClick={() => handleFileDelete(index, false)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">의뢰 업무</Typography>
              </Grid>
              <Grid item md={8}>
                <Box component="div">
                  <TextField
                    name="rqstWork"
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                    value={inputs.rqstWork}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">메모</Typography>
              </Grid>
              <Grid item md={8}>
                {prjcData.length !== 0 &&
                  prjcData.map((item, index) => (
                    <Grid key={index} item md={12} mb={2}>
                      <Box component="div">
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography variant="body2" color={'gray'}>
                            {setDateFormat(item.regDttm, 'YYYY-MM-DD HH:mm')}
                          </Typography>
                          <IconButton onClick={() => handleDeleteMemo(type === 'reg' ? item.salesMemoSn : item.memoSn)}>
                            <Close color="error" sx={{ width: '14px', height: '14px' }} />
                          </IconButton>
                        </Box>
                        <Typography variant="body1">{item.memo}</Typography>
                      </Box>
                    </Grid>
                  ))}
                <Grid item md={12}>
                  <Box component="div">
                    <TextField name="memo" multiline rows={4} onChange={handleInputChange} fullWidth />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className="btn-white" onClick={() => setCancelPop(true)} sx={{ width: '100%' }}>
          취소
        </Button>
        <Button className="btn-navy" onClick={handleConfirmPostCntr} sx={{ width: '100%' }}>
          {type === 'reg' ? '등록' : '저장'}
        </Button>
      </DialogActions>
      {isOpen && <DaumPostCode isOpen={isOpen} setIsOpen={setIsOpen} handleZipSearch={handleZipSearch} />}
      {isProjectOpen && (
        <PageLoadProjectModal
          isOpen={isProjectOpen}
          setIsOpen={setIsProjectOpen}
          inputs={inputs}
          setInputs={setInputs}
          setPrevMemo={setPrevMemo}
        />
      )}
      {isSalesOpen && (
        <PageLoadSalesModal
          type={coType}
          isOpen={isSalesOpen}
          setIsOpen={setIsSalesOpen}
          inputs={inputs}
          setInputs={setInputs}
          title={'계약사 가져오기'}
          subTitle={'정산을 지급 해주는 업체를 선택해주세요.'}
        />
      )}
      {isDeletePop && (
        // <PageContractSttsChagneModal
        //   isOpen={isSttsChangeOpen}
        //   setIsOpen={setIsSttsChangeOpen}
        //   setRegOpen={setRegOpen}
        //   setIsSuccessYn={setIsSuccessYn}
        //   isSuccessYn={isSuccessYn}
        // />
        <ConfirmPopUp
          open={isDeletePop}
          close={setIsDeletePop}
          title={'잔여 계약 기간이 있습니다.\n즉시 계약 중단 처리 하시겠습니까?'}
          comment={'중단 후에는 되돌릴 수 없습니다.'}
          btnNm1={'아니요'}
          btnNm2={'예'}
          btnEvent={handleDeleteCntrStts}
        />
      )}
      {deletePop && (
        // <PageContractSttsChagneModal
        //   isOpen={isSttsChangeOpen}
        //   setIsOpen={setIsSttsChangeOpen}
        //   setRegOpen={setRegOpen}
        //   setIsSuccessYn={setIsSuccessYn}
        //   isSuccessYn={isSuccessYn}
        // />
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'계약 중단 처리 되었습니다.\n정산 목록을 확인해주세요.'}
          btnNm2={'확인'}
          btnEvent={handleDelCntrCallback}
        />
      )}
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={type === 'reg' ? '등록하시겠습니까?' : '저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={type === 'reg' ? '등록' : '저장'}
          btnEvent={handlePostCntr}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setRegOpen}
        />
      )}
    </Dialog>
  );
};

export default PageContractRegModal;
