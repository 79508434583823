import axios from 'axios';
import { resetState, setLoading } from '@actions/commonAction';
import { store } from '../../index';

const API_URL = '/';
/**
 * request 전 토큰 check
 * @param {*} config
 * @returns
 */
const beforeReqCheckToken = async (config) => {
  const userInfo = store.getState().commonReducer.user;
  // const {
  //   userReducer: {
  //     userInfo: { authKey },
  //   },
  // } = store.getState();
  // let authKey = 'authKeyauthKeyauthKeyauthKeyauthKeyauthKeyauthKey';
  config.headers['Authorization'] = `Bearer ${userInfo.token}`;
  return config;
};

const handleErrorResponse = async (e) => {
  const { config, response } = e;

  if (response.status === 403) {
    store.dispatch(resetState(true));
  }

  return Promise.reject(e);
};

const customAxios = axios.create({
  baseURL: API_URL,
  // headers: {
  //   Accept: 'application/json',
  //   'Content-Type': 'application/json;charset=UTF-8',
  //   Authorization: '',
  // },
});
customAxios.interceptors.request.use(beforeReqCheckToken);
customAxios.interceptors.response.use(
  (response) => response,
  (e) => handleErrorResponse(e)
);

export const sendAPI = async (method, url, params, payload, isLoading = true) => {
  //const user = store.getState().commonReducer.user;
  //const updatedPayload = user ? { ...payload, userSn: user.userSn, userId: user.userId } : payload;
  if (isLoading) store.dispatch(setLoading(true));
  try {
    const { status, data } = await customAxios({
      method,
      url,
      params,
      data: payload,
    });
    store.dispatch(setLoading(false));
    return { status, data };
  } catch (error) {
    const { status, data } = error.response;
    return { status, data };
  } finally {
    store.dispatch(setLoading(false));
  }
};

const fileAxios = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data;charset=UTF-8',
    Authorization: '',
  },
});
fileAxios.interceptors.request.use(beforeReqCheckToken);
fileAxios.interceptors.response.use(
  (response) => response,
  (e) => handleErrorResponse(e)
);

export const fileAPI = async (method, url, params, payload, isLoading = true) => {
  //const user = store.getState().commonReducer.user;
  //const updatedPayload = user ? { ...payload, userSn: user.userSn, userId: user.userId } : payload;
  if (isLoading) store.dispatch(setLoading(true));
  try {
    const { status, data } = await fileAxios({
      method,
      url,
      params,
      data: payload,
    });
    store.dispatch(setLoading(false));
    return { status, data };
  } catch (error) {
    const { status, data } = error.response;
    return { status, data };
  } finally {
    store.dispatch(setLoading(false));
  }
};

const downloadAxios = axios.create({
  baseURL: API_URL,
  responseType: 'blob',
});
downloadAxios.interceptors.request.use(beforeReqCheckToken);
downloadAxios.interceptors.response.use(
  (response) => response,
  (e) => handleErrorResponse(e)
);

export const downloadAPI = async (method, url, params, payload, isLoading = true) => {
  //const user = store.getState().commonReducer.user;
  //const updatedPayload = user ? { ...payload, userSn: user.userSn, userId: user.userId } : payload;
  if (isLoading) store.dispatch(setLoading(true));
  try {
    const { status, data } = await downloadAxios({
      method,
      url,
      params,
      data: payload,
    });
    store.dispatch(setLoading(false));
    return { status, data };
  } catch (error) {
    const { status, data } = error.response;
    return { status, data };
  } finally {
    store.dispatch(setLoading(false));
  }
};
