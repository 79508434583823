import * as actions from '@actions/commonAction';

const INIT_STATE = {
  user: {
    userId: '',
    userSn: '',
    userNm: '',
    userFlag: '',
    token: '',
    dvlpCoSn: '',
    authList: [],
    authMap: [],
    coNm: '',
    alarmCnt: 0,
  },
  notifyCount: 0,
  searchType: '',
  boolPopup: false,
  loading: false,
  projectSn: '',
  issueSn: '',
  isTokenOut: false,
};

const commonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.SET_USER_INIT:
      return INIT_STATE;
    case actions.SET_USER_INFO:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case actions.SET_USER_STATE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.GET_TOKEN_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case actions.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.payload,
      };
    case actions.SET_ALARM_CNT:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case actions.SET_BOOL_POPUP:
      return {
        ...state,
        boolPopup: action.payload,
      };
    case actions.RESET_STATE:
      return { ...INIT_STATE, isTokenOut: action.payload };
    case actions.MOVE_PROJECT:
      return {
        ...state,
        projectSn: action.payload,
      };
    case actions.MOVE_ISSUE:
      return {
        ...state,
        issueSn: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
