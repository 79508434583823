import { setTelFormat } from '@common/common';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { getInptPrsnDtl } from '@store/actions/projectAction';
import { ko } from 'date-fns/locale/ko';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageProjectInptPrsnDetail = (prop) => {
  const { detailPopup, setDetailPopup, inptPrsnSn } = prop;
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [resultData, setResultData] = useState({
    rank: '',
    position: '',
    dtlPosition: '',
    inptStartDt: '',
    inptEndDt: '',
    userNm: '',
    gender: '',
    mblNo1: '',
    mblNo2: '',
    age: '',
    taskCnt: '',
    email: '',
    coNm: '',
  });
  const fn_dtlResult = (data) => {
    if (data.resultCode === 0) {
      setResultData(data.data);
    }
  };
  useEffect(() => {
    dispatch(
      getInptPrsnDtl({
        inptPrsnSn: inptPrsnSn,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: fn_dtlResult,
      })
    );
  }, []);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      size="small"
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
    />
  ));

  return (
    <Dialog open={detailPopup} fullWidth maxWidth={'xs'} disableEnforceFocus>
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'end'}>
            <Typography
              className="modal-tit"
              sx={{
                border: 'none',
                '& fieldset': { border: 'none' },
                input: {
                  '&::placeholder': {
                    fontSize: '1.5rem',
                    color: '#000000',
                    fontWeight: 'bold',
                  },
                  fontSize: '1.5rem',
                },
                mr: 2,
              }}
            >
              {resultData.userNm}
            </Typography>
            <Typography color={'grey'} fontSize={'18px'}>
              {resultData.gender}({resultData.age}세)
            </Typography>
          </Box>
          <Close onClick={() => setDetailPopup(false)} sx={{ cursor: 'pointer' }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <Typography variant="subtitle2">개인정보</Typography>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">소속회사</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={resultData.coNm}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">직급</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={resultData.rank}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">연락처1</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={setTelFormat(resultData.mblNo1, '-')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">연락처2</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={setTelFormat(resultData.mblNo2, '-')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">이메일</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={resultData.email}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <Typography variant="subtitle2">프로젝트 정보</Typography>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">담당 직무</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={resultData.position}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">상세 직무</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={resultData.dtlPosition}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">투입 기간</Typography>
              </Grid>
              <Grid item xs={8}>
                <Box className="inp-date">
                  <DatePicker
                    locale={ko}
                    selected={resultData.inptStartDt}
                    startDate={resultData.inptStartDt}
                    endDate={resultData.inptEndDt}
                    dateFormat={'YYYY-MM-dd'}
                    selectsRange
                    customInput={<ExampleCustomInput />}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">맡은 업무</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{ readOnly: true }}
                  value={resultData.taskCnt + ' 건'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PageProjectInptPrsnDetail;
