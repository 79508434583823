import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Typography, ToggleButtonGroup, ToggleButton, Grid, TextField, Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postUserReg, postCoReg, getMblAuth, getMblCnfr } from '@store/actions/userAction';
import { isValue, isNumberExact, showToastError, isPassword, isId, isMobile, isYmd } from '@common/common';
import { AccessTime } from '@mui/icons-material';
import PageJoinRequestLayout from './PageJoinRequestLayout';

export default function PageJoinAccountMain() {
  const limitTime = 180;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [timer, setTimer] = useState(limitTime);
  const [intervalId, setIntervalId] = useState(null); // interval ID 저장할 상태

  const [errorMsg1, setErrorMsg1] = useState('');
  const [errorMsg2, setErrorMsg2] = useState('');
  const [errorMsg3, setErrorMsg3] = useState('');

  const { userFlag, bsnRegNo, rprNm, opnDt, coNm, rprNo, faxNo, zip, addr, daddr } = useSelector(
    (state) => state.userReducer.userJoin
  );

  const [inputs, setInputs] = useState({
    userId: '',
    userPw: '',
    userPwRe: '',
    userNm: '',
    brdt: '',
    mblNo: '',
    authNo: '',
    authResNo: '',
    userSex: 'M',
    isAuth: false,
    uuid: '',
    isAuthCompl: false,
  });

  useEffect(() => {
    if (!isValue(userFlag)) {
      navigate('/joinType');
    }
  }, []);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleTimer = () => {
    const time = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(time);
          setAuth(false);
          setInputs({
            ...inputs,
            uuid: '',
            authNo: '',
            authResNo: '',
          });
        }
        return prevTimer - 1;
      });
    }, 1000);
    setIntervalId(time); // interval ID를 상태에 저장
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let _value = '';

    if (name === 'opnDt') _value = setOnlyNumber(value);
    else _value = value;

    setInputs({
      ...inputs,
      [name]: _value,
    });
  };

  const handleValid = (e) => {
    const { name, value } = e.target;

    setErrorMsg1('');
    setErrorMsg2('');
    setErrorMsg3('');
    if (name == 'userId' && !isId(inputs.userId)) {
      setErrorMsg1('아이디: 8 ~ 16자의 영문, 숫자를 사용해주세요.');
    } else if (name == 'userPw' && !isPassword(inputs.userPw)) {
      setErrorMsg1(`비밀번호: 8 ~ 16자의 영문 대/소문자,숫자,특수문자 @$!%*^?&를 사용해주세요.`);
    } else if (name == 'userPwRe' && inputs.userPw !== inputs.userPwRe) {
      setErrorMsg1('비밀번호 확인: 비밀번호가 올바르지 않습니다.');
    } else if (name == 'userNm' && !isValue(inputs.userNm)) {
      setErrorMsg2('이름: 입력하신 내용울 확인해주세요.');
    } else if (name == 'brdt' && !isYmd(inputs.brdt)) {
      setErrorMsg2('생년월일: 입력하신 내용을 확인해주세요.');
    }
  };

  const successValid = () => {
    if (!isId(inputs.userId)) {
      setErrorMsg1('아이디: 8 ~ 16자의 영문, 숫자를 사용해주세요.');
      return false;
    } else if (!isPassword(inputs.userPw)) {
      setErrorMsg1(`비밀번호: 8 ~ 16자의 영문 대/소문자,숫자,특수문자 @$!%*^?&를 사용해주세요.`);
      return false;
    } else if (inputs.userPw !== inputs.userPwRe) {
      setErrorMsg1('비밀번호 확인: 비밀번호가 올바르지 않습니다.');
      return false;
    } else if (!isValue(inputs.userNm)) {
      setErrorMsg2('이름: 입력하신 내용울 확인해주세요.');
    } else if (!isYmd(inputs.brdt)) {
      setErrorMsg2('생년월일: 입력하신 내용을 확인해주세요.');
      return false;
    } else if (!isMobile(inputs.mblNo)) {
      setErrorMsg2('휴대폰번호: 입력하신 내용을 확인해주세요.');
      return false;
    } else if (!inputs.isAuthCompl) {
      setErrorMsg3('휴대폰인증: 휴대폰 인증을 확인해주세요.');
      return false;
    }
    return true;
  };

  const handleGenderChange = (event, newValue) => {
    if (newValue !== null) {
      setInputs({
        ...inputs,
        userSex: newValue,
      });
    }
  };

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null); // interval ID 초기화
    }
  };

  const handleAuthRequest = () => {
    if (!isMobile(inputs.mblNo)) {
      setErrorMsg2('휴대폰번호: 입력하신 내용을 확인해주세요.');
    } else {
      resetInterval();
      setInputs({
        ...inputs,
        uuid: '',
        authNo: '',
        authResNo: '',
        isAuth: false,
        isAuthCompl: false,
      });
      dispatch(
        getMblAuth({
          mblNo: inputs.mblNo,
          regIp: '192.169.0.72',
          callback: handleAuthCallback,
        })
      );
    }
  };

  const handleAuthCallback = (data, status) => {
    if (data.resultCode === 0) {
      console.log('handleAuthCallback', data, data.data.authNo);
      setInputs({
        ...inputs,
        uuid: data.data.uuid,
        authResNo: data.data.authNo,
        isAuth: true,
      });
      handleTimer();
      setAuth(!auth);
      setTimer(limitTime);
    } else {
      showToastError(data);
    }
  };

  const handleSuccRequest = () => {
    if (successValid()) {
      if (userFlag == 'E') {
        dispatch(
          postUserReg({
            userFlag: userFlag,
            brdt: inputs.brdt,
            mblNo: inputs.mblNo,
            userId: inputs.userId,
            userNm: inputs.userNm,
            userPw: inputs.userPw,
            userSex: inputs.userSex,
            callback: handleSuccCallback,
          })
        );
      } else {
        dispatch(
          postCoReg({
            bsnRegNo,
            coNm,
            zip,
            addr,
            daddr,
            faxNo,
            opnDt,
            rprNm,
            rprNo,
            userFlag: userFlag,
            brdt: inputs.brdt,
            mblNo: inputs.mblNo,
            userId: inputs.userId,
            userNm: inputs.userNm,
            userPw: inputs.userPw,
            userSex: inputs.userSex,
            callback: handleSuccCallback,
          })
        );
      }
    }
  };

  const handleSuccCallback = (data, status) => {
    if (data.resultCode === 0) {
      navigate('/joinSucc');
    } else {
      showToastError(data, '0.9em');
    }
  };

  const handleComplRequest = () => {
    if (!isNumberExact(inputs.authNo, 6)) {
      setErrorMsg3('인증번호 숫자 6자리를 입력해주세요.', 'warning');
    } else if (inputs.authNo != inputs.authResNo) {
      setErrorMsg3('유효한 인증번호가 아닙니다.', 'warning');
    } else {
      dispatch(
        getMblCnfr({
          uuid: inputs.uuid,
          authNo: inputs.authNo,
          callback: handleAuthComplCallback,
        })
      );
    }
  };

  const handleAuthComplCallback = (data, status) => {
    if (data.resultCode === 0) {
      resetInterval();
      setInputs({
        ...inputs,
        uuid: '',
        authResNo: '',
        isAuthCompl: true,
      });
    } else {
      showToastError(data);
    }
  };

  return (
    <PageJoinRequestLayout
      title={'계정 정보'}
      titleBlack={'입력'}
      content={'계정 정보를 입력하고 휴대폰 인증을 진행해주세요'}
    >
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          sx={{ mt: 3 }}
          margin="normal"
          required
          fullWidth
          placeholder="아이디"
          autoComplete="off"
          autoFocus
          name="userId"
          value={inputs.userId}
          onChange={handleChange}
          onBlur={handleValid}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          required
          fullWidth
          placeholder="비밀번호"
          autoComplete="off"
          name="userPw"
          value={inputs.userPw}
          onChange={handleChange}
          onBlur={handleValid}
          type="password"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          margin="dense"
          required
          fullWidth
          placeholder="비밀번호 확인"
          autoComplete="off"
          name="userPwRe"
          value={inputs.userPwRe}
          onChange={handleChange}
          onBlur={handleValid}
          type="password"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {isValue(errorMsg1) && (
          <Typography variant="caption" color={'red'}>
            {errorMsg1}
          </Typography>
        )}
      </Grid>
      {/*
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="caption" color={'red'}>
          비밀번호: 8 ~ 16자의 영문 대/소문자,숫자,특수문자를 사용해주세요.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="caption" color={'red'}>
          비밀번호 확인: 비밀번호가 올바르지 않습니다.
        </Typography>
      </Grid>
      */}
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          sx={{ mt: 3 }}
          margin="dense"
          required
          fullWidth
          placeholder="이름"
          autoComplete="off"
          name="userNm"
          value={inputs.userNm}
          onChange={handleChange}
          onBlur={handleValid}
        />
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={8} md={8}>
          <TextField
            margin="dense"
            required
            fullWidth
            placeholder="생년월일(YYYYMMDD 형식의 숫자 8자리)"
            autoComplete="off"
            name="brdt"
            value={inputs.brdt}
            onChange={handleChange}
            onBlur={handleValid}
            sx={{ '& input::placeholder': { fontSize: '12px' } }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <ToggleButtonGroup
            sx={{ mt: 1 }}
            color="primary"
            value={inputs.userSex}
            exclusive
            onBlur={handleValid}
            onChange={handleGenderChange}
            aria-label="Gender"
            fullWidth
          >
            <ToggleButton value="M">남자</ToggleButton>
            <ToggleButton value="F">여자</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <TextField
          margin="dense"
          required
          fullWidth
          placeholder="휴대폰번호('-'제외 숫자 11자리)"
          name="mblNo"
          autoComplete="off"
          onChange={handleChange}
          onBlur={handleValid}
          value={inputs.mblNo}
          sx={{ '& input::placeholder': { fontSize: '13px' } }}
          InputProps={{
            endAdornment: (
              <>
                <Chip
                  icon={<AccessTime />}
                  label={formatTime(timer)}
                  variant="outlined"
                  sx={{ mr: 1, display: !inputs.isAuth ? 'none' : '' }}
                />
                <Button variant="outlined" onClick={handleAuthRequest} disabled={auth} sx={{ width: '40%' }}>
                  인증번호
                </Button>
              </>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {isValue(errorMsg2) && (
          <Typography variant="caption" color={'red'}>
            {errorMsg2}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ display: !auth ? 'none' : '' }}>
        <TextField
          margin="dense"
          required
          fullWidth
          placeholder="인증번호 숫자 6자리"
          autoComplete="off"
          name="authNo"
          value={inputs.authNo}
          onChange={handleChange}
          onBlur={handleValid}
          InputProps={{
            endAdornment: (
              <Button
                variant="outlined"
                color="info"
                sx={{ width: '40%' }}
                onClick={handleComplRequest}
                disabled={inputs.isAuthCompl}
              >
                인증
              </Button>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {isValue(errorMsg3) && (
          <Typography variant="caption" color={'red'}>
            {errorMsg3}
          </Typography>
        )}
      </Grid>

      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleSuccRequest}
        disabled={!inputs.isAuthCompl}
      >
        회원가입 완료
      </Button>
    </PageJoinRequestLayout>
  );
}
