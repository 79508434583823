import React, { useState, useEffect, useRef } from "react";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const treeData = [
  {
    key: "0-0",
    title: "parent 1",
    children: [
      {
        key: "0-0-0",
        title: "parent 1-1",
        children: [{ key: "0-0-0-0", title: "parent 1-1-0" }],
      },
      {
        key: "0-0-1",
        title: "parent 1-2",
        children: [
          { key: "0-0-1-0", title: "parent 1-2-0", disableCheckbox: true },
          { key: "0-0-1-1", title: "parent 1-2-1" },
          { key: "0-0-1-2", title: "parent 1-2-2" },
          { key: "0-0-1-3", title: "parent 1-2-3" },
          { key: "0-0-1-4", title: "parent 1-2-4" },
          { key: "0-0-1-5", title: "parent 1-2-5" },
          { key: "0-0-1-6", title: "parent 1-2-6" },
          { key: "0-0-1-7", title: "parent 1-2-7" },
          { key: "0-0-1-8", title: "parent 1-2-8" },
          { key: "0-0-1-9", title: "parent 1-2-9" },
          { key: 1128, title: 1128 },
        ],
      },
    ],
  },
];

const PageTestTreeModal = ({ keys = ["0-0"], treeOpen, setTreeOpen }) => {
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState(keys);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(keys);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState(keys);
  const treeRef = useRef(null);
  const selKeyRef = useRef(null);

  const onExpand = (expandedKeys) => {
    console.log("onExpand", expandedKeys);
  };

  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
    selKeyRef.current = info.node.props.eventKey;
  };

  const onCheck = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
  };

  const onEdit = () => {
    setTimeout(() => {
      console.log("current key: ", selKeyRef.current);
    }, 0);
  };

  const onDel = (e) => {
    if (!window.confirm("sure to delete?")) {
      return;
    }
    e.stopPropagation();
  };

  return (
    <Dialog open={treeOpen} fullWidth maxWidth={"xs"} disableEnforceFocus>
      <DialogTitle>
        <Typography className="modal-tit">업무 삭제</Typography>
        <Typography color={"grey"} variant="body2" mt={1}>
          그룹 또는 상위 업무 삭제 시 하위에 속한 업무도 함께 삭제됩니다.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <h2>Check on Click TreeNode</h2>
        <Tree
          className="myCls"
          showLine
          checkable
          selectable={false}
          defaultExpandAll
          onExpand={onExpand}
          defaultSelectedKeys={defaultSelectedKeys}
          defaultCheckedKeys={defaultCheckedKeys}
          onSelect={onSelect}
          onCheck={onCheck}
          treeData={treeData}
        />

        <button
          type="button"
          onClick={() => {
            setTimeout(() => {
              console.log("scroll!!!");
              treeRef.current.scrollTo({ key: "0-0-1-9" });
            }, 100);
          }}
        >
          Scroll Last
        </button>
        {/*  </div> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTreeOpen(false)} className="btn-white">
          취소
        </Button>
        <Button className="btn-navy">N건 삭제</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PageTestTreeModal;
