import { fileAPI, sendAPI } from '@axios/index';

const getDashboardInfoD = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-dashboard-info-d`, params, payload);
};

const getDashboardInfoE = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-dashboard-info-e`, params, payload);
};

const api = {
  getDashboardInfoD,
  getDashboardInfoE,
};

export default api;
