export const GET_PRJC_MAIN_LIST = 'GET_PRJC_MAIN_LIST';
export const PUT_PRJC_MAIN_FVRT_YN = 'PUT_PRJC_MAIN_FVRT_YN';
export const GET_CNTR_NL_LIST = 'GET_CNTR_NL_LIST';
export const GET_PRJC_EVENT_LIST = 'GET_PRJC_EVENT_LIST';
export const GET_PRJC_DTL = 'GET_PRJC_DTL';
export const POST_PRJC = 'POST_PRJC';
export const PUT_PRJC_MAIN = 'PUT_PRJC_MAIN';
export const PUT_PRJC_MAIN_DEL_YN = 'PUT_PRJC_MAIN_DEL_YN';

export const SET_PRJC_DVLP_INIT = 'SET_PRJC_DVLP_INIT';
export const SET_PRJC_DVLP_DTL_INIT = 'SET_PRJC_DVLP_DTL_INIT';
export const SET_PRJC_TYPE = 'SET_PRJC_TYPE';
export const GET_PRJC_DVLP_DTL_SUCCESS = 'GET_PRJC_DVLP_DTL_SUCCESS';
export const GET_PRJC_DVLP_SUCCESS = 'GET_PRJC_DVLP_SUCCESS';
export const GET_UPD_PRJC_DVLP_SUCCESS = 'GET_UPD_PRJC_DVLP_SUCCESS';
export const DELETE_PRJC_DVLP_INFO = 'DELETE_PRJC_DVLP_INFO';
export const UPDATE_PRJC_DVLP = 'UPDATE_PRJC_DVLP';
export const SET_PRJC_DTL = 'SET_PRJC_DTL';
export const SET_PRJC_INFO = 'SET_PRJC_INFO';

export const GET_PRJC_MAIN_INFO = 'GET_PRJC_MAIN_INFO';
export const GET_PRJC_GRP_LIST = 'GET_PRJC_GRP_LIST';
export const GET_PRJC_GRP_DTL = 'GET_PRJC_GRP_DTL';
export const POST_PRJC_GRP = 'POST_PRJC_GRP';
export const PUT_PRCJ_GRP = 'PUT_PRCJ_GRP';
export const GET_PRNT_PRJC_TASK_LIST = 'GET_PRNT_PRJC_TASK_LIST';
export const POST_PRJC_TASK = 'POST_PRJC_TASK';
export const GET_PRJC_TASK_INFO = 'GET_PRJC_TASK_INFO';
export const GET_PRJC_TASK_LIST = 'GET_PRJC_TASK_LIST';
export const GET_PRJC_TASK_NM_LIST = 'GET_PRJC_TASK_NM_LIST';
export const GET_PRJC_TASK_DTL = 'GET_PRJC_TASK_DTL';
export const PUT_PRCJ_TASK = 'PUT_PRCJ_TASK';
export const PUT_PRCJ_TASK_LIST = 'PUT_PRCJ_TASK_LIST';
export const GET_PRJC_TASK_PRSN_DTL = 'GET_PRJC_TASK_PRSN_DTL';

export const POST_PRJC_EVENT = 'POST_PRJC_EVENT';
export const PUT_PRJC_EVENT = 'PUT_PRJC_EVENT';
export const DEL_PRJC_EVENT = 'DEL_PRJC_EVENT';

export const GET_INPT_PRSN_LIST = 'GET_INPT_PRSN_LIST';
export const POST_INPT_PRSN = 'POST_INPT_PRSN';

export const DEL_INPT_PRSN = 'DEL_INPT_PRSN';
export const GET_INPT_PRSN_DTL = 'GET_INPT_PRSN_DTL';

export const GET_INPT_PRSN_UPD_DTL = 'GET_INPT_PRSN_UPD_DTL';
export const PUT_INPT_PRSN = 'PUT_INPT_PRSN';

export const GET_ISSUE_LIST = 'GET_ISSUE_LIST';
export const POST_ISSUE = 'POST_ISSUE';
export const DEL_ISSUE = 'DEL_ISSUE';
export const GET_ISSUE_DTL = 'GET_ISSUE_DTL';
export const POST_ISSUE_CMT = 'POST_ISSUE_CMT';
export const PUT_ISSUE_STTS = 'PUT_ISSUE_STTS';
export const PUT_ISSUE = 'PUT_ISSUE';

export const SET_PREV_STTS = 'SET_PREV_STTS';

export const PUT_INPT_PRSN_STOP = 'PUT_INPT_PRSN_STOP';

export const POST_PRJC_TASK_LIST_EXCEL = 'POST_PRJC_TASK_LIST_EXCEL';

export const GET_INPT_PRSN_CHECK = 'GET_INPT_PRSN_CHECK';

export const GET_PRJC_MAIN_NM_LIST = 'GET_PRJC_MAIN_NM_LIST';
export const POST_CLNT_PRJC = 'POST_CLNT_PRJC';
export const PUT_CLNT_PRJC = 'PUT_CLNT_PRJC';

export const getPrjcMainList = (payload) => {
  return { type: GET_PRJC_MAIN_LIST, payload };
};

export const putPrjcMainFvrtYn = (payload) => {
  return { type: PUT_PRJC_MAIN_FVRT_YN, payload };
};

export const getCntrNmList = (payload) => {
  return { type: GET_CNTR_NL_LIST, payload };
};

export const getPrjcEventList = (payload) => {
  return { type: GET_PRJC_EVENT_LIST, payload };
};

export const getPrjcDtl = (payload) => {
  return { type: GET_PRJC_DTL, payload };
};

export const setPrjcDvlpInit = (payload) => {
  return { type: SET_PRJC_DVLP_INIT, payload };
};

export const setPrjcDvlpDtlInit = (payload) => {
  return { type: SET_PRJC_DVLP_DTL_INIT, payload };
};

export const getPrjcDvlpSuccess = (payload) => {
  return { type: GET_PRJC_DVLP_SUCCESS, payload };
};

export const getUpdPrjcDvlpSuccess = (payload) => {
  return { type: GET_UPD_PRJC_DVLP_SUCCESS, payload };
};

export const deletePrjcDvlpInfo = (payload) => {
  return { type: DELETE_PRJC_DVLP_INFO, payload };
};

export const getPrjcDvlpDtlSuccess = (payload) => {
  return { type: GET_PRJC_DVLP_DTL_SUCCESS, payload };
};

export const setPrjcInfo = (payload) => {
  return { type: SET_PRJC_INFO, payload };
};

export const updatePrjcDvlpInfo = (payload) => {
  return { type: UPDATE_PRJC_DVLP, payload };
};

export const postPrjc = (payload) => {
  return { type: POST_PRJC, payload };
};

export const putPrjcMain = (payload) => {
  return { type: PUT_PRJC_MAIN, payload };
};

export const putPrjcMainDelYn = (payload) => {
  return { type: PUT_PRJC_MAIN_DEL_YN, payload };
};

export const getPrjcMainInfo = (payload) => {
  return { type: GET_PRJC_MAIN_INFO, payload };
};

export const getPrjcGrpList = (payload) => {
  return { type: GET_PRJC_GRP_LIST, payload };
};

export const getPrjcGrpDtl = (payload) => {
  return { type: GET_PRJC_GRP_DTL, payload };
};

export const postPrjcGrp = (payload) => {
  return { type: POST_PRJC_GRP, payload };
};

export const putPrjcGrp = (payload) => {
  return { type: PUT_PRCJ_GRP, payload };
};

export const getPrntPrjcTaskList = (payload) => {
  return { type: GET_PRNT_PRJC_TASK_LIST, payload };
};

export const postPrjcTask = (payload) => {
  return { type: POST_PRJC_TASK, payload };
};

export const getPrjcTaskInfo = (payload) => {
  return { type: GET_PRJC_TASK_INFO, payload };
};

export const getPrjcTaskList = (payload) => {
  return { type: GET_PRJC_TASK_LIST, payload };
};

export const getPrjcTaskNmList = (payload) => {
  return { type: GET_PRJC_TASK_NM_LIST, payload };
};

export const getPrjcTaskDtl = (payload) => {
  return { type: GET_PRJC_TASK_DTL, payload };
};

export const putPrjcTask = (payload) => {
  return { type: PUT_PRCJ_TASK, payload };
};

export const putPrjcTaskList = (payload) => {
  return { type: PUT_PRCJ_TASK_LIST, payload };
};

export const getPrjcTaskPrsnDtl = (payload) => {
  return { type: GET_PRJC_TASK_PRSN_DTL, payload };
};

export const postPrjcEvent = (payload) => {
  return { type: POST_PRJC_EVENT, payload };
};

export const putPrjcEvent = (payload) => {
  return { type: PUT_PRJC_EVENT, payload };
};

export const delPrjcEvent = (payload) => {
  return { type: DEL_PRJC_EVENT, payload };
};

export const getInptPrsnList = (payload) => {
  return { type: GET_INPT_PRSN_LIST, payload };
};

export const postInptPrsn = (payload) => {
  return { type: POST_INPT_PRSN, payload };
};

export const delInptPrsn = (payload) => {
  return { type: DEL_INPT_PRSN, payload };
};

export const getInptPrsnDtl = (payload) => {
  return { type: GET_INPT_PRSN_DTL, payload };
};

export const getInptPrsnUpdDtl = (payload) => {
  return { type: GET_INPT_PRSN_UPD_DTL, payload };
};

export const putInptPrsn = (payload) => {
  return { type: PUT_INPT_PRSN, payload };
};

export const getIssueList = (payload) => {
  return { type: GET_ISSUE_LIST, payload };
};

export const postIssue = (payload, callback) => {
  return { type: POST_ISSUE, payload: { payload, callback } };
};

export const delIssue = (payload) => {
  return { type: DEL_ISSUE, payload };
};

export const getIssueDtl = (payload) => {
  return { type: GET_ISSUE_DTL, payload };
};

export const postIssueCmt = (payload) => {
  return { type: POST_ISSUE_CMT, payload };
};

export const putIssueStts = (payload) => {
  return { type: PUT_ISSUE_STTS, payload };
};

export const putIssue = (payload, callback) => {
  return { type: PUT_ISSUE, payload: { payload, callback } };
};

export const setPrevStts = (payload) => {
  return { type: SET_PREV_STTS, payload };
};

export const putInptPrsnStop = (payload) => {
  return { type: PUT_INPT_PRSN_STOP, payload };
};

export const postPrjcTaskListDownload = (payload) => {
  return { type: POST_PRJC_TASK_LIST_EXCEL, payload };
};

export const getInptPrsnCheck = (payload) => {
  return { type: GET_INPT_PRSN_CHECK, payload };
};

export const getPrjcMainNmList = (payload) => {
  return { type: GET_PRJC_MAIN_NM_LIST, payload };
};

export const postClntPrjc = (payload) => {
  return { type: POST_CLNT_PRJC, payload };
};

export const putClntPrjc = (payload) => {
  return { type: PUT_CLNT_PRJC, payload };
};
