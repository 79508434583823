import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserJoin } from '@store/actions/userAction';
import { isValue, setOnlyNumber , isTel, isNumber } from '@common/common';
import PageJoinRequestLayout from './PageJoinRequestLayout';
import DaumPostcode from 'react-daum-postcode';

export default function PageJoinBizMain() {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [compl, setCompl] = useState(true);
  
  const userFlag = useSelector((state) => state.userReducer.userJoin.userFlag);

  const [inputs, setInputs] = useState({
    coNm: '',
    rprNo: '',
    faxNo: '',
  });


  useEffect(() => {
    if(!isValue(userFlag)) {
      navigate('/joinType');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let _value = '';

    if(name === 'rprNo') _value =  setOnlyNumber(value)
    else if(name === 'faxNo') _value =  setOnlyNumber(value)
    else _value = value

    setInputs({
      ...inputs,
      [name]: _value
    });

  };

  const handleZipClick = () => {
    setIsOpen(true);
  };

  const [zonecode, setZonecode] = useState('');
  const [address, setAddress] = useState('');
  const [daddr, setDaddr] = useState('');

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsOpen(false);
    setZonecode(zonecode);
    setAddress(address);
  };

  const handleZipCode = (e) => {
    const { name, value } = e.target;

    if(name === 'zip') {
      setZonecode(setOnlyNumber(value));
    } else if(name === 'addr') {
      setAddress(value)
    } else {
      setDaddr(value)
    }

    if(name === 'daddr' && isValue(value)) {
      setCompl(false);
    }
    
  }

  const handleValid = (e) => {
    const { name, value } = e.target;

    setErrorMsg('');
    if (name == 'coNm' && !isValue(inputs.coNm)) {
      setErrorMsg('회사명을 확인해주세요.');
    } else if (name == 'rprNo' && !isTel(inputs.rprNo)) {
      setErrorMsg('대표번호를 확인해주세요.');
    } else if (name == 'faxNo' && !isTel(inputs.faxNo)) {
      setErrorMsg('팩스번호를 확인해주세요.');
    } else if (name == 'zip' && !isNumber(zonecode)) {
      setErrorMsg('우편번호를 확인해주세요.');
    } else if (name == 'addr' && !isValue(address)) {
      setErrorMsg('기본 주소를 확인해주세요.');
    } else if (name == 'daddr' && !isValue(daddr)) {
      setErrorMsg('상세 주소를 확인해주세요.');
    } 
  }

  const handleNext = () => {
    setErrorMsg('');
    if (!isValue(inputs.coNm)) {
      setErrorMsg('회사명을 확인해주세요.');
    } else if (!isTel(inputs.rprNo)) {
      setErrorMsg('대표번호를 확인해주세요.');
    } else if (!isTel(inputs.faxNo)) {
      setErrorMsg('팩스번호를 확인해주세요.');
    } else if (!isNumber(zonecode)) {
      setErrorMsg('우편번호를 확인해주세요.');
    } else if (!isValue(address)) {
      setErrorMsg('기본 주소를 확인해주세요.');
    } else if (!isValue(daddr)) {
      setErrorMsg('상세 주소를 확인해주세요.');                  
    } else {
      dispatch(
        setUserJoin({
          coNm: inputs.coNm,
          rprNo: inputs.rprNo,
          faxNo: inputs.faxNo,
          zip: zonecode,
          addr: address,
          daddr: daddr
        })
      );
      navigate('/joinAccount');
    }
  };

  /*
  const postModal = {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
  };
  */

  return (
    <PageJoinRequestLayout title={'회사 정보'} titleBlack={'입력'} content={'회사에 대한 기본 정보를 입력해주세요.'}>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          sx={{ mt: 3 }}
          margin="normal"
          required
          fullWidth
          placeholder="회사명"
          autoComplete="off"
          autoFocus
          name="coNm"
          value={inputs.coNm}
          onChange={handleChange}
          onBlur={handleValid}
        />
      </Grid>
      {/* TextField */}
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          fullWidth
          required
          placeholder="대표번호('-'없이 숫자만 입력)"
          name="rprNo"
          autoComplete="off"
          value={inputs.rprNo}
          onChange={handleChange}
          onBlur={handleValid}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          margin="dense"
          required
          fullWidth
          placeholder="팩스번호('-'없이 숫자만 입력)"
          name="faxNo"
          autoComplete="off"
          onChange={handleChange}
          value={inputs.faxNo}
          onBlur={handleValid}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          sx={{ mt: 3 }}
          margin="dense"
          required
          fullWidth
          placeholder="우편번호"
          name="zip"
          autoComplete="off"
          onChange={handleZipCode}
          onBlur={handleValid}
          value={zonecode ? zonecode : ''}
          InputProps={{
            endAdornment: (
              <Button variant="outlined" color="info" sx={{ width: '50%' }} onClick={handleZipClick}>
                우편번호 검색
              </Button>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          margin="dense"
          required
          fullWidth
          placeholder="기본 주소"
          name="addr"
          autoComplete="off"
          onChange={handleZipCode}
          onBlur={handleValid}
          value={address ? address : ''}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          margin="dense"
          required
          fullWidth
          placeholder="상세 주소 입력"
          name="daddr"
          autoComplete="off"
          onChange={handleZipCode}
          onBlur={handleValid}
          value={daddr}
        />
      </Grid>
      { errorMsg && (<Typography variant="caption" color={'red'}>{errorMsg}</Typography>)}
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleNext}
        disabled={compl}
      >
        다음
      </Button>
      <Dialog open={isOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
        <DialogTitle id="scroll-dialog-title">우편번호 검색</DialogTitle>
        <DialogContent>
          <DaumPostcode onComplete={handleZipSearch} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>닫기</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </PageJoinRequestLayout>
  );
}
