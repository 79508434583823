import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';
import { AddBox, CalendarMonth, IndeterminateCheckBox } from '@mui/icons-material';
import PageLoadTargetModal from './PageLoadTargetModal';
import PageLoadContractModal from '../contract/PageLoadContractModal';
import PageLoadProjectModal from '../project/PageLoadProjectModal';
import PageLoadSalesModal from '../contract/PageLoadSalesModal';
import ConfirmPopUp from 'component/ConfirmPopUp';
import PagePaymentRegDetailModal from './PagePaymentRegDetailModal';
import { DatePickerRound, isValue, setDateFormat, showToast, showToastError } from '@common/common';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { postPayment } from '@store/actions/paymentAction';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PagePaymentReg = (props) => {
  const { isOpen, setIsOpen, isSuceess, setIsSuccess } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  const [toggleValue, setToggleValue] = useState('S');
  const [selectedDays, setSelectedDays] = useState('');
  const [days, setDays] = useState([]);
  const [settlementAmount, setSettlementAmount] = useState({
    index: '',
    amountList: [],
  });
  const [originalPrsnList, setOriginalPrsnList] = useState([]);

  const [cancelPop, setCancelPop] = useState(false);
  const [isPrjcOpen, setIsPrjcOpen] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);
  const [isTargetOpen, setIsTargetOpen] = useState(false);
  const [isPaymentDetailOpen, setIsPaymentDetailOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [sendData, setSendData] = useState({
    cntrStDt: '',
    cntrEndDt: '',
    prjcNm: '',
    coNm: '',
    coCd: '',
    cntrSn: '',
  });
  const [monthData, setMonthData] = useState({
    stYearMonth: '',
    endYearMonth: '',
    cost: '0',
    day: 0,
  });

  useEffect(() => {
    const daysArray = [];
    for (let i = 1; i <= 28; i++) {
      if (i < 10) {
        daysArray.push('0' + i);
      } else {
        daysArray.push(i + '');
      }
    }
    setDays(daysArray);
    setSelectedDays('01');
  }, []);

  const handleLoadProject = () => {};

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      InputProps={{
        readOnly: true,
      }}
    />
  ));

  const handleToggleChange = (e) => {
    const value = e.target.value;
    setToggleValue(value);

    setSendData({
      ...sendData,
      dtlList: [],
    });
    if (value === 'S') {
    }
  };
  console.log(sendData);

  const handleRegDetailOpen = (index) => {
    setSettlementAmount({
      index: index,
    });
    setIsPaymentDetailOpen(true);
  };

  const handleCntrChange = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setInputs({
      ...inputs,
      cntrStDt: start,
      cntrEndDt: end,
    });
  };

  const handleMonthDateChange = (dates, index) => {
    if (inputs.cntrStDt === '' || inputs.cntrEndDt === null) {
      showToast('계약기간을 입력해 주세요.');
      return;
    }

    const [start, end] = dates;

    // Ensure the selected dates are within the contract period
    if (
      moment(inputs.cntrStDt, 'YYYYMM').format('YYYYMM') > moment(start, 'YYYYMM').format('YYYYMM') ||
      moment(inputs.cntrEndDt, 'YYYYMM').format('YYYYMM') < moment(start, 'YYYYMM').format('YYYYMM') ||
      (end !== null && moment(inputs.cntrEndDt, 'YYYYMM').format('YYYYMM') < moment(end, 'YYYYMM').format('YYYYMM'))
    ) {
      showToast('정산 기간은 계약 기간 내의 날짜로 선택해주세요.');
      return;
    }

    // Generate all the months between the start and end dates
    const monthRange = [];
    let current = moment(start);
    const endDate = end ? moment(end) : moment(start);
    while (current <= endDate) {
      monthRange.push(current.format('YYYY-MM'));
      current.add(1, 'month');
    }

    // Update the monthData state
    setMonthData({
      ...monthData,
      stYearMonth: start,
      endYearMonth: end,
    });

    // Update the sendData state
    setSendData((prevSendData) => {
      const updatedDtlList = prevSendData.dtlList.map((item, i) => {
        if (i === index) {
          // Initialize or copy tgtList
          let updatedTgtList = item.tgtList ? [...item.tgtList] : [];

          // Filter out entries that are not in the current month range
          updatedTgtList = updatedTgtList.filter((tgt) => monthRange.includes(tgt.startMonth));

          // Update existing entries and add new ones
          monthRange.forEach((month) => {
            const existingIndex = updatedTgtList.findIndex((tgt) => tgt.startMonth === month);
            if (existingIndex !== -1) {
              // Update the existing entry for this month
              updatedTgtList[existingIndex] = {
                ...updatedTgtList[existingIndex],
                startMonth: month,
                endYearMonth: month, // Single month entries
                ...monthData,
              };
            } else {
              // Add a new entry for this month
              updatedTgtList.push({
                startMonth: month,
                endYearMonth: month, // Single month entries
                ...monthData,
              });
            }
          });

          return {
            ...item,
            tgtList: updatedTgtList,
          };
        }
        return item; // Leave other items unchanged
      });

      return {
        ...prevSendData,
        dtlList: updatedDtlList,
      };
    });
  };

  const handleMonthDataChange = (e, index) => {
    const { name, value } = e.target;
    if (inputs.cntrStDt === '' || inputs.cntrEndDt === null) {
      showToast('계약기간을 입력해 주세요.');
      return;
    }

    setMonthData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setSendData((prevSendData) => {
      const updatedDtlList = prevSendData.dtlList.map((item, itemIndex) => {
        if (itemIndex === index) {
          // Ensure tgtList exists or initialize an empty array
          const updatedTgtList = item.tgtList ? [...item.tgtList] : [];

          // Update the tgtList based on the new values
          const newTgtList = updatedTgtList.map((tgt) => {
            // Update the specific property based on the name and value
            return {
              ...tgt,
              [name]: value,
            };
          });

          // If no existing entry for the given `index`, add it
          const existingIndex = newTgtList.findIndex((tgt) => tgt.index === monthData.index);
          if (existingIndex === -1) {
            newTgtList.push({
              cost: monthData.cost || '',
              type: monthData.type || '',
              day: monthData.day || 0,
              [name]: value,
            });
          }

          return {
            ...item,
            tgtList: newTgtList,
          };
        }
        return item; // Leave other items unchanged
      });

      return {
        ...prevSendData,
        dtlList: updatedDtlList,
      };
    });
  };

  const handleTypeChange = (e, sn) => {
    const newType = e.target.value;

    setSendData((prevState) => ({
      ...prevState,
      dtlList: prevState.dtlList.map((item) => {
        const currentSn = item.type === 1 ? item.wrkfSn : item.dvlpCoSn;

        // Update emplType only if the computed sn matches the provided sn
        if (currentSn === sn) {
          return { ...item, emplType: newType };
        } else {
          return item; // Return the item unchanged if sn does not match
        }
      }),
    }));
  };

  const [regPop, setRegPop] = useState(false);
  const handlePaymentRegConfirm = () => {
    const hasEmptyFields =
      sendData.dtlList &&
      sendData.dtlList.some((item, index) => {
        if (item.emplType === 0 || !isValue(item.emplType)) {
          // Log if `tgtList` is missing or empty
          console.log(`  tgtList is missing or empty for dtlList entry ${index}, and emplType is ${item.emplType}.`);
          return true; // Consider this entry as having empty fields
        }

        // Check if `tgtList` exists and has entries
        if (item.tgtList && Array.isArray(item.tgtList) && item.tgtList.length > 0) {
          // Check each entry in `tgtList` for empty fields
          return item.tgtList.some((tgt, tgtIndex) => {
            console.log(tgt);
            return toggleValue === 'S'
              ? !tgt.day ||
                  tgt.day.trim() === '' ||
                  !tgt.startMonth ||
                  tgt.startMonth.trim() === '' ||
                  !tgt.cost ||
                  tgt.cost.trim() === ''
              : !tgt.paymentDt || tgt.paymentDt === '';
          });
        } else {
          // Log if `tgtList` is missing or empty
          console.log(`  tgtList is missing or empty for dtlList entry ${index}, and emplType is ${item.emplType}.`);
          return true; // Consider this entry as having empty fields
        }
        // Return false if `tgtList` does not exist or is empty
        return false;
      });

    if (!isValue(inputs.prjcNm) || !isValue(inputs.clntCoNm) || !isValue(inputs.cntrStDt)) {
      return showToast('계약정보를 모두 입력해주세요.');
    } else if (!isValue(sendData.dtlList) || sendData.dtlList.length <= 0) {
      return showToast('지급방식을 입력해주세요.');
    } else if (hasEmptyFields) {
      return showToast('입력한 지급정보를 다시 확인해주세요.');
    }
    setRegPop(true);
  };

  const handlePaymentReg = () => {
    dispatch(
      postPayment({
        userSn: user.userSn,
        userId: user.userId,
        prjcNm: inputs.prjcNm,
        coNm: inputs.clntCoNm,
        cntrStDt: setDateFormat(inputs.cntrStDt, 'YYYY-MM-DD'),
        cntrEndDt: setDateFormat(inputs.cntrEndDt, 'YYYY-MM-DD'),
        cntrSn: inputs.cntrSn ? inputs.cntrSn : '', // Assuming this is coming from `inputs`
        coCd: inputs.coCd ? inputs.coCd : '', // Assuming this is coming from `inputs`
        tgtList: sendData.dtlList.map((item) => ({
          tgtSn: item.type === 1 ? item.wrkfSn : item.dvlpCoSn,
          tgtType: item.type,
          tgtNm: item.type === 1 ? item.userNm : item.dvlpNm,
          emplType: item.emplType,
          dtlList: item.tgtList.map((dtlItem) => ({
            category: toggleValue === 'S' ? 1 : dtlItem.category,
            cost: dtlItem.cost,
            paymentDt:
              toggleValue === 'M'
                ? setDateFormat(dtlItem.paymentDt, 'YYYY-MM-DD')
                : setDateFormat(`${dtlItem.startMonth}-${dtlItem.day}`, 'YYYY-MM-DD'),
          })),
        })),
        callback: handlePostPaymentCallbck,
      })
    );
  };

  const handlePostPaymentCallbck = (data) => {
    if (data.resultCode === 0) {
      showToast('신규지급이 등록되었습니다.');
      setIsOpen(false);
      setRegPop(false);
      setIsSuccess(!isSuceess);
    } else {
      showToastError(data);
    }
  };

  const handleDeleteCell = (sn) => {
    setSendData((prevState) => {
      return {
        ...prevState,
        dtlList: prevState.dtlList.filter((item) => item.wrkfSn !== sn && item.dvlpCoSn !== sn),
      };
    });
  };
  console.log(inputs);

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={'lg'} disableEnforceFocus>
        <DialogTitle id="scroll-dialog-title">
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
            <Box>
              <Typography className="modal-tit" fontWeight={'bold'}>
                지급 등록
              </Typography>
              <Typography color={'grey'} variant="body2" mt={1}>
                프로젝트의 지급 정보를 입력해주세요.
              </Typography>
            </Box>
            <Button type="button" className="btn-orange" onClick={() => setIsPrjcOpen(true)}>
              가져오기
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={5}>
            <Typography variant="subtitle2">계약 정보</Typography>
            <Grid px={2}>
              <Grid container my={2}>
                <Grid item md={12} display={'flex'} alignItems={'center'}>
                  <Grid item md={2} display={'flex'} alignItems={'center'}>
                    <Typography variant="subtitle1">프로젝트</Typography>
                    <Typography variant="subtitle1" color={'red'}>
                      *
                    </Typography>
                  </Grid>
                  <Grid item md={10}>
                    <TextField
                      name="prjcNm"
                      variant="standard"
                      size="small"
                      fullWidth
                      value={inputs.prjcNm ? inputs.prjcNm : ''}
                      onChange={handleCntrChange}
                      InputProps={{
                        readOnly: inputs.cntrYN === 'Y' ? true : false,
                      }}
                      // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container my={2}>
                <Grid item md={6}>
                  <Grid container alignItems={'center'}>
                    <Grid item md={4} display={'flex'} alignItems={'center'}>
                      <Typography variant="subtitle1">계약사</Typography>
                      <Typography variant="subtitle1" color={'red'}>
                        *
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        name="clntCoNm"
                        variant="standard"
                        size="small"
                        fullWidth
                        onChange={handleCntrChange}
                        value={inputs.clntCoNm ? inputs.clntCoNm : ''}
                        InputProps={{
                          readOnly: inputs.cntrYN === 'Y' ? true : false,
                        }}
                      />
                    </Grid>
                    <Grid item md={3.5} ml={1}>
                      <Button
                        className="btn-orange"
                        size="small"
                        disabled={inputs.cntrYN === 'Y' ? true : false}
                        onClick={() => setIsSalesOpen(true)}
                      >
                        정보 가져오기
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} display={'flex'} alignItems={'center'}>
                  <Grid item md={4} display={'flex'} alignItems={'center'}>
                    <Typography variant="subtitle1">계약기간</Typography>
                    <Typography variant="subtitle1" color={'red'}>
                      *
                    </Typography>
                  </Grid>
                  <Grid item md={8} className="inp-date">
                    <DatePickerWrapper>
                      <DatePicker
                        locale={ko}
                        selected={inputs.cntrStDt}
                        onChange={handleDateChange}
                        startDate={inputs.cntrStDt}
                        endDate={inputs.cntrEndDt}
                        dateFormat={'YYYY-MM-dd'}
                        selectsRange
                        customInput={<ExampleCustomInput />}
                        disabled={inputs.cntrYN === 'Y' ? true : false}
                      />
                    </DatePickerWrapper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="subtitle2">지급 정보</Typography>
          <Grid px={2}>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={2} display={'flex'} alignItems={'center'}>
                  <Typography variant="subtitle1">지급 방식</Typography>
                  <Typography variant="subtitle1" color={'red'}>
                    *
                  </Typography>
                </Grid>
                <Grid item md={10}>
                  <ToggleButtonGroup
                    color="primary"
                    value={toggleValue}
                    className="radio-btn"
                    exclusive
                    onChange={handleToggleChange}
                  >
                    <ToggleButton
                      value="S"
                      aria-label="S"
                      sx={{
                        whiteSpace: 'nowrap',
                        width: '80px',
                      }}
                    >
                      월 지급
                    </ToggleButton>
                    <ToggleButton
                      value="M"
                      aria-label="M"
                      sx={{
                        whiteSpace: 'nowrap',
                        width: '80px',
                      }}
                    >
                      약정 지급
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                {toggleValue === 'S' ? (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell size="small" width={30}>
                          <IconButton color="primary" onClick={() => setIsTargetOpen(true)}>
                            <AddBox />
                          </IconButton>
                        </TableCell>
                        <TableCell width={200}>지급 대상</TableCell>
                        <TableCell width={250}>지급 기간</TableCell>
                        <TableCell width={250}>고용 형태</TableCell>
                        <TableCell width={130}>지급일</TableCell>
                        <TableCell width={250}>지급액(월)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sendData.dtlList &&
                        sendData.dtlList.length > 0 &&
                        sendData.dtlList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <IconButton onClick={() => handleDeleteCell(item.wrkfSn || item.dvlpCoSn)}>
                                <IndeterminateCheckBox color="error" />
                              </IconButton>
                            </TableCell>
                            <TableCell>{item.userNm || item.dvlpNm}</TableCell>
                            <TableCell>
                              <Box>
                                <DatePicker
                                  locale={ko}
                                  selected={item.tgtList && item.tgtList.length > 0 ? item.tgtList[0].stYearMonth : ''}
                                  onChange={(date) => handleMonthDateChange(date, index)}
                                  startDate={item.tgtList && item.tgtList.length > 0 ? item.tgtList[0].stYearMonth : ''}
                                  endDate={item.tgtList && item.tgtList.length > 0 ? item.tgtList[0].endYearMonth : ''}
                                  dateFormat={'YYYY-MM'}
                                  showMonthYearPicker
                                  selectsRange
                                  customInput={<DatePickerRound placehol="YYYY-MM ~ YYYY-MM" />}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <FormControl size="small" fullWidth>
                                {/* <Select defaultValue={0} name="type" onChange={(e) => handleMonthDataChange(e, index)}> */}
                                <Select
                                  defaultValue={0}
                                  name="emplType"
                                  onChange={(e) => handleTypeChange(e, item.wrkfSn || item.dvlpCoSn)}
                                >
                                  <MenuItem value={0}>선택</MenuItem>
                                  <MenuItem value={1}>고용(3.3% + 산재)</MenuItem>
                                  <MenuItem value={2}>개인/법인사업자(3.3%)</MenuItem>
                                  <MenuItem value={3}>사업자(세금계산서 발행)</MenuItem>
                                  <MenuItem value={4}>기타(0%로 계산)</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl size="small" fullWidth>
                                <Select
                                  fullWidth
                                  name="day"
                                  value={
                                    item.tgtList && item.tgtList.length > 0
                                      ? item.tgtList[0].day || 0 // Default to an empty string if `day` is undefined
                                      : 0 // Default to an empty string if `tgtList` is missing or empty
                                  }
                                  onChange={(e) => handleMonthDataChange(e, index)}
                                >
                                  <MenuItem value={0}>선택</MenuItem>
                                  {days &&
                                    days.map((day) => (
                                      <MenuItem key={day} value={day}>
                                        {day}일
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <TextField
                                className="inp-round"
                                fullWidth
                                autoComplete="off"
                                size="small"
                                name="cost"
                                onChange={(e) => handleMonthDataChange(e, index)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" sx={{ pr: 2 }}>
                                      원
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </>
                ) : (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell size="small" width={30}>
                          <IconButton color="primary" onClick={() => setIsTargetOpen(true)}>
                            <AddBox />
                          </IconButton>
                        </TableCell>
                        <TableCell width={250}>지급 대상</TableCell>
                        <TableCell width={200}>고용 형태</TableCell>
                        <TableCell width={200}>지급 종류</TableCell>
                        <TableCell width={130}>지급 횟수</TableCell>
                        <TableCell width={250}>총 지급액</TableCell>
                        <TableCell width={100}>지급 상세</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sendData.dtlList &&
                        sendData.dtlList.length > 0 &&
                        sendData.dtlList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <IconButton onClick={() => handleDeleteCell(item.wrkfSn || item.dvlpCoSn)}>
                                <IndeterminateCheckBox color="error" />
                              </IconButton>
                            </TableCell>
                            <TableCell>{item.userNm || item.dvlpNm}</TableCell>
                            <TableCell>
                              <FormControl size="small" fullWidth sx={{ maxWidth: 153 }}>
                                <Select
                                  defaultValue={0}
                                  onChange={(e) => handleTypeChange(e, item.wrkfSn || item.dvlpCoSn)}
                                >
                                  <MenuItem value={0}>선택</MenuItem>
                                  <MenuItem value={1}>고용(3.3% + 산재)</MenuItem>
                                  <MenuItem value={2}>개인/법인사업자(3.3%)</MenuItem>
                                  <MenuItem value={3}>사업자(세금계산서 발행)</MenuItem>
                                  <MenuItem value={4}>기타(0%로 계산)</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="grey">
                                {item.tgtList && item.tgtList.length > 0
                                  ? Array.from(
                                      new Set(
                                        item.tgtList.map((tgt) =>
                                          tgt.category === 2 ? '착수금' : tgt.category === 3 ? '잔금' : '중도금'
                                        )
                                      )
                                    ).join(', ')
                                  : '지급 상세에서 입력 필요'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color={'grey'}>
                                {item.tgtList && item.tgtList.length > 0 ? item.tgtList.length : '입력 필요'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" color="grey">
                                {item.tgtList && item.tgtList.length > 0
                                  ? `${item.tgtList.reduce(
                                      (total, tgt) => Number(total) + Number(tgt.cost),
                                      0 // Initial value for total
                                    )}`
                                  : '지급 상세에서 입력 필요'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Button className="btn" onClick={() => handleRegDetailOpen(index)}>
                                입력
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="btn-white" onClick={() => setCancelPop(true)} sx={{ width: '100%' }}>
            취소
          </Button>
          <Button className="btn-navy" onClick={handlePaymentRegConfirm}>
            등록
          </Button>
        </DialogActions>
      </Dialog>
      {isPrjcOpen && (
        <PageLoadProjectModal
          isOpen={isPrjcOpen}
          setIsOpen={setIsPrjcOpen}
          inputs={inputs}
          setInputs={setInputs}
          title={'프로젝트 선택'}
          subTitle={'계약목록 중 정산 정보를 등록할 프로젝트를 선택해주세요.'}
        />
      )}
      {isSalesOpen && (
        <PageLoadSalesModal
          isOpen={isSalesOpen}
          setIsOpen={setIsSalesOpen}
          inputs={inputs}
          setInputs={setInputs}
          title={'계약사 가져오기'}
          subTitle={'계약사는 정산 주체인 업체를 의미합니다.'}
        />
      )}
      {isTargetOpen && (
        <PageLoadTargetModal
          isOpen={isTargetOpen}
          setIsOpen={setIsTargetOpen}
          setSendData={setSendData}
          sendData={sendData}
          setOriginalPrsnList={setOriginalPrsnList}
          originalPrsnList={originalPrsnList}
        />
      )}
      {isPaymentDetailOpen && (
        <PagePaymentRegDetailModal
          isOpen={isPaymentDetailOpen}
          setIsOpen={setIsPaymentDetailOpen}
          settlementAmount={settlementAmount}
          setSettlementAmount={setSettlementAmount}
          setSendData={setSendData}
          sendData={sendData}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setIsOpen}
        />
      )}

      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'등록하시겠습니까'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handlePaymentReg}
        />
      )}
    </>
  );
};

export default PagePaymentReg;
