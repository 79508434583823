import { fileAPI, sendAPI } from '@axios/index';

/* 지급 관리 */
const getPaymentList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-payment-list`, params, payload);
};

const putPaymentProcess = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-payment-process`, params, payload);
};

const delPaymentProcess = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-payment-process`, params, payload);
};

const getPaymentMonth = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-payment-month`, params, payload);
};

const getPaymentAgreement = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-payment-agreement`, params, payload);
};

const getPaymentCompDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-payment-comp-dtl`, params, payload);
};

const getPaymentStatistics = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-payment-statistics`, params, payload);
};

const getPaymentUpdateDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-payment-update-dtl`, params, payload);
};

const putPaymentStop = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-payment-stop`, params, payload);
};

const putPaymentList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-payment-list`, params, payload);
};

const postPayment = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-payment`, params, payload);
};

const api = {
  getPaymentList,
  putPaymentProcess,
  delPaymentProcess,
  getPaymentMonth,
  getPaymentAgreement,
  getPaymentCompDtl,
  getPaymentStatistics,
  getPaymentUpdateDtl,
  putPaymentStop,
  putPaymentList,
  postPayment,
};

export default api;
