import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Pie } from '@nivo/pie';
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Chip,
  Box,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Container,
  Tab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useDispatch, useSelector } from 'react-redux';
import { isValue, setDateFormat, setNumberComma, showToast } from '@common/common';
import MainProjectListMyTask from './MainProjectListMyTask';
import MainProjectListMyIssue from './MainProjectListMyIssue';

const theme = createTheme();

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default function MainProjectList({ data }) {
  const dispatch = useDispatch();

  const [dataList, setDataList] = useState([]);

  const user = useSelector((state) => state.commonReducer.user);

  const taskColor = ['#307bff', '#00b050', '#ed7d31', '#7030a0', '#c00000', '#595959'];

  // const [expanded, setExpanded] = useState('panel0');
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  // 각 Accordion의 상태를 관리하기 위한 객체
  // const [expandedPanels, setExpandedPanels] = useState({});
  const [expandedPanels, setExpandedPanels] = useState({ panel0: true });

  // Accordion이 열릴 때 상태를 업데이트하는 함수
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prevExpandedPanels) => ({
      ...prevExpandedPanels,
      [panel]: isExpanded,
    }));
  };

  const getDashboardInfoRequest = () => {
    // dispatch(
    //   getDashboardInfo({
    //     userSn: user.userSn,
    //     userId: user.userId,
    //     //userFlag: user.userFlag,
    //     callback: getDashboardInfoCallback,
    //   })
    // );
  };
  const getDashboardInfoCallback = () => {
    if (data && data.length > 0) {
      let dataList = !isValue(data) ? [] : data;
      setDataList(dataList);
    }
  };
  console.log('datalit2', dataList);
  console.log('data1', data);
  useEffect(() => {
    getDashboardInfoCallback();
    console.log('datalist1', data);
  }, [data]);

  const getTaskData = (data) => {
    let arrData = [];
    let objData = {};

    if (Number(data.taskStts0) > 0) {
      objData = {};
      objData.id = '대기';
      objData.label = '대기';
      objData.value = data.taskStts0;
      arrData.push(objData);
    }

    if (Number(data.taskStts0) > 0) {
      objData = {};
      objData.id = '진행';
      objData.label = '진행';
      objData.value = data.taskStts1;
      arrData.push(objData);
    }

    if (Number(data.taskStts0) > 0) {
      objData = {};
      objData.id = '피드백';
      objData.label = '피드백';
      objData.value = data.taskStts2;
      arrData.push(objData);
    }

    if (Number(data.taskStts0) > 0) {
      objData = {};
      objData.id = '완료';
      objData.label = '완료';
      objData.value = data.taskStts3;
      arrData.push(objData);
    }

    if (Number(data.taskStts0) > 0) {
      objData = {};
      objData.id = '지연';
      objData.label = '지연';
      objData.value = data.taskStts4;
      arrData.push(objData);
    }

    if (Number(data.taskStts0) > 0) {
      objData = {};
      objData.id = '보류';
      objData.label = '보류';
      objData.value = data.taskStts5;
      arrData.push(objData);
    }

    return arrData;
  };

  const getIssueData = (data) => {
    let arrData = [];
    let objData = {};

    if (Number(data.issueStts0) > 0) {
      objData = {};
      objData.id = '신규';
      objData.label = '신규';
      objData.value = data.issueStts0;
      arrData.push(objData);
    }

    if (Number(data.issueStts1) > 0) {
      objData = {};
      objData.id = '처리중';
      objData.label = '처리중';
      objData.value = data.issueStts1;
      arrData.push(objData);
    }

    if (Number(data.issueStts2) > 0) {
      objData = {};
      objData.id = '협의중';
      objData.label = '협의중';
      objData.value = data.issueStts2;
      arrData.push(objData);
    }

    if (Number(data.issueStts3) > 0) {
      objData = {};
      objData.id = '완료';
      objData.label = '완료';
      objData.value = data.issueStts3;
      arrData.push(objData);
    }

    if (Number(data.issueStts4) > 0) {
      objData = {};
      objData.id = '지연';
      objData.label = '지연';
      objData.value = data.issueStts4;
      arrData.push(objData);
    }

    if (Number(data.issueStts5) > 0) {
      objData = {};
      objData.id = '철회';
      objData.label = '철회';
      objData.value = data.issueStts5;
      arrData.push(objData);
    }

    return arrData;
  };

  const [tabValues, setTabValues] = useState(
    dataList.reduce((acc, project) => ({ ...acc, [project.prjcMainSn]: 0 }), {})
  );

  const [issueTabValues, setIssueTabValues] = useState(
    dataList.reduce((acc, project) => ({ ...acc, [project.prjcMainSn]: 0 }), {})
  );

  const handleTabChange = (prjcMainSn, newValue) => {
    setTabValues((prev) => ({ ...prev, [prjcMainSn]: newValue }));
  };

  const handleIssueTabChange = (prjcMainSn, newValue) => {
    setIssueTabValues((prev) => ({ ...prev, [prjcMainSn]: newValue }));
  };

  const taskLabels = ['대기', '진행', '피드백', '완료', '지연', '보류'];
  const issueLabels = ['신규', '처리중', '협의중', '완료', '지연', '철회'];

  // Pairs to display: {index1, index2} structure
  const taskPairs = [
    { index1: 0, index2: 3 },
    { index1: 1, index2: 4 },
    { index1: 2, index2: 5 },
  ];
  return (
    <>
      {dataList &&
        dataList.length > 0 &&
        dataList.map((row, index) => (
          <Fragment key={index}>
            <CardContent>
              <Accordion expanded={expandedPanels['panel' + index] || false} onChange={handleChange('panel' + index)}>
                <AccordionSummary
                  expandIcon={expandedPanels['panel' + index] || false ? <RemoveIcon /> : <AddIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {row.prjcNm}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                      <Chip label="업무 현황" variant="outlined" sx={{ borderColor: '#000000' }} />
                      {row.taskCnt && row.taskCnt.cnt && parseInt(row.taskCnt.cnt) > 0 ? (
                        <Grid container alignItems={'center'} key={index}>
                          <Grid item xs={6}>
                            <Pie
                              data={getTaskData(row.taskCnt)}
                              margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                              innerRadius={0.8}
                              //padAngle={0.7}
                              //cornerRadius={3}
                              width={200}
                              height={200}
                              activeOuterRadiusOffset={8}
                              borderWidth={1}
                              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                              arcLinkLabelsSkipAngle={10}
                              //arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsTextColor="transparent"
                              arcLinkLabelsThickness={2}
                              //arcLinkLabelsColor={{ from: 'color' }}
                              arcLinkLabelsColor="transparent"
                              arcLabel={false}
                              arcLabelsSkipAngle={10}
                              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                              colors={({ id }) => {
                                switch (id) {
                                  case '대기':
                                    return taskColor[0];
                                  case '진행':
                                    return taskColor[1];
                                  case '피드백':
                                    return taskColor[2];
                                  case '완료':
                                    return taskColor[3];
                                  case '지연':
                                    return taskColor[4];
                                  case '보류':
                                    return taskColor[5];
                                  default:
                                    return '#ffffff';
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Grid style={{ display: 'flex' }}>
                              <Grid>
                                {console.log(row.taskCnt)}
                                {taskPairs.map((pair) => (
                                  <Box key={pair.index1} display="flex" alignItems="center" gap="20px" mb={2}>
                                    {/* Task Status index1 */}
                                    <Chip
                                      label={taskLabels[pair.index1]}
                                      variant="outlined"
                                      sx={{ width: '65px', backgroundColor: taskColor[pair.index1], color: '#ffffff' }}
                                    />
                                    <Typography>
                                      {isValue(row.taskCnt[`taskStts${pair.index1}`])
                                        ? setNumberComma(String(row.taskCnt[`taskStts${pair.index1}`]))
                                        : 0}
                                    </Typography>

                                    {/* Task Status index2 */}
                                    <Chip
                                      label={taskLabels[pair.index2]}
                                      variant="outlined"
                                      sx={{ width: '65px', backgroundColor: taskColor[pair.index2], color: '#ffffff' }}
                                    />
                                    <Typography>
                                      {isValue(row.taskCnt[`taskStts${pair.index2}`])
                                        ? setNumberComma(String(row.taskCnt[`taskStts${pair.index2}`]))
                                        : 0}
                                    </Typography>
                                  </Box>
                                ))}
                                {/* <Box display="flex" alignItems="center" gap={'20px'} mb={2}>
                                  <Chip
                                    label="대기"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[0], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.taskCnt.taskStts0) ? setNumberComma(String(row.taskCnt.taskStts0)) : 0}
                                  </Typography>
                                  <Chip
                                    label="완료"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[3], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.taskCnt.taskStts3) ? setNumberComma(String(row.taskCnt.taskStts3)) : 0}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={'20px'} mb={2}>
                                  <Chip
                                    label="진행"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[1], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.taskCnt.taskStts1) ? setNumberComma(String(row.taskCnt.taskStts1)) : 0}
                                  </Typography>
                                  <Chip
                                    label="지연"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[4], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.taskCnt.taskStts4) ? setNumberComma(String(row.taskCnt.taskStts4)) : 0}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={'20px'}>
                                  <Chip
                                    label="피드백"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[2], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.taskCnt.taskStts2) ? setNumberComma(String(row.taskCnt.taskStts2)) : 0}
                                  </Typography>
                                  <Chip
                                    label="보류"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[5], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.taskCnt.taskStts5) ? setNumberComma(String(row.taskCnt.taskStts5)) : 0}
                                  </Typography>
                                </Box> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          key={index}
                          container
                          alignItems={'center'}
                          justifyContent="center"
                          style={{ height: '60%' }}
                        >
                          <Typography key={index}>업무 현황 정보가 없습니다.</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <Chip label="이슈 현황" variant="outlined" sx={{ borderColor: '#000000' }} />
                      {row.issueCnt && row.issueCnt.cnt && parseInt(row.issueCnt.cnt) > 0 ? (
                        <Grid container alignItems={'center'}>
                          <Grid item sm={6} md={6}>
                            <Pie
                              data={getIssueData(row.issueCnt)}
                              margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                              innerRadius={0.8}
                              //padAngle={0.7}
                              //cornerRadius={3}
                              width={200}
                              height={200}
                              activeOuterRadiusOffset={8}
                              borderWidth={1}
                              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                              arcLinkLabelsSkipAngle={10}
                              //arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsTextColor="transparent"
                              arcLinkLabelsThickness={2}
                              //arcLinkLabelsColor={{ from: 'color' }}
                              arcLinkLabelsColor="transparent"
                              arcLabelsSkipAngle={10}
                              arcLabel={false}
                              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                              colors={({ id }) => {
                                switch (id) {
                                  case '신규':
                                    return taskColor[0];
                                  case '처리중':
                                    return taskColor[1];
                                  case '협의중':
                                    return taskColor[2];
                                  case '완료':
                                    return taskColor[3];
                                  case '지연':
                                    return taskColor[4];
                                  case '철회':
                                    return taskColor[5];
                                  default:
                                    return '#ffffff';
                                }
                              }}
                            />
                          </Grid>
                          <Grid item sm={6} md={6}>
                            <Grid style={{ display: 'flex' }}>
                              <Grid>
                                {taskPairs.map((pair) => (
                                  <Box key={pair.index1} display="flex" alignItems="center" gap="20px" mb={2}>
                                    {/* Task Status index1 */}
                                    <Chip
                                      label={issueLabels[pair.index1]}
                                      variant="outlined"
                                      sx={{ width: '65px', backgroundColor: taskColor[pair.index1], color: '#ffffff' }}
                                    />
                                    <Typography>
                                      {isValue(row.issueCnt[`issueStts${pair.index1}`])
                                        ? setNumberComma(String(row.issueCnt[`issueStts${pair.index1}`]))
                                        : 0}
                                    </Typography>

                                    {/* Task Status index2 */}
                                    <Chip
                                      label={issueLabels[pair.index2]}
                                      variant="outlined"
                                      sx={{ width: '65px', backgroundColor: taskColor[pair.index2], color: '#ffffff' }}
                                    />
                                    <Typography>
                                      {isValue(row.issueCnt[`issueStts${pair.index2}`])
                                        ? setNumberComma(String(row.issueCnt[`issueStts${pair.index2}`]))
                                        : 0}
                                    </Typography>
                                  </Box>
                                ))}
                                {/* <Box display="flex" alignItems="center" gap={'20px'}>
                                  <Chip
                                    label="대기"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[0], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.issueCnt.issueStts0)
                                      ? setNumberComma(String(row.issueCnt.issueStts0))
                                      : 0}
                                  </Typography>
                                  <Chip
                                    label="완료"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[3], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.issueCnt.issueStts3)
                                      ? setNumberComma(String(row.issueCnt.issueStts3))
                                      : 0}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={'20px'}>
                                  <Chip
                                    label="진행"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[1], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.issueCnt.issueStts1)
                                      ? setNumberComma(String(row.issueCnt.issueStts1))
                                      : 0}
                                  </Typography>
                                  <Chip
                                    label="지연"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[4], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.issueCnt.issueStts4)
                                      ? setNumberComma(String(row.issueCnt.issueStts4))
                                      : 0}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={'20px'}>
                                  <Chip
                                    label="피드백"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[2], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.issueCnt.issueStts2)
                                      ? setNumberComma(String(row.issueCnt.issueStts2))
                                      : 0}
                                  </Typography>
                                  <Chip
                                    label="보류"
                                    variant="outlined"
                                    sx={{ width: '65px', backgroundColor: taskColor[5], color: '#ffffff' }}
                                  />
                                  <Typography>
                                    {isValue(row.issueCnt.issueStts5)
                                      ? setNumberComma(String(row.issueCnt.issueStts5))
                                      : 0}
                                  </Typography>
                                </Box> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          key={index}
                          container
                          alignItems={'center'}
                          justifyContent="center"
                          style={{ height: '60%' }}
                        >
                          <Typography>이슈 현황 정보가 없습니다.</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <Chip
                        label="다가오는 이벤트"
                        variant="outlined"
                        sx={{ borderColor: '#000000', marginBottom: '20px' }}
                      />
                      {row.eventCnt && Array.isArray(row.eventCnt) && row.eventCnt.length > 0 ? (
                        row.eventCnt.map((event, index) => (
                          <Grid
                            key={index}
                            container
                            alignItems={'center'}
                            style={{ display: 'flex', padding: '3px 0px 3px 0px' }}
                          >
                            <Grid item display={'flex'} gap={'20px'}>
                              <EventNoteIcon style={{ color: '#000000', fontSize: '46px' }} />
                              <Box>
                                <Typography>{event.prjcEventNm}</Typography>
                                <Typography style={{ fontSize: '12px' }}>
                                  {setDateFormat(event.prjcStDttm, 'YYYY-MM-DD HH:mm')} ~{' '}
                                  {setDateFormat(event.prjcEndDttm, 'YYYY-MM-DD HH:mm')}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Grid
                          key={index}
                          container
                          alignItems={'center'}
                          justifyContent="center"
                          style={{ height: '45%' }}
                        >
                          <Typography>이벤트 정보가 없습니다.</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </CardContent>
            {user.userFlag !== 'D' && user.userFlag !== 'C' && (
              <Grid key={index} container minHeight={'500px'} px={2}>
                <Grid item sm={12} md={6}>
                  <Grid
                    item
                    md={12}
                    component={Card}
                    variant="outlined"
                    sx={{
                      mt: 2,
                      mr: 1,
                      borderColor: 'primary',
                      minHeight: '440px',
                    }}
                  >
                    <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                      <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                          <Typography variant="body1" fontWeight={'500'}>
                            나의 담당 업무
                          </Typography>
                        </Grid>
                      </Grid>
                    </Toolbar>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Box>
                        <Tabs
                          className="tab"
                          value={tabValues[row.prjcMainSn] || 0}
                          onChange={(e, newValue) => handleTabChange(row.prjcMainSn, newValue)}
                          aria-label="tabs"
                        >
                          <Tab
                            label={
                              <Box display={'flex'} alignItems={'center'}>
                                예정된 업무
                                <Chip label={row.myTask1.length} variant="outlined" sx={{ ml: 1, width: 50 }} />
                              </Box>
                            }
                            sx={{
                              fontSize: 16,
                              fontWeight: 500,

                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              },
                              '&:hover': {
                                color: theme.palette.primary.dark,
                              },
                              '&:focus': {
                                outline: 'none',
                                color: theme.palette.primary.dark,
                              },
                              '&:active': {
                                color: theme.palette.primary.dark,
                              },
                            }}
                          />
                          <Tab
                            // label="지연된 업무"
                            label={
                              <Box>
                                지연된 업무
                                <Chip label={row.myTask2.length} variant="outlined" sx={{ ml: 1, width: 50 }} />
                              </Box>
                            }
                            sx={{
                              fontSize: 16,
                              fontWeight: 500,
                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              },
                              '&:hover': {
                                color: theme.palette.primary.dark,
                              },
                              '&:focus': {
                                outline: 'none',
                                color: theme.palette.primary.dark,
                              },
                              '&:active': {
                                color: theme.palette.primary.dark,
                              },
                            }}
                          />
                        </Tabs>
                        <TabPanel value={tabValues[row.prjcMainSn] || 0} index={0}>
                          <MainProjectListMyTask project={row} index={0} />
                        </TabPanel>
                        <TabPanel value={tabValues[row.prjcMainSn] || 0} index={1}>
                          <MainProjectListMyTask project={row} index={1} />
                        </TabPanel>
                      </Box>
                    </ThemeProvider>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Grid
                    item
                    md={12}
                    component={Card}
                    variant="outlined"
                    sx={{
                      mt: 2,
                      mr: 1,
                      borderColor: 'primary',
                      minHeight: '440px',
                    }}
                  >
                    <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                      <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                          <Typography variant="body1" fontWeight={'500'}>
                            나의 담당 이슈
                          </Typography>
                        </Grid>
                      </Grid>
                    </Toolbar>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Box>
                        <Tabs
                          className="tab"
                          value={issueTabValues[row.prjcMainSn] || 0}
                          onChange={(e, newValue) => handleIssueTabChange(row.prjcMainSn, newValue)}
                          aria-label="tabs"
                        >
                          <Tab
                            label={
                              <Box>
                                담당 이슈
                                <Chip label={row.myIssue1.length} variant="outlined" sx={{ ml: 1, width: 50 }} />
                              </Box>
                            }
                            sx={{
                              fontSize: 16,
                              fontWeight: 500,

                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              },
                              '&:hover': {
                                color: theme.palette.primary.dark,
                              },
                              '&:focus': {
                                outline: 'none',
                                color: theme.palette.primary.dark,
                              },
                              '&:active': {
                                color: theme.palette.primary.dark,
                              },
                            }}
                          />
                          <Tab
                            label={
                              <Box>
                                검수 이슈
                                <Chip label={row.myIssue2.length} variant="outlined" sx={{ ml: 1, width: 50 }} />
                              </Box>
                            }
                            sx={{
                              fontSize: 16,
                              fontWeight: 500,
                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                              },
                              '&:hover': {
                                color: theme.palette.primary.dark,
                              },
                              '&:focus': {
                                outline: 'none',
                                color: theme.palette.primary.dark,
                              },
                              '&:active': {
                                color: theme.palette.primary.dark,
                              },
                            }}
                          />
                        </Tabs>
                        <TabPanel value={issueTabValues[row.prjcMainSn] || 0} index={0}>
                          <MainProjectListMyIssue project={row} index={0} />
                        </TabPanel>
                        <TabPanel value={issueTabValues[row.prjcMainSn] || 0} index={1}>
                          <MainProjectListMyIssue project={row} index={1} />
                        </TabPanel>
                      </Box>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Fragment>
        ))}
    </>
  );
}
