import { isComplHangul, showToast, showToastError } from '@common/common';
import { SEARCH_TIME } from '@common/constants';
import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { getDvlpCoDtl, getDvlpCoNmList } from '@store/actions/contractAction';
import { getClientDetail } from '@store/actions/counterpartyAction';
import { getClntCoNmList } from '@store/actions/salesAction';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageLoadSalesModal = ({ isOpen, setIsOpen, inputs, setInputs, title, subTitle }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [salesList, setSalesList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [type, setType] = useState('clnt');

  useEffect(() => {
    handleGetClntNmList();
  }, [dispatch]);

  const handleGetClntNmList = () => {
    dispatch(
      getClntCoNmList({
        userId: user.userId,
        userSn: user.userSn,
        coNm: '',
        callback: handleGetTypeCoNmListCallback,
      })
    );
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList = salesList.filter((data) => {
      const clntNmMatch = data.clntNm ? data.clntNm.toLowerCase().includes(newKeyword) : false;
      const dvlpNmMatch = data.dvlpNm ? data.dvlpNm.toLowerCase().includes(newKeyword) : false;
      const coCodeMatch = data.clntCd ? data.clntCd.toLowerCase().includes(newKeyword) : false;
      const dvlpCodeMatch = data.dvlpCd ? data.dvlpCd.toLowerCase().includes(newKeyword) : false;
      return type === 'clnt' ? clntNmMatch || coCodeMatch : dvlpNmMatch || dvlpCodeMatch;
    });

    setFilteredList(filteredList);
  };

  const handleGetProjectDetail = (coSn) => {
    if (type === 'clnt') {
      dispatch(
        getClientDetail({
          userId: user.userId,
          userSn: user.userSn,
          clntCoSn: coSn,
          callback: handleGetProjectDetailCallback,
        })
      );
    } else {
      dispatch(
        getDvlpCoDtl({
          userId: user.userId,
          userSn: user.userSn,
          dvlpCoSn: coSn,
          callback: handleGetProjectDetailCallback,
        })
      );
    }
  };

  const handleGetProjectDetailCallback = (data) => {
    if (data.resultCode === 0) {
      setIsOpen(false);
      setInputs({
        ...inputs,
        clntCoNm: type === 'clnt' ? data.data.clntNm : data.data.dvlpNm,
        coCd: data.data.coCd,
        clntZip: data.data.zip,
        clntAddr: data.data.addr,
        clntDaddr: data.data.daddr,
        clntMngrNm: data.data.mngr,
        clntMngrTelno: data.data.mngrTelno,
        clntMngrEmail: data.data.mngrEmail,
      });
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };

  const handleSrchTypeChange = (e) => {
    const value = e.target.value;

    setType(value);
    setKeyword('');
    if (value === 'clnt') {
      handleGetClntNmList();
    } else {
      dispatch(
        getDvlpCoNmList({
          userId: user.userId,
          userSn: user.userSn,
          coNm: '',
          callback: handleGetDvlpListCallback,
        })
      );
    }
  };

  const handleGetTypeCoNmListCallback = (data) => {
    if (data.resultCode === 0) {
      // setSalesList(data.)
      setSalesList(data.data.clntCoNmList);
    } else {
      showToastError(data);
    }
  };

  const handleGetDvlpListCallback = (data) => {
    if (data.resultCode === 0) {
      setSalesList(data.data.dvlpCoNmList);
    }
  };
  const displayList = keyword ? filteredList : salesList;

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          {title}
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          {subTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={3}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={'clnt'}
                size="small"
                onChange={handleSrchTypeChange}
              >
                <MenuItem value={'clnt'}>의뢰사</MenuItem>
                <MenuItem value={'dvlp'}>개발사</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={9}>
            {/* <input onChange={handleSearchChange} /> */}
            <FormControl fullWidth>
              <TextField
                required
                className="inp-round"
                placeholder="회사명/회사코드를 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onChange={handleSearchChange}
                value={keyword}
                sx={{ ml: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          {/* <Grid item md={2}>
            <Button variant="outlined" onClick={handleGetSalesNmList} fullWidth>
              검색
            </Button>
          </Grid> */}
        </Grid>
        <Grid item className="list-type" sx={{ height: '350px' }}>
          {displayList && displayList.length > 0 ? (
            displayList.map((item, index) => (
              <Grid container key={index}>
                <Grid item>
                  <Typography variant="body1" fontWeight={'500'}>
                    {type === 'clnt' ? item.clntNm : item.dvlpNm}
                  </Typography>
                  <Typography variant="body2">{type === 'clnt' ? item.clntCd : item.dvlpCd}</Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="btn-orange"
                    onClick={() => handleGetProjectDetail(type === 'clnt' ? item.clntCoSn : item.dvlpCoSn)}
                  >
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageLoadSalesModal;
