import { setNumberComma } from '@common/common';
import { ArrowDropDown, ArrowDropUp, Info } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const PageCalculationStat = ({ resultStats }) => {
  const [years, setYears] = useState([]);

  const [allScheduled, setAllScheduled] = useState([]);

  useEffect(() => {
    if (Object.keys(resultStats).length !== 0) {
      const toDay = new Date();

      const toDayYear = toDay.getFullYear();

      let yearList = [];
      for (let i = toDayYear; i <= toDayYear + 5; i++) {
        yearList.push(i);
      }

      setYears(yearList);
      console.log(resultStats);

      setClctPays(resultStats.clctPays);
      setDelayed(resultStats.delayed);
      setAllScheduled(resultStats.scheduled);
      setScheduled(resultStats.scheduled);
    }
  }, [resultStats]);

  const [clctPays, setClctPays] = useState({
    lastMonthPay: 0,
    lastYearPay: 0,
    thisMonthPay: 0,
    thisYearCoPay: 0,
    thisYearDlPay: 0,
    thisYearScPay: 0,
  });
  const [delayed, setDelayed] = useState([]);
  const [scheduled, setScheduled] = useState([]);

  const handleSelectChange = (event) => {
    if (event.target.value === '') {
      setScheduled(allScheduled);
    } else {
      setScheduled(allScheduled.filter((e) => Number(e.clctDt.slice(0, 4)) === event.target.value));
    }
  };

  return (
    <>
      <Grid container mb={5}>
        <Grid item xs={6}>
          <Container>
            <Grid
              item
              component={Card}
              variant="outlined"
              sx={{
                mt: 2,
                borderColor: 'primary',
                minHeight: '200px',
                borderRadius: '15px',
              }}
            >
              <Grid item md={12} p={'24px'} pb={2} display={'flex'} alignItems={'center'}>
                <Typography sx={{ mr: '2px' }}>당월 정산 예정 금액</Typography>
                <CustomWidthTooltip
                  placement="right"
                  title={
                    <>
                      당월 정산 예정 금액 =
                      <br />
                      정산 예정인 모든 당월 정산 금액의 합
                      <br />
                      + 정산 완료된 모든 당월 정산 금액의 합
                      <br />
                      <br />
                      ** 정산 지연되거나, 미리 정산된 다른 월의 금액은 미포함
                    </>
                  }
                >
                  <Info sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </CustomWidthTooltip>
              </Grid>
              <Grid container p={'24px'} pt={0}>
                <Grid item md={12} display={'flex'} alignItems={'center'}>
                  <Typography variant="h5">{setNumberComma(String(clctPays.thisMonthPay))}</Typography>
                  <Typography color={'gray'}>&nbsp;원</Typography>
                </Grid>
                <Grid item md={6} my={4}>
                  <Chip label={'작년 동일 월 대비'} variant="outlined" />
                  <Grid item md={12} display={'flex'} alignItems={'center'}>
                    <IconButton
                      color={clctPays.thisMonthPay >= clctPays.lastYearPay ? 'error' : 'primary'}
                      sx={{
                        cursor: 'default !important',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                      disableRipple
                    >
                      {clctPays.thisMonthPay >= clctPays.lastYearPay ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Typography>
                      {setNumberComma(String(Math.abs(clctPays.thisMonthPay - clctPays.lastYearPay)))}
                    </Typography>
                    <Typography variant="body1" color={'grey'}>
                      &nbsp;({clctPays.lastYearPay} 원)
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={6} my={4}>
                  <Chip label={'전월 정산 예정 금액 대비'} variant="outlined" />
                  <Grid item md={12} display={'flex'} alignItems={'center'}>
                    <IconButton
                      color={clctPays.thisMonthPay >= clctPays.lastMonthPay ? 'error' : 'primary'}
                      sx={{
                        cursor: 'default !important',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                      disableRipple
                    >
                      {clctPays.thisMonthPay >= clctPays.lastMonthPay ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                    <Typography>
                      {setNumberComma(String(Math.abs(clctPays.thisMonthPay - clctPays.lastMonthPay)))}
                    </Typography>
                    <Typography variant="body1" color={'grey'}>
                      &nbsp;({setNumberComma(String(clctPays.lastMonthPay))} 원)
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <Container>
            <Grid
              item
              component={Card}
              variant="outlined"
              sx={{
                mt: 2,
                borderColor: 'primary',
                minHeight: '200px',
                borderRadius: '15px',
              }}
            >
              <Grid item md={12} p={'24px'} pb={2} display={'flex'} alignItems={'center'}>
                <Typography sx={{ mr: '2px' }}>올해 정산 예정 금액</Typography>
                <CustomWidthTooltip
                  placement="right"
                  title={
                    <>
                      올해 정산 예정 금액 =
                      <br />
                      올해 정산될 금액 + 올해 정산이 완료된 금액 + 올해 지연된 금액
                      <br />
                      <br />
                      ** 지연 금액은 이전 연도 이월 금액도 포함
                    </>
                  }
                >
                  <Info sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </CustomWidthTooltip>
              </Grid>
              <Grid container p={'24px'} pt={0}>
                <Grid item md={12} display={'flex'} alignItems={'center'}>
                  <Typography variant="h5">
                    {setNumberComma(String(clctPays.thisYearScPay + clctPays.thisYearDlPay + clctPays.thisYearCoPay))}
                  </Typography>
                  <Typography color={'gray'}>&nbsp;원</Typography>
                </Grid>
                <Grid item md={4} my={4}>
                  <Chip label={'예정'} variant="outlined" color="primary" />
                  <Grid item md={12} display={'flex'} alignItems={'center'}>
                    <Typography padding={'8px'}>{setNumberComma(String(clctPays.thisYearScPay))} 원</Typography>
                  </Grid>
                </Grid>
                <Grid item md={4} my={4}>
                  <Chip label={'지연'} variant="outlined" color="warning" />
                  <Grid item md={12} display={'flex'} alignItems={'center'}>
                    <Typography padding={'8px'}>{setNumberComma(String(clctPays.thisYearDlPay))} 원</Typography>
                  </Grid>
                </Grid>
                <Grid item md={4} my={4}>
                  <Chip label={'완료'} variant="outlined" color="success" />
                  <Grid item md={12} display={'flex'} alignItems={'center'}>
                    <Typography padding={'8px'}>{setNumberComma(String(clctPays.thisYearCoPay))} 원</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Container maxWidth="100%">
            <Grid container>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="h6" fontWeight={'bold'}>
                  정산 지연
                </Typography>
                <Chip label={delayed.length} variant="outlined" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} mt={2}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '30%' }}>프로젝트</TableCell>
                        <TableCell sx={{ width: '25%' }}>계약사(정산주체)</TableCell>
                        <TableCell sx={{ width: '15%' }}>정산 종류</TableCell>
                        <TableCell sx={{ width: '15%' }}>정산 예정일</TableCell>
                        <TableCell sx={{ width: '30%', textAlign: 'end' }}>정산 금액</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {delayed.length > 0 ? (
                        delayed.map((item) => (
                          <TableRow key={item.clctDtlSn}>
                            <TableCell>{item.prjcNm}</TableCell>
                            <TableCell>
                              {item.coNm}
                              {item.coCd}
                            </TableCell>
                            <TableCell>{item.categoryNm}</TableCell>
                            <TableCell>{item.clctDt}</TableCell>
                            <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.cost))} 원</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            정산 지연 금액이 없습니다.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Container maxWidth="100%">
            <Grid container>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="h6" fontWeight={'bold'}>
                  정산 예정
                </Typography>
                <Chip label={scheduled.length} variant="outlined" />
              </Grid>
              <Grid item xs={10.5} sx={{ display: 'flex', justifyContent: 'end' }}>
                <FormControl size="small" sx={{ minWidth: 150, mr: 1 }}>
                  <Select fullWidth displayEmpty defaultValue={''} onChange={(e) => handleSelectChange(e)}>
                    <MenuItem value="">전체</MenuItem>
                    {years.length > 0 &&
                      years.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} mt={2}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '30%' }}>프로젝트</TableCell>
                        <TableCell sx={{ width: '25%' }}>계약사(정산주체)</TableCell>
                        <TableCell sx={{ width: '15%' }}>정산 종류</TableCell>
                        <TableCell sx={{ width: '15%' }}>정산 예정일</TableCell>
                        <TableCell sx={{ width: '30%', textAlign: 'end' }}>정산 금액</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scheduled.length > 0 ? (
                        scheduled.map((item) => (
                          <TableRow key={item.clctDtlSn}>
                            <TableCell>{item.prjcNm}</TableCell>
                            <TableCell>
                              {item.coNm}
                              {item.coCd}
                            </TableCell>
                            <TableCell>{item.categoryNm}</TableCell>
                            <TableCell>{item.clctDt}</TableCell>
                            <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.cost))} 원</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            정산 예정 금액이 없습니다.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default PageCalculationStat;
