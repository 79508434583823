import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  FormControl,
  Select,
  Checkbox,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { postClientList, delClientList } from '@store/actions/counterpartyAction';
import ClientReg from './PageCounterPartyClientReg';
import ClientDetail from './PageCounterPartyClientDetail';
import { Search } from '@mui/icons-material';
import PagiNation from 'component/pagination';
import { isComplHangul, setDateFormat, showToast, showToastError } from '@common/common';

//tree
import Tree from './PageTestTreeModal_';
import _ from 'lodash';
import { SEARCH_TIME } from '@common/constants';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageCounterPartyClient = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);
  const [activePage, setActivePage] = useState(1);
  const [itemCountPerPage, setItemCountPerPage] = useState(20);
  const [clientList, setClientList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const [filterOption, setFilterOption] = useState('All');
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [regOpen, setRegOpen] = useState(false);
  const [dtlOpen, setDtlOpen] = useState(false);
  const [isAllChecked, setAllChecked] = useState(false);
  const [deletePop, setDeletePop] = useState(false);
  const [checkedState, setCheckedState] = useState([]);

  const [srchType, setSrchType] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  //tree
  const [treeOpen, setTreeOpen] = useState(false);

  const [search, setSearch] = useState({
    keyword: '',
    dealYn: '',
    pageNum: 1,
    pageSize: 20,
    searchType: '1',
    searchValue: '',
  });

  useEffect(() => {
    onGetClientList();
  }, [isSuccess]);

  const onGetClientList = () => {
    const params = {
      userSn: userInfo.userSn,
      userId: userInfo.userId,
      pageSize: search.searchType === 'All' ? 0 : itemCountPerPage,
      pageNum: activePage,
      ...search,
      callback: fn_callback,
    };

    dispatch(postClientList(params));
  };

  const fn_callback = (data) => {
    // console.log("data", data);
    setClientList(data);
    if (data && data.data) {
      setCheckedState(new Array(data.data.length).fill(false));
    }
  };

  const returnPage = (event) => {
    setSearch((prev) => ({
      ...prev,
      pageNum: event,
    }));
    setActivePage(event);
  };

  useEffect(() => {
    if (clientList.data) {
      setCheckedState(new Array(clientList.data.length).fill(false));
    }
  }, [clientList.data]);

  // 체크
  const handleAllCheck = () => {
    const newAllChecked = !isAllChecked;
    setAllChecked(newAllChecked);

    const newCheckedState = clientList.data.map(() => newAllChecked);
    setCheckedState(newCheckedState);

    if (newAllChecked) {
      setCheckedList(clientList.data.map((item) => item.clntCoSn));
    } else {
      setCheckedList([]);
    }
  };

  const handleMonoCheck = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);

    const newCheckedList = updatedCheckedState.reduce((list, currentState, index) => {
      if (currentState) {
        list.push(clientList.data[index].clntCoSn);
      }
      return list;
    }, []);

    setCheckedList(newCheckedList);
    setAllChecked(newCheckedList.length === clientList.data.length);
  };

  const handleDeleteConfirm = () => {
    if (checkedList.length === 0) {
      showToast('삭제할 의뢰사 목록을 선택해주세요.');
      return;
    }

    setDeletePop(true);
  };

  const onDelClientList = () => {
    dispatch(
      delClientList({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        clntCoSnList: checkedList,
        callback: fn_delClientList,
      })
    );
  };

  const fn_delClientList = (data, status) => {
    if (data.resultCode === 0) {
      showToast('의뢰사 목록이 삭제되었습니다.');
      setActivePage(1); // 삭제 후 첫 페이지로 이동
      setIsSuccess(!isSuccess);
      setCheckedList([]); // 체크된 항목 초기화
      setAllChecked(false); // 모두 선택 체크박스 초기화
      setDeletePop(false);
    } else {
      showToast('삭제에 실패하였습니다.');
    }
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  const checkHandler = (e, value) => {
    checkedItemHandler(value, e.target.checked);
  };

  const changeSize = (e) => {
    setItemCountPerPage(e.target.value);
  };

  const handleRegOpen = () => {
    setRegOpen(true);
  };

  const handleDtlOpen = () => {
    setDtlOpen(true);
  };

  const addClientToList = (newClient) => {
    setClientList((clientList) => [...clientList, newClient]);
  };

  const handleDtlClick = (clientId) => {
    setSelectedClientId(clientId);
    handleDtlOpen(true);
  };

  const handletreeOpen = () => {
    setTreeOpen(true);
  };

  const handleSearchTypeChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
      keyword: name === 'searchType' && value === 'All' ? '' : prevSearch.keyword,
    }));
    if (name === 'searchType' && value === 'All') {
      setSearch((prevSearch) => ({
        ...prevSearch,
        pageNum: 1,
        pageSize: 0, //
      }));
    }
  };

  const handleKeywordChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const debouncedDispatch = useCallback(
    _.debounce((newSearch) => {
      if (isComplHangul(newSearch.keyword)) {
        dispatch(
          postClientList({
            ...newSearch,
            userId: userInfo.userId,
            userSn: userInfo.userSn,
            callback: fn_callback,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  useEffect(() => {
    debouncedDispatch(search);
  }, [debouncedDispatch, search]);

  return (
    <>
      <Box component="div">
        <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
          <Grid container item xs={4} spacing={1}>
            <Grid item>
              <FormControl size="small" fullWidth>
                <Select
                  id="demo-simple-select"
                  variant="outlined"
                  name="searchType"
                  sx={{ width: '120px' }}
                  value={search.searchType}
                  onChange={handleSearchTypeChange}
                  defaultValue={'1'}
                >
                  <MenuItem value="1">회사명</MenuItem>
                  <MenuItem value="2">회사주소</MenuItem>
                  <MenuItem value="3">대표번호</MenuItem>
                  <MenuItem value="4">팩스번호</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                className="inp-round srh"
                required
                placeholder="내용을 검색해주세요"
                autoComplete="off"
                size="small"
                fullWidth
                name="keyword"
                value={search.keyword}
                onChange={handleKeywordChange}
                //onChange={handleSearchTypeChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={7} alignItems={'center'} justifyContent={'flex-end'}>
            {/* <Grid item xs={1.5}>
              <Button className="btn" onClick={handletreeOpen}>
                Tree test
              </Button>
            </Grid> */}

            <Grid item container xs={6} justifyContent={'flex-end'} alignItems={'center'} spacing={1}>
              <Grid item>
                <FormControl size="small" variant="outlined">
                  <Select
                    id="demo-simple-select"
                    variant="outlined"
                    displayEmpty
                    onChange={handleSearchTypeChange}
                    defaultValue="All"
                    sx={{ width: '120px' }}
                    name="dealYn"
                  >
                    <MenuItem value="All">전체</MenuItem>
                    <MenuItem value="Y">거래 중</MenuItem>
                    <MenuItem value="N">거래종료</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl size="small" variant="outlined">
                  <Select
                    variant="outlined"
                    id="demo-simple-select"
                    displayEmpty
                    inputProps={{
                      name: 'pageSize',
                    }}
                    defaultValue="20"
                    onChange={handleSearchTypeChange}
                  >
                    <MenuItem value={20}>20건</MenuItem>
                    <MenuItem value={50}>50건</MenuItem>
                    <MenuItem value={100}>100건</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button className="btn" onClick={handleRegOpen}>
                  등록
                </Button>
              </Grid>
              <Grid item>
                <Button className="btn-red" onClick={handleDeleteConfirm}>
                  삭제
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: 1, width: '5%' }}>
                    <Checkbox
                      checked={isAllChecked}
                      onChange={() => handleAllCheck()}
                      disabled={!(clientList && clientList.data && clientList.data.length > 0)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 1, width: '5%' }}>NO</TableCell>
                  <TableCell sx={{ padding: 1, width: '15%' }}>회사명</TableCell>
                  <TableCell sx={{ padding: 1, width: '25%' }}>회사주소</TableCell>
                  <TableCell sx={{ padding: 1, width: '15%' }}>대표번호</TableCell>
                  <TableCell sx={{ padding: 1, width: '15%' }}>팩스번호</TableCell>
                  <TableCell sx={{ padding: 1, width: '10%' }}>거래상태</TableCell>
                  <TableCell sx={{ padding: 1, width: '20%' }}>등록일</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {clientList && clientList.data && clientList.data.length > 0 ? (
                  clientList.data.map((item, index) => (
                    <TableRow
                      key={item.clntCoSn}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleDtlClick(item.clntCoSn)}
                    >
                      <TableCell sx={{ padding: 1 }} onClick={(event) => event.stopPropagation()}>
                        <Checkbox checked={checkedState[index] || false} onChange={() => handleMonoCheck(index)} />
                      </TableCell>
                      <TableCell sx={{ padding: 1 }}>{index + (activePage - 1) * itemCountPerPage + 1}</TableCell>
                      <TableCell sx={{ padding: 1 }}>{item.clntNm}</TableCell>
                      <TableCell sx={{ padding: 1 }}>
                        {item.addr} {item.daddr}
                      </TableCell>
                      <TableCell sx={{ padding: 1 }}>{item.telno}</TableCell>
                      <TableCell sx={{ padding: 1 }}>{item.fxno}</TableCell>

                      <TableCell sx={{ padding: 1, width: '10%' }}>
                        {item.dealYn === 'Y' ? '거래 중' : '거래 종료'}
                      </TableCell>
                      <TableCell>{setDateFormat('2024-08-19', 'YYYY-MM-DD')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      검색결과가 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
      {regOpen && (
        <ClientReg
          regOpen={regOpen}
          setRegOpen={setRegOpen}
          addClient={addClientToList}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
        />
      )}
      {dtlOpen && (
        <ClientDetail
          dtlOpen={dtlOpen}
          setDtlOpen={setDtlOpen}
          clientId={selectedClientId}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
        />
      )}
      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'선택한 의뢰사 목록을 삭제하시겠습니까?'}
          comment={'삭제 시 해당 의뢰사의 영업/계약관련 모든 정보가\n삭제되며, 되돌릴 수 없습니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={onDelClientList}
        />
      )}
      <Tree keys={['0-0']} treeOpen={treeOpen} setTreeOpen={setTreeOpen} />
      <PagiNation
        totalItems={clientList && clientList.totalCount}
        itemCountPerPage={search.pageSize}
        currentPage={activePage}
        returnPage={returnPage}
      />
    </>
  );
};

export default PageCounterPartyClient;
