import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '@actions/paymentAction';
import api from '@api/apiPayment';

function* getPaymentList({ payload }) {
  const { status, data } = yield call(api.getPaymentList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPaymentProcess({ payload }) {
  const { status, data } = yield call(api.putPaymentProcess, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* delPaymentProcess({ payload }) {
  const { status, data } = yield call(api.delPaymentProcess, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPaymentMonth({ payload }) {
  const { status, data } = yield call(api.getPaymentMonth, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPaymentAgreement({ payload }) {
  const { status, data } = yield call(api.getPaymentAgreement, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPaymentCompDtl({ payload }) {
  const { status, data } = yield call(api.getPaymentCompDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPaymentStatistics({ payload }) {
  const { status, data } = yield call(api.getPaymentStatistics, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPaymentUpdateDtl({ payload }) {
  const { status, data } = yield call(api.getPaymentUpdateDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPaymentStop({ payload }) {
  const { status, data } = yield call(api.putPaymentStop, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPaymentList({ payload }) {
  const { status, data } = yield call(api.putPaymentList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postPayment({ payload }) {
  const { status, data } = yield call(api.postPayment, { payload: payload });
  if (status === 200) payload.callback(data);
}

// watcher
function* watchUser() {
  yield takeLatest(actions.GET_PAYMENT_LIST, getPaymentList);
  yield takeLatest(actions.PUT_PAYMENT_PROCESS, putPaymentProcess);
  yield takeLatest(actions.DEL_PAYMENT_PROCESS, delPaymentProcess);
  yield takeLatest(actions.GET_PAYMENT_MONTH, getPaymentMonth);
  yield takeLatest(actions.GET_PAYMENT_AGREEMENT, getPaymentAgreement);
  yield takeLatest(actions.GET_PAYMENT_COMP_DTL, getPaymentCompDtl);
  yield takeLatest(actions.GET_PAYMENT_STATISTICS, getPaymentStatistics);
  yield takeLatest(actions.GET_PAYMENT_UPDATE_DTL, getPaymentUpdateDtl);
  yield takeLatest(actions.PUT_PAYMENT_STOP, putPaymentStop);
  yield takeLatest(actions.PUT_PAYMENT_LIST, putPaymentList);
  yield takeLatest(actions.POST_PAYMENT, postPayment);
}

export default function* paymentSaga() {
  yield all([fork(watchUser)]);
}
