import { DatePickerRound, ExampleCustomInput, isNumber, setNumberComma, showToast } from '@common/common';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { ko } from 'date-fns/locale/ko';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageCalculationAgreement = ({ settlementAmount, setSettlementAmount, sendData }) => {
  const [checkBoxList, setCheckBoxList] = useState({
    fstBox: false,
    midBox: false,
    endBox: false,
  });

  useEffect(() => {
    setSettlementAmount([]);
    setCheckBoxList({
      fstBox: false,
      midBox: false,
      endBox: false,
    });
  }, [sendData]);

  const handleCalculList = () => {
    if (sendData.cntrStDt === '' || sendData.cntrEndDt === null) {
      showToast('계약기간을 입력해 주세요.');
      return;
    }

    if (!checkBoxList.fstBox && !checkBoxList.midBox && !checkBoxList.endBox) {
      showToast('정산금을 선택해주세요.');
      return;
    }

    if (checkBoxList.midBox && midlePay === '') {
      showToast('중도금을 선택해주세요.');
      return;
    }

    setSettlementAmount([]);

    const list = [];

    if (checkBoxList.fstBox) {
      list.push({
        name: '착수금',
        category: 2,
        clctDt: '',
        cost: '0',
      });
    }

    if (checkBoxList.midBox) {
      for (var i = 4; i <= midlePay; i++) {
        list.push({
          name: i - 3 + '차 중도금',
          category: i,
          clctDt: '',
          cost: '0',
        });
      }
    }

    if (checkBoxList.endBox) {
      list.push({
        name: '잔금',
        category: 3,
        clctDt: '',
        cost: '0',
      });
    }

    setSettlementAmount(list);
  };

  const handleCheckBox = (name) => {
    setCheckBoxList((prev) => ({
      ...prev,
      [name]: !checkBoxList[name],
    }));
  };

  const [midlePay, setMidlePay] = useState('');

  const [confirm, setConfirm] = useState(false);
  const handleValues = (name, value, category) => {
    console.log(new Date(sendData.cntrStDt) + '  ' + value);
    if (
      name === 'clctDt' &&
      (new Date(sendData.cntrStDt + ' 00:00:00') > value || new Date(sendData.cntrEndDt + ' 00:00:00') < value)
    ) {
      setConfirm(true);
      //   showToast('정산일은 계약 기간 내의 날짜로 선택해주세요.');
      return;
    }
    setSettlementAmount((prev) => prev.map((item) => (item.category === category ? { ...item, [name]: value } : item)));
  };

  const [inputAmt, setInputAmt] = useState('0');

  useEffect(() => {
    let inputCost = BigInt(0);

    settlementAmount.map((e) => {
      inputCost += BigInt(e.cost);
    });

    setInputAmt(inputCost.toString());
  }, [settlementAmount]);

  const handleCost = (e, category) => {
    let inputValue = e.target.value
      .replace(/,/g, '')
      .replace(/^0+/, '')
      .replace(/[^0-9]+/g, '');

    if (inputValue === '' || /^0+$/.test(inputValue)) {
      inputValue = '0';
    }

    if (inputValue.length > 20) {
      inputValue = '99999999999999999999';
    }

    handleValues('cost', inputValue, category);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} display={'flex'} alignItems={'center'}>
          <Grid item xs={6.5} display={'flex'} alignItems={'center'}>
            <Box
              onClick={() => handleCheckBox('fstBox')}
              display={'flex'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Checkbox sx={{ padding: 0, mr: 1 }} checked={checkBoxList.fstBox} />
              <Typography variant="subtitle2">착수금</Typography>
            </Box>
            <Box
              onClick={() => handleCheckBox('midBox')}
              display={'flex'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Checkbox sx={{ padding: 0, mx: 1 }} checked={checkBoxList.midBox} />
              <Typography variant="subtitle2">중도금</Typography>
            </Box>
            <Box
              onClick={() => handleCheckBox('endBox')}
              display={'flex'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Checkbox sx={{ padding: 0, mx: 1 }} checked={checkBoxList.endBox} />
              <Typography variant="subtitle2">잔금</Typography>
            </Box>
          </Grid>
          <Grid item xs={5.5} display={'flex'} alignItems={'center'}>
            <FormControl size="small" fullWidth disabled={!checkBoxList.midBox}>
              <Select
                fullWidth
                displayEmpty
                value={checkBoxList.midBox ? midlePay : ''}
                onChange={(e) => setMidlePay(e.target.value)}
              >
                <MenuItem value="">선택</MenuItem>
                <MenuItem value={4}>1차 중도금</MenuItem>
                <MenuItem value={5}>2차 중도금</MenuItem>
                <MenuItem value={6}>3차 중도금</MenuItem>
                <MenuItem value={7}>4차 중도금</MenuItem>
                <MenuItem value={8}>5차 중도금</MenuItem>
                <MenuItem value={9}>6차 중도금</MenuItem>
                <MenuItem value={10}>7차 중도금</MenuItem>
                <MenuItem value={11}>8차 중도금</MenuItem>
                <MenuItem value={12}>9차 중도금</MenuItem>
                <MenuItem value={13}>10차 중도금</MenuItem>
              </Select>
            </FormControl>
            <Button
              className="btn"
              sx={{
                ml: 1,
              }}
              onClick={() => handleCalculList()}
            >
              적용
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          {settlementAmount.length > 0 && (
            <Grid container>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Paper sx={{ width: '100%', mt: 2 }}>
                  {settlementAmount.map((item) => (
                    <Grid container key={item.category} mb={1}>
                      <Grid item md={1}></Grid>
                      <Grid item md={2} display={'flex'} alignItems={'center'}>
                        <Typography variant="subtitle2">{item.name}</Typography>
                      </Grid>
                      <Grid item md={9} display={'flex'} alignItems={'center'}>
                        <Grid item md={6}>
                          <DatePickerWrapper>
                            <DatePicker
                              locale={ko}
                              selected={item.clctDt}
                              onChange={(e) => handleValues('clctDt', e, item.category)}
                              dateFormat={'YYYY-MM-dd'}
                              customInput={<DatePickerRound placehol="YYYY-MM-DD" />}
                              popperPlacement="top"
                            />
                          </DatePickerWrapper>
                        </Grid>
                        <Grid item md={6} mx={2}>
                          <TextField
                            className="inp-round"
                            fullWidth
                            size="small"
                            value={setNumberComma(item.cost)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="subtitle2" mr={2}>
                                    원
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => handleCost(e, item.category)}
                            sx={{
                              '& .MuiInputBase-input': {
                                textAlign: 'right',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Paper>
              </Grid>
              <Grid item md={12} display={'flex'} alignItems={'center'} my={1}>
                <Grid item md={3} />
                <Grid item md={9} display={'flex'} alignItems={'center'}>
                  <Grid item md={3}>
                    <Typography variant="subtitle2" fontWeight={'bold'}>
                      계약 금액
                    </Typography>
                  </Grid>
                  <Grid item md={8} display={'flex'} justifyContent={'end'}>
                    <Typography variant="subtitle2">
                      {setNumberComma(sendData.cntrAmnt === '' ? '0' : sendData.cntrAmnt)} 원
                    </Typography>
                  </Grid>
                  <Grid item md={1} />
                </Grid>
              </Grid>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={3} />
                <Grid item md={9} display={'flex'} alignItems={'center'}>
                  <Grid item md={3}>
                    <Typography variant="subtitle2" fontWeight={'bold'}>
                      입력 금액
                    </Typography>
                  </Grid>
                  <Grid item md={8} display={'flex'} justifyContent={'end'}>
                    <Typography variant="subtitle2">{setNumberComma(inputAmt)} 원</Typography>
                  </Grid>
                  <Grid item md={1} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {confirm && (
        <ConfirmPopUp
          open={confirm}
          close={setConfirm}
          title={'정산일은 계약 기간 내의 날짜로 선택해주세요.'}
          btnNm2={'확인'}
        />
      )}
    </>
  );
};

export default PageCalculationAgreement;
