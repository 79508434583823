import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";


const PagiNation = ({totalItems, itemCountPerPage, currentPage, returnPage }) => {

    
    return (
        <>
            <Pagination 
                activePage={currentPage} 
                itemsCountPerPage={itemCountPerPage} 
                totalItemsCount={totalItems? totalItems : 0} 
                pageRangeDisplayed={3}
                onChange={returnPage}
                disabledClass="opacity-50"
            />
        </>
    );


}

export default PagiNation;