import { all, fork } from 'redux-saga/effects';
import commonSaga from './commonSaga';
import userSaga from './userSaga';
import contractSaga from './contractSaga';
import counterpartySaga from './counterpartySaga';
import wrkfSaga from './wrkfSaga';
import salesSaga from './salesSaga';
import projectSaga from './projectSaga';
import mainSaga from './mainSaga';
import calculationSaga from './calculationSaga';
import paymentSaga from './paymentSaga';

export default function* rootSaga() {
  yield all([
    fork(commonSaga),
    fork(userSaga),
    fork(contractSaga),
    fork(wrkfSaga),
    fork(counterpartySaga),
    fork(salesSaga),
    fork(projectSaga),
    fork(mainSaga),
    fork(calculationSaga),
    fork(paymentSaga),
  ]);
}
