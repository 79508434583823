export const GET_CLCT_STATS = 'GET_CLCT_STATS';
export const GET_CLCT_BOARD = 'GET_CLCT_BOARD';
export const GET_CLCT_LIST = 'GET_CLCT_LIST';
export const PUT_CLCT_PROCESS = 'PUT_CLCT_PROCESS';
export const POST_CLCT = 'POST_CLCT';
export const DEL_CLCT_PROCESS = 'DEL_CLCT_PROCESS';
export const GET_CLCT_DTL = 'GET_CLCT_DTL';
export const PUT_CLCT_LIST = 'PUT_CLCT_LIST';
export const PUT_CLCT_STOP = 'PUT_CLCT_STOP';
export const GET_CLCT_DOWNLOAD = 'GET_CLCT_DOWNLOAD';

export const getClctStats = (payload) => {
  return { type: GET_CLCT_STATS, payload };
};

export const getClctBoard = (payload) => {
  return { type: GET_CLCT_BOARD, payload };
};

export const getClctList = (payload) => {
  return { type: GET_CLCT_LIST, payload };
};

export const putClctProcess = (payload) => {
  return { type: PUT_CLCT_PROCESS, payload };
};

export const postClct = (payload) => {
  return { type: POST_CLCT, payload };
};

export const delClctProcess = (payload) => {
  return { type: DEL_CLCT_PROCESS, payload };
};

export const getClctDtl = (payload) => {
  return { type: GET_CLCT_DTL, payload };
};

export const putClctList = (payload, callback) => {
  return { type: PUT_CLCT_LIST, payload: { payload, callback } };
};

export const putClctStop = (payload) => {
  return { type: PUT_CLCT_STOP, payload };
};

export const getClctDownload = (payload) => {
  return { type: GET_CLCT_DOWNLOAD, payload };
};
