import './App.css';
import React from 'react';
import RoutesPage from '@router/RoutesPage';
import Spinner from 'react-spinkit';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  const LONGDING = useSelector((state) => state.commonReducer.loading);

  const SPINNER_DIV = {
    margin: '0px',
    top: '0px',
    left: '0px',
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'block',
    background: 'rgba(0,0,0,0.5)',
    zIndex: '9999',
  };
  const SPINNER = {
    top: '50%',
    left: '50%',
    position: 'absolute',
    width: '50px',
    height: '50px',
    zIndex: '99999',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={{ height: '100%' }}>
      {LONGDING ? (
        <>
          <div style={SPINNER_DIV}></div>
          <Spinner name="circle" color="white" fadeIn="none" style={SPINNER} />
        </>
      ) : null}

      <RoutesPage />
      {/* <ToastContainer /> */}
    </div>
  );
}

export default App;
