import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import authSetup from '@common/authSetup';
import { setSidebarDisplay } from '@common/common';

export const SidebarItems = () => {
  const { A10, B10, C10, D10, E10, F10, G10, H10, I10 } = useSelector(authSetup);
  const authMap = useSelector((state) => state.commonReducer.user.authMap);
  const [selectedMenuNo, setSelectedMenuNo] = useState(null);

  const [sidebarList, setSidebarList] = useState([
    {
      type: 'board',
      label: '대시보드',
      path: '/',
      isShow: A10,
      menuNo: 'A10',
    },
    {
      type: 'menu',
      label: '영업관리',
      path: '/mng/sales',
      isShow: C10,
      menuNo: 'C10',
    },
    {
      type: 'menu',
      label: '계약관리',
      path: '/mng/contract',
      isShow: D10,
      menuNo: 'D10',
    },
    {
      type: 'menu',
      label: '프로젝트관리',
      path: '/mng/project',
      isShow: E10,
      menuNo: 'E10',
    },
    {
      type: 'menu',
      label: '인력관리',
      path: '/mng/workforce',
      isShow: F10,
      menuNo: 'F10',
    },
    {
      type: 'menu',
      label: '정산관리',
      path: '/mng/caculation',
      isShow: G10,
      menuNo: 'G10',
    },
    {
      type: 'menu',
      label: '지급관리',
      path: '/mng/payment',
      isShow: H10,
      menuNo: 'H10',
    },
    {
      type: 'menu',
      label: '거래처관리',
      path: '/mng/counterparty',
      isShow: I10,
      menuNo: 'I10',
    },
  ]);

  const boardItems = sidebarList.filter((item) => item.type === 'board');
  const menuItems = sidebarList.filter((item) => item.type === 'menu');

  const handleClick = (menuNo) => {
    setSelectedMenuNo(menuNo); // Set the clicked item's menuNo
  };

  useEffect(() => {
    const currentMenuItem = sidebarList.find((item) => item.path === location.pathname);
    if (currentMenuItem) {
      setSelectedMenuNo(currentMenuItem.menuNo);
    }
  }, [location, menuItems]);

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        BOARD
      </ListSubheader>
      {boardItems.map((item, index) =>
        item.isShow ? (
          <ListItemButton
            key={index}
            component={Link}
            to={item.path}
            onClick={() => handleClick(item.menuNo)}
            className={`
              ${item.menuNo === selectedMenuNo ? 'bg-side-gray' : ''}`}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ) : (
          ''
        )
      )}
      <Divider sx={{ my: 1 }} />
      <ListSubheader component="div" inset>
        MAIN MENU
      </ListSubheader>
      {menuItems.map((item, index) =>
        item.isShow ? (
          <ListItemButton
            key={index}
            component={Link}
            to={item.path}
            onClick={() => handleClick(item.menuNo)}
            className={`
              ${item.menuNo === selectedMenuNo ? 'bg-side-gray' : ''}`}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        ) : (
          ''
        )
      )}
    </React.Fragment>
  );
};
