import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { resetState, setSearchType, setUserInfo } from '@store/actions/commonAction';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { postLogin, setUserJoin } from '@store/actions/userAction';
import { Checkbox, FormControlLabel, Grid, InputAdornment, Tab, Tabs } from '@mui/material';
import { AccountCircle, Lock } from '@mui/icons-material';
import { showToast, showToastMsg } from '@common/common';
import { useSelector } from 'react-redux';
import ConfirmPopUp from 'component/ConfirmPopUp';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://nexlink.co.kr/">
        NEXLINK
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function PageLogin() {
  const userInfo = useSelector((state) => state.commonReducer);

  const [isTokenOut, setTokenOut] = useState(false);

  React.useEffect(() => {
    if (userInfo.isTokenOut) {
      setTokenOut(true);
    } else {
      setTokenOut(false);
    }
  }, [userInfo]);

  const [value, setValue] = useState('D');
  // const [inputs, setInputs] = useState({
  //   username: 'admin',
  //   password: 'admin1!!',
  // });
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = () => {
    if (!inputs.username) {
      return showToast('아이디를 입력해주세요.');
    } else if (!inputs.password) {
      return showToast('비밀번호를 입력해주세요.');
    }
    dispatch(
      postLogin({
        lgnId: inputs.username,
        lgnPw: inputs.password,
        userFlag: value,
        callback: fnHandleCallback,
      })
    );
  };

  const fnHandleCallback = (data, status) => {
    if (data.resultCode === 0) {
      dispatch(setUserInfo(data.data));
      navigate('/');
    } else {
      setIsError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMoveSearchPage = (type) => {
    dispatch(setSearchType(type));

    navigate('/searchType');
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" sx={{ minHeight: '100%', alignContent: 'center' }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Typography component="h1" variant="h5">
            PMT
          </Typography> */}
          <Box className="logo" minWidth="500px" minHeight="100px" />
          <Box width={'100%'}>
            <Tabs
              width={'100%'}
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
              variant="fullWidth"
            >
              <Tab value="D" label="관리사/개발사" wrapped />
              <Tab value="E" label="프리랜서/직원" />
              <Tab value="C" label="의뢰사" />
            </Tabs>
          </Box>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="ID"
              name="username"
              autoComplete="ID"
              autoFocus
              value={inputs.username}
              onChange={handleChangeLogin}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={inputs.password}
              autoComplete="current-password"
              onChange={handleChangeLogin}
              onKeyDown={handleOnKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
            {isError && (
              <Typography variant="caption" color={'red'} lineHeight={'2'}>
                아이디 또는 비밀번호를 잘못 입력했습니다.
                <br />
                입력하신 내용을 다시 확인해주세요.
              </Typography>
            )}
            <Button type="button" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }} onClick={handleLogin}>
              로그인
            </Button>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox value="auto" color="primary" size="small" />}
                  label="자동로그인"
                  componentsProps={{ typography: { variant: 'body2' } }}
                />
              </Grid>
              <Grid item display={'flex'} alignItems={'center'}>
                <Button variant="body2" href="/joinType" sx={{ padding: 0 }}>
                  회원가입
                </Button>
                <Typography variant="caption" m={1}>
                  |
                </Typography>
                <Button variant="body2" onClick={() => handleMoveSearchPage('id')} sx={{ padding: 0 }}>
                  아이디 찾기
                </Button>
                <Typography variant="caption" m={1}>
                  |
                </Typography>
                <Button variant="body2" onClick={() => handleMoveSearchPage('psw')} sx={{ padding: 0 }}>
                  비밀번호 찾기
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
      {isTokenOut && (
        <ConfirmPopUp
          open={isTokenOut}
          close={setTokenOut}
          title={'로그인 시간이 만료되었습니다.\n다시 로그인 해주세요.'}
          btnNm2={'확인'}
          btnEvent={() => dispatch(resetState(false))}
        />
      )}
    </ThemeProvider>
  );
}
