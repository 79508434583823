import { AddBox, ArrowBackIosNew, HdrPlus, Help, HelpOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageProjectPositonModal from './PageProjectPositonModal';
import PageProjectRegProjectInfoMain from './PageProjectRegProjectInfoMain';
import PageProjectRegClntInfoMain from './PageProjectRegClntInfoMain';
import PageProjectRegMngInfoMain from './PageProjectRegMngInfoMain';
import PageProjectRegDvlpInfoMain from './PageProjectRegDvlpInfoMain';
import { useDispatch } from 'react-redux';
import { getPrjcDvlpSuccess, postPrjc, setPrjcDvlpInit } from '@store/actions/projectAction';
import { useSelector } from 'react-redux';
import { isValue, setDateFormat, showToast, showToastError } from '@common/common';
import { getDvlpCoDtl } from '@store/actions/contractAction';

import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectRegMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const dvlpData = useSelector((state) => state.projectReducer.projectDvlpInfo);

  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [regPop, setRegPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [projectInputs, setProjectInputs] = useState({});
  const [clntInputs, setClntInputs] = useState({});
  const [dvlpInputs, setDvlpInputs] = useState({});

  const items = [
    {
      title: '개발사',
      value: 0,
    },
    {
      title: '관리사',
      value: 1,
    },
    {
      title: '개발&관리사',
      value: 2,
    },
    {
      title: '개발&관리&의뢰사',
      value: 3,
    },
  ];

  const handleHelpClick = () => {
    setIsHelpOpen(true);
  };

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index, value) => {
    setSelectedItemIndex(value);
    dispatch(setPrjcDvlpInit());

    if (value == 3) {
      dispatch(
        getDvlpCoDtl({
          userSn: user.userSn,
          userId: user.userId,
          callback: handleGetDvlpCoDtlCallback,
        })
      );
    }
  };

  const handleGetDvlpCoDtlCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      dispatch(getPrjcDvlpSuccess(data.data));
    } else {
      showToastError(data);
    }
  };

  const handleConfirmRegProject = () => {
    if (!isValue(projectInputs.cntrStDt)) {
      return showToast('개발기간을 입력해주세요.');
    } else if (!isValue(projectInputs.prjcNm)) {
      return showToast('프로젝트명을 입력해주세요.');
    } else if (selectedItemIndex !== 3 && !isValue(clntInputs.clntNm)) {
      return showToast('의뢰사 회사명을 입력해주세요.');
    } else if (selectedItemIndex === 0 && !isValue(dvlpInputs.dvlpNm)) {
      return showToast('관리사 회사명을 입력해주세요.');
    } else if (
      (isValue(clntInputs.mngrTelno) && clntInputs.mngrTelno.length > 11) ||
      (selectedItemIndex === 0 && isValue(dvlpInputs.mngrTelno) && dvlpInputs.mngrTelno.length > 11)
    ) {
      return showToast('전화번호는 최대 11자리입니다.');
    } else if (selectedItemIndex !== 0 && dvlpData.length <= 0) {
      return showToast('개발사 정보를 입력해주세요.');
    }

    setRegPop(true);
  };

  const handleRegProject = () => {
    dispatch(
      postPrjc({
        userSn: user.userSn,
        userId: user.userId,
        stts: projectInputs.stts,
        prjcNm: projectInputs.prjcNm,
        prjcDscr: projectInputs.prjcDscr,
        stDt: setDateFormat(projectInputs.cntrStDt, 'YYYY-MM-DD'),
        endDt: setDateFormat(projectInputs.cntrEndDt, 'YYYY-MM-DD'),
        type: selectedItemIndex,
        clntCoCd: clntInputs.clntCd,
        clntCoNm: clntInputs.clntNm,
        clntZip: clntInputs.zip,
        clntAddr: clntInputs.addr,
        clntDaddr: clntInputs.daddr,
        clntMngrNm: clntInputs.mngr,
        clntMngrTelno: clntInputs.mngrTelno,
        clntMngrEmail: clntInputs.mngrEmail,
        dvlpCoList: selectedItemIndex === 0 ? [dvlpInputs] : dvlpData,
        callback: handleRegProjectCallback,
      })
    );
  };

  const handleRegProjectCallback = (data) => {
    if (data.resultCode === 0) {
      setRegPop(false);
      showToast('등록되었습니다.');
      setTimeout(() => {
        navigate('/mng/project');
      }, 1000);
    } else {
      showToastError(data);
    }
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton onClick={() => navigate('/mng/project')}>
            <ArrowBackIosNew />
          </IconButton>
          <Typography variant="h6">프로젝트 신규 생성</Typography>
        </Box>
      </Toolbar>
      <Box py={2} px={5}>
        <Grid container borderBottom={1} borderColor={'divider'} pb={2}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography>프로젝트 포지션</Typography>
            </Grid>
            <Grid item>
              <IconButton sx={{ color: 'orange' }} onClick={handleHelpClick}>
                <HelpOutline />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'left'}>
            {items.map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={2} mr={2}>
                <Card
                  variant="outlined"
                  component={Button}
                  onClick={() => handleItemClick(index, item.value)}
                  sx={{
                    p: 2,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    backgroundColor: selectedItemIndex === item.value ? '#deecfa' : undefined,
                    borderColor: selectedItemIndex === item.value ? 'primary.light' : 'primary',
                  }}
                >
                  <Box
                    sx={{
                      color: selectedItemIndex === item.value ? 'primary.main' : 'primary',
                    }}
                  >
                    <Typography color="primary.light" variant="body2" fontWeight="bold">
                      {item.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <PageProjectRegProjectInfoMain projectInputs={projectInputs} setProjectInputs={setProjectInputs} />
        {selectedItemIndex !== 3 && (
          <PageProjectRegClntInfoMain clntInputs={clntInputs} setClntInputs={setClntInputs} />
        )}
        {selectedItemIndex === 0 ? (
          <PageProjectRegMngInfoMain dvlpInputs={dvlpInputs} setDvlpInputs={setDvlpInputs} />
        ) : (
          <PageProjectRegDvlpInfoMain selectedItemIndex={selectedItemIndex} />
        )}
        <Grid container justifyContent={'center'} my={2}>
          <Grid item md={2}>
            <Button variant="outlined" className="btn-white" onClick={() => setCancelPop(true)}>
              취소
            </Button>
          </Grid>
          <Grid item md={2}>
            <Button variant="outlined" className="btn-navy" onClick={handleConfirmRegProject}>
              등록
            </Button>
          </Grid>
        </Grid>
      </Box>
      {isHelpOpen && <PageProjectPositonModal isOpen={isHelpOpen} setIsOpen={setIsHelpOpen} />}
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'등록하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'등록'}
          btnEvent={handleRegProject}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={() => navigate('/mng/project')}
        />
      )}
    </>
  );
};

export default PageProjectRegMain;
