import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ConfirmPopUp = (prop) => {
  const { open, close, title, comment, btnNm1, btnNm2, btnEvent } = prop;

  const formatTitle = title ? title.replace(/\r/g, '\n') : '';
  const formatComment = comment ? comment.replace(/\r/g, '\n') : '';

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: '30px',
            minWidth: '300px',
            maxWidth: '100%',
          },
        }}
        // maxWidth="xs"
      >
        <DialogTitle
          sx={{
            padding: '30px 40px !important',
            paddingBottom: '0px !important',
            textAlign: 'center',
            fontSize: '1.1rem',
          }}
        >
          {formatTitle &&
            formatTitle.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
        </DialogTitle>
        {formatComment && (
          <DialogContent sx={{ marginTop: '20px', padding: '0px 40px', textAlign: 'center' }}>
            {formatComment &&
              formatComment.split('\n').map((line, index) => (
                <DialogContentText key={index} sx={{ color: 'red', fontSize: '1rem' }}>
                  {line}
                </DialogContentText>
              ))}
          </DialogContent>
        )}
        <DialogActions>
          {btnNm1 && (
            <Button className="btn-white" onClick={() => close(false)}>
              {btnNm1}
            </Button>
          )}
          {btnNm2 && (
            <Button onClick={() => (btnEvent ? btnEvent() : close(false))} className="btn-navy">
              {btnNm2}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmPopUp;
