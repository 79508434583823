export const POST_CLIENT_LIST = 'POST_CLIENT_LIST';
export const POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS';
export const POST_CLIENT_REG = 'POST_CLIENT_REG';
export const POST_CLIENT_REG_SUCCESS = 'POST_CLIENT_REG_SUCCESS';
export const DEL_CLIENT_LIST = 'DEL_CLIENT_LIST';
export const GET_CLIENT_DETAIL = 'GET_CLIENT_DETAIL';
export const GET_CLIENT_DETAIL_SUCCESS = 'GET_CLIENT_DETAIL_SUCCESS';
export const GET_CLNNM_LIST = ' GET_CLNNM_LIST';
export const POST_DEV_LIST = 'POST_DEV_LIST';
export const DEL_DEV_LIST = 'DEL_DEV_LIST';
export const POST_DEV_REG = 'POST_DEV_REG';
export const GET_DEV_DETAIL = 'GET_DEV_DETAIL';
export const GET_DEV_DETAIL_SUCCESS = 'GET_DEV_DETAIL_SUCCESS';
export const GET_CODTL_LIST = 'GET_CODTL_LIST';
export const PUT_CLNT_CO = 'PUT_CLNT_CO';
export const PUT_DVLP_CO = 'PUT_DVLP_CO';
//export const GET_DVLPNM_LIST = 'GET_DVLPNM_LIST';

//의뢰사 관리
export const postClientList = (payload) => {
  return { type: POST_CLIENT_LIST, payload };
};

// export const postClientSuccess = (payload) => {
//   return { type: POST_CLIENT_SUCCESS, payload };
// };

export const postclientReg = (payload) => {
  return { type: POST_CLIENT_REG, payload };
};

export const delClientList = (payload) => {
  return { type: DEL_CLIENT_LIST, payload };
};

export const getClientDetail = (payload) => {
  return { type: GET_CLIENT_DETAIL, payload };
};

export const putClntCo = (payload) => {
  return { type: PUT_CLNT_CO, payload };
};

export const getClientDetailSuccess = (payload) => {
  return { type: GET_CLIENT_DETAIL_SUCCESS, payload };
};

export const getClntNmList = (payload) => {
  return { type: GET_CLNNM_LIST, payload };
};

export const getCoDtl = (payload) => {
  return { type: GET_CODTL_LIST, payload };
};

//개발사 관리
export const postDevList = (payload) => {
  return { type: POST_DEV_LIST, payload };
};

export const postDevReg = (payload) => {
  return { type: POST_DEV_REG, payload };
};

export const delDevList = (payload) => {
  return { type: DEL_DEV_LIST, payload };
};

export const getDevDetail = (payload) => {
  return { type: GET_DEV_DETAIL, payload };
};

export const getDevDetailSuccess = (payload) => {
  return { type: GET_DEV_DETAIL_SUCCESS, payload };
};

export const putDvlpCo = (payload) => {
  return { type: PUT_DVLP_CO, payload };
};
