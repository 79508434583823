import React, { useEffect, useState, useCallback } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { Card, Chip, Menu, MenuItem } from '@mui/material';
import { AccessTime, AccountCircle, SmartToy } from '@mui/icons-material';
import { SidebarItems } from './ListItem';
import Footer from './Footer';
import { AppBar, Drawer } from './layoutData';
import { setUserInit, getTokenSuccess } from '@store/actions/commonAction';
import { getToken } from '@store/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TimerModal from './TimerModal';
import { showToastError } from '@common/common';

const Layout = ({ isLogin, children }) => {
  const dispatch = useDispatch();
  const defaultTheme = createTheme();
  const limitTime = 1800; // 30분 설정
  const [timer, setTimer] = useState(limitTime);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [timerOpen, setTimerOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const user = useSelector((state) => state.commonReducer.user);
  const alarmCnt = useSelector((state) => state.commonReducer.user.alarmCnt);

  const moveAlarmPage = () => {
    navigate('/alarm');
  };

  // Timer reference
  const timerRef = React.useRef(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const getTokenCallback = (data) => {
    if (data.resultCode === 0) {
      dispatch(
        getTokenSuccess({
          token: data.data,
        })
      );
      resetTimehandler();
    } else {
      showToastError(data);
    }
  };

  const getTokenhandler = () => {
    dispatch(
      getToken({
        lgnId: user.userId,
        callback: getTokenCallback,
      })
    );
  };

  const resetTimehandler = () => {
    setTimerOpen(false);
    const expirationTime = Date.now() + limitTime * 1000;
    localStorage.setItem('logoutTime', expirationTime);
    setTimer(limitTime);

    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      const remainingTime = Math.max(expirationTime - Date.now(), 0);
      setTimer(Math.floor(remainingTime / 1000));

      if (remainingTime <= 0) {
        clearInterval(timerRef.current);
        logout();
      } else if (remainingTime <= 60000 && remainingTime > 59000) {
        setTimerOpen(true); // Alert when 1 minute is left
      } else if (remainingTime <= 30000 && remainingTime > 29000) {
        setTimerOpen(true); // Alert when 30 seconds are left
      }
    }, 1000);
  };

  const logout = useCallback(() => {
    handleMenuClose();
    dispatch(setUserInit());
    localStorage.removeItem('logoutTime');
    clearInterval(timerRef.current); // Clear timer on logout
    navigate('/login');
  }, [dispatch, handleMenuClose, navigate]);

  useEffect(() => {
    if (!isLogin) {
      const storedExpirationTime = localStorage.getItem('logoutTime');
      if (storedExpirationTime) {
        const remainingTime = Math.max(storedExpirationTime - Date.now(), 0);
        if (remainingTime <= 0) {
          logout();
        } else {
          setTimer(Math.floor(remainingTime / 1000));
          timerRef.current = setInterval(() => {
            const newRemainingTime = Math.max(storedExpirationTime - Date.now(), 0);
            setTimer(Math.floor(newRemainingTime / 1000));
            if (newRemainingTime <= 0) {
              clearInterval(timerRef.current);
              logout();
            } else if (newRemainingTime <= 60000 && newRemainingTime > 59000) {
              setTimerOpen(true); // Alert when 1 minute is left
            } else if (newRemainingTime <= 30000 && newRemainingTime > 29000) {
              setTimerOpen(true); // Alert when 30 seconds are left
            }
          }, 1000);
        }
      } else {
        resetTimehandler();
      }

      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [isLogin, logout, resetTimehandler]);

  if (!isLogin) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}></Typography>
              <IconButton color="inherit">
                <SmartToy />
              </IconButton>
              <IconButton color="inherit" onClick={moveAlarmPage}>
                <Badge badgeContent={alarmCnt > 0 ? alarmCnt : null} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Chip
                icon={<AccessTime />}
                label={formatTime(timer)}
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={getTokenhandler}
              />
              <IconButton color="inherit" onClick={handleMenuOpen}>
                <AccountCircle />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => navigate('/user/pageMypage')}>내 정보</MenuItem>
                <MenuItem onClick={logout}>로그아웃</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>

          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              {/* <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1, textAlign: 'center' }}>
                PMT
              </Typography> */}

              <Box className="logo" width="100%" height="100%" />
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <SidebarItems />
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Card sx={{ margin: '1.2%', minHeight: '740px' }}>{children}</Card>
            <Footer />
          </Box>
        </Box>
        {timerOpen && (
          <TimerModal timerOpen={timerOpen} setTimerOpen={setTimerOpen} resetTimehandler={resetTimehandler} />
        )}
      </ThemeProvider>
    );
  } else {
    return children;
  }
};

export default Layout;
