import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageProjectIssueBoard from './PageProjectIssueBoard';
import PageProjectIssueChart from './PageProjectIssueChart';
import PageProjectIssueReg from './PageProjectIssueReg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getIssueList } from '@store/actions/projectAction';
import PageProjectIssueDelete from './PageProjectIssueDelete';
import { useLocation, useNavigate } from 'react-router-dom';

const PageProjectIssue = ({ projectSn }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);
  const navigate = useNavigate();

  const [toggleValue, setToggleValue] = useState('');
  const [regOpen, setRegOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    if (type) {
      setToggleValue(type);
    }
  }, [location]);

  useEffect(() => {
    if (toggleValue !== '') getIssue();
  }, [toggleValue]);

  const getIssue = () => {
    console.log('여기서?');
    dispatch(
      getIssueList({
        searchType: toggleValue === 'B' ? 1 : 2,
        prjcMainSn: projectSn,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        userFlag: userInfo.userFlag,
        callback: fn_issueList,
      })
    );
  };

  const [resultData, setResultData] = useState({});

  const fn_issueList = (data) => {
    if (data.resultCode === 0) {
      setResultData(data.data);
    }
  };

  return (
    <>
      <Toolbar>
        <Grid
          container
          borderBottom={1}
          borderColor={'divider'}
          pb={2}
          pt={2}
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={toggleValue}
            className="radio-btn"
            exclusive
            onChange={(e) => navigate('/mng/project/detail?tab=2&&type=' + e.target.value)}
          >
            <ToggleButton value="B" aria-label="B" name="issue">
              Board
            </ToggleButton>
            <ToggleButton value="C" aria-label="C" name="issue">
              Chart
            </ToggleButton>
          </ToggleButtonGroup>
          <Box>
            <Button className="btn" sx={{ mr: 1 }} onClick={() => setRegOpen(true)}>
              이슈 등록
            </Button>
            <Button
              className="btn-red"
              sx={{
                visibility: resultData.delBtnExpsYn !== 'Y' ? 'hidden' : 'visible',
              }}
              onClick={() => setDeleteOpen(true)}
            >
              삭제
            </Button>
          </Box>
        </Grid>
      </Toolbar>
      {toggleValue === 'B' && <PageProjectIssueBoard projectSn={projectSn} resultData={resultData} />}
      {toggleValue === 'C' && <PageProjectIssueChart projectSn={projectSn} resultData={resultData} />}

      {regOpen && (
        <PageProjectIssueReg regOpen={regOpen} setRegOpen={setRegOpen} projectSn={projectSn} handleIssue={getIssue} />
      )}
      {deleteOpen && (
        <PageProjectIssueDelete
          deleteOpen={deleteOpen}
          setDeleteOpen={setDeleteOpen}
          resultData={resultData}
          handleIssue={getIssue}
        />
      )}
    </>
  );
};

export default PageProjectIssue;
