import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
// import styles from 'bootswatch/dist/litera/bootstrap.min.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../../styles/bootstrap.min.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageProjectCalendarPop from './PageProjectCalendarPop';
import { useDispatch } from 'react-redux';
import { getPrjcEventList } from '@store/actions/projectAction';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const PageProjectCalendar = (prop) => {
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);

  const [eventList, setEventList] = useState();

  const { projectSn } = prop;

  const fn_eventList = (data) => {
    if (data.resultCode === 0) {
      setEventList(data.data.eventList);
    }
  };

  const changeDates = (arg) => {
    const startDate = format(arg.start, 'yyyy-MM-dd HH:mm:ss');
    const endDate = format(arg.endStr, 'yyyy-MM-dd HH:mm:ss');

    dispatch(
      getPrjcEventList({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        prjcStDttm: startDate,
        prjcEndDttm: endDate,
        prjcMainSn: projectSn,
        callback: fn_eventList,
      })
    );
  };

  const calendarRef = useRef(null);

  const handleSaveResult = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  };

  const [selectEvent, setSelectEvent] = useState('');
  const handleEvent = (e) => {
    const event = e.event;
    console.log(event.extendedProps.kebabYn);
    setSelectEvent({
      prjcEventSn: event.extendedProps.prjcEventSn,
      prjcMainSn: projectSn,
      title: event.title,
      start: event.start,
      end: event.end === null ? event.start : event.end,
      prjcContent: event.extendedProps.prjcContent,
      inptPrsnSn: event.extendedProps.inptPrsnSn,
      userSn: userInfo.userSn,
      userId: userInfo.userId,
      kebabYn: event.extendedProps.kebabYn,
    });
    setOpenPopup(true);
  };
  const containerRef = useRef(null);

  const debouncedUpdateSize = useCallback(
    debounce(() => {
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.updateSize();
      }
    }, 100),
    []
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateSize();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      debouncedUpdateSize.cancel();
    };
  }, [debouncedUpdateSize]);

  return (
    <>
      <Box className="proejct-fullCalendar" sx={{ p: 2 }} ref={containerRef}>
        <FullCalendar
          ref={calendarRef}
          editable
          selectable
          headerToolbar={{
            start: 'prev,next today addEventButton',
            center: 'title',
            end: 'dayGridMonth,timeGridWeek,timeGridDay,listDay',
          }}
          events={eventList}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin]}
          buttonText={{
            listWeek: 'list',
          }}
          customButtons={{
            addEventButton: {
              text: 'NEW',
              click: function () {
                setSelectEvent('');
                setOpenPopup(true);
              },
            },
          }}
          locale="ko"
          eventClick={handleEvent}
          themeSystem="bootstrap5"
          // dayMaxEventRows={4} // month 부분 이벤트 최대갯수
          eventMaxStack={3} // week, day 부분 최대갯수
          allDaySlot={false} // week부분 allday 부분 안보이도록 설정
          slotEventOverlap={false} // week와 day등에서 서로 안겹치도록 하게 설정
          // showNonCurrentDates={false}  // 다른 달 안보이게(false시)
          fixedWeekCount={false} // 1달 기준 주 수 default 6주
          dayMaxEvents={true}
          datesSet={(e) => changeDates(e)}
          noEventsContent={'오늘의 일정은 존재하지 않습니다.'}
        />
      </Box>
      {openPopup && (
        <PageProjectCalendarPop
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          projectSn={projectSn}
          updateEvent={selectEvent}
          saveResult={handleSaveResult}
        />
      )}
    </>
  );
};

export default PageProjectCalendar;
