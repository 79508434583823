import { Close, Search } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getCntrDtl, getCntrList } from '@store/actions/contractAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageLoadContractModal = ({ cntrPopup, setCntrPopup, setSendData }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList =
      projectList &&
      projectList.length > 0 &&
      projectList.filter((data) => {
        const slsNmMatch = data.prjcNm ? data.prjcNm.toLowerCase().includes(newKeyword) : false;
        const coNmMatch = data.clntCoNm ? data.clntCoNm.toLowerCase().includes(newKeyword) : false;
        return slsNmMatch || coNmMatch;
      });

    setFilteredList(filteredList);
  };

  const displayList = keyword ? filteredList : projectList;
  const fn_cntrList = (data) => {
    if (data.resultCode === 0) {
      const list = [];

      Object.keys(data.data).forEach((e) => {
        list.push(...data.data[e]);
      });

      console.log(list);
      setProjectList(list);
    }
  };

  useEffect(() => {
    dispatch(
      getCntrList({
        userId: user.userId,
        userSn: user.userSn,
        callback: fn_cntrList,
      })
    );
  }, []);

  const handleGetCntrDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setSendData((prev) => ({
        ...prev,
        cntrStDt: data.data.cntrStDt,
        cntrEndDt: data.data.cntrEndDt,
        cntrAmnt: data.data.cntrAmnt,
        prjcNm: data.data.prjcNm,
        coNm: data.data.clntCoNm,
        cntrYN: 'Y',
        cntrSn: data.data.cntrSn,
      }));
      setCntrPopup(false);
    }
  };

  const handleGetCntrDtl = (cntrSn) => {
    dispatch(
      getCntrDtl({
        userId: user.userId,
        userSn: user.userSn,
        cntrSn,
        callback: handleGetCntrDtlCallback,
      })
    );
  };

  return (
    <>
      <Dialog open={cntrPopup} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
        <DialogTitle>
          <Button className="btn-close">
            <Close onClick={() => setCntrPopup(false)} />
          </Button>
          <Typography className="modal-tit" fontWeight={'bold'}>
            프로젝트 선택
          </Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            계약목록 중 정산 정보를 등록할 프로젝트를 선택해주세요.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  className="inp-round"
                  placeholder="프로젝트를 검색해주세요."
                  autoComplete="off"
                  size="small"
                  name="keyword"
                  onKeyUp={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item className="list-type">
            {displayList && displayList.length > 0 ? (
              displayList.map((item) => (
                <Grid container key={item.cntrSn}>
                  <Grid item>
                    <Typography variant="body1" fontWeight={'500'}>
                      {item.prjcNm}
                    </Typography>
                    <Typography variant="body2">{item.clntCoNm}</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Button className="btn-orange" onClick={() => handleGetCntrDtl(item.cntrSn)}>
                      선택
                    </Button>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid item md={12}>
                <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PageLoadContractModal;
