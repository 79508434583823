import React from 'react';
import { Route, Routes } from 'react-router-dom';
import pages from './pages';
import PrivateRoute from './PrivateRoute';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Layout from '@pages/layout/Layout';

function PageHome(props) {

  const stateSelector = createSelector(
    (state) => state.commonReducer,
    (commonReducer) => ({
      userNm: commonReducer?.user.userNm ?? '',
      userId: commonReducer?.user.userId ?? '',
    })
  );

  const { userId } = useSelector(stateSelector);

  const isLogin = userId ? true : false;

  return (
    <>
      <Routes>
        {pages.map((i) => {

          const isAuthenticated = i.isPublic || isLogin;

          return (
            <Route
              key={i.pathname}
              path={i.pathname}
              element={
                <>
                  <PrivateRoute pathname={i.pathname} isAuthenticated={isAuthenticated}>
                    <Layout isLogin={!i.isAuth}>{i.element}</Layout>
                  </PrivateRoute>
                </>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default PageHome;
