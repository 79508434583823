import { setDateFormat } from '@common/common';
import { Chip, Grid, Popover, Typography } from '@mui/material';
import { getCntrHistory } from '@store/actions/contractAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SALES_STEP_COLORS } from '@common/constants';

const PageSalesHistory = ({ anchorEl, id, open, handleClose, data }) => {

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '.MuiPopover-paper': {
          minWidth: '350px',
          padding: '20px',
          borderRadius: '15px',
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.01), 0px 8px 5px 1px rgba(0, 0, 0, 0.02), 0px 3px 5px 2px rgba(0, 0, 0, 0.02)',
        },
      }}
    >
      <Typography variant="h5" color={'gray'} sx={{fontSize: '1rem', padding: '0px'}}>
        HISTORY
      </Typography>
      {data &&
        data.data.stgLog.map((item, index) => (
          <Grid container key={index} alignItems={'center'} justifyContent={'space-between'} sx={{paddingTop: '16px'}}>
            <Grid item md={4}>
                <Chip label={item.stgNm} variant="outlined" sx={{ minWidth: '90px', borderColor: SALES_STEP_COLORS[index % SALES_STEP_COLORS.length] }} />
            </Grid>
            <Grid item md={7}>
              <Typography variant="body2">
                {setDateFormat(item.regDttm, 'YYYY-MM-DD HH:mm')} {item.userNm}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Popover>
  );
};

export default PageSalesHistory;
