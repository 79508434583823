import { setNumberComma, showToastError, strToPriceFormat } from '@common/common';
import { ArrowDropDown, ArrowDropUp, Info } from '@mui/icons-material';
import { Box, Card, Chip, Grid, IconButton, Typography } from '@mui/material';
import { Pie, ResponsivePie } from '@nivo/pie';
import { getPaymentStatistics } from '@store/actions/paymentAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const MainSalesData = ({ resultData }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [paymentCostData, setPaymentCostData] = useState({});
  const [monthPaysData, setMonthPaysData] = useState([]);

  useEffect(() => {
    dispatch(
      getPaymentStatistics({
        userSn: user.userSn,
        userId: user.userId,
        callback: handleGetPaymentStatisticsCallback,
      })
    );
    const formattedData = Array.isArray(resultData.monthPays)
      ? resultData.monthPays.map((item) => ({
          id: item.prjcNm, // 'id' for the pie slice
          label: item.prjcNm, // Optional: 'label' for display
          value: item.cost, // 'value' holds the numeric data
        }))
      : []; // Default to an empty array if paysData is not an array

    setMonthPaysData(formattedData);
  }, [resultData.monthPays]);

  const handleGetPaymentStatisticsCallback = (data) => {
    if (data.resultCode === 0) {
      setPaymentCostData(data.data.paymentCost);
    } else {
      showToastError(data);
    }
  };
  return (
    <Grid container px={2} mb={2}>
      <Grid item sm={12} md={6}>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
          }}
        >
          <Box p={2}>
            <Typography>월별 매출 기여 금액</Typography>
          </Box>
          <Grid container justifyContent={'center'}>
            <Pie
              data={monthPaysData}
              innerRadius={0.6}
              margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
              //padAngle={0.7}
              //cornerRadius={3}
              width={450}
              height={450}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              //arcLinkLabelsTextColor="#333333"
              arcLinkLabelsTextColor="transparent"
              arcLinkLabelsThickness={2}
              //arcLinkLabelsColor={{ from: 'color' }}
              arcLinkLabelsColor="transparent"
              arcLabelsSkipAngle={10}
              valueFormat={(value) => strToPriceFormat(value)}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
          }}
        >
          <Grid item md={12} p={2} display={'flex'} alignItems={'center'}>
            <Typography>당월 실지급액 합계</Typography>
            <IconButton>
              <Info />
            </IconButton>
          </Grid>
          <Grid container px={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Typography variant="h5">{setNumberComma(String(paymentCostData.thisMonthCost))}</Typography>
              <Typography color={'gray'}>&nbsp;원</Typography>
            </Grid>
            <Grid item md={6} my={4.5}>
              <Chip label={'작년 동일 월 대비'} variant="outlined" />
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <IconButton color="primary">
                  {paymentCostData.thisMonthCost >= paymentCostData.lastYearCost ? (
                    <ArrowDropUp color="primary" />
                  ) : (
                    <ArrowDropDown color="error" />
                  )}
                </IconButton>
                <Typography>
                  {' '}
                  {setNumberComma(String(paymentCostData.thisMonthCost - paymentCostData.lastYearCost))}
                </Typography>
                <Typography variant="body1" color={'grey'}>
                  &nbsp;({setNumberComma(String(Math.abs(paymentCostData.lastYearCost)))}원)
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={6} my={4.5}>
              <Chip label={'전월 실지급액 대비'} variant="outlined" />
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <IconButton color="error">
                  {paymentCostData.thisMonthCost >= paymentCostData.lastMonthCost ? (
                    <ArrowDropUp color="primary" />
                  ) : (
                    <ArrowDropDown color="error" />
                  )}
                </IconButton>
                <Typography>
                  {' '}
                  {setNumberComma(String(Math.abs(paymentCostData.thisMonthCost - paymentCostData.lastMonthCost)))}
                </Typography>
                <Typography variant="body1" color={'grey'}>
                  &nbsp;({setNumberComma(String(paymentCostData.lastMonthCost))}원)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
          }}
        >
          <Grid item md={12} p={2} display={'flex'} alignItems={'center'}>
            <Typography>지급 임박 총액</Typography>
            <IconButton>
              <Info />
            </IconButton>
          </Grid>
          <Grid container py={2.5}>
            <Grid container px={2} mb={2}>
              <Grid item md={5}>
                <Typography color={'error'}>당일 지급 예정 금액</Typography>
              </Grid>
              <Grid item md={7} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Typography variant="h6">{setNumberComma(String(paymentCostData.todayCost))}</Typography>
                <Typography color={'grey'}>&nbsp;원</Typography>
              </Grid>
            </Grid>
            <Grid container px={2} mb={2}>
              <Grid item md={5}>
                <Typography color={'primary'}>익일 지급 예정 금액</Typography>
              </Grid>
              <Grid item md={7} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Typography variant="h6">{setNumberComma(String(paymentCostData.nextDayCost))}</Typography>
                <Typography color={'grey'}>&nbsp;원</Typography>
              </Grid>
            </Grid>
            <Grid container px={2}>
              <Grid item md={5}>
                <Typography>지급 지연 금액</Typography>
              </Grid>
              <Grid item md={7} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Typography variant="h6">{setNumberComma(String(paymentCostData.delayCost))}</Typography>
                <Typography color={'grey'}>&nbsp;원</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
