import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PageSuccLayout({ title, titleContent, succMsg }) {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // 너비를 100%로 설정
          }}
        >
          <Typography component="h1" variant="h5">
            PMT
          </Typography>
          <Box noValidate sx={{ mt: 5 }} minWidth={'500px'}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: 4, // 모서리를 둥글게 설정 (단위는 4px)
                padding: 5, // 카드 내부 여백 추가
              }}
            >
              <CardContent>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'} fontWeight={'bold'}>
                    {title}
                  </Typography>
                  <Typography variant="h6" fontWeight={'bold'}>
                    {titleContent}
                  </Typography>
                </Box>
                <Typography sx={{ mt: 1 }} whiteSpace={'pre-line'} variant="body2" color="text.secondary">
                  {succMsg}
                </Typography>
                <Typography>
                  <Button type="button" fullWidth variant="contained" sx={{ mt: 5 }} onClick={() => navigate('/login')}>
                    로그인 화면으로 이동
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default PageSuccLayout;
