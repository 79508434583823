import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { getInptPrsnList } from '@store/actions/projectAction';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import PageProjectInptPrsnList from './PageProjectInptPrsnList';
import PageProjectInptPrsnReg from './PageProjectInptPrsnReg';
import { isComplHangul, setDateFormat } from '@common/common';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectInptPrsn = (prop) => {
  const { projectSn } = prop;
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();

  const [searchData, setSearchData] = useState({
    searchType: 'userNm',
    searchValue: '',
  });

  const [resultData, setResultData] = useState([]);

  const [wrkfList, setWrkfList] = useState([]);

  const [allData, setAllData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 5;

  const fn_searchResult = (data) => {
    if (data.resultCode === 0) {
      if (data.data.length > 0) {
        const sortData = data.data.sort((a, b) => new Date(b.inptEndDt) - new Date(a.inptEndDt));
        setAllData(sortData);
        setTotalCount(data.totalCount);

        let wrkfSnList = [];
        data.data.forEach((item) => {
          if (item.inptEndDt > setDateFormat(new Date(), 'YYYY-MM-DD')) {
            wrkfSnList.push(item.wrkfSn);
          }
        });

        setWrkfList(wrkfSnList);
        setResultData(data.data.slice(0, itemsPerPage));
        setCurrentIndex(itemsPerPage);
      }
    }
  };

  const fetchAllData = () => {
    dispatch(
      getInptPrsnList({
        prjcMainSn: projectSn,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        pageNum: 0,
        callback: fn_searchResult,
      })
    );
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const loadMoreItems = () => {
    if (currentIndex >= allData.length && searchData.searchValue === '') return;

    if (searchData.searchValue === '') {
      const nextIndex = Math.min(currentIndex + itemsPerPage, allData.length);

      setResultData([...resultData, ...allData.slice(currentIndex, nextIndex)]);
      setCurrentIndex(nextIndex);
    } else {
      const data = allData.filter((e) => e[searchData.searchType].includes(searchData.searchValue));

      const nextIndex = Math.min(currentIndex + itemsPerPage, data.length);
      setResultData([...resultData, ...data.slice(currentIndex, nextIndex)]);
      setCurrentIndex(nextIndex);
    }
  };

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadMoreItems();
      }
    },
    [loadMoreItems]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 1,
    });
    const observerTarget = document.getElementById('observer');
    if (observerTarget) {
      observer.observe(observerTarget);
    }
    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [handleObserver]);

  const [movePage, setMovePage] = useState(false);
  const handleInpt = () => {
    fetchAllData();
    setMovePage(true);
  };

  const [regInpt, setRegInpt] = useState(false);

  const handleOpenPopup = () => {
    setRegInpt(true);
  };

  const handleUpdate = () => {
    fetchAllData();
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'searchValue' && isComplHangul(value)) {
      setResultData(() => {
        if (value === '') {
          setTotalCount(allData.length);
          setCurrentIndex(itemsPerPage);
          return allData.slice(0, itemsPerPage);
        }

        const data = allData.filter((e) => e[searchData.searchType].includes(value));
        setTotalCount(data.length);
        setCurrentIndex(itemsPerPage);
        return data.slice(0, itemsPerPage);
      });
    }
  };

  const handleMovePage = () => {
    setMovePage(false);
  };

  return (
    <>
      <Toolbar
        sx={{
          mx: 2,
          paddingRight: '0px !important',
          paddingLeft: '0px !important',
        }}
      >
        <Grid container borderBottom={1} borderColor={'divider'}>
          <Grid
            item
            md={12}
            sx={{
              justifyContent: 'space-between',
            }}
            display={'flex'}
            alignItems={'center'}
            py={2}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Box sx={{ minWidth: 150, mr: 1 }}>
                <FormControl size="small" fullWidth>
                  <Select
                    name="searchType"
                    displayEmpty
                    value={searchData.searchType}
                    onChange={(e) => handleSearch(e)}
                  >
                    <MenuItem value="userNm">이름</MenuItem>
                    <MenuItem value="gender">성별</MenuItem>
                    <MenuItem value="age">나이</MenuItem>
                    <MenuItem value="rank">직급</MenuItem>
                    <MenuItem value="position">담당직무</MenuItem>
                    <MenuItem value="dtlPosition">상세직무</MenuItem>
                    <MenuItem value="mblNo1">연락처</MenuItem>
                    <MenuItem value="email">이메일</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <TextField
                sx={{ minWidth: '330px' }}
                placeholder={'내용을 검색해주세요'}
                disabled={searchData.searchType === '' ? true : false}
                autoComplete="off"
                size="small"
                name="searchValue"
                className="inp-round"
                onChange={(e) => handleSearch(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <FormControl size="small" sx={{ mr: 2, minWidth: '100px' }}>
                <Select name="position" displayEmpty fullWidth defaultValue={''}>
                  <MenuItem value="">전체</MenuItem>
                  <MenuItem value="1">개발자</MenuItem>
                </Select>
              </FormControl>
              {userInfo.userFlag === 'D' && (
                <Button className="btn" onClick={handleOpenPopup}>
                  투입인력추가
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container m={2} mb={0}>
        <Typography variant="subtitle1">총 {totalCount} 건</Typography>
      </Grid>
      <Grid container>
        {resultData &&
          resultData.length > 0 &&
          resultData.map((item) => (
            <PageProjectInptPrsnList item={item} key={item.inptPrsnSn} updateResult={handleUpdate} />
          ))}
      </Grid>
      <Grid display="hidden" id="observer" />

      {regInpt && (
        <PageProjectInptPrsnReg
          regInpt={regInpt}
          setRegInpt={setRegInpt}
          projectSn={projectSn}
          saveInpt={handleInpt}
          wrkfList={wrkfList}
        />
      )}

      {/* {movePage && (
        <ConfirmPopUp
          open={movePage}
          close={setMovePage}
          title={'투입인력 추가가 완료되었습니다.\n즉시 지급 정보를 생성 하시겠습니까?'}
          comment={'* 추후 지급관리 메뉴에서 생성하실 수 있습니다.'}
          btnNm1={'아니요'}
          btnNm2={'예'}
          btnEvent={handleMovePage}
        />
      )} */}
    </>
  );
};

export default PageProjectInptPrsn;
