import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageLoadDvlpModal from './PageLoadDvlpModal';
import { isValue, setTelFormat, showToast } from '@common/common';
import DaumPostCode from 'component/DaumPostCode';
import { useDispatch } from 'react-redux';
import { getPrjcDvlpSuccess, updatePrjcDvlpInfo } from '@store/actions/projectAction';
import { useSelector } from 'react-redux';

const PageAddDvlpModal = ({ isOpen, setIsOpen, type }) => {
  const dvlpData = useSelector((state) => state.projectReducer.projectInfo);
  const [isDvlpOpen, setIsDvlpOpen] = useState(false);
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [dvlpInputs, setDvlpInputs] = useState(dvlpData);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setDvlpInputs({
      ...dvlpInputs,
      [name]: value,
    });
  };

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsPostOpen(false);
    setDvlpInputs({
      ...dvlpInputs,
      zip: zonecode,
      addr: address,
    });
  };

  const handleSaveDvlpInfo = () => {
    if (!isValue(dvlpInputs.dvlpNm)) {
      return showToast('회사명을 입력해주세요.');
    }

    setIsOpen(false);
    if (type === 'reg') {
      dispatch(getPrjcDvlpSuccess(dvlpInputs));
    } else {
      dispatch(updatePrjcDvlpInfo(dvlpInputs));
    }
  };
  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
        <DialogTitle>
          <Grid container justifyContent={'space-between'}>
            <Typography className="modal-tit" fontWeight={'bold'}>
              개발사 {type === 'reg' ? '추가' : '수정'}
            </Typography>
            {type === 'reg' && (
              <Button className="btn-orange" onClick={() => setIsDvlpOpen(true)}>
                정보 가져오기
              </Button>
            )}
          </Grid>
          {type === 'upd' && (
            <Grid>
              <Typography color={'grey'} variant="body2" mt={1}>
                현 위치에서의 수정은 프로젝트 내 개발사 정보 수정으로,
                <br />
                회사 정보 수저은 개발사 관리 메뉴에서 가능합니다.
              </Typography>
            </Grid>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle2">회사코드</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpCoSn"
                  variant="standard"
                  onChange={handleInputChange}
                  value={dvlpInputs.coCd ? dvlpInputs.coCd : ''}
                  fullWidth
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle2">회사명</Typography>
                <Typography variant="subtitle2" color={'red'}>
                  *
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="dvlpNm"
                  variant="standard"
                  onChange={handleInputChange}
                  value={dvlpInputs.dvlpNm ? dvlpInputs.dvlpNm : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle2">회사 주소</Typography>
              </Grid>
              <Grid item md={8} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item md={6}>
                  <TextField
                    name="zip"
                    variant="standard"
                    onChange={handleInputChange}
                    value={dvlpInputs.zip ? dvlpInputs.zip : ''}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={5}>
                  <Button type="button" className="btn" onClick={() => setIsPostOpen(true)}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  name="addr"
                  variant="standard"
                  placeholder="기본 주소 입력"
                  onChange={handleInputChange}
                  value={dvlpInputs.addr ? dvlpInputs.addr : ''}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  name="daddr"
                  variant="standard"
                  placeholder="상세 주소 입력"
                  onChange={handleInputChange}
                  value={dvlpInputs.daddr ? dvlpInputs.daddr : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle2">담당자</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="mngr"
                  variant="standard"
                  onChange={handleInputChange}
                  value={dvlpInputs.mngr ? dvlpInputs.mngr : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle2">담당자 연락처</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="mngrTelno"
                  variant="standard"
                  onChange={handleInputChange}
                  value={dvlpInputs.mngrTelno ? dvlpInputs.mngrTelno : ''}
                  fullWidth
                  inputProps={{ maxLength: '13' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle2">담당자 이메일</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  name="mngrEmail"
                  variant="standard"
                  onChange={handleInputChange}
                  value={dvlpInputs.mngrEmail ? dvlpInputs.mngrEmail : ''}
                  fullWidth
                  inputProps={{ maxLength: '50' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'space-around'}>
            <Grid item md={5}>
              <Button className="btn-white" onClick={() => setIsOpen(false)} sx={{ width: '100%' }}>
                취소
              </Button>
            </Grid>
            <Grid item md={5}>
              <Button
                className="btn-navy"
                // onClick={handlePostCntr}
                sx={{ width: '100%' }}
                onClick={handleSaveDvlpInfo}
              >
                {type === 'reg' ? '추가' : '수정'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {isPostOpen && <DaumPostCode isOpen={isPostOpen} setIsOpen={setIsPostOpen} handleZipSearch={handleZipSearch} />}
      {isDvlpOpen && (
        <PageLoadDvlpModal
          isOpen={isDvlpOpen}
          setIsOpen={setIsDvlpOpen}
          inputs={dvlpInputs}
          setInputs={setDvlpInputs}
        />
      )}
    </>
  );
};

export default PageAddDvlpModal;
