import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';
import { forwardRef } from 'react';
import { TextField } from '@mui/material';

export const getDateByformat = (dateString, formatString) => {
  try {
    return moment(dateString).format(formatString);
  } catch {
    return dateString ?? '';
  }
};

export const strToPriceFormat = (str) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }

  return str
    .toString()
    .replace(/[^0-9]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const showToastMsg = (title, icon) => {
  Swal.fire({
    position: 'top-end',
    icon: icon,
    title: title,
    showConfirmButton: false,
    timer: 1500,
    width: '50%',
  });
};

export const showToast = (title, type = 'info', font = '1.1rem') => {
  toast(title, {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: true,
    style: {
      fontSize: font, // 원하는 폰트 크기로 설정
    },
  });
};

export const showToastError = (data, font = '1.1rem') => {
  toast(data.resultCode + ':' + data.resultMsg, {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: true,
    style: {
      fontSize: font, // 원하는 폰트 크기로 설정
    },
  });
};

/* 빈값 체크 */
export const isValue = (value) => {
  return !(value === null || value === undefined || (typeof value === 'string' && value.trim() === ''));
};

/* 이메일 유효성 체크 */
export const isEmail = (value) => {
  if (value == '') {
    return false;
  }
  const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regEmail.test(value);
};

/* 휴대폰 번호 유효성 체크 */
export const isMobile = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regPhoneNumber = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
  return regPhoneNumber.test(value);
};

/* 일반 전화 번호 유효성 체크 */
export const isTel = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regPhoneNumber = /^(070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
  return regPhoneNumber.test(value);
};

/* 아이디 체크 */
export const isId = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regex = /^(?=.*[a-z])[a-z\d]{8,20}$/i;
  return regex.test(value);
};

/* 패스워드 체크 */
export const isPassword = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*^?&])[A-Za-z\d@$!%*^?&]{8,16}$/;
  return regex.test(value);
};

/* 숫자로만 이루어진 문자열을 검사하는 정규식 */
export const isNumber = (value) => {
  if (!isValue(value)) {
    return false;
  }
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

/* 숫자로만 이루어진 문자열이고, 입력된 자릿수 이상인지를 검사하는 정규식 */
export const isNumberExact = (value, length) => {
  if (!isValue(value)) {
    return false;
  }
  const regNumber = new RegExp(`^\\d{${length},}$`);
  return regNumber.test(value);
};

/* 입력된 값의 길이가 minLength 이상인지를 확인 */
export const isLeast = (value, minLength) => {
  if (!isValue(value)) {
    return false;
  }
  return typeof value === 'string' && value.length >= minLength;
};

/* 입력된 값의 길이가 maxLength 미만인지를 확인 */
export const isless = (value, maxLength) => {
  if (!isValue(value)) {
    return false;
  }
  return typeof value === 'string' && value.length < maxLength;
};

/* 입력된 값의 길이가 minLength 이상이고 maxLength 이하인지를 확인 */
export const isBetween = (value, minLength, maxLength) => {
  if (!isValue(value)) {
    return false;
  }
  return typeof value === 'string' && value.length >= minLength && value.length <= maxLength;
};

/* 쿼리 문자열에서 특정 파라미터 값을 확인 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

/* 숫자만 입력가능하게 설정 */
export const setOnlyNumber = (value) => {
  if (!isValue(value)) {
    return '';
  }
  return value.replace(/[^0-9]/g, '');
};

/* YYYYMMDD 날짜 형식 유효성 체크 */
export const isYmd = (value) => {
  if (!isValue(value)) {
    return false;
  }
  return moment(value, 'YYYYMMDD', true).isValid();
};

/* 사업자등록번호 유효성 체크 */
export const isBizNumber = (value) => {
  if (!isValue(value)) {
    return false;
  }

  if (!/^\d{10}$/.test(value)) {
    return false;
  }

  var numberMap = value
    .replace(/-/gi, '')
    .split('')
    .map(function (d) {
      return parseInt(d, 10);
    });

  if (numberMap.length == 10) {
    var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    var chk = 0;

    keyArr.forEach(function (d, i) {
      chk += d * numberMap[i];
    });

    chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);
    console.log(chk);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }

  return false;
};

const isHangulSyllable = (value) => {
  const code = value.charCodeAt(0);
  return code >= 0xac00 && code <= 0xd7a3;
};

const isEnglishLetter = (value) => {
  const code = value.charCodeAt(0);
  return (code >= 0x41 && code <= 0x5a) || (code >= 0x61 && code <= 0x7a); // A-Z or a-z
};

const isDigit = (value) => {
  const code = value.charCodeAt(0);
  return code >= 0x30 && code <= 0x39; // 0-9
};

const isValidCharacter = (value) => {
  return isHangulSyllable(value) || isEnglishLetter(value) || isDigit(value);
};

/* 완성형 한글(영어, 숫자) 유효성 체크 */
export const isComplHangul = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  for (let char of value) {
    if (!isValidCharacter(char)) {
      return false;
    }
  }
  return true;
};

/* 천 단위로 콤마를 추가 */
export const setNumberComma = (value) => {
  if (!isValue(value)) {
    return '';
  }
  // 입력 값에서 숫자와 콤마만 남기기
  const numericValue = value.replace(/[^\d]/g, '');

  // 콤마 추가
  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* YYYYMMDD에 FORMAT 추가 */
export const setDateFormat = (value, format = 'YYYY.MM.DD') => {
  if (!isValue(value)) {
    return '';
  }

  let formats = [
    'YYYYMMDD',
    'YYYY-MM-DD',
    'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm:ss.s',
    'YYYYMMDDHHmmss',
    'YYYYMMDDHHmm',
  ];
  let formattedDate = ''; // 변환된 날짜를 저장할 변수
  // 유효한 형식을 찾아서 변환
  for (let fmt of formats) {
    let date = moment(value, fmt, true); // strict parsing
    if (date.isValid()) {
      formattedDate = date.format(format); // 원하는 포맷으로 날짜를 변환하여 변수에 저장
      break; // 유효한 날짜를 찾으면 반복을 멈춥니다.
    }
  }

  if (!formattedDate) {
    console.warn('No valid date format found for:', value);
  }

  return formattedDate;
  /*
  if (value.length === 8) {
    value = value.substring(0, 4) + format + value.substring(4, 6) + format + value.substring(6, 8);
  } else if (value.length === 14) {
    value =
      value.substring(0, 4) +
      '-' +
      value.substring(4, 6) +
      '-' +
      value.substring(6, 8) +
      ' ' +
      value.substring(8, 10) +
      ':' +
      value.substring(10, 12) +
      ':' +
      value.substring(12, 14);
  }
  return moment(value).format(format);
  */
};

/* 전화번호에 FORMAT 추가 */
export const setTelFormat = (value, format) => {
  if (!isValue(value)) {
    return '';
  }

  let cleaned = ('' + value).replace(/\D/g, '');
  let mobileMatch = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);

  if (mobileMatch) {
    return `${mobileMatch[1]}${format}${mobileMatch[2]}${format}${mobileMatch[3]}`;
  }

  let phoneMatch = cleaned.match(/^(\d{2,3})(\d{3,4})(\d{4})$/);

  if (phoneMatch) {
    return `${phoneMatch[1]}${format}${phoneMatch[2]}${format}${phoneMatch[3]}`;
  }

  return value;
};

/* 파일업로드 - (DTO, FILE)  */
export const convertFomData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'files') {
      data[key].forEach((file) => {
        formData.append('files', file);
      });
    } else formData.append(key, new Blob([JSON.stringify(data[key])]), { type: 'application/json' });
  });

  return formData;
};

/* 생년월일에 FORMAT 추가 */
export const setBrdtFormat = (value, format) => {
  if (!isValue(value)) {
    return '';
  }

  let cleaned = ('' + value).replace(/\D/g, '');
  let brdtMatch = cleaned.match(/^(\d{4})(\d{2})(\d{2})$/);

  return `${brdtMatch[1]}${format}${brdtMatch[2]}${format}${brdtMatch[3]}`;
};

/* Input File 일때 다운로드 */
export const downloadFileInput = (file) => {
  const downloadUrl = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = file.name;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/* DB 저장된 파일 다운로드 */
export const downloadFileDB = (fileSn, columnSn, columnNm, fileNm) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const a = document.createElement('a');
  a.href = `${apiUrl}/api/get-file-download?fileSn=${fileSn}&columnSn=${columnSn}&columnNm=${columnNm}`;
  a.download = fileNm;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const postDownloadExcel = (data, fileNm) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.download = fileNm + '.xlsx';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const failDownloadExcel = (data) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
    const htmlContent = e.target.result;
    alert(htmlContent);
  };
  reader.onerror = (e) => console.error('FileReader error:', e);
  reader.readAsText(data);
};

export const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <TextField
    onClick={onClick}
    ref={ref}
    value={value}
    variant="standard"
    fullWidth
    InputProps={{
      readOnly: true,
    }}
  />
));

export const DatePickerRound = forwardRef(({ value, onClick, placehol }, ref) => (
  <TextField
    size="small"
    onClick={onClick}
    ref={ref}
    value={value}
    fullWidth
    InputProps={{
      readOnly: true,
    }}
    className="inp-date round"
    placeholder={placehol}
  />
));

/* 화면상에 권한 관리 */
export const setDisplay = (auth) => {
  const authWYn = auth?.authWYn == 'Y' ? ture : false;
  const authXYn = auth?.authXYn == 'Y' ? ture : false;

  if (!authWYn) {
    const dispSv = document.querySelectorAll('[class*="dispSv"]');

    dispSv.forEach((element) => {
      element.style.display = 'none';
    });
  }

  if (!authXYn) {
    const dispRm = document.querySelectorAll('[class*="dispRm"]');

    dispRm.forEach((element) => {
      element.style.display = 'none';
    });
  }
};

export const isInputEmail = (value) => {
  if (value == '') {
    return false;
  }
  const emailRegex = /^[a-zA-Z0-9._-]*@?[a-zA-Z0-9.-]*\.?[a-zA-Z]{0,}$/;
  return emailRegex.test(value);
};
