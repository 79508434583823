export const CUSTOMER_CENTER_PHONE_NUMBER = '000-0000-0000';

export const JOIN_TERMS_1 = `약관1
본 서비스 약관에는 Google의 사업 운영 방식, Google에 적용되는 법률, Google이 항상 진실이라고 여기는 특정 내용이 반영되어 있습니다. 따라서 귀하가 Google 서비스와 상호작용하면 본 서비스 약관을 근거로 Google과의 관계가 정의됩니다. 예를 들어, 약관에는 다음과 같은 제목의 주제들이 포함됩니다.

Google에 기대할 수 있는 사항. Google이 서비스를 제공 및 개발하는 방법을 설명합니다.
Google이 귀하에게 기대하는 사항. Google 서비스 사용과 관련된 일정한 규칙들을 정합니다.
Google 서비스 내 콘텐츠. Google 서비스에서 찾을 수 있는 콘텐츠의 지적 재산권에 대해 설명하며, 이러한 콘텐츠의 소유권이 귀하, Google 또는 다른 사람에게 있는지 설명합니다.
문제 또는 의견 충돌이 있는 경우. 귀하가 갖는 기타 법적 권리와 타인이 본 약관을 위반했을 때를 대비해 알아 두어야 할 사항을 설명합니다.
Google 서비스에 액세스하거나 서비스를 이용함으로써 귀하는 본 약관에 동의하게 되므로 본 약관을 숙지하는 것이 중요합니다.`;

export const JOIN_TERMS_2 = `약관2
Google 서비스를 제공하고 귀하와 계약을 체결하는 주체는 다음과 같습니다.

Google LLC
미국 델라웨어주 법률에 따라 설립되고 미국법에 따라 운영됨

1600 Amphitheatre Parkway
Mountain View, California 94043
USA`;

export const JOIN_TERMS_3 = `약관3
Google 계정을 직접 관리할 수 있는 연령 미만인 경우 Google 계정을 사용하기 위해서는 부모 또는 법정대리인의 허락이 필요합니다. 본 약관을 부모 또는 법정대리인과 함께 읽으시기 바랍니다.

귀하가 부모 또는 법정대리인으로서 아동이 서비스를 사용하도록 허락하는 경우, 본 약관은 귀하에게 적용되며 서비스 내 아동의 활동에 대해 귀하가 책임을 집니다.

일부 Google 서비스에는 서비스별 추가 약관 및 정책에 명시된 추가적인 연령 요건이 적용됩니다.`;

export const MAX_SIZE_MB = 50;

export const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

export const SEARCH_TIME = 300;

export const SALES_STEP_COLORS = [
    '#000000',
    '#307bff',
    '#ed7d31',
    '#00b050',
    '#7030a0',
    '#000000',
    '#307bff',
    '#ed7d31',
    '#00b050',
    '#7030a0'
  ];

  export const SALES_PRRT_COLORS = [
    '#7f7f7f',
    '#ef1a98',
    '#ed7d31',
    '#00b050',
    '#307bff',
    '#000000',
  ];

  