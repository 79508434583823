import {
  Grid,
  Button,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
  InputAdornment,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postDevReg } from '@store/actions/counterpartyAction';
import CompanyCode from './PageCounterPartyCode';
import { isValue, showToast, showToastError } from '@common/common';
import DaumPostCode from 'component/DaumPostCode';
import ConfirmPopUp from 'component/ConfirmPopUp';

//showToast;
const PageDevReg = ({ regOpen, setRegOpen, isSuccess, setIsSuccess }) => {
  const initialFormData = {
    dealYn: '',
    dvlpNm: '',
    coCd: '',
    zip: '',
    addr: '',
    daddr: '',
    telno: '',
    fxno: '',
    mngr: '',
    mngrTelno: '',
    mngrEmail: '',
    memo: '',
  };

  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();
  const [isCodeOpen, setIsCodeOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [regPop, setRegPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [zipType, setZipType] = useState('');

  const [formData, setFormData] = useState(initialFormData);

  //등록input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegConfirm = () => {
    if (!isValue(formData.dvlpNm)) {
      return showToast('개발사명을 입력해주세요.');
    } else if (!isValue(formData.dealYn)) {
      return showToast('거래상태를 선택해주세요.');
    } else if (
      (isValue(formData.telno) && formData.telno.length < 9) ||
      (isValue(formData.mngrTelno) && formData.mngrTelno.length < 9) ||
      (isValue(formData.fxno) && formData.fxno.length < 9)
    ) {
      return showToast('전화번호 형식을 확인해주세요.');
    }

    setRegPop(true);
  };

  const onDevReg = () => {
    const payload = {
      userId: userInfo.userId,
      userSn: userInfo.userSn,
      ...formData,
      callback: devRegcallback,
    };
    dispatch(postDevReg(payload));
  };

  const devRegcallback = (data) => {
    if (data.resultCode === 0) {
      showToast('신규 개발사가 등록되었습니다.');
      setIsSuccess(!isSuccess);
      setRegOpen(false);
      setRegPop(false);
    } else {
      // showToastError(data);
    }
  };

  const handleSetCompanyCode = (companyData) => {
    // setFormData((prev) => ({ ...prev, coCd: code }));
    setFormData((prev) => ({
      ...prev,
      coCd: companyData.coCd,
      dvlpNm: companyData.coNm,
      telno: companyData.rprNo,
      fxno: companyData.faxNo,
      zip: companyData.zip,
      addr: companyData.addr,
      daddr: companyData.daddr,
      mngr: companyData.userNm,
      mngrTelno: companyData.mblNo,
      mngrEmail: companyData.mngrEmail,
      memo: companyData.memo,
    }));
    setIsOpen(false);
  };

  const handleChangeCode = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCodeOpen = () => {
    setIsCodeOpen(true);
  };

  const closeModal = () => {
    setIsCodeOpen(false);
  };

  const handleZipSearch = (data) => {
    setFormData({
      ...formData,
      zip: data.zonecode,
      addr: data.address,
    });
    setIsOpen(false);
  };

  const handleZipClick = (type) => {
    setZipType(type);
    setIsOpen(true);
  };

  // const handleZipSearch = (data) => {
  //   const { address, zonecode } = data;
  //   setIsOpen(false);
  //   if (zipType === 'dvlp') {
  //     setFormData({
  //       ...formData,
  //       dvlpZip: zonecode,
  //       dvlpAddr: address,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       dvlpZip: zonecode,
  //       dvlpAddr: address,
  //     });
  //   }
  // };

  return (
    <Dialog open={regOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
      <DialogTitle>
        <FormControl variant="outlined">
          <Select
            variant="outlined"
            size="small"
            displayEmpty
            fullWidth
            name="dealYn"
            // value={formData.devStatus}
            onChange={handleChange}
            sx={{ width: '150px', marginBottom: '10px' }}
            defaultValue=""
          >
            <MenuItem value="">거래상태 선택</MenuItem>
            <MenuItem value="Y">거래 중</MenuItem>
            <MenuItem value="N">거래 종료</MenuItem>
          </Select>
        </FormControl>
        <Grid container>
          <Grid item md={6}>
            <TextField
              size="medium"
              fullWidth
              name="dvlpNm"
              variant="standard"
              className="modal-tit2 rqd"
              value={formData.dvlpNm ? formData.dvlpNm : ''}
              placeholder="개발사명을 입력해주세요"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={2}>
              <Typography variant="subtitle1">회사 코드</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                size="small"
                fullWidth
                name="coCd"
                variant="standard"
                value={formData.coCd ? formData.coCd : ''}
                onChange={handleChangeCode}
                onClick={handleCodeOpen}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={2}>
              <Typography variant="subtitle1">회사 주소</Typography>
            </Grid>
            <Grid container item md={8} spacing={2}>
              <Grid container item justifyContent={'space-between'} alignItems={'flex-end'} spacing={1}>
                <Grid item md={7}>
                  <TextField
                    variant="standard"
                    fullWidth
                    name="zip"
                    onChange={handleChange}
                    value={formData.zip ? formData.zip : ''}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item md={5}>
                  <Button className="btn" type="button" onClick={() => handleZipClick('zip')}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  size="small"
                  placeholder="기본주소"
                  name="addr"
                  fullWidth
                  value={formData.addr ? formData.addr : ''}
                  onChange={handleChange}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  size="small"
                  placeholder="상세주소 입력"
                  name="daddr"
                  fullWidth
                  value={formData.daddr ? formData.daddr : ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">대표번호</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="telno"
                value={formData.telno ? formData.telno : ''}
                onChange={handleChange}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">팩스번호</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="fxno"
                value={formData.fxno ? formData.fxno : ''}
                onChange={handleChange}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">담당자</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="mngr"
                value={formData.mngr ? formData.mngr : ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">담당자 연락처</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="mngrTelno"
                value={formData.mngrTelno ? formData.mngrTelno : ''}
                onChange={handleChange}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">담당자 이메일</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="mngrEmail"
                value={formData.mngrEmail ? formData.mngrEmail : ''}
                onChange={handleChange}
                placeholder="텍스트 입력 완료"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">메모</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField multiline fullWidth rows={3} name="memo" value={formData.memo} onChange={handleChange} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCancelPop(true)} className="btn-white">
          취소
        </Button>
        <Button onClick={handleRegConfirm} className="btn-navy">
          등록
        </Button>
      </DialogActions>
      {isOpen && <DaumPostCode isOpen={isOpen} setIsOpen={setIsOpen} handleZipSearch={handleZipSearch} />}
      {isCodeOpen && (
        <CompanyCode
          isOpen={isCodeOpen}
          setIsOpen={setIsCodeOpen}
          setCompanyCode={handleSetCompanyCode}
          close={closeModal}
        />
      )}
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'등록하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'등록'}
          btnEvent={onDevReg}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setRegOpen}
        />
      )}
    </Dialog>
  );
};

export default PageDevReg;
