import { isComplHangul, showToast, showToastError } from '@common/common';
import { SEARCH_TIME } from '@common/constants';
import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { getClientDetail } from '@store/actions/counterpartyAction';
import { getClntCoNmList } from '@store/actions/salesAction';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageSalesLoadModal = ({ isOpen, setIsOpen, inputs, setInputs }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [salesList, setSalesList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    handleGetClntNmList();
  }, [dispatch]);

  const handleGetClntNmList = () => {
    dispatch(
      getClntCoNmList({
        userId: user.userId,
        userSn: user.userSn,
        coNm: '',
        callback: handleGetTypeCoNmListCallback,
      })
    );
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList = salesList.filter((data) => {
      const clntNmMatch = data.clntNm ? data.clntNm.toLowerCase().includes(newKeyword) : false;
      const dvlpNmMatch = data.dvlpNm ? data.dvlpNm.toLowerCase().includes(newKeyword) : false;
      const coCodeMatch = data.clntCd ? data.clntCd.toLowerCase().includes(newKeyword) : false;
      const dvlpCodeMatch = data.dvlpCd ? data.dvlpCd.toLowerCase().includes(newKeyword) : false;
      return clntNmMatch || coCodeMatch;
    });

    setFilteredList(filteredList);
  };

  const handleGetProjectDetail = (coSn) => {
    dispatch(
      getClientDetail({
        userId: user.userId,
        userSn: user.userSn,
        clntCoSn: coSn,
        callback: handleGetProjectDetailCallback,
      })
    );
  };

  const handleGetProjectDetailCallback = (data) => {
    console.log('data>> ', data);
    if (data.resultCode === 0) {
      setIsOpen(false);
      setInputs({
        ...inputs,
        addr: data.data.addr,
        coSn: data.data.coCd,
        coNm: data.data.clntNm,
        daddr: data.data.daddr,
        //memo: data.data.memo,
        mngr: data.data.mngr,
        mngrEmail: data.data.mngrEmail,
        mngrTelno: data.data.mngrTelno,
        zip: data.data.zip,
      });
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };

  const handleGetTypeCoNmListCallback = (data) => {
    if (data.resultCode === 0) {
      // setSalesList(data.)
      setSalesList(data.data.clntCoNmList);
    } else {
      showToastError(data);
    }
  };

  const displayList = keyword ? filteredList : salesList;

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'xs'}
      scroll="paper"
      disableEnforceFocus
      PaperProps={{
        style: {
          height: '460px',
          paddingBottom: '30px',
        },
      }}
    >
      <DialogTitle sx={{ marginBottom: '0px', padding: '30px 40px' }}>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          계약사 가져오기
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          정산을 지급 해주는 업체를 선택해주세요.
        </Typography>

        <Grid item md={12} sx={{ width: '100%', marginTop: '30px' }}>
          {/* <input onChange={handleSearchChange} /> */}
          <FormControl sx={{ width: '100%' }}>
            <TextField
              required
              className="inp-round"
              placeholder="회사명/회사코드를 검색해주세요."
              autoComplete="off"
              size="small"
              name="keyword"
              onChange={handleSearchChange}
              value={keyword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '100%' }} // TextField 너비를 100%로 설정
            />
          </FormControl>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 40px' }}>
        <Grid item className="list-type" style={{ overflowY: 'hidden' }}>
          {displayList && displayList.length > 0 ? (
            displayList.map((item, index) => (
              <Grid container key={index}>
                <Grid item>
                  <Typography variant="body1" fontWeight={'500'}>
                    {item.clntNm}
                  </Typography>
                  <Typography variant="body2">{item.clntCd}</Typography>
                </Grid>
                <Grid item>
                  <Button className="btn-orange" onClick={() => handleGetProjectDetail(item.clntCoSn)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageSalesLoadModal;
