import { setDateFormat, setNumberComma, showToastError } from '@common/common';
import { ArrowDropDown, ArrowDropUp, Info } from '@mui/icons-material';
import {
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { getPaymentStatistics } from '@store/actions/paymentAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const PagePaymentStatistic = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [paymentCostData, setPaymentCostData] = useState({});
  const [todayList, setTodayList] = useState([]);
  const [nextList, setNextList] = useState([]);
  const [delayList, setDelayList] = useState([]);

  useEffect(() => {
    dispatch(
      getPaymentStatistics({
        userSn: user.userSn,
        userId: user.userId,
        callback: handleGetPaymentStatisticsCallback,
      })
    );
  }, []);

  const handleGetPaymentStatisticsCallback = (data) => {
    if (data.resultCode === 0) {
      setPaymentCostData(data.data.paymentCost);
      setTodayList(data.data.toDayPayment);
      setNextList(data.data.nextDayPayment);
      setDelayList(data.data.delayPayment);
    } else {
      showToastError(data);
    }
  };

  return (
    <Grid container mb={5}>
      <Grid item xs={6}>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
          }}
        >
          <Grid item md={12} p={2} display={'flex'} alignItems={'center'}>
            <Typography sx={{ mr: '2px' }}>당월 실지급액 합계</Typography>
            <CustomWidthTooltip
              placement="right"
              title={
                <>
                  당월 실 지급액 합계 =
                  <br />
                  지급 예정인 모든 당월 실지급액의 합
                  <br />
                  + 지급 완료한 모든 당월 실지급액의 합
                  <br />
                  <br />
                  ** 지급 지연되거나, 미리 지급된 다른 월의 금액은 미포함
                </>
              }
            >
              <Info sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </CustomWidthTooltip>
          </Grid>
          <Grid container px={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Typography variant="h5">{setNumberComma(String(paymentCostData.thisMonthCost))}</Typography>
              <Typography color={'gray'}>&nbsp;원</Typography>
            </Grid>
            <Grid item md={6} my={4}>
              <Chip label={'작년 동일 월 대비'} variant="outlined" />
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <IconButton>
                  {paymentCostData.thisMonthCost >= paymentCostData.lastYearCost ? (
                    <ArrowDropUp color="primary" />
                  ) : (
                    <ArrowDropDown color="error" />
                  )}
                </IconButton>
                <Typography>
                  {setNumberComma(String(paymentCostData.thisMonthCost - paymentCostData.lastYearCost))}
                </Typography>
                <Typography variant="body1" color={'grey'}>
                  &nbsp;({setNumberComma(String(Math.abs(paymentCostData.lastYearCost)))}원)
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={6} my={4}>
              <Chip label={'전월 실지급액 대비'} variant="outlined" />
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <IconButton>
                  {paymentCostData.thisMonthCost >= paymentCostData.lastMonthCost ? (
                    <ArrowDropUp color="primary" />
                  ) : (
                    <ArrowDropDown color="error" />
                  )}
                </IconButton>
                <Typography>
                  {setNumberComma(String(Math.abs(paymentCostData.thisMonthCost - paymentCostData.lastMonthCost)))}
                </Typography>
                <Typography variant="body1" color={'grey'}>
                  &nbsp;({setNumberComma(String(paymentCostData.lastMonthCost))}원)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
          }}
        >
          <Grid item md={12} p={2} display={'flex'} alignItems={'center'}>
            <Typography sx={{ mr: '2px' }}>지급 임박 금액</Typography>
            <CustomWidthTooltip
              placement="right"
              title={
                <>
                  당월 지급 예정 금액 =
                  <br />
                  지금 예정인 당일 실지급액의 합 - 지급 완료한 당일 실지급액의 합
                  <br />
                  <br />
                  익일 지급 예정 금액 =
                  <br />
                  지급 예정인 익일 실지급액의 합 - 지급 완료한 익일 실지급액의 합
                  <br />
                  <br />
                  지급 지연 금액 = 지급 지연된 모든 실지급액의 합
                </>
              }
            >
              <Info sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
            </CustomWidthTooltip>
          </Grid>
          <Grid container py={2.5}>
            <Grid container px={2} mb={2}>
              <Grid item md={5}>
                <Typography color={'error'}>당일 지급 예정 금액</Typography>
              </Grid>
              <Grid item md={7} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Typography variant="h6">{setNumberComma(String(paymentCostData.todayCost))}</Typography>
                <Typography color={'grey'}>&nbsp;원</Typography>
              </Grid>
            </Grid>
            <Grid container px={2} mb={2}>
              <Grid item md={5}>
                <Typography color={'primary'}>익일 지급 예정 금액</Typography>
              </Grid>
              <Grid item md={7} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Typography variant="h6">{setNumberComma(String(paymentCostData.nextDayCost))}</Typography>
                <Typography color={'grey'}>&nbsp;원</Typography>
              </Grid>
            </Grid>
            <Grid container px={2}>
              <Grid item md={5}>
                <Typography>지급 지연 금액</Typography>
              </Grid>
              <Grid item md={7} display={'flex'} alignItems={'center'} justifyContent={'end'}>
                <Typography variant="h6">{setNumberComma(String(paymentCostData.delayCost))}</Typography>
                <Typography color={'grey'}>&nbsp;원</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Grid container>
          <Typography variant="h6" fontWeight={'bold'}>
            당일 지급 예정
          </Typography>
          <Chip label={todayList.length} variant="outlined" sx={{ minWidth: '50px', ml: 2 }} />
        </Grid>
        <Grid container mt={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '30%' }}>프로젝트</TableCell>
                  <TableCell sx={{ width: '30%' }}>지급 대상</TableCell>
                  <TableCell sx={{ width: '15%' }}>고용형태</TableCell>
                  <TableCell sx={{ width: '15%' }}>지급액</TableCell>
                  <TableCell sx={{ width: '30%', textAlign: 'end' }}>실지급액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todayList.length > 0 ? (
                  todayList.map((item) => (
                    <TableRow key={item.paymentDtlSn}>
                      <TableCell>{item.prjcNm}</TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.tgtNm}</Typography>
                        <Typography variant="body2" color={'grey'}>
                          {item.gender}({item.age})/{item.teamNm}/{item.rankNm}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.emplTypeNm}</TableCell>
                      <TableCell>{item.cost}</TableCell>
                      <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.realCost))} 원</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      지급 예정 금액이 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Grid container>
          <Typography variant="h6" fontWeight={'bold'}>
            익일 지급 예정
          </Typography>
          <Chip label={nextList.length} variant="outlined" sx={{ minWidth: '50px', ml: 2 }} />
        </Grid>
        <Grid container mt={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '30%' }}>프로젝트</TableCell>
                  <TableCell sx={{ width: '30%' }}>지급 대상</TableCell>
                  <TableCell sx={{ width: '15%' }}>고용형태</TableCell>
                  <TableCell sx={{ width: '15%' }}>지급액</TableCell>
                  <TableCell sx={{ width: '30%', textAlign: 'end' }}>실지급액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nextList.length > 0 ? (
                  nextList.map((item) => (
                    <TableRow key={item.paymentDtlSn}>
                      <TableCell>{item.prjcNm}</TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.tgtNm}</Typography>
                        <Typography variant="body2" color={'grey'}>
                          {item.gender}({item.age})/{item.teamNm}/{item.rankNm}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.emplTypeNm}</TableCell>
                      <TableCell>{item.cost}</TableCell>
                      <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.realCost))} 원</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      지급 예정 금액이 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Grid container>
          <Typography variant="h6" fontWeight={'bold'}>
            지급 지연(이월)
          </Typography>
          <Chip label={delayList.length} variant="outlined" sx={{ minWidth: '50px', ml: 2 }} />
        </Grid>
        <Grid container mt={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>프로젝트</TableCell>
                  <TableCell sx={{ width: '20%' }}>지급 대상</TableCell>
                  <TableCell sx={{ width: '10%' }}>고용형태</TableCell>
                  <TableCell sx={{ width: '15%' }}>지급 예정일</TableCell>
                  <TableCell sx={{ width: '10%', textAlign: 'end' }}>지급액</TableCell>
                  <TableCell sx={{ width: '10%', textAlign: 'end' }}>실지급액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {delayList.length > 0 ? (
                  delayList.map((item) => (
                    <TableRow key={item.paymentDtlSn}>
                      <TableCell>{item.prjcNm}</TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.tgtNm}</Typography>
                        <Typography variant="body2" color={'grey'}>
                          {item.gender}({item.age}&nbsp;세){item.teamNm !== '' ? '/' + item.teamNm : ''}
                          {item.teamNm !== '' ? '/' + item.teamNm : ''}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.emplTypeNm}</TableCell>
                      <TableCell>{setDateFormat(item.paymentDt, 'YYYY-MM-DD')}</TableCell>
                      <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.cost))}&nbsp;원</TableCell>
                      <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.realCost))}&nbsp;원</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      지급 예정 금액이 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PagePaymentStatistic;
