import { fileAPI, sendAPI } from '@axios/index';

/* 로그인 */
const postLogin = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-user-login`, params, payload);
};

/* 회원가입 */
const postSignUp = ({ params = {}, payload = {} }) => {
  //return sendAPI('POST', `/api/signup`, params, payload);
  return null;
};

const postCoReg = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-co-reg`, params, payload);
};

const postUserReg = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-user-reg`, params, payload);
};

const getSearchId = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sch-id`, params, payload);
};

const getMblAuth = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-mbl-auth`, params, payload);
};

const getMblCnfr = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-mbl-cnfr`, params, payload);
};

const getBsnInf = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-bsn-inf`, params, payload);
};

/* 아이디 / 비밀번호 찾기 */
const postSearchId = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sch-id`, params, payload);
};

const postSearchPw = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sch-pw`, params, payload);
};

const getSearchPw = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sch-pw`, params, payload);
};

/* 대시보드 */

/* 캘린더 */

/* 영업 관리 */

/* 계약 관리 */
const getCntrList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-cntr-list`, params, payload);
};

/* 프로젝트 관리 */

/* 인력 관리  */
const getWrkfList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-wrkf-list`, params, payload);
};

const getBlngList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-blng-list`, params, payload);
};

const delWrkfList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-wrkf-list`, params, payload);
};

/* 정산 관리 */

/* 지급 관리 */

/* 거래처 관리 */
const postCounterpartyList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clnt-co-list`, params, payload);
};

/* 내 정보 */
const getMyInfo = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-my-info`, params, payload);
};

const putPwEdit = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-my-pw`, params, payload);
};

const putTelEdit = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-my-tel`, params, payload);
};

const getToken = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-token`, params, payload);
};

const getAlarmList = ({ params = {}, payload = {}, isLoading = false }) => {
  return sendAPI('POST', `/api/get-alarm-list`, params, payload, isLoading);
};

const postBrnoCheck = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/brno-ovrlp-check`, params, payload);
};

const api = {
  postLogin,
  postSignUp,
  postCoReg,
  postUserReg,
  getMblAuth,
  getMblCnfr,
  getBsnInf,
  postSearchId,
  postSearchPw,
  getSearchId,
  getSearchPw,
  getCntrList,
  getWrkfList,
  getBlngList,
  postCounterpartyList,
  delWrkfList,
  getMyInfo,
  putPwEdit,
  putTelEdit,
  getToken,
  getAlarmList,
  postBrnoCheck,
};

export default api;
