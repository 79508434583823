import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { moveIssue } from '@store/actions/commonAction';
import { useNavigate } from 'react-router-dom';

const PageProjectIssueBoardList = ({ issueList }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleMoveDetail = (value) => {
    dispatch(moveIssue(value));
    navigate('/mng/project/detail/issue?type=B');
  };

  return (
    <>
      <DragDropContext style={{ margin: '0px', padding: '0px' }}>
        <Grid container spacing={1} sx={{ paddingTop: '26px' }}>
          {issueList &&
            issueList.length > 0 &&
            issueList.map((item, index) => (
              <Grid key={item.issueSn} item xs={12} sm={12} md={12} onClick={() => handleMoveDetail(item.issueSn)}>
                <Droppable droppableId={item.issueSn + ''}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        minHeight: '200px',
                        width: '100%',
                      }}
                    >
                      <Draggable key={item.issueSn} draggableId={item.issueSn + ''} index={index} isDragDisabled>
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            elevation={3}
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '0 0 16px 0',
                              minHeight: '50px',
                              backgroundColor: '#fff',
                              color: '#333',
                              border: '1px solid lightgrey',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <CardContent>
                              <Grid container mb={1}>
                                <Chip label={item.typeNm} variant="outlined" sx={{ borderColor: '#307bff' }} />
                              </Grid>

                              <Grid container justifyContent={'space-between'} height={'40px'}>
                                <Typography gutterBottom variant="subtitle1" fontWeight={'bold'} component="div">
                                  {item.issueNm}
                                </Typography>
                              </Grid>

                              <Grid container>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                  <PersonIcon />
                                  <Typography pl={2} variant="body2">
                                    {item.mngrList[0]}{' '}
                                    {item.mngrList.length > 1 ? '외 ' + (item.mngrList.length - 1) + '명' : ''}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} mb={1}>
                                  <PersonSearchIcon />
                                  <Typography pl={2} variant="body2">
                                    {item.chkList[0]}{' '}
                                    {item.chkList.length > 1 ? '외 ' + (item.chkList.length - 1) + '명' : ''}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} mb={1}>
                                  <EditNoteIcon />
                                  <Typography pl={2} variant="body2">
                                    {item.userNm} / {item.coNm}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Grid>
            ))}
        </Grid>
      </DragDropContext>
    </>
  );
};

export default PageProjectIssueBoardList;
