import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ko } from 'date-fns/locale/ko';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PageLoadSalesModal from '../contract/PageLoadSalesModal';
import { getClctDownload } from '@store/actions/calculationAction';
import { failDownloadExcel, postDownloadExcel, setDateFormat } from '@common/common';
const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageCalculationDownload = ({ downloadPopup, setDownloadPopup }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const contractData = useSelector((state) => state.contractReducer.contractInfo);
  const [inputs, setInputs] = useState(contractData);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
      placeholder="yyyy-mm-dd - yyyy-mm-dd"
    />
  ));

  const [sendData, setSendData] = useState({
    userSn: userInfo.userSn,
    userId: userInfo.userId,
    clctStDt: '',
    clctEndDt: '',
    coNm: '',
    coCd: '',
  });

  const [render, setRender] = useState(true);

  useEffect(() => {
    if (render) {
      setRender(false);
      return;
    }
    setSendData((prev) => ({
      ...prev,
      coCd: inputs.coCd ? inputs.coCd : '',
      coNm: inputs.clntCoNm,
    }));
  }, [inputs]);

  const [isSalesOpen, setIsSalesOpen] = useState(false);

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setSendData({
      ...sendData,
      clctStDt: start,
      clctEndDt: end,
    });
  };

  const handleDownload = () => {
    dispatch(
      getClctDownload({
        ...sendData,
        clctStDt: setDateFormat(sendData.clctStDt, 'YYYY-MM-DD'),
        clctEndDt: setDateFormat(sendData.clctEndDt, 'YYYY-MM-DD'),
        callback: fn_download,
      })
    );
  };

  const fn_download = async (data, status) => {
    if (status === 200) {
      postDownloadExcel(data, '정산내역서');
      setDownloadPopup(false);
    } else {
      failDownloadExcel(data);
    }
  };

  return (
    <>
      <Dialog
        open={downloadPopup}
        fullWidth
        maxWidth={'xs'}
        disableEnforceFocus
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Typography className="modal-tit" sx={{ mr: 2 }}>
                정산 내역 다운로드
              </Typography>
              <Typography color={'grey'} variant="body2" mt={1}>
                거래처 등록한 정산 주체인 경우에는 <br />그 정산 주체에 관한 정산 내역을 한번에 다운로드할 수 있습니다.
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="rqd">
                정산 주체
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                variant="standard"
                fullWidth
                size="small"
                onClick={() => setIsSalesOpen(true)}
                InputProps={{
                  readOnly: true,
                  sx: {
                    '& input': {
                      cursor: 'pointer',
                    },
                  },
                }}
                value={sendData.coNm + ' ' + sendData.coCd}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" className="rqd">
                기간
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <DatePickerWrapper className="inp-date">
                <DatePicker
                  locale={ko}
                  selected={sendData.clctStDt}
                  onChange={handleDateChange}
                  startDate={sendData.clctStDt}
                  endDate={sendData.clctEndDt}
                  dateFormat={'YYYY-MM-dd'}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                />
              </DatePickerWrapper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDownloadPopup(false)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={() => handleDownload()}>
            다운로드
          </Button>
        </DialogActions>
      </Dialog>
      {isSalesOpen && (
        <PageLoadSalesModal
          isOpen={isSalesOpen}
          setIsOpen={setIsSalesOpen}
          inputs={inputs}
          setInputs={setInputs}
          title={'계약사 가져오기'}
          subTitle={'계약사는 정산 주체인 업체를 의미합니다.'}
        />
      )}
    </>
  );
};

export default PageCalculationDownload;
