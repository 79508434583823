import PageLogin from '@pages/auth/PageLogin';
import Calrendar from '@pages/board/Calrendar';
import Main from '@pages/board/Main';
import PageCalculationMain from '@pages/menu/calculation/PageCalculationMain';
import PageContractMain from '@pages/menu/contract/PageContractMain';
import PageCounterPartyMain from '@pages/menu/counterparty/PageCounterPartyMain';
import PagePaymentMain from '@pages/menu/payment/PagePaymentMain';
import PageProjectMain from '@pages/menu/project/PageProjectMain';
import PageSalesMain from '@pages/menu/sales/PageSalesMain';
import PageWorkforceMain from '@pages/menu/workforce/PageWorkforceMain';
import PageSearchIdMain from '@pages/menu/user/PageSearchIdMain';
import PageSearchPwMain from '@pages/menu/user/PageSearchPwMain';
import PageSearchIdSuccMain from '@pages/menu/user/PageSearchIdSuccMain';
import PageSearchPwSuccMain from '@pages/menu/user/PageSearchPwSuccMain';
import PageJoinTypeMain from '@pages/menu/user/PageJoinTypeMain';
import PageJoinTermsMain from '@pages/menu/user/PageJoinTermsMain';
import PageJoinBizMain from '@pages/menu/user/PageJoinBizMain';
import PageJoinCoMain from '@pages/menu/user/PageJoinCoMain';
import PageJoinAccountMain from '@pages/menu/user/PageJoinAccountMain';
import PageJoinSuccMain from '@pages/menu/user/PageJoinSuccMain';
import PageProjectRegMain from '@pages/menu/project/PageProjectRegMain';
import PageProjectDetail from '@pages/menu/project/PageProjectDetail';
import PageProjectUpdMain from '@pages/menu/project/PageProjectUpdMain';
import PageMypage from '@pages/menu/user/PageMypage';
import PageMypageEdit from '@pages/menu/user/PageMypageEdit';
import { useSelector } from 'react-redux';
import PageProjectIssueDetail from '@pages/menu/project/PageProjectIssueDetail';
import PageAlarm from '@pages/layout/PageAlarm';
import PageSearchTypeMain from '@pages/menu/user/PageSearchTypeMain';

const pages = [
  // 로그인 & 회원가입 & 아이디 비밀번호 찾기
  { pathname: '/login', element: <PageLogin />, isPublic: true, isAuth: false },

  // BOARD
  { pathname: '/', element: <Main />, isPublic: false, isAuth: true },
  {
    pathname: '/calendar',
    element: <Calrendar />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/alarm',
    element: <PageAlarm />,
    isPublic: false,
    isAuth: true,
  },
  //MAIN MENU
  {
    pathname: '/mng/sales',
    element: <PageSalesMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/contract',
    element: <PageContractMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/project',
    element: <PageProjectMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/project/reg',
    element: <PageProjectRegMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: `/mng/project/upd`,
    element: <PageProjectUpdMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/workforce',
    element: <PageWorkforceMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/caculation',
    element: <PageCalculationMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/payment',
    element: <PagePaymentMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/counterparty',
    element: <PageCounterPartyMain />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/searchType',
    element: <PageSearchTypeMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/searchId',
    element: <PageSearchIdMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/searchPw',
    element: <PageSearchPwMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/searchIdSucc',
    element: <PageSearchIdSuccMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/searchPwSucc',
    element: <PageSearchPwSuccMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/joinType',
    element: <PageJoinTypeMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/joinTerms',
    element: <PageJoinTermsMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/joinBiz',
    element: <PageJoinBizMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/joinCo',
    element: <PageJoinCoMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/joinAccount',
    element: <PageJoinAccountMain />,
    isPublic: true,
    isAuth: false,
  },
  {
    pathname: '/joinSucc',
    element: <PageJoinSuccMain />,
    isPublic: true,
    isAuth: false,
  },
  // {
  //   pathname: '/mng/project/dtl/:id',
  //   element: <PageProjectDetail />,
  //   isPublic: false,
  //   isAuth: true,
  // },
  //내정보
  { pathname: '/user/pageMypage', element: <PageMypage />, isPublic: false, isAuth: true },
  { pathname: '/user/pageMypageEdit', element: <PageMypageEdit />, isPublic: false, isAuth: true },
  {
    pathname: '/mng/project/detail',
    element: <PageProjectDetail />,
    isPublic: false,
    isAuth: true,
  },
  {
    pathname: '/mng/project/detail/issue',
    element: <PageProjectIssueDetail />,
    isPublic: false,
    isAuth: true,
  },
];

export default pages;
