import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '@actions/contractAction';
import api from '@api/apiContract';

function* getCntrList({ payload }) {
  const { status, data } = yield call(api.getCntrList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getCntrHistory({ payload }) {
  const { status, data } = yield call(api.getCntrHistory, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postCntr({ payload }) {
  const { status, data } = yield call(api.postCntr, payload);
  console.log(payload);
  if (status === 200) payload.callback(data);
}

function* putCntr({ payload }) {
  const { status, data } = yield call(api.putCntr, payload);
  if (status === 200) payload.callback(data);
}

function* getCntrDtl({ payload }) {
  const { status, data } = yield call(api.getCntrDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

// function* getSalesNmList({ payload }) {
//   const { status, data } = yield call(api.getSalesNmList, { payload: payload });
//   if (status === 200) payload.callback(data);
// }

// function* getSalesDtl({ payload }) {
//   const { status, data } = yield call(api.getSalesDtl, { payload: payload });
//   if (status === 200) payload.callback(data);
// }

function* getDvlpCoNmList({ payload }) {
  const { status, data } = yield call(api.getDvlpCoNmList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getDvlpCoDtl({ payload }) {
  const { status, data } = yield call(api.getDvlpCoDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getDelCntrList({ payload }) {
  const { status, data } = yield call(api.getDelCntrList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* delCntrUseYn({ payload }) {
  const { status, data } = yield call(api.delCntrUseYn, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* delCntrStts({ payload }) {
  const { status, data } = yield call(api.delCntrStts, { payload: payload });
  if (status === 200) payload.callback(data);
}

// watcher
function* watchUser() {
  yield takeLatest(actions.GET_CNTR_LIST, getCntrList);
  yield takeLatest(actions.GET_CNTR_HISTORY, getCntrHistory);
  yield takeLatest(actions.POST_CNTR, postCntr);
  yield takeLatest(actions.PUT_CNTR, putCntr);
  yield takeLatest(actions.GET_CNTR_DTL, getCntrDtl);
  // yield takeLatest(actions.GET_SALES_NM_LIST, getSalesNmList);
  // yield takeLatest(actions.GET_SALES_DTL, getSalesDtl);
  yield takeLatest(actions.GET_DVLP_CO_NM_LIST, getDvlpCoNmList);
  yield takeLatest(actions.GET_DVLP_CO_DTL, getDvlpCoDtl);
  yield takeLatest(actions.GET_DEL_CNTR_LIST, getDelCntrList);
  yield takeLatest(actions.DEL_CNTR_USE_YN, delCntrUseYn);
  yield takeLatest(actions.DEL_CNTR_STTS, delCntrStts);
}

export default function* contractSaga() {
  yield all([fork(watchUser)]);
}
