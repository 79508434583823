import { createSelector } from 'reselect';

const authSetup = createSelector(
  (state) => state.commonReducer,
  (commonReducer) => ({
    A10: commonReducer.user?.authMap['A10']?.authRYn == 'Y' ? true : false,  //대시보드
    B10: commonReducer.user?.authMap['B10']?.authRYn == 'Y' ? true : false,  //캘린더
    C10: commonReducer.user?.authMap['C10']?.authRYn == 'Y' ? true : false,  //영업관리
    D10: commonReducer.user?.authMap['D10']?.authRYn == 'Y' ? true : false,  //계약관리
    E10: commonReducer.user?.authMap['E10']?.authRYn == 'Y' ? true : false,  //프로젝트관리
    F10: commonReducer.user?.authMap['F10']?.authRYn == 'Y' ? true : false,  //인력관리
    G10: commonReducer.user?.authMap['G10']?.authRYn == 'Y' ? true : false,  //정산관리
    H10: commonReducer.user?.authMap['H10']?.authRYn == 'Y' ? true : false,  //지급관리
    I10: commonReducer.user?.authMap['I10']?.authRYn == 'Y' ? true : false,  //거래처관리
  })
);

export default authSetup;
