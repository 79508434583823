import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/counterpartyAction';
import api from '@api/apiCounterparty';

// function* postClientList({ payload }) {
//   const { status, data } = yield call(api.postClientList, { payload: payload });
//   if (status === 200) payload.callback(data, status);
// }

function* postClientList({ payload }) {
  const { status, data } = yield call(api.postClientList, { payload: payload });
  if (status === 200) {
    if (payload.callback) payload.callback(data);
  }
}

function* postclientReg({ payload }) {
  const { status, data } = yield call(api.postClientReg, { payload: payload });
  if (status === 200) {
    if (payload.callback) payload.callback(data);
  }
}

function* delClientList({ payload }) {
  const { status, data } = yield call(api.delClientList, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getClientDetail({ payload }) {
  const { status, data } = yield call(api.getClientDetail, {
    payload: payload,
  });
  if (status === 200) payload.callback(data, status);
}

function* putClntCo({ payload }) {
  const { status, data } = yield call(api.putClntCo, {
    payload: payload,
  });
  if (status === 200) payload.callback(data, status);
}

function* getClntNmList({ payload }) {
  const { status, data } = yield call(api.getCoNmList, {
    payload: payload,
  });
  if (status === 200) payload.callback(data, status);
}

function* getCoDtl({ payload }) {
  const { status, data } = yield call(api.getCoDtl, {
    payload: payload,
  });
  if (status === 200) payload.callback(data, status);
}

function* postDevtList({ payload }) {
  const { status, data } = yield call(api.postDevtList, { payload: payload });
  if (status === 200) {
    if (payload.callback) payload.callback(data);
  }
}

function* postDevReg({ payload }) {
  const { status, data } = yield call(api.postDevReg, { payload: payload });
  if (status === 200) {
    if (payload.callback) payload.callback(data);
  }
}

function* delDevList({ payload }) {
  const { status, data } = yield call(api.delDevList, { payload: payload });
  if (status === 200) {
    if (payload.callback) payload.callback(data);
  }
}

function* getDevDetail({ payload }) {
  const { status, data } = yield call(api.getDevDetail, {
    payload: payload,
  });
  if (status === 200) payload.callback(data, status);
}

function* putDvlpCo({ payload }) {
  const { status, data } = yield call(api.putDvlpCo, {
    payload: payload,
  });
  if (status === 200) payload.callback(data, status);
}

// function* getDvlpNmList({ payload }) {
//   const { status, data } = yield call(api.getDvlpNmList, {
//     payload: payload,
//   });
//   if (status === 200) payload.callback(data, status);
// }

// watcher
function* watchCounterparty() {
  yield takeLatest(actions.POST_CLIENT_LIST, postClientList);
  yield takeLatest(actions.POST_CLIENT_REG, postclientReg);
  yield takeLatest(actions.DEL_CLIENT_LIST, delClientList);
  yield takeLatest(actions.GET_CLNNM_LIST, getClntNmList);
  yield takeLatest(actions.GET_CODTL_LIST, getCoDtl);
  yield takeLatest(actions.GET_CLIENT_DETAIL, getClientDetail);
  yield takeLatest(actions.POST_DEV_LIST, postDevtList);
  yield takeLatest(actions.POST_DEV_REG, postDevReg);
  yield takeLatest(actions.DEL_DEV_LIST, delDevList);
  yield takeLatest(actions.GET_DEV_DETAIL, getDevDetail);
  yield takeLatest(actions.PUT_CLNT_CO, putClntCo);
  yield takeLatest(actions.PUT_DVLP_CO, putDvlpCo);

  // yield takeLatest(actions.GET_DVLPNM_LIST, getDvlpNmList);
}

export default function* counterpartySaga() {
  yield all([fork(watchCounterparty)]);
}
