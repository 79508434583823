import { setDateFormat, showToastError } from '@common/common';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { getPrjcMainInfo } from '@store/actions/projectAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const PageProjectHome = ({ setValue, projectSn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.commonReducer.user);
  const id = projectSn;

  const [mainData, setMaindata] = useState();

  const handleGetPrjcMainInfoCallback = (data) => {
    if (data.resultCode === 0) {
      setMaindata(data.data);
      console.log(data.data);
    } else {
      showToastError(data);
    }
  };

  useEffect(() => {
    dispatch(
      getPrjcMainInfo({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: id,
        callback: handleGetPrjcMainInfoCallback,
      })
    );
  }, []);

  return (
    <Grid item md={12}>
      <Box p={2}>
        <Grid
          container
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            borderColor: 'primary',
          }}
        >
          <Box p={2}>
            <Typography color="text.primary" variant="body2" fontWeight="bold">
              {mainData && mainData.prjcDscr}
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            borderColor: 'primary',
          }}
        >
          <Grid item md={12}>
            <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Typography variant="body1" color={'orange'} mr={2}>
                TODAY
              </Typography>
              <Typography variant="body1">{mainData && setDateFormat(mainData.today, 'YYYY-MM-DD')}</Typography>
            </Toolbar>
          </Grid>
          <Grid container textAlign={'center'} py={2}>
            <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
              <Grid item md={12}>
                <Typography>진행중 업무</Typography>
              </Grid>
              <Grid item md={12} justifyContent={'center'}>
                <Typography variant="h6" color={'primary'}>
                  {mainData && mainData.taskStts1}
                </Typography>
              </Grid>
            </Grid>
            <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
              <Grid item md={12}>
                <Typography>지연된 업무</Typography>
              </Grid>
              <Grid item md={12} justifyContent={'center'}>
                <Typography variant="h6" color={'primary'}>
                  {mainData && mainData.taskStts2}
                </Typography>
              </Grid>
            </Grid>
            <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
              <Grid item md={12}>
                <Typography>피드백 업무</Typography>
              </Grid>
              <Grid item md={12} justifyContent={'center'}>
                <Typography variant="h6" color={'primary'}>
                  {mainData && mainData.taskStts4}
                </Typography>
              </Grid>
            </Grid>
            <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
              <Grid item md={12}>
                <Typography>신규 이슈</Typography>
              </Grid>
              <Grid item md={12} justifyContent={'center'}>
                <Typography variant="h6" color={'error'}>
                  {mainData && mainData.issueStts1}
                </Typography>
              </Grid>
            </Grid>
            <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
              <Grid item md={12}>
                <Typography>처리중 이슈</Typography>
              </Grid>
              <Grid item md={12} justifyContent={'center'}>
                <Typography variant="h6" color={'error'}>
                  {mainData && mainData.issueStts2}
                </Typography>
              </Grid>
            </Grid>
            <Grid item p={2} md={2}>
              <Grid item md={12}>
                <Typography>지연된 이슈</Typography>
              </Grid>
              <Grid item md={12} justifyContent={'center'}>
                <Typography variant="h6" color={'error'}>
                  {mainData && mainData.issueStts3}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={'space-between'} minHeight={'440px'}>
          <Grid item sm={12} md={6}>
            <Grid
              item
              md={12}
              component={Card}
              variant="outlined"
              sx={{
                mt: 2,
                mr: 1,
                borderColor: 'primary',
                borderRadius: '15px',
                minHeight: '530px',
                maxHeight: '530px',
              }}
            >
              <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item>
                    <Typography variant="body1" fontWeight={'500'}>
                      프로젝트 현황
                    </Typography>
                  </Grid>
                  <Grid item onClick={() => navigate('/mng/project/detail?tab=1')}>
                    <IconButton>
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              <Grid p={3}>
                <Grid item xs={12} sm={12} md={12} mt={2}>
                  <Typography variant="body1">프로젝트 진행률</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={1}>
                  <Typography variant="body1" color={'primary'} fontWeight={'bold'}>
                    {mainData && Math.round(mainData.taskRate)}&nbsp;%
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={1} mb={2}>
                  <LinearProgress
                    variant="determinate"
                    value={mainData && mainData.taskRate !== '' ? Number(mainData.taskRate) : 0}
                  />
                </Grid>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={200} align="center">
                          업무명
                        </TableCell>
                        <TableCell width={100} align="center">
                          담당자
                        </TableCell>
                        <TableCell width={80} align="center">
                          상태
                        </TableCell>
                        <TableCell width={80} align="center">
                          진행률
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mainData &&
                        mainData.prjcTaskList.map((item, index) => (
                          <TableRow key={item.prjcTaskSn}>
                            <TableCell>{item.taskNm}</TableCell>
                            <TableCell align="center">{item.prjcTaskPrsnNm}</TableCell>
                            <TableCell align="center">
                              {item.stts === '0'
                                ? '대기'
                                : item.stts === '1'
                                ? '진행'
                                : item.stts === '2'
                                ? '피드백'
                                : item.stts === '3'
                                ? '완료'
                                : item.stts === '4'
                                ? '지연'
                                : '보류'}
                            </TableCell>
                            <TableCell align="center">{item.prgr}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid
              item
              md={12}
              component={Card}
              variant="outlined"
              sx={{
                mt: 2,
                ml: 1,
                borderColor: 'primary',
                borderRadius: '15px',
                minHeight: '530px',
                maxHeight: '530px',
              }}
            >
              <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item>
                    <Typography variant="body1" fontWeight={'500'}>
                      이슈 현황
                    </Typography>
                  </Grid>
                  <Grid item onClick={() => navigate('/mng/project/detail?tab=2&&type=B')}>
                    <IconButton>
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              <Grid p={3}>
                <Grid item xs={12} sm={12} md={12} mt={2}>
                  <Typography variant="body1">이슈 처리율</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={1}>
                  <Typography variant="body1" color={'primary'} fontWeight={'bold'}>
                    {mainData && Math.round(mainData.issueRate)}&nbsp;%
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} mt={1} mb={2}>
                  <LinearProgress
                    variant="determinate"
                    value={mainData && mainData.issueRate !== '' ? Number(mainData.issueRate) : 0}
                  />
                </Grid>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={100} align="center">
                          등록일
                        </TableCell>
                        <TableCell width={130} align="center">
                          이슈명
                        </TableCell>
                        <TableCell width={80} align="center">
                          상태
                        </TableCell>
                        <TableCell width={80} align="center">
                          종료일
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mainData &&
                        mainData.prjcIssueList.map((item, index) => (
                          <TableRow key={item.issueSn}>
                            <TableCell align="center">{setDateFormat(item.regDttm, 'YYYY-MM-DD')}</TableCell>
                            <TableCell>{item.issueNm}</TableCell>
                            <TableCell align="center">{item.sttsNm}</TableCell>
                            <TableCell align="center">{setDateFormat(item.issueEndDt, 'YYYY-MM-DD')}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container minHeight={'440px'}>
          <Grid item sm={12} md={6}>
            <Grid
              item
              md={12}
              component={Card}
              variant="outlined"
              sx={{
                mt: 2,
                mr: 1,
                borderColor: 'primary',
                minHeight: '440px',
              }}
            >
              <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item>
                    <Typography variant="body1" fontWeight={'500'}>
                      다가오는 이벤트
                    </Typography>
                  </Grid>
                  <Grid item onClick={() => navigate('/mng/project/detail?tab=3')}>
                    <IconButton>
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              <Grid item p={3}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={80} align="center">
                          일자
                        </TableCell>
                        <TableCell width={200} align="center">
                          내용
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mainData &&
                        mainData.prjcEventList.map((item, index) => (
                          <TableRow key={item.prjcEventSn}>
                            <TableCell align="center">{setDateFormat(item.start, 'YYYY-MM-DD')}</TableCell>
                            <TableCell align="center">{item.title}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid
              item
              md={12}
              component={Card}
              variant="outlined"
              sx={{
                mt: 2,
                ml: 1,
                borderColor: 'primary',
                minHeight: '440px',
              }}
            >
              <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item>
                    <Typography variant="body1" fontWeight={'500'}>
                      철수 예정 인력
                    </Typography>
                  </Grid>
                  <Grid item onClick={() => navigate('/mng/project/detail?tab=4')}>
                    <IconButton>
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
              <Grid item p={3}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={100} align="center">
                          이름
                        </TableCell>
                        <TableCell width={80} align="center">
                          담당 직무
                        </TableCell>
                        <TableCell width={100} align="center">
                          철수일
                        </TableCell>
                        <TableCell width={100} align="center">
                          남은 기한
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mainData &&
                        mainData.inptPrsnList.map((item, index) => (
                          <TableRow key={item.inptPrsnSn}>
                            <TableCell align="center">{item.userNm}</TableCell>
                            <TableCell>{item.position}</TableCell>
                            <TableCell align="center">{setDateFormat(item.inptEndDt, 'YYYY-MM-DD')}</TableCell>
                            <TableCell align="center">{item.remain}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PageProjectHome;
