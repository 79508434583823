import { fileAPI, sendAPI } from '@axios/index';

const getSalesList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-sales-list`, params, payload);
};

const getStgList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-stg-list`, params, payload);
};

const getPrrtList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-prrt-list`, params, payload);
};
  
const postSales = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/post-sales`, params, payload);
};

const delSales = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/del-sales`, params, payload);
};

const getSalesDtl = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-sales-dtl`, params, payload);
};

const getSalesNmList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-sales-nm-list`, params, payload);
};

const putDnd = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/put-dnd`, params, payload);
};

const putPrrtList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/put-prrt-list`, params, payload);
};

const putSalesDtl = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/put-sales-dtl`, params, payload);
};

const getChkStgDel = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-chk-stg-del`, params, payload);
};

const getChkPrrtDel = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-chk-prrt-del`, params, payload);
};

const putStgList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/put-stg-list`, params, payload);
};

const getSalesLog = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-sales-log`, params, payload);
};

const getClntCoNmList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-clnt-co-nm-list`, params, payload);
};

const testPrjcTaskList = ({ params = {}, payload = {} }) => {
  return sendAPI("POST", `/api/get-prjc-task-list`, params, payload);
};

const api = {
    getSalesList,
    getStgList,
    getPrrtList,
    postSales,
    delSales,
    getSalesDtl,
    getSalesNmList,
    putDnd,
    putPrrtList,
    putSalesDtl,
    getChkStgDel,
    getChkPrrtDel,
    putStgList,
    getSalesLog,
    getClntCoNmList,
    testPrjcTaskList,
};

export default api;
