import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Backup } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import DaumPostcode from 'react-daum-postcode';
import {
  showToast,
  convertFomData,
  setOnlyNumber,
  isValue,
  isEmail,
  isMobile,
  showToastError,
  setTelFormat,
  isInputEmail,
} from '@common/common';
import { postWrkf } from '@store/actions/wrkfAction';
import PageWorkfoceUserCode from './PageWorkfoceUserCode';
import ErrorIcon from '@mui/icons-material/Error';

import ConfirmPopUp from 'component/ConfirmPopUp';

const PageWorkfoceSave = (prop) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const { regWrkfSaveOpen, setRegWrkfSaveOpen, teamList, resultSaveWrkf } = prop;
  const [sendData, setSendData] = useState({
    userSn: userInfo.userSn,
    userId: userInfo.userId,
    userNm: '',
    email: '',
    rank: '',
    blngSn: '0',
    gender: '',
    brdt: '',
    mblNo1: '',
    mblNo2: '',
    userCd: '',
    bankCd: '',
    bankNb: '',
    bankOwnr: '',
    zip: '',
    addr: '',
    daddr: '',
    kywr: '',
    cpNm: '',
    ourCp: '',
  });

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');

  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');

  const [days, setDays] = useState([]);
  const [selectedDays, setSelectedDays] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const [todayString, setTodayString] = useState();
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();

    setTodayString(
      currentYear +
        '' +
        (today.getMonth() > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)) +
        '' +
        (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
    );

    const startYear = currentYear - 70;
    const yearsArray = [];
    for (let i = startYear; i <= currentYear; i++) {
      yearsArray.push(i + '');
    }
    setYears(yearsArray);
    setSelectedYear(currentYear);

    const monthsArray = [];
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        monthsArray.push('0' + i);
      } else {
        monthsArray.push(i + '');
      }
    }
    setMonths(monthsArray);
    setSelectedMonth('01');

    const daysArray = [];
    for (let i = 1; i <= 31; i++) {
      if (i < 10) {
        daysArray.push('0' + i);
      } else {
        daysArray.push(i + '');
      }
    }
    setDays(daysArray);
    setSelectedDays('01');
  }, []);

  const saveWrkf = () => {
    sendData.brdt = selectedYear + selectedMonth + selectedDays;
    sendData.kywr = keyWords.join(',');

    if (checkValid()) {
      dispatch(postWrkf(convertFomData({ wrkfSaveRequest: sendData, files: files }), fn_wrkfSave));
    }
  };

  const fn_wrkfSave = (data) => {
    if (data.resultCode === 0) {
      resultSaveWrkf();
      showToast('저장되었습니다.');
    } else {
      showToast('저장에 실패하였습니다.' + data.resultMsg, 'error');
    }
  };

  const checkValid = () => {
    if (sendData.userNm === '') {
      showToast('이름을 입력해주세요.', 'error');
      return false;
    } else if (sendData.cpNm === '') {
      showToast('회사를 입력해주세요.', 'error');
      return false;
    } else if (sendData.blngSn === '0') {
      showToast('소속을 선택해주세요.', 'error');
      return false;
    } else if (sendData.rank === '') {
      showToast('직급을 선택해주세요.', 'error');
      return false;
    } else if (sendData.gender === '') {
      showToast('성별을 선택해주세요.', 'error');
      return false;
    } else if (!isEmail(sendData.email)) {
      showToast('이메일을 확인해주세요.', 'error');
      return false;
    } else if (!isMobile(sendData.mblNo1)) {
      showToast('연락처를 확인해주세요.', 'error');
      return false;
    } else if (selectedYear + selectedMonth + selectedDays > todayString) {
      showToast('생년월일을 확인해주세요.', 'error');
      return false;
    }

    return true;
  };

  const handleOnchange = (event) => {
    const { name, value } = event.target;

    let _value = value;

    if (value !== '') {
      if (name === 'mblNo1' || name === 'mblNo2' || name === 'bankNb') {
        _value = setOnlyNumber(value);
      }

      if (name === 'email' && (!isInputEmail(value) || value.length > 50)) return;

      if ((name === 'mblNo1' || name === 'mblNo2') && _value.length > 11) return;

      if (name === 'bankOwnr' && value.length > 40) return;
    }

    setSendData((prev) => ({
      ...prev,
      [name]: _value,
    }));
  };

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsOpen(false);

    setSendData((prev) => ({
      ...prev,
      addr: address,
      zip: zonecode,
    }));
  };

  const [keyWords, setKeyWords] = useState([]);
  const [inputKeyWord, setInputKeyWord] = useState('');
  const handleKeyPress = (event) => {
    if (event.isComposing || event.keyCode === 229) return;
    if (event.key === 'Enter') {
      const value = event.target.value;

      if (value.trim() === '') return;

      setKeyWords((prev) => {
        const itemExists = prev.some((item) => item === value);
        if (!itemExists) {
          setTimeout(() => setInputKeyWord(''), 0);
          return [...prev, value];
        } else {
          showToast('동일한 값이 있습니다.', 'info');
          return [...prev];
        }
      });
    }
  };

  const [files, setFiles] = useState([]);
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    droppedFiles.forEach((file) => {
      file.id = Math.random().toString(36).substring(2, 11);
    });
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((file) => {
      file.id = Math.random().toString(36).substring(2, 11);
    });
    setFiles([...files, ...selectedFiles]);
  };

  const handleDeleteFile = (value) => {
    console.log(value);
    setFiles(files.filter((item) => item.id !== value));
  };
  const handleDeleteKeyWord = (value) => {
    console.log(value);
    setKeyWords(keyWords.filter((item) => item !== value));
  };

  const handleDatePick = (e) => {
    const { name, value } = e.target;
    if (name === 'year') {
      setSelectedYear(value);
    } else if (name === 'month') {
      setSelectedMonth(value);
    } else if (name === 'day') {
      setSelectedDays(value);
    }
  };

  useEffect(() => {
    const date = new Date(selectedYear, selectedMonth, 0);
    const days = date.getDate();

    if (selectedDays > days) {
      setSelectedDays(days);
    }
    setDays(
      Array.from({ length: days }, (_, i) => {
        if (i < 9) {
          return '0' + (i + 1);
        } else {
          return i + 1 + '';
        }
      })
    );
  }, [selectedYear, selectedMonth]);

  const [isUserPopup, setIsUserPopUp] = useState(false);

  const [selectUser, setSelectUser] = useState();
  const handleSelectUser = (data) => {
    setSelectUser(data);
    setIsUserPopUp(false);

    setSendData((prev) => ({
      ...prev,
      userNm: data.userNm,
      userCd: data.userCd,
      mblNo1: data.mblNo,
      gender: data.userSex === 'M' ? '0' : '1',
      brdt: data.brdt,
    }));

    const brdt = data.brdt.match(/^(\d{4})(\d{2})(\d{2})$/);
    setSelectedYear(brdt[1]);
    setSelectedMonth(brdt[2]);
    setSelectedDays(brdt[3]);
  };

  const handleIconClick = (e) => {
    e.stopPropagation();

    setSelectUser();

    setSendData((prev) => ({
      ...prev,
      userCd: '',
    }));
  };

  const downloadFile = (file) => {
    const downloadUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handelCampany = (e) => {
    const { name, value } = e.target;

    if (name === 'cpNm' && value.length > 40) {
      showToast('회사명은 최대 40자리 입니다.');
      return;
    }

    if (name === 'cpNm' && value !== '') {
      setSendData((prev) => ({
        ...prev,
        ourCp: 2,
        cpNm: value,
      }));
    } else if (name === 'cpNm' && value === '') {
      setSendData((prev) => ({
        ...prev,
        ourCp: '',
        cpNm: '',
      }));
    } else if (name === 'ourCp' && value === sendData.ourCp) {
      setSendData((prev) => ({
        ...prev,
        ourCp: '',
        cpNm: '',
      }));
    } else if (name === 'ourCp' && value === '0') {
      setSendData((prev) => ({
        ...prev,
        ourCp: value,
        cpNm: userInfo.coNm,
      }));
    } else if (name === 'ourCp' && value === '1') {
      setSendData((prev) => ({
        ...prev,
        ourCp: value,
        cpNm: '없음',
      }));
    }
  };

  const [canclePopup, setCanclePopup] = useState(false);

  return (
    <>
      <Dialog open={regWrkfSaveOpen} fullWidth maxWidth={'md'} disableEnforceFocus sx={{ minHeight: '500px' }}>
        <DialogTitle>
          <Grid container>
            <Grid item md={3}>
              <TextField
                size="medium"
                fullWidth
                variant="standard"
                name="userNm"
                className="modal-tit2 rqd"
                placeholder="이름을 입력해주세요."
                onChange={(e) => handleOnchange(e)}
                value={sendData.userNm}
                disabled={selectUser ? true : false}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <Typography variant="subtitle2">개인정보</Typography>
            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      회사
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      name="cpNm"
                      onChange={(e) => handelCampany(e)}
                      value={sendData.cpNm}
                      disabled={sendData.ourCp === '0' || sendData.ourCp === '1'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={8} sx={{ alignContent: 'center' }} display={'flex'}>
                    <Box sx={{ mr: 2 }}>
                      <input
                        type="checkbox"
                        value="0"
                        checked={sendData.ourCp === '0'}
                        onChange={(e) => handelCampany(e)}
                        style={{ marginRight: '0.5rem' }}
                        name="ourCp"
                      />
                      자사 소속
                    </Box>
                    <Box>
                      <input
                        type="checkbox"
                        value="1"
                        checked={sendData.ourCp === '1'}
                        onChange={(e) => handelCampany(e)}
                        style={{ marginRight: '0.5rem' }}
                        name="ourCp"
                      />
                      소속 회사 없음
                    </Box>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      소속
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      fullWidth
                      value={sendData.blngSn}
                      size="small"
                      onChange={(e) => handleOnchange(e)}
                      name="blngSn"
                    >
                      <MenuItem value={0}>선택</MenuItem>
                      {teamList &&
                        teamList.map((item) => (
                          <MenuItem key={item.blngSn} value={item.blngSn}>
                            {item.teamNm}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      직급
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      fullWidth
                      size="small"
                      name="rank"
                      onChange={(e) => handleOnchange(e)}
                      value={sendData.rank}
                      displayEmpty
                    >
                      <MenuItem value="">선택</MenuItem>
                      <MenuItem value={0}>인턴</MenuItem>
                      <MenuItem value={1}>사원</MenuItem>
                      <MenuItem value={2}>주임</MenuItem>
                      <MenuItem value={3}>대리</MenuItem>
                      <MenuItem value={4}>과장</MenuItem>
                      <MenuItem value={5}>차장</MenuItem>
                      <MenuItem value={6}>부장</MenuItem>
                      <MenuItem value={7}>이사</MenuItem>
                      <MenuItem value={8}>상무</MenuItem>
                      <MenuItem value={9}>전무</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      성별
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      fullWidth
                      size="small"
                      name="gender"
                      onChange={(e) => handleOnchange(e)}
                      value={sendData.gender}
                      disabled={selectUser ? true : false}
                      displayEmpty
                    >
                      <MenuItem value="">선택</MenuItem>
                      <MenuItem value={0}>남자</MenuItem>
                      <MenuItem value={1}>여자</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      생년월일
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      fullWidth
                      value={selectedYear}
                      size="small"
                      onChange={(e) => handleDatePick(e)}
                      name="year"
                      disabled={selectUser ? true : false}
                    >
                      {years &&
                        years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      fullWidth
                      value={selectedMonth}
                      size="small"
                      onChange={(e) => handleDatePick(e)}
                      name="month"
                      disabled={selectUser ? true : false}
                    >
                      {months &&
                        months.map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      fullWidth
                      value={selectedDays}
                      size="small"
                      onChange={(e) => handleDatePick(e)}
                      name="day"
                      disabled={selectUser ? true : false}
                    >
                      {days &&
                        days.map((day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center', alignItems: 'center' }} display={'flex'}>
                    <Typography variant="subtitle1" sx={{ mr: '2px' }}>
                      회원코드
                    </Typography>
                    <Tooltip
                      title={
                        <>
                          회원코드를 입력해야만 프로젝트 투입 시
                          <br />
                          정확하게 연동이 가능합니다.
                        </>
                      }
                    >
                      <ErrorIcon color="error" />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      onClick={() => setIsUserPopUp(true)}
                      value={sendData.userCd}
                      InputProps={{
                        readOnly: true,
                        endAdornment: selectUser && (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              onClick={handleIconClick}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          '& input': {
                            cursor: 'pointer',
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      이메일
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      name="email"
                      onChange={(e) => handleOnchange(e)}
                      value={sendData.email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1" className="rqd">
                      연락처1
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="'-' 없이 숫자만 입력"
                      onChange={(e) => handleOnchange(e)}
                      name="mblNo1"
                      value={setTelFormat(sendData.mblNo1, '-')}
                      disabled={selectUser ? true : false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1">연락처2</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="'-' 없이 숫자만 입력"
                      onChange={(e) => handleOnchange(e)}
                      name="mblNo2"
                      value={setTelFormat(sendData.mblNo2, '-')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1">계좌번호</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      fullWidth
                      value={sendData.bankCd}
                      size="small"
                      onChange={(e) => handleOnchange(e)}
                      name="bankCd"
                      displayEmpty
                    >
                      <MenuItem value="">은행 선택</MenuItem>
                      <MenuItem value="088">신한은행</MenuItem>
                      <MenuItem value="090">카카오뱅크</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="subtitle1">주소</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      value={sendData.zip}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      className="btn"
                      onClick={() => setIsOpen(true)}
                      sx={{
                        height: '37.69px !important',
                      }}
                    >
                      우편번호 검색
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="body1" ml={1} />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="계좌번호 ('-' 없이 숫자만 입력)"
                      name="bankNb"
                      onChange={(e) => handleOnchange(e)}
                      value={sendData.bankNb}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="body1" ml={1}></Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="기본주소"
                      InputProps={{ readOnly: true }}
                      value={sendData.addr}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent={'space-between'} alignItems={'center'} my={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3.2} sx={{ alignContent: 'center' }}>
                    <Typography variant="body1" ml={1} />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="예금주"
                      name="bankOwnr"
                      onChange={(e) => handleOnchange(e)}
                      value={sendData.bankOwnr}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sx={{ alignContent: 'center' }}>
                    <Typography variant="body1" ml={1}></Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      placeholder="상세주소 입력"
                      name="daddr"
                      onChange={(e) => handleOnchange(e)}
                      value={sendData.daddr}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2} pt={2}>
              <Grid item md={12} display={'flex'}>
                <Grid item md={1.6}>
                  <Typography variant="subtitle1">개인 첨부파일</Typography>
                </Grid>
                <Grid item md={10.4}>
                  <Box component="section" className="file-Box" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <Backup />
                    <Typography variant="body2">첨부할 파일을 끌어다 놓거나</Typography>
                    <Button component="label">
                      찾아보기
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={1.6} />
                <Grid item md={10.4} alignItems={'center'}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      justifyItems: 'start',
                    }}
                  >
                    {files &&
                      files.map((file) => (
                        <Box
                          key={file.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="button"
                            display="block"
                            sx={{ textTransform: 'none', cursor: 'pointer' }}
                            onClick={() => downloadFile(file)}
                            gutterBottom
                          >
                            {file.name}
                          </Typography>
                          <IconButton
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            onClick={() => handleDeleteFile(file.id)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={1.6}>
                  <Typography variant="subtitle1">개인 키워드</Typography>
                </Grid>
                <Grid item md={10.4}>
                  <TextField
                    size="small"
                    variant="standard"
                    fullWidth
                    placeholder="키워드 입력 후 Enter를 눌러주세요"
                    onKeyDown={(e) => handleKeyPress(e)}
                    value={inputKeyWord}
                    onChange={(e) => setInputKeyWord(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item md={12} display={'flex'} alignItems={'center'}>
                <Grid item md={1.6}></Grid>
                <Grid item md={10.4} alignItems={'center'}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      justifyItems: 'start',
                    }}
                  >
                    {keyWords &&
                      keyWords.map((keyWord) => (
                        <Box
                          key={keyWord}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="button" display="block" sx={{ textTransform: 'none' }} gutterBottom>
                            {keyWord}
                          </Typography>
                          <IconButton
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            onClick={() => handleDeleteKeyWord(keyWord)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCanclePopup(true)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={saveWrkf}>
            저장
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpen} fullWidth maxWidth={'sm'}>
        <DialogTitle id="scroll-dialog-title">우편번호 검색</DialogTitle>
        <DialogContent>
          <DaumPostcode onComplete={handleZipSearch} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>닫기</Button>
        </DialogActions>
      </Dialog>

      {isUserPopup && (
        <PageWorkfoceUserCode isUserPopUp={isUserPopup} setIsUserPopUp={setIsUserPopUp} selectUser={handleSelectUser} />
      )}
      {canclePopup && (
        <ConfirmPopUp
          open={canclePopup}
          close={setCanclePopup}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setRegWrkfSaveOpen}
        />
      )}
    </>
  );
};

export default PageWorkfoceSave;
