import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography, Grid, Box, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserJoin, getBsnInf, postBrnoCheck } from '@store/actions/userAction';
import { isValue, isYmd, isBizNumber, setOnlyNumber, showToastError } from '@common/common';
import { useSelector } from 'react-redux';
import PageJoinRequestLayout from './PageJoinRequestLayout';

const defaultTheme = createTheme();

export default function PageJoinCoMain() {
  const userFlag = useSelector((state) => state.userReducer.userJoin.userFlag);

  const limitTime = 180;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState('');
  const [compl, setCompl] = useState(true);

  const [inputs, setInputs] = useState({
    bsnRegNo: '',
    rprNm: '',
    opnDt: '',
  });

  useEffect(() => {
    if (!isValue(userFlag)) {
      navigate('/joinType');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let _value = '';
    if (name === 'bsnRegNo') _value = setOnlyNumber(value);
    if (name === 'rprNm') _value = value;
    if (name === 'opnDt') _value = setOnlyNumber(value);
    setInputs({
      ...inputs,
      [name]: _value,
    });

    if (name === 'opnDt' && isYmd(_value)) {
      setCompl(false);
    }
  };

  const handleValid = (e) => {
    const { name, value } = e.target;

    setErrorMsg('');
    if (false && name == 'bsnRegNo' && !isBizNumber(inputs.bsnRegNo)) {
      setErrorMsg('사업자등록번호 10자리를 확인해주세요.');
    } else if (name == 'rprNm' && !isValue(inputs.rprNm)) {
      setErrorMsg('대표자명을 확인해주세요.');
    } else if (name == 'opnDt' && !isYmd(inputs.opnDt)) {
      setErrorMsg('개업일자를 8자리를 입력해주세요.');
    }
  };

  const handleGetBsnInfRequest = () => {
    // 1058642463
    setErrorMsg('');

    if (false && !isBizNumber(inputs.bsnRegNo)) {
      setErrorMsg('사업자등록번호 10자리를 확인해주세요.');
    } else if (!isValue(inputs.rprNm)) {
      setErrorMsg('대표자명을 확인해주세요.');
    } else if (!isYmd(inputs.opnDt)) {
      setErrorMsg('개업일자를 8자리를 입력해주세요.');
    } else {
      // dispatch(
      //   getBsnInf({
      //     bsnRegNo: inputs.bsnRegNo,
      //     rprNm: inputs.rprNm,
      //     opnDt: inputs.opnDt,
      //     callback: handleGetBsnInfCallback,
      //   })
      // );
      dispatch(
        postBrnoCheck({
          bsnRegNo: inputs.bsnRegNo,
          rprNm: inputs.rprNm,
          opnDt: inputs.opnDt,
          authYn: 'Y',
          callback: handleGetBsnInfCallback,
        })
      );
    }
  };

  const handleGetBsnInfCallback = (data, status) => {
    if (data.resultCode === 0 && data.data.errorCode === 0) {
      dispatch(
        setUserJoin({
          bsnRegNo: inputs.bsnRegNo,
          rprNm: inputs.rprNm,
          opnDt: inputs.opnDt,
        })
      );
      navigate(`/joinCo`);
      console.log(data);
    } else if (data.resultCode === 0 && (data.data.errorCode === 1 || data.data.errorCode === 2)) {
      setErrorMsg(data.data.errorMsg);
    } else {
      showToastError(data);
    }
  };

  return (
    <PageJoinRequestLayout
      title={'사업자 등록 정보'}
      titleBlack={'확인'}
      content={'사업자등록번호,대표자명,개업일자를 입력해주세요.'}
    >
      <CssBaseline />

      <TextField
        sx={{ mt: 3 }}
        margin="normal"
        required
        fullWidth
        placeholder="사업자등록번호('-' 제외 숫자 10자리)"
        autoComplete="off"
        autoFocus
        name="bsnRegNo"
        value={inputs.bsnRegNo}
        onChange={handleChange}
        inputProps={{ maxLength: 10 }}
        onBlur={handleValid}
      />
      <Grid container alignItems="center">
        {/* TextField */}
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            required
            placeholder="대표자명"
            name="rprNm"
            autoComplete="off"
            value={inputs.rprNm}
            onChange={handleChange}
            onBlur={handleValid}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            margin="dense"
            required
            fullWidth
            placeholder="개업일자(YYYYMMDD 형식의 숫자 8자리)"
            name="opnDt"
            autoComplete="off"
            onChange={handleChange}
            value={inputs.opnDt}
            inputProps={{ maxLength: 8 }}
            onBlur={handleValid}
          />
        </Grid>
      </Grid>
      {errorMsg && (
        <Typography variant="caption" color={'red'}>
          {errorMsg}
        </Typography>
      )}
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleGetBsnInfRequest}
        disabled={compl}
      >
        인증
      </Button>
    </PageJoinRequestLayout>
  );
}
