import { setBrdtFormat, showToast } from '@common/common';
import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getSchUserInfoByCode } from '@store/actions/wrkfAction';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const PageWorkfoceUserCode = ({ isUserPopUp, setIsUserPopUp, selectUser }) => {
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState([]);

  const [userCode, setUserCode] = useState('');

  const searchUser = () => {
    // if (userCode.length === 6) {
    if (userCode.length > 0) {
      dispatch(
        getSchUserInfoByCode({
          // userCd: userCode,
          searchValue: userCode,
          callback: fn_resultUser,
        })
      );
    } else {
      showToast('검색 값을 입력해주세요.');
    }
    // } else {
    //   showToast('회원코드는 6자리입니다.', 'error');
    // }
  };

  const fn_resultUser = (data) => {
    if (data.resultCode === 0) {
      setUserInfo(data.data);
    } else {
      setUserInfo();
    }
  };

  const handlEnter = (event) => {
    if (event.key === 'Enter') {
      searchUser();
    }
  };

  return (
    <>
      <Dialog
        open={isUserPopUp}
        fullWidth
        disableEnforceFocus
        PaperProps={{
          sx: {
            minHeight: '300px',
            maxWidth: '550px !important',
            borderRadius: '30px !important',
          },
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h6" fontWeight={'bold'}>
                  {/* 회원코드 */}
                  회원명
                </Typography>
                {/* <Typography variant="body2">회원코드를 입력하면, 회원 기본 정보가 자동 삽입됩니다.</Typography> */}
                <Typography variant="body2">회원명을 입력하면, 회원 기본 정보가 자동 삽입됩니다.</Typography>
              </Box>
              <Close onClick={() => setIsUserPopUp(false)} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item md={9}>
              <FormControl fullWidth>
                <TextField
                  required
                  // placeholder="회원코드 6자리를 모두 입력해주세요."
                  placeholder="회원명을 입력해주세요."
                  autoComplete="off"
                  size="small"
                  name="keyword"
                  className="inp-round"
                  onChange={(e) => {
                    // if (e.target.value.length < 7) {
                    setUserCode(e.target.value);
                    // }
                  }}
                  onKeyUp={(e) => handlEnter(e)}
                  value={userCode}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    sx: {
                      fontSize: '0.875rem',
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <Button className="btn" fullWidth onClick={searchUser}>
                검색
              </Button>
            </Grid>
          </Grid>
          {userInfo && userInfo.length > 0 ? (
            userInfo.map((item) => (
              <Grid container alignItems={'center'} py={2} borderBottom={'1px solid #ccc'} key={item.userCd}>
                <Grid item md={10}>
                  <Box component={'div'}>
                    <Typography variant="body2" fontWeight={'bold'}>
                      {item.userNm}
                    </Typography>
                    <Typography variant="body2">
                      {item.userSex === 'M' ? '남' : '여'} / {setBrdtFormat(item.brdt, '-')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-red" onClick={() => selectUser(item)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid container py={2}>
              <Grid item xs={12} height={'100px'} alignContent="center" textAlign={'center'}>
                <Typography variant="body2" fontWeight={'bold'}>
                  검색결과가 없습니다.
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ height: '30px' }} />
      </Dialog>
    </>
  );
};

export default PageWorkfoceUserCode;
