import { downloadAPI, fileAPI, sendAPI } from '@axios/index';

/* 정산 관리 */
const getClctStats = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clct-stats`, params, payload);
};

const getClctBoard = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clct-board`, params, payload);
};

const getClctList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clct-list`, params, payload);
};

const putClctProcess = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-clct-process`, params, payload);
};

const postClct = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-clct`, params, payload);
};

const delClctProcess = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-clct-process`, params, payload);
};

const getClctDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-clct-dtl`, params, payload);
};

const putClctList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-clct-list`, params, payload);
};

const putClctStop = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-clct-stop`, params, payload);
};

const getClctDownload = ({ params = {}, payload = {} }) => {
  return downloadAPI('POST', `/api/get-clct-download`, params, payload);
};

const api = {
  getClctStats,
  getClctBoard,
  getClctList,
  putClctProcess,
  postClct,
  delClctProcess,
  getClctDtl,
  putClctList,
  putClctStop,
  getClctDownload,
};

export default api;
