import { setDateFormat, setNumberComma } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getPaymentCompDtl } from '@store/actions/paymentAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PagePaymentComplteDetailModal = (props) => {
  const { isOpen, setIsOpen, paymentDtl } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [compList, setCompList] = useState([]);

  useEffect(() => {
    dispatch(
      getPaymentCompDtl({
        userSn: user.userSn,
        userId: user.useId,
        paymentSn: paymentDtl.paymentSn,
        tgtSn: paymentDtl.tgtSn,
        tgtType: paymentDtl.tgtType,
        callback: handleGetPaymentComlDtlCallback,
      })
    );
  }, []);
  const handleGetPaymentComlDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setCompList(data.data);
    } else {
      showToastError(data);
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'md'} disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit">{compList.prjcNm}</Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          {compList.cntrStDt} ~ {compList.cntrEndDt}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mb: 2 }}>
        <Grid container alignItems={'center'} mb={2}>
          <Grid item md={3}>
            <Typography variant="subtitle1">지급 대상</Typography>
          </Grid>
          <Grid
            item
            md={9}
            display={'flex'}
            alignItems={'center'}
            sx={{
              borderBottom: 1,
              py: 1,
            }}
          >
            <Typography>{compList.tgtNm}</Typography>
            <Typography variant="body1" color={'grey'}>
              ({compList.age}&nbsp;세)
            </Typography>
            <Typography variant="body1" color={'grey'}>
              {compList.teamNm !== '' ? '/' + compList.teamNm : ''}
            </Typography>
            <Typography variant="body1" color={'grey'}>
              {compList.rankNm !== '' ? '/' + compList.rankNm : ''}
            </Typography>
            <Typography variant="body1" color={'grey'}>
              {compList.userCd !== '' ? '/' + compList.userCd : ''}
            </Typography>
            {/* <TextField
              name="totalCost"
              variant="standard"
              size="small"
              fullWidth
              value={compList.tgtNm}
              // disabled={isValue(prjcSn) ? true : false}
            /> */}
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '10%' }}>고용 형태</TableCell>
                <TableCell sx={{ width: '10%' }}>지급 종류</TableCell>
                <TableCell sx={{ width: '15%' }}>지급 예정일</TableCell>
                <TableCell sx={{ width: '15%' }}>지급처리일시</TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'end' }}>지급액</TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'end' }}>공제액</TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'end' }}>실제금액</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compList.paymentCompList &&
                compList.paymentCompList.length > 0 &&
                compList.paymentCompList.map((item) => (
                  <TableRow key={item.paymentDtlSn}>
                    <TableCell>{item.emplTypeNm}</TableCell>
                    <TableCell>{item.categoryNm}</TableCell>
                    <TableCell>{setDateFormat(item.paymentDt, 'YYYY-MM-DD')}</TableCell>
                    <TableCell>{setDateFormat(item.paymentRealDttm, 'YYYY-MM-DD HH:mm')}</TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.cost))}&nbsp;원</TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>
                      {setNumberComma(String(item.deductionAmount))}&nbsp;원
                    </TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.realCost))}&nbsp;원</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PagePaymentComplteDetailModal;
