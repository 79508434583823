import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, Typography, Divider, Grid, Button } from '@mui/material';
import { AccessTime, Mail } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setDateFormat, showToast, showToastError } from '@common/common';
import { getAlarmList } from '@store/actions/userAction';
import { setAlarmCnt } from '@store/actions/commonAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { putClntPrjc } from '@store/actions/projectAction';

const PageAlarm = () => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [closestDt, setClosestDt] = useState('');
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  const user = useSelector((state) => state.commonReducer.user);

  const getAlarmListCallback = (data) => {
    if (data.resultCode === 0) {
      if (data.data) {
        setClosestDt(data.data.closestDt);
        setDataList((prevDataList) => [...prevDataList, ...data.data.alarmList]);
        setLoading(false);
        dispatch(
          setAlarmCnt({
            alarmCnt: data.data.alarmCnt,
          })
        );
      }
    } else {
      showToastError(data.resultCode + ':' + data.resultMsg);
      setLoading(false);
    }
  };

  const getAlarmListHandler = (closestDt = '') => {
    if (loading) return;
    setLoading(true);

    if (closestDt != '' || closestDt == null) {
      if (closestDt == null) closestDt = '';
      getAlarm(closestDt);
    }
  };

  const getAlarm = (e) => {
    dispatch(
      getAlarmList({
        startDt: e || '',
        userSn: user.userSn,
        userId: user.userId,
        callback: getAlarmListCallback,
      })
    );
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          getAlarmListHandler(closestDt);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, closestDt]
  );

  useEffect(() => {
    getAlarmListHandler(null);
  }, []);

  let beforeDttm = '';
  let showTimeLabel = false;
  let alertTimeHm = '';

  const [confirmComment, setConfirmComment] = useState('');

  const [sendData, setSendData] = useState({
    clctPrjcSn: '',
    regUserSn: '',
    clntRequest: '',
    userSn: user.userSn,
    userId: user.userId,
  });

  const handelApproval = (data, isCheck) => {
    console.log(data);

    if (isCheck) {
      setConfirmComment('접근 요청을 승인 하시겠습니까?');
    } else {
      setConfirmComment('접근 요청을 거절 하시겠습니까?');
    }

    setSendData((prev) => ({
      ...prev,
      clctPrjcSn: data.refColumnSn,
      regUserSn: data.regUserSn,
      clntRequest: isCheck ? 2 : 3,
    }));

    setConfirmPop(true);
  };

  const handleSendApproval = () => {
    dispatch(
      putClntPrjc({
        ...sendData,
        callback: fn_sendApproval,
      })
    );
  };

  const fn_sendApproval = (data) => {
    if (data.resultCode === 0) {
      showToast(
        sendData.clntRequest === 2 ? '프로젝트 접근 요청이 승인되었습니다.' : '프로젝트 접근 요청이 거절 되었습니다.'
      );
      setConfirmPop(false);
      setDataList([]);
      getAlarm();
    } else {
      showToast('요청이 실패하였습니다.', 'error');
    }
  };

  const [confirmPop, setConfirmPop] = useState(false);

  const renderDataList = () => {
    return dataList.map((alarm, index) => {
      alertTimeHm = alarm.regDttm.substring(8, 10) + ':' + alarm.regDttm.substring(10, 12);

      showTimeLabel = setDateFormat(alarm.regDttm) !== beforeDttm;
      if (showTimeLabel) {
        beforeDttm = setDateFormat(alarm.regDttm);
      }

      if (dataList.length === index + 1) {
        return (
          <Grid key={index} ref={lastElementRef}>
            {showTimeLabel && (
              <Grid className="time-label">
                <Typography className="time-label-content">{setDateFormat(alarm.regDttm)}</Typography>
              </Grid>
            )}
            <Grid className="timeline-item">
              <Grid className="timeline-item-icon mail">
                <Mail />
              </Grid>
              <Grid className="timeline-item-content">
                <Grid className="time">
                  <Typography>{alarm.title}</Typography>
                  <Grid className="icon">
                    <AccessTime fontSize="small" /> {alertTimeHm}
                  </Grid>
                </Grid>
                <Typography className="alarm-txt">{alarm.content}</Typography>

                {alarm.clntRequest && alarm.clntRequest === '1' && (
                  <Typography className="alarm-txt" pt={0}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        backgroundColor: 'rgba(288, 53, 53, 0.8)', // error 색상의 rgba 값, 알파값 0.7
                        '&:hover': {
                          backgroundColor: 'rgba(288, 53, 53, 1)', // hover 시 약간 더 진하게
                        },
                        mr: 2,
                      }}
                      onClick={() => handelApproval(alarm, false)}
                    >
                      거절
                    </Button>
                    <Button variant="outlined" onClick={() => handelApproval(alarm, true)}>
                      승인
                    </Button>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid key={index}>
            {showTimeLabel && (
              <Grid className="time-label">
                <Typography className="time-label-content">{setDateFormat(alarm.regDttm)}</Typography>
              </Grid>
            )}
            <Grid className="timeline-item">
              <Grid className="timeline-item-icon mail">
                <Mail />
              </Grid>
              <Grid className="timeline-item-content">
                <Grid className="time">
                  <Typography>{alarm.title}</Typography>
                  <Grid className="icon">
                    <AccessTime fontSize="small" /> {alertTimeHm}
                  </Grid>
                </Grid>
                <Typography className="alarm-txt">{alarm.content}</Typography>
                {alarm.clntRequest && alarm.clntRequest === '1' && (
                  <Typography className="alarm-txt" pt={0}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        backgroundColor: 'rgba(288, 53, 53, 0.8)', // error 색상의 rgba 값, 알파값 0.7
                        '&:hover': {
                          backgroundColor: 'rgba(288, 53, 53, 1)', // hover 시 약간 더 진하게
                        },
                        mr: 2,
                      }}
                      onClick={() => handelApproval(alarm, false)}
                    >
                      거절
                    </Button>
                    <Button variant="outlined" onClick={() => handelApproval(alarm, true)}>
                      승인
                    </Button>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      }
    });
  };

  return (
    <Grid className="timeline-container">
      {dataList.length > 0 ? (
        <Grid className="timeline-list">{renderDataList()}</Grid>
      ) : (
        <Grid>
          <Typography>알람 내역이 존재하지 않습니다.</Typography>
        </Grid>
      )}
      {confirmPop && (
        <ConfirmPopUp
          open={confirmPop}
          close={setConfirmPop}
          title={confirmComment}
          btnNm1={'아니오'}
          btnNm2={'예'}
          btnEvent={handleSendApproval}
        />
      )}
    </Grid>
  );
};

export default PageAlarm;
