import { ExampleCustomInput, isValue, setDateFormat, showToast, showToastError } from '@common/common';
import { CheckBox, Percent } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ko } from 'date-fns/locale/ko';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import PageLoadGroupModal from './PageLoadGroupModal';
import PageLoadPrntTaskModal from './PageLoadPrntTaskModal';
import PageLoadPrsnModal from './PageLoadPrsnModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { postPrjcTask } from '@store/actions/projectAction';
import { useParams } from 'react-router-dom';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectTaskRegModal = ({ isOpen, setIsOpen, isSuccess, setIsSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);
  const pUser = useSelector((state) => state.projectReducer.project);

  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [isPrntTaskOpen, setIsPrntTaskOpen] = useState(false);
  const [isPrsnOpen, setIsPrsnOpen] = useState(false);
  const [regPop, setRegPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [inputs, setInputs] = useState({});
  const [originalPrsnList, setOriginalPrsnList] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;

    // If the input is a checkbox, handle its checked state
    const inputValue = type === 'checkbox' ? checked : value;

    setInputs({
      ...inputs,
      [name]: inputValue,
    });
  };

  //날짜 change
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    // setStartDate(start);
    // setEndDate(end);
    setInputs({
      ...inputs,
      stDt: start,
      endDt: end,
    });
  };

  const handleConfirmPostPrjcTask = () => {
    console.log(inputs);
    if (!isValue(inputs.stts)) {
      return showToast('상태를 선택해주세요.');
    } else if (!isValue(inputs.nm)) {
      return showToast('업무명을 입력해주세요.');
    } else if (!isValue(inputs.prjcGrpSn)) {
      return showToast('그룹을 선택해주세요.');
    } else if (!isValue(inputs.prntTaskSn) && !inputs.check) {
      return showToast('상위업무를 선택해주세요.');
    } else if (!isValue(inputs.prjcTaskPrsnList) || inputs.prjcTaskPrsnList.length <= 0) {
      return showToast('업무담당자를 선택해주세요.');
    } else if (!isValue(inputs.stDt)) {
      return showToast('개발기간을 입력해주세요.');
    }

    setRegPop(true);
  };

  const handlePostPrjcTask = () => {
    dispatch(
      postPrjcTask({
        userId: user.userId,
        userSn: user.userSn,
        puserSn: pUser.puserSn,
        dvlpCoSn: user.dvlpCoSn,
        prjcMainSn: id,
        stts: inputs.stts,
        nm: inputs.nm,
        prjcGrpSn: inputs.prjcGrpSn,
        prntTaskSn: inputs.check ? 0 : inputs.prntTaskSn,
        inptPrsnSnList: inputs.prjcTaskPrsnList.map((prsn) => prsn.inptPrsnSn),
        stDt: setDateFormat(inputs.stDt, 'YYYYMMDD'),
        endDt: setDateFormat(inputs.endDt, 'YYYYMMDD'),
        prgr: inputs.prgr,
        memo: inputs.memo,
        callback: handlePostPrjcTaskCallback,
      })
    );
  };

  const handlePostPrjcTaskCallback = (data) => {
    if (data.resultCode === 0) {
      setIsOpen(false);
      setRegPop(false);
      showToast('신규업무가 등록되었습니다.');
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };
  return (
    <>
      <Dialog open={isOpen} fullWidth scroll="paper" disableEnforceFocus>
        <DialogTitle>
          <FormControl variant="outlined">
            <Select
              variant="outlined"
              size="small"
              displayEmpty
              fullWidth
              name="stts"
              sx={{ width: '150px', marginBottom: '10px' }}
              defaultValue=""
              onChange={handleInputChange}
            >
              <MenuItem value="">상태선택</MenuItem>
              <MenuItem value="0">대기</MenuItem>
              <MenuItem value="1">진행</MenuItem>
              <MenuItem value="2">피드백</MenuItem>
              <MenuItem value="3">완료</MenuItem>
              <MenuItem value="4">지연</MenuItem>
              <MenuItem value="5">보류</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="nm"
            variant="outlined"
            placeholder="업무명을 입력해주세요"
            InputProps={{ maxLength: 50 }}
            onChange={handleInputChange}
            // value={inputs.prjcNm ? inputs.prjcNm : ''}
            className="modal-tit2 rqd"
            fullWidth
            size="medium"
          />
        </DialogTitle>
        <DialogContent>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1" className="rqd">
                  그룹
                </Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="cntrAmnt"
                  size="small"
                  variant="standard"
                  inputProps={{ readOnly: true }}
                  onClick={() => setIsGroupOpen(true)}
                  // onChange={handleInputChange}
                  // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                  fullWidth
                  value={inputs.grpNm ? inputs.grpNm : ''}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1" className="rqd">
                  상위 업무
                </Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="cntrAmnt"
                  size="small"
                  variant="standard"
                  inputProps={{ readOnly: true }}
                  onClick={() => setIsPrntTaskOpen(true)}
                  // onChange={handleInputChange}
                  value={inputs.prntTaskNm && !inputs.check ? inputs.prntTaskNm : ''}
                  fullWidth
                  disabled={inputs.check ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}></Grid>
              <Grid item md={9}>
                <FormControlLabel
                  control={<Checkbox name="check" onChange={handleInputChange} />}
                  label="상위 업무로 등록"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1" className="rqd">
                  업무 담당자
                </Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="cntrAmnt"
                  size="small"
                  variant="standard"
                  className="inp-round"
                  inputProps={{ readOnly: true }}
                  onClick={() => setIsPrsnOpen(true)}
                  value={
                    inputs.prjcTaskPrsnList && inputs.prjcTaskPrsnList.length > 0
                      ? inputs.prjcTaskPrsnList.map((prsn) => prsn.userNm)
                      : ''
                  }
                  // onChange={handleInputChange}
                  // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                  fullWidth
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={3}>
                <Typography variant="subtitle1" className="rqd">
                  개발 기간
                </Typography>
              </Grid>
              <Grid item md={9} className="inp-date">
                <DatePicker
                  locale={ko}
                  selected={inputs.stDt}
                  onChange={handleDateChange}
                  startDate={inputs.stDt}
                  endDate={inputs.endDt}
                  dateFormat={'YYYY-MM-dd'}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">진행률</Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="prgr"
                  size="small"
                  variant="standard"
                  onChange={handleInputChange}
                  // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Percent fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'}>
              <Grid item md={3}>
                <Typography variant="subtitle1">메모</Typography>
              </Grid>
              <Grid item md={9}>
                <TextField name="memo" multiline rows={4} fullWidth onChange={handleInputChange} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="btn-white" onClick={() => setCancelPop(true)}>
            취소
          </Button>
          <Button className="btn-navy" onClick={handleConfirmPostPrjcTask}>
            등록
          </Button>
        </DialogActions>
      </Dialog>
      {isGroupOpen && (
        <PageLoadGroupModal isOpen={isGroupOpen} setIsOpen={setIsGroupOpen} setInputs={setInputs} inputs={inputs} />
      )}
      {isPrntTaskOpen && (
        <PageLoadPrntTaskModal
          isOpen={isPrntTaskOpen}
          setIsOpen={setIsPrntTaskOpen}
          setInputs={setInputs}
          inputs={inputs}
        />
      )}
      {isPrsnOpen && (
        <PageLoadPrsnModal
          isOpen={isPrsnOpen}
          setIsOpen={setIsPrsnOpen}
          setInputs={setInputs}
          inputs={inputs}
          setOriginalPrsnList={setOriginalPrsnList}
          originalPrsnList={originalPrsnList}
        />
      )}
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'등록하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'등록'}
          btnEvent={handlePostPrjcTask}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setIsOpen}
        />
      )}
    </>
  );
};

export default PageProjectTaskRegModal;
