import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '@actions/userAction';
import api from '@api/api';

function* postLogin({ payload }) {
  const { status, data } = yield call(api.postLogin, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* postSignUp({ payload }) {
  const { status, data } = yield call(api.postSignUp, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getSearchId({ payload }) {
  const { status, data } = yield call(api.getSearchId, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getSearchPw({ payload }) {
  const { status, data } = yield call(api.getSearchPw, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getMblAuth({ payload }) {
  const { status, data } = yield call(api.getMblAuth, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getMblCnfr({ payload }) {
  const { status, data } = yield call(api.getMblCnfr, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getBsnInf({ payload }) {
  const { status, data } = yield call(api.getBsnInf, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* postUserReg({ payload }) {
  const { status, data } = yield call(api.postUserReg, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* postCoReg({ payload }) {
  const { status, data } = yield call(api.postCoReg, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

// 내 정보
function* getMyInfo({ payload }) {
  const { status, data } = yield call(api.getMyInfo, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* putPwEdit({ payload }) {
  const { status, data } = yield call(api.putPwEdit, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* putTelEdit({ payload }) {
  const { status, data } = yield call(api.putTelEdit, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getToken({ payload }) {
  const { status, data } = yield call(api.getToken, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* getAlarmList({ payload }) {
  const { status, data } = yield call(api.getAlarmList, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

function* postBrnoCheck({ payload }) {
  const { status, data } = yield call(api.postBrnoCheck, { payload: payload });
  if (status === 200) payload.callback(data, status);
}

// watcher
function* watchUser() {
  yield takeLatest(actions.POST_LOGIN, postLogin);
  yield takeLatest(actions.POST_SIGN_UP, postSignUp);
  yield takeLatest(actions.GET_SEARCH_ID, getSearchId);
  yield takeLatest(actions.GET_SEARCH_PW, getSearchPw);
  yield takeLatest(actions.GET_MBL_AUTH, getMblAuth);
  yield takeLatest(actions.GET_MBL_CNFR, getMblCnfr);
  yield takeLatest(actions.GET_BSN_INF, getBsnInf);
  yield takeLatest(actions.POST_USER_REG, postUserReg);
  yield takeLatest(actions.POST_CO_REG, postCoReg);
  yield takeLatest(actions.GET_MY_INFO, getMyInfo);
  yield takeLatest(actions.PUT_PW_EDIT, putPwEdit);
  yield takeLatest(actions.PUT_TEL_EDIT, putTelEdit);
  yield takeLatest(actions.GET_TOKEN, getToken);
  yield takeLatest(actions.GET_ALARM_LIST, getAlarmList);
  yield takeLatest(actions.POST_BRNO_OVRLP_CHECK, postBrnoCheck);
}

export default function* userSaga() {
  yield all([fork(watchUser)]);
}
