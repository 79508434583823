import { Backup, Block, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale/ko';
import { format } from 'date-fns';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { postSales, putSalesDtl } from '@store/actions/salesAction';
import { isValue, setDateFormat, setNumberComma, setTelFormat, showToast, showToastError } from '@common/common';
import DaumPostCode from 'component/DaumPostCode';
import { useSelector } from 'react-redux';
import PageSalesLoadModal from './PageSalesLoadModal';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import ConfirmPopUp from 'component/ConfirmPopUp';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageSalesRegModal = ({
  regType,
  regOpen,
  setRegOpen,
  setIsSuccessYn,
  isSuccessYn,
  type,
  slsStgSn,
  slsPrrtSn,
  stg,
  prrt,
  salesData,
  whatModal,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);

  const [inputs, setInputs] = useState({
    salesSn: '',
    coCd: '', // 회사코드
    coSn: '', // 회사고유번호
    coNm: '', // 회사명
    slsNm: '', // 영업프로젝트명
    userId: '', // 사용자 아이디
    userSn: 0, // 사용자 고유번호
    zip: '', // 우편번호
    addr: '', // 주소
    daddr: '', // 상세주소
    mngr: '', // 담당자
    mngrTelno: '', // 담당자연락처
    mngrEmail: '', // 담당자이메일
    payment: '', // 계약금
    slsMngr: '', // 영업담당자
    slsDt: '', // 영업일자
    memo: '', // 메모
    slsStgSn: '', // 영업단계고유번호
    slsPrrtSn: '', // 영업우선순위고유번호
  });

  const [memo, setMemo] = useState('');
  const [zipType, setZipType] = useState('');
  const [coType, setCoType] = useState('');
  const [stgList, setStgList] = useState(stg);
  const [prrtList, setPrrtList] = useState(prrt);
  const [savePop, setSavePop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [arrTempMemo, setArrTempMemo] = useState(inputs.memo);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      size="small"
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      placeholder="YYYY-MM-DD"
      InputProps={{
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            <CalendarMonth />
          </InputAdornment>
        ),
      }}
    />
  ));

  //날짜 change
  const handleDateChange = (date) => {
    const start = date;
    setInputs({
      ...inputs,
      slsDt: start,
    });
  };

  //등록input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === 'clntMngrTelno' || name === 'dvlpMngrTelno') {
      formattedValue = setTelFormat(value, '-');
    } else if (name === 'payment') {
      formattedValue = setNumberComma(value);
    }
    setInputs({
      ...inputs,
      [name]: formattedValue,
    });
  };

  const handleMemoChange = (e) => {
    setMemo(e.target.value);
  };

  //등록
  const handlePostSalesRequestConfirm = () => {
    if (!isValue(inputs.slsStgSn)) {
      return showToast('단계 선택을 해주세요');
    } else if (!isValue(inputs.slsPrrtSn)) {
      return showToast('우선순위 선택을 해주세요.');
    } else if (!isValue(inputs.slsNm)) {
      return showToast('프로젝트명을 입력해주세요.');
    } else if (!isValue(inputs.coNm)) {
      return showToast('회사명을 입력해주세요.');
    }
    if (regType === 'reg') {
      let slsDt = isValue(inputs.slsDt) ? format(inputs.slsDt, 'yyyy-MM-dd') : '';
      let payment = isValue(inputs.payment) ? inputs.payment : 0;

      let arrMemo = [];

      if (isValue(memo)) {
        let objMemo = {
          salesMemoSn: '',
          memo: memo,
          regDttm: '',
        };

        arrMemo.push(objMemo);

        setInputs({
          ...inputs,
          memo: arrMemo,
        });
      }

      setInputs({
        ...inputs,
        slsDt: slsDt,
        payment: payment,
      });

      setSavePop(true);

      // dispatch(
      //   postSales({
      //     ...inputs,
      //     userId: user.userId,
      //     userSn: user.userSn,
      //     memo: memo,
      //     callback: handlePostSalesCallback,
      //   })
      // );
    } else {
      let slsDt = isValue(inputs.slsDt) ? format(inputs.slsDt, 'yyyy-MM-dd') : '';
      let payment = isValue(inputs.payment) ? inputs.payment : 0;

      let arrMemo = inputs.memo;

      if (isValue(memo)) {
        let objMemo = {
          salesMemoSn: '',
          memo: memo,
          regDttm: '',
        };
        setArrTempMemo([...arrMemo, objMemo]);
        // arrMemo.push(objMemo);
      } else {
        setArrTempMemo([...arrMemo]);
      }

      setInputs({
        ...inputs,
        slsDt: slsDt,
        payment: payment.replace(/[^0-9]/g, ''),
      });

      setSavePop(true);

      // dispatch(
      //   putSalesDtl({
      //     ...inputs,
      //     userId: user.userId,
      //     userSn: user.userSn,
      //     memo: arrMemo,
      //     callback: handlePostSalesCallback,
      //   })
      // );
    }
  };

  const handlePostSalesRequest = () => {
    if (regType === 'reg') {
      dispatch(
        postSales({
          ...inputs,
          userId: user.userId,
          userSn: user.userSn,
          memo: memo,
          callback: handlePostSalesCallback,
        })
      );
    } else {
      dispatch(
        putSalesDtl({
          ...inputs,
          userId: user.userId,
          userSn: user.userSn,
          memo: arrTempMemo,
          callback: handlePostSalesCallback,
        })
      );
    }
  };

  const handlePostSalesCallback = (data) => {
    if (data.resultCode === 0) {
      setRegOpen(false);
      showToast(regType === 'reg' ? '등록되었습니다.' : '저장되었습니다.');
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError(data);
    }
  };

  const hanldeZipClick = (type) => {
    setZipType(type);
    setIsOpen(true);
  };

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsOpen(false);
    setInputs({
      ...inputs,
      zip: zonecode,
      addr: address,
    });
  };

  useEffect(() => {
    if (whatModal == 'list') {
      if (type === 1) {
        setInputs({
          ...inputs,
          slsStgSn: slsStgSn,
        });
      } else if (type === 2) {
        setInputs({
          ...inputs,
          slsPrrtSn: slsPrrtSn,
        });
      }
    }

    if (whatModal === 'detail') {
      setInputs({
        ...inputs,
        salesSn: salesData.salesSn || '',
        coCd: salesData.coCd || '',
        coSn: salesData.coSn || '',
        coNm: salesData.coNm || '',
        slsNm: salesData.slsNm || '',
        userId: salesData.userId || '',
        userSn: salesData.userSn || 0,
        zip: salesData.zip || '',
        addr: salesData.addr || '',
        daddr: salesData.daddr || '',
        mngr: salesData.mngr || '',
        mngrTelno: salesData.mngrTelno || '',
        mngrEmail: salesData.mngrEmail || '',
        payment: salesData.payment || '',
        slsMngr: salesData.slsMngr || '',
        slsDt: salesData.slsDt || '',
        memo: salesData.memo || [],
        slsStgSn: salesData.slsStgSn || '',
        slsPrrtSn: salesData.slsPrrtSn || '',
      });
    }
  }, []);

  const handleLoadSales = (type) => {
    setCoType(type);
    setIsSalesOpen(true);
  };

  const handleMemoDelete = (fileIndex, isUploadedFile) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      memo: prevInputs.memo.filter((_, i) => i !== fileIndex),
    }));
  };
  return (
    <Dialog open={regOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title" sx={{ padding: '30px 40px' }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl size="small" fullWidth sx={{ width: 130 }}>
            <Select
              name="slsStgSn"
              // onChange={handleSearchTypeChange}
              onChange={handleInputChange}
              value={inputs.slsStgSn}
              displayEmpty
            >
              <MenuItem value="">단계 선택</MenuItem>
              {stgList.map((item, index) => (
                <MenuItem key={index} value={item.slsStgSn}>
                  {item.stgNm}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" fullWidth sx={{ width: 150 }}>
            <Select name="slsPrrtSn" onChange={handleInputChange} value={inputs.slsPrrtSn} displayEmpty>
              <MenuItem value="">우선순위 선택</MenuItem>
              {prrtList.map((item, index) => (
                <MenuItem key={index} value={item.slsPrrtSn}>
                  {item.prrtNm}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* {type === 'upd' && <Chip label={sttsChip(contractData.stts)} variant="outlined" />} */}

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <TextField
            size="small"
            name="slsNm"
            className="modal-tit2 rqd"
            fullWidth
            onChange={handleInputChange}
            placeholder="영업 프로젝트명을 입력해주세요"
            autoComplete="off"
            inputProps={{ maxLength: 50 }}
            value={inputs.slsNm ? inputs.slsNm : ''}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Grid container my={2} justifyContent={'space-between'}>
            <Typography variant="subtitle2" mr={1}>
              의뢰/제안사 정보
            </Typography>
            <Button type="button" className="btn-orange" onClick={() => handleLoadSales('sales')}>
              정보 가져오기
            </Button>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">회사코드</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="coCd"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.coCd ? inputs.coCd : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">회사명</Typography>
                <Typography variant="subtitle1" color={'red'}>
                  *
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="coNm"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.coNm ? inputs.coNm : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">회사 주소</Typography>
              </Grid>
              <Grid item md={8} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item md={6}>
                  <TextField
                    size="small"
                    name="zip"
                    variant="standard"
                    autoComplete="off"
                    onChange={handleInputChange}
                    value={inputs.zip ? inputs.zip : ''}
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item md={5}>
                  <Button type="button" className="btn" onClick={() => hanldeZipClick('clnt')}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="addr"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  placeholder="기본 주소 입력"
                  value={inputs.addr ? inputs.addr : ''}
                  fullWidth
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}></Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="daddr"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  placeholder="상세 주소 입력"
                  value={inputs.daddr ? inputs.daddr : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">담당자</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="mngr"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.mngr ? inputs.mngr : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">담당자 연락처</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="mngrTelno"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.mngrTelno ? setTelFormat(inputs.mngrTelno, '-') : ''}
                  fullWidth
                  inputProps={{ maxLength: '13' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">담당자 이메일</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="mngrEmail"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.mngrEmail ? inputs.mngrEmail : ''}
                  fullWidth
                  inputProps={{ maxLength: '50' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box my={2}>
          <Typography variant="subtitle2">영업 정보</Typography>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">계약금(예상)</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="payment"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.payment ? setNumberComma(inputs.payment) : ''}
                  fullWidth
                  placeholder="숫자만 입력"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">원</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">영업 담당자</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size="small"
                  name="slsMngr"
                  variant="standard"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={inputs.slsMngr ? inputs.slsMngr : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={4} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1">영업일자</Typography>
              </Grid>
              <Grid item md={8}>
                <DatePickerWrapper>
                  <DatePicker
                    name="slsDt"
                    locale={ko}
                    selected={inputs.slsDt}
                    autoComplete="off"
                    onChange={handleDateChange}
                    startDate={inputs.slsDt}
                    dateFormat={'YYYY-MM-dd'}
                    customInput={<ExampleCustomInput />}
                  />
                </DatePickerWrapper>
                {/* <TextField variant="standard" fullWidth /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'}>
              <Grid item md={4}>
                <Typography variant="subtitle1">메모</Typography>
              </Grid>
              <Grid item md={8}>
                {isValue(inputs.memo) &&
                  inputs.memo.length !== 0 &&
                  inputs.memo.map((item, index) => (
                    <Grid key={index} item md={12} sx={{ marginBottom: '14px' }}>
                      <Box component="div">
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography variant="body2" color={'gray'}>
                            {setDateFormat(item.regDttm, 'YYYY-MM-DD HH:mm')}
                          </Typography>
                          <IconButton onClick={() => handleMemoDelete(index, true)}>
                            <Close color="error" sx={{ width: '14px', height: '14px' }} />
                          </IconButton>
                        </Box>
                        <Typography variant="body1">{item.memo}</Typography>
                      </Box>
                    </Grid>
                  ))}
                <Grid item md={12}>
                  <Box component="div">
                    <TextField
                      name="memo"
                      size="small"
                      multiline
                      rows={4}
                      onChange={handleMemoChange}
                      fullWidth
                      autoComplete="off"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className="btn-white" onClick={() => setCancelPop(true)} sx={{ width: '100%' }}>
          취소
        </Button>
        <Button className="btn-navy" onClick={handlePostSalesRequestConfirm} sx={{ width: '100%' }}>
          {regType === 'reg' ? '등록' : '저장'}
        </Button>
      </DialogActions>
      {isOpen && <DaumPostCode isOpen={isOpen} setIsOpen={setIsOpen} handleZipSearch={handleZipSearch} />}

      {isSalesOpen && (
        <PageSalesLoadModal isOpen={isSalesOpen} setIsOpen={setIsSalesOpen} inputs={inputs} setInputs={setInputs} />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setRegOpen}
        />
      )}
      {savePop && (
        <ConfirmPopUp
          open={savePop}
          close={setSavePop}
          title={regType === 'reg' ? '등록하시겠습니까?' : '저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handlePostSalesRequest}
        />
      )}
    </Dialog>
  );
};

export default PageSalesRegModal;
