import { setNumberComma, showToastError } from '@common/common';
import { Category, Close, Edit } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getPaymentAgreement, getPaymentMonth } from '@store/actions/paymentAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import PagePaymentMonthUpdateModal from './PagePaymentMonthUpdateModal';

const PagePaymentListMonthDetailModal = (props) => {
  const { isOpen, setIsOpen, paymentDtl, isSuccess, setIsSuccess } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    dispatch(
      getPaymentMonth({
        userSn: user.userSn,
        userId: user.userId,
        paymentSn: paymentDtl.sn,
        callback: handleGetPaymentMonthCallback,
      })
    );
  }, []);

  const handleGetPaymentMonthCallback = (data) => {
    if (data.resultCode === 0) {
      setMonthList(data.data);
    } else {
      showToastError(data);
    }
  };

  const handleUpdateOpen = (tgtSn, tgtType) => {
    setIsUpdateOpen(true);
    setUpdateData({
      paymentSn: monthList.paymentSn,
      tgtSn,
      tgtType,
      cntrStDt: monthList.cntrStDt,
      cntrEndDt: monthList.cntrEndDt,
    });
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={'md'} disableEnforceFocus>
        <DialogTitle>
          <Button className="btn-close">
            <Close onClick={() => setIsOpen(false)} />
          </Button>
          <Typography className="modal-tit">{monthList.prjcNm}</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            {monthList.coNm} / {monthList.cntrStDt} ~ {monthList.cntrEndDt}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mb: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: 1, width: '25%' }}>지급 대상</TableCell>
                  <TableCell sx={{ padding: 1, width: '20%' }}>지급 기간</TableCell>
                  <TableCell sx={{ padding: 1, width: '15%', textAlign: 'center' }}>고용 형태</TableCell>
                  <TableCell sx={{ padding: 1, width: '15%', textAlign: 'end' }}>총 지급액</TableCell>
                  <TableCell sx={{ padding: 1, width: '10%', textAlign: 'center' }}>지급 수정</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {monthList.tgtMonth &&
                  monthList.tgtMonth.length > 0 &&
                  monthList.tgtMonth.map((item, index) => (
                    <TableRow
                      key={item.tgtSn}
                      sx={{ cursor: 'pointer' }}
                      // onClick={() => handleDtlClick(item.paymentDtlSn)}
                    >
                      <TableCell sx={{ padding: 1 }}>
                        <Typography variant="body1">{item.tgtNm}</Typography>
                        <Typography variant="body2">
                          {item.age}
                          {item.teamNm !== '' ? '/' + item.teamNm : ''}
                          {item.rankNm !== '' ? '/' + item.rankNm : ''}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: 1 }}>
                        {item.paymentStDt}~ {item.paymentEndDt}
                      </TableCell>
                      <TableCell sx={{ padding: 1, textAlign: 'center' }}>{item.emplTypeNm}</TableCell>
                      <TableCell sx={{ padding: 1, textAlign: 'end' }}>
                        {setNumberComma(String(item.totalCost))}&nbsp;원
                      </TableCell>
                      <TableCell sx={{ padding: 1, textAlign: 'center' }}>
                        <IconButton onClick={() => handleUpdateOpen(item.tgtSn, item.tgtType)}>
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      {isUpdateOpen && (
        <PagePaymentMonthUpdateModal
          isOpen={isUpdateOpen}
          setIsOpen={setIsUpdateOpen}
          setIsDetailOpen={setIsOpen}
          updateData={updateData}
          isSuceess={isSuccess}
          setIsSuccess={setIsSuccess}
        />
      )}
    </>
  );
};

export default PagePaymentListMonthDetailModal;
