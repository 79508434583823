import { setDateFormat, showToast } from '@common/common';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { getInptPrsnUpdDtl, putInptPrsn } from '@store/actions/projectAction';
import { ko } from 'date-fns/locale/ko';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';

import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectInptPrsnUpdate = (prop) => {
  const { updatePopup, setUpdatePopup, inptPrsnSn, updateResult } = prop;

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);
  const [resultData, setResultData] = useState({
    inptPrsnSn: '',
    rank: '',
    rankNm: '',
    position: '',
    positionNm: '',
    dtlPosition: '',
    inptStartDt: '',
    inptEndDt: '',
    userNm: '',
    gender: '',
    age: '',
    teamNm: '',
    userCd: '',
  });

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
    />
  ));

  const fn_dtlResult = (data) => {
    if (data.resultCode === 0) {
      setResultData(data.data);
    }
  };
  useEffect(() => {
    dispatch(
      getInptPrsnUpdDtl({
        inptPrsnSn: inptPrsnSn,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: fn_dtlResult,
      })
    );
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setResultData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setResultData({
      ...resultData,
      inptStartDt: start,
      inptEndDt: end,
    });
  };

  const fn_updateResult = (data) => {
    if (data.resultCode === 0) {
      updateResult();
      setUpdatePopup(false);
    }
  };

  const handleSendUpdate = () => {
    const sendData = {
      inptPrsnSn: inptPrsnSn,
      rank: resultData.rank,
      position: resultData.position,
      dtlPosition: resultData.dtlPosition,
      inptStartDt: setDateFormat(resultData.inptStartDt, 'YYYY-MM-DD'),
      inptEndDt: setDateFormat(resultData.inptEndDt, 'YYYY-MM-DD'),
      userSn: userInfo.userSn,
      userId: userInfo.userId,
    };

    if (checkValue(sendData)) {
      dispatch(
        putInptPrsn({
          ...sendData,
          callback: fn_updateResult,
        })
      );
    }
  };

  const checkValue = (data) => {
    console.log(data);
    if (data.rank === '') {
      showToast('직급을 선택해주세요', 'error');
      return false;
    } else if (data.position === '') {
      showToast('담당 직무를 선택해주세요', 'error');
      return false;
    } else if (data.dtlPosition === '') {
      showToast('상세 직무를 선택해주세요', 'error');
      return false;
    } else if (data.inptStartDt === '' || data.inptEndDt === '') {
      showToast('투입 기간을 선택해주세요', 'error');
      return false;
    }

    return true;
  };

  const [canclePopup, setCanclePopup] = useState(false);

  return (
    <>
      <Dialog
        open={updatePopup}
        fullWidth
        maxWidth={'xs'}
        disableEnforceFocus
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle>
          <Box display={'flex'} alignItems={'end'}>
            <Typography className="modal-tit" sx={{ mr: 2 }}>
              {resultData.userNm}
            </Typography>
            <Typography color={'grey'} fontSize={'18px'}>
              {resultData.gender}({resultData.age}세) / {resultData.teamNm} / {resultData.rankNm}{' '}
              {resultData.userCd ? '/ ' + resultData.userCd : ''}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11} sx={{ alignContent: 'center' }}>
                      <Typography variant="subtitle1">직급</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={6} sx={{ alignContent: 'center' }}>
                      <FormControl size="small" fullWidth>
                        <Select
                          fullWidth
                          displayEmpty
                          value={resultData.rank}
                          name="rank"
                          onChange={(e) => handleOnChange(e)}
                        >
                          <MenuItem value="">선택</MenuItem>
                          <MenuItem value="0">인턴</MenuItem>
                          <MenuItem value="1">사원</MenuItem>
                          <MenuItem value="2">주임</MenuItem>
                          <MenuItem value="3">대리</MenuItem>
                          <MenuItem value="4">과장</MenuItem>
                          <MenuItem value="5">차장</MenuItem>
                          <MenuItem value="6">부장</MenuItem>
                          <MenuItem value="7">이사</MenuItem>
                          <MenuItem value="8">상무</MenuItem>
                          <MenuItem value="9">전무</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11} sx={{ alignContent: 'center' }}>
                      <Typography variant="subtitle1">담당 직무</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={6} sx={{ alignContent: 'center' }}>
                      <FormControl size="small" fullWidth>
                        <Select
                          fullWidth
                          displayEmpty
                          value={resultData.position}
                          name="position"
                          onChange={(e) => handleOnChange(e)}
                        >
                          <MenuItem value="">선택</MenuItem>
                          <MenuItem value="1">개발자</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11} sx={{ alignContent: 'center' }}>
                      <Typography variant="subtitle1">상세 직무</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={11} sx={{ alignContent: 'center' }}>
                      <TextField
                        size="small"
                        variant="standard"
                        fullWidth
                        value={resultData.dtlPosition}
                        name="dtlPosition"
                        onChange={(e) => handleOnChange(e)}
                      />
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11} sx={{ alignContent: 'center', alignItems: 'center' }} display={'flex'}>
                      <Typography variant="subtitle1" sx={{ mr: 1 }}>
                        투입 기간
                      </Typography>
                      <Tooltip
                        title={
                          <>
                            ** 주의 **
                            <br />
                            투입기간을 수정하는 경우,
                            <br />
                            해당 프로젝트와 관련된 지급 목록에 대한
                            <br />
                            삭제 처리가 필요합니다.
                          </>
                        }
                      >
                        <ErrorIcon color="error" />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={11} sx={{ alignContent: 'center' }}>
                      <Box className="inp-date">
                        <DatePicker
                          locale={ko}
                          selected={resultData.inptStartDt}
                          onChange={handleDateChange}
                          startDate={resultData.inptStartDt}
                          endDate={resultData.inptEndDt}
                          dateFormat={'YYYY-MM-dd'}
                          selectsRange
                          customInput={<ExampleCustomInput />}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCanclePopup(true)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={handleSendUpdate}>
            저장
          </Button>
        </DialogActions>
      </Dialog>
      {canclePopup && (
        <ConfirmPopUp
          open={canclePopup}
          close={setCanclePopup}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setUpdatePopup}
        />
      )}
    </>
  );
};

export default PageProjectInptPrsnUpdate;
