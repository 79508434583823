import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getWrkfList } from '@store/actions/wrkfAction';
import { setDateFormat, showToast } from '@common/common';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';
import { getInptPrsnCheck, postInptPrsn } from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageProjectInptPrsnReg = (prop) => {
  const { regInpt, setRegInpt, projectSn, saveInpt, wrkfList } = prop;

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [wrkfData, setWrkfData] = useState([]);
  const [wrkfDataTotal, setWrkfDataTotal] = useState(0);

  const [filterWrkf, setFilterWrkf] = useState([]);

  const [inptList, setInptList] = useState([]);

  const fn_dataResult = (data) => {
    if (data.resultCode === 0 && data.data) {
      const resultData = data.data
        .filter((e) => !wrkfList.includes(e.wrkfSn))
        .sort((a, b) => a.userNm.localeCompare(b.userNm));

      setWrkfData(resultData);
      setFilterWrkf(resultData);
      setWrkfDataTotal(data.totalCount);
    }
  };

  useEffect(() => {
    dispatch(
      getWrkfList({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        pageNum: 0,
        callback: fn_dataResult,
      })
    );
  }, []);

  let wrkfInfo = {};
  const fn_checkInpt = (data) => {
    console.log(wrkfInfo);

    if (data.data) {
      showToast(data.data.coNm + '에서 이미 투입등록 되었습니다. 확인해주세요.');
    } else {
      setInptList((prev) => {
        const itemExists = prev.some((item) => item.wrkfSn === wrkfInfo.wrkfSn);
        if (!itemExists) {
          return [...prev, wrkfInfo];
        } else {
          return [...prev];
        }
      });
    }

    wrkfInfo = {};
  };

  const handleInpt = (wrkf) => {
    console.log(wrkf);
    wrkfInfo = wrkf;
    if (wrkf.userSnCd) {
      dispatch(
        getInptPrsnCheck({
          prjcMainSn: projectSn,
          wrkfUserSn: wrkf.userSnCd,
          callback: fn_checkInpt,
        })
      );
    } else {
      setInptList((prev) => {
        const itemExists = prev.some((item) => item.wrkfSn === wrkfInfo.wrkfSn);
        if (!itemExists) {
          return [...prev, wrkfInfo];
        } else {
          return [...prev];
        }
      });
    }
  };

  const handleDelete = (wrkf) => {
    setInptList((prev) => {
      prev.forEach((e) => {
        if (e.wrkfSn === wrkf.wrkfSn) {
          e.inptStartDt = null;
          e.inptEndDt = null;
          e.inptRank = null;
          e.inptPs = null;
          e.inptDPs = null;
        }
      });
      return prev.filter((item) => item.wrkfSn !== wrkf.wrkfSn);
    });
  };

  const [isNext, setIsNext] = useState(false);
  const handleNext = () => {
    if (!inptList.length > 0) {
      showToast('투입 인력을 선택해주세요.');
    } else {
      setIsNext(!isNext);
    }
  };

  const handleDateChange = (item, dates) => {
    const [start, end] = dates;

    setInptList((prev) => {
      prev.forEach((e) => {
        if (e.wrkfSn === item.wrkfSn) {
          e.inptStartDt = start;
          e.inptEndDt = end;
        }
      });

      return [...prev];
    });
  };

  const handleOnChange = (item, event) => {
    const { name, value } = event.target;

    setInptList((prev) => {
      prev.forEach((e) => {
        if (e.wrkfSn === item.wrkfSn) {
          e[name] = value;
        }
      });

      return [...prev];
    });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
      placeholder="yyyy-mm-dd - yyyy-mm-dd"
    />
  ));

  const fn_result = (data) => {
    if (data.resultCode === 0) {
      setRegInpt(false);
      saveInpt();
      showToast('저장에 성공하였습니다.');
    } else {
      showToast('저장에 실패하였습니다.', 'error');
    }
  };

  const [confirmPopUp, setConfirmPopUp] = useState(false);

  const saveInptPrsn = () => {
    const dataListMap = inptList.map((item) => ({
      prjcMainSn: projectSn,
      wrkfSn: item.wrkfSn,
      rank: item.inptRank,
      position: item.inptPs,
      dtlPosition: item.inptDPs,
      inptStartDt: setDateFormat(item.inptStartDt, 'YYYY-MM-DD'),
      inptEndDt: setDateFormat(item.inptEndDt, 'YYYY-MM-DD'),
    }));

    if (checkSave(dataListMap)) {
      setConfirmPopUp(true);
      setDataList(dataListMap);
    }
  };
  const [dataList, setDataList] = useState([]);

  const sendPostInptPrsn = () => {
    dispatch(
      postInptPrsn({
        dataList,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: fn_result,
      })
    );
  };

  const checkSave = (dataList) => {
    for (let i = 0; i < dataList.length; i++) {
      if (!dataList[i].rank) {
        showToast('직급을 선택해주세요.', 'error');
        return false;
      } else if (!dataList[i].inptStartDt || !dataList[i].inptEndDt) {
        showToast('투입기간을 선택해주세요.', 'error');
        return false;
      } else if (!dataList[i].position) {
        showToast('담당 직무를 선택해주세요.', 'error');
        return false;
      } else if (!dataList[i].dtlPosition) {
        showToast('상세 직무를 선택해주세요.', 'error');
        return false;
      }
    }

    return true;
  };

  const handleFilter = (e) => {
    const value = e.target.value;

    setFilterWrkf(() => {
      if (value === '') return wrkfData;

      const data = wrkfData.filter(
        (e) =>
          e.userNm.includes(value) || e.gender.includes(value) || e.rank.includes(value) || e.teamNm.includes(value)
      );
      return data;
    });
  };

  return (
    <>
      <Dialog open={regInpt} fullWidth maxWidth={'lg'} disableEnforceFocus sx={{ minHeight: '500px' }}>
        <DialogTitle>
          <Typography className="modal-tit">투입인력 추가</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            프로젝트에 투입할 인원을 선택해주세요.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent={'space-between'} hidden={isNext}>
            <Grid item sm={12} md={5.5}>
              <Grid
                item
                md={12}
                component={Card}
                variant="outlined"
                sx={{
                  mt: 2,
                  mr: 1,
                  borderColor: 'primary',
                  minHeight: '440px',
                }}
              >
                <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                      <Typography variant="body1" fontWeight={'500'}>
                        등록 인력 목록(총 {wrkfDataTotal} 명)
                      </Typography>
                    </Grid>
                  </Grid>
                </Toolbar>
                <Grid p={3}>
                  <Grid item xs={12} sm={12} md={12} mt={1}>
                    <TextField
                      sx={{ minWidth: '330px' }}
                      placeholder="이름/소속팀/직급을 검색해주세요."
                      className="inp-round"
                      autoComplete="off"
                      size="small"
                      name="searchValue"
                      fullWidth
                      onChange={handleFilter}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mt={1} mb={2}>
                    <Paper sx={{ height: '402px', overflow: 'auto' }}>
                      {filterWrkf ? (
                        filterWrkf.map((item) => (
                          <Grid container alignItems={'center'} px={3} key={item.wrkfSn}>
                            <Grid item md={12} display="flex" alignItems={'center'} borderBottom={'1px solid #ccc'}>
                              <Grid item md={10}>
                                <Box>
                                  <Typography variant="body2" component="div" fontWeight={'bold'} py={1}>
                                    {item.userNm}
                                  </Typography>
                                  <Typography variant="body2" pb={1}>
                                    {item.gender}({item.age}세) / {item.teamNm} / {item.rank}
                                    {item.userCd !== '' ? ' / ' + item.userCd : ''}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={2} sx={{ height: '100%', alignContent: 'center' }}>
                                <Button
                                  className="btn-orange"
                                  onClick={() => handleInpt(item)}
                                  disabled={inptList.some((prev) => prev.wrkfSn === item.wrkfSn)}
                                >
                                  투입
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Grid item md={12} px={3}>
                          <Typography variant="body2" fontWeight={'bold'} py={2} align="center">
                            검색결과 없습니다.
                          </Typography>
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={1} alignContent={'center'}>
              <IconButton
                disableRipple
                sx={{
                  alignContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '50%',
                  '& .MuiSvgIcon-root': {
                    transform: 'scaleY(9) scaleX(3)',
                    transformOrigin: 'center',
                    marginLeft: '-8px',
                    '&:first-of-type': {
                      marginLeft: 0,
                    },
                    color: '#6f6f6f',
                  },
                }}
                disabled
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
            </Grid>
            <Grid item sm={12} md={5.5}>
              <Grid
                item
                md={12}
                component={Card}
                variant="outlined"
                sx={{
                  mt: 2,
                  ml: 1,
                  borderColor: 'primary',
                  minHeight: '440px',
                }}
              >
                <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                      <Typography variant="body1" fontWeight={'500'}>
                        투입 인력 목록(총 {inptList.length} 명)
                      </Typography>
                    </Grid>
                  </Grid>
                </Toolbar>
                <Grid p={3}>
                  <Grid item xs={12} sm={12} md={12} mt={1} mb={2}>
                    <Paper sx={{ height: '450px', overflow: 'auto' }}>
                      {inptList.length > 0 ? (
                        inptList.map((item) => (
                          <Grid container alignItems={'center'} px={3} key={item.wrkfSn}>
                            <Grid item md={12} display="flex" alignItems={'center'} borderBottom={'1px solid #ccc'}>
                              <Grid item md={10}>
                                <Box>
                                  <Typography variant="body2" component="div" fontWeight={'bold'} py={1}>
                                    {item.userNm}
                                  </Typography>
                                  <Typography variant="body2" pb={1}>
                                    {item.gender}({item.age}세) / {item.teamNm} / {item.rank}
                                    {item.userCd !== '' ? ' / ' + item.userCd : ''}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={2} sx={{ height: '100%', alignContent: 'center' }}>
                                <Button className="btn" onClick={() => handleDelete(item)}>
                                  제외
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          fontWeight={'bold'}
                          py={2}
                          align="center"
                          alignContent="center"
                          height={'100%'}
                        >
                          투입인력을 선택해주세요.
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container hidden={!isNext}>
            <Paper
              sx={{
                height: '450px',
                overflow: 'auto',
                width: '100%',
                borderTop: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.12)',
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="25%">이름</TableCell>
                      <TableCell width="12%">직급</TableCell>
                      <TableCell width="25%">투입기간</TableCell>
                      <TableCell width="13%">담당 직무</TableCell>
                      <TableCell width="25%">상세 직무</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inptList ? (
                      inptList.map((item) => (
                        <TableRow key={item.wrkfSn}>
                          <TableCell>
                            <Box>
                              <Typography variant="body2" component="div" fontWeight={'bold'}>
                                {item.userNm}
                              </Typography>
                              <Typography variant="body2" pb={1}>
                                {item.gender}({item.age}세) / {item.teamNm} / {item.rank}
                                {item.userCd !== '' ? ' / ' + item.userCd : ''}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <FormControl size="small" fullWidth>
                              <Select
                                fullWidth
                                defaultValue=""
                                displayEmpty
                                onChange={(e) => handleOnChange(item, e)}
                                name="inptRank"
                              >
                                <MenuItem value="">선택</MenuItem>
                                <MenuItem value="0">인턴</MenuItem>
                                <MenuItem value="1">사원</MenuItem>
                                <MenuItem value="2">주임</MenuItem>
                                <MenuItem value="3">대리</MenuItem>
                                <MenuItem value="4">과장</MenuItem>
                                <MenuItem value="5">차장</MenuItem>
                                <MenuItem value="6">부장</MenuItem>
                                <MenuItem value="7">이사</MenuItem>
                                <MenuItem value="8">상무</MenuItem>
                                <MenuItem value="9">전무</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <DatePickerWrapper className="inp-date">
                              <DatePicker
                                locale={ko}
                                selected={item.inptStartDt}
                                onChange={(e) => handleDateChange(item, e)}
                                startDate={item.inptStartDt}
                                endDate={item.inptEndDt}
                                dateFormat={'YYYY-MM-dd'}
                                selectsRange
                                customInput={<ExampleCustomInput />}
                              />
                            </DatePickerWrapper>
                          </TableCell>
                          <TableCell>
                            <FormControl size="small" fullWidth>
                              <Select
                                fullWidth
                                defaultValue=""
                                displayEmpty
                                onChange={(e) => handleOnChange(item, e)}
                                name="inptPs"
                              >
                                <MenuItem value="">선택</MenuItem>
                                <MenuItem value="1">개발자</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              className="inp-round"
                              size="small"
                              fullWidth
                              placeholder=""
                              onChange={(e) => handleOnChange(item, e)}
                              name="inptDPs"
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          검색결과가 없습니다.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRegInpt(false)} className="btn-white" hidden={isNext}>
            취소
          </Button>
          <Button className="btn-navy" onClick={handleNext} hidden={isNext}>
            다음
          </Button>
          <Button onClick={() => setIsNext(!isNext)} className="btn-white" hidden={!isNext}>
            이전
          </Button>
          <Button className="btn-navy" onClick={saveInptPrsn} hidden={!isNext}>
            추가 완료
          </Button>
        </DialogActions>
      </Dialog>

      {confirmPopUp && (
        <ConfirmPopUp
          open={confirmPopUp}
          close={setConfirmPopUp}
          title={'투입인력 추가를 완료하시겠습니까?'}
          btnNm1={'아니요'}
          btnNm2={'예'}
          btnEvent={sendPostInptPrsn}
        />
      )}
    </>
  );
};

export default PageProjectInptPrsnReg;
