import { AddBox, IndeterminateCheckBox, ModeEdit } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import PageAddDvlpModal from './PageAddDvlpModal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deletePrjcDvlpInfo, getPrjcDvlpDtlSuccess, setPrjcDvlpDtlInit } from '@store/actions/projectAction';

const PageProjectRegDvlpInfoMain = ({ selectedItemIndex }) => {
  const [isAddDvlpOpen, setIsAddDvlpOpen] = useState(false);
  const [type, setType] = useState('');

  const dispatch = useDispatch();
  const dvlpData = useSelector((state) => state.projectReducer.projectDvlpInfo);

  const handleDeleteDvlpInfo = (dvlpSn) => {
    dispatch(deletePrjcDvlpInfo(dvlpSn));
  };

  const handleLoadDvlpInfo = (dvlpSn) => {
    dispatch(getPrjcDvlpDtlSuccess(dvlpSn));
    setType('upd');
    setIsAddDvlpOpen(true);
  };

  const handleLoadAddPrjcDvlp = () => {
    dispatch(setPrjcDvlpDtlInit());
    setType('reg');
    setIsAddDvlpOpen(true);
  };
  return (
    <>
      <Grid container borderBottom={1} borderColor={'divider'}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} mt={2}>
          <Grid item>
            <Typography variant="subtitle1">개발사 정보</Typography>
          </Grid>
        </Grid>
        <Grid container p={2}>
          <Grid container mb={2}>
            <Typography variant="body2" color={'primary'}>
              회사코드 입력 시 해당 개발사 화면에 프로젝트가 자동 생성되어 연동됩니다. 프로젝트의 원활한 관리를 위해서는
              개발사 회사코드를 꼭 입력해주세요.
            </Typography>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell size="small" width={50}>
                    <IconButton color="primary" onClick={handleLoadAddPrjcDvlp}>
                      <AddBox />
                    </IconButton>
                  </TableCell>
                  <TableCell width={250}>회사코드</TableCell>
                  <TableCell width={350}>회사명</TableCell>
                  <TableCell width={400}>회사 주소</TableCell>
                  <TableCell width={100}>상세</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dvlpData &&
                  dvlpData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {(selectedItemIndex !== 3 || index !== 0) && (
                          <IconButton onClick={() => handleDeleteDvlpInfo(item.dvlpCoSn)}>
                            <IndeterminateCheckBox />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>{item.coCd}</TableCell>
                      <TableCell>{item.dvlpNm}</TableCell>
                      <TableCell>
                        {item.zip}, {item.addr}, {item.daddr}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleLoadDvlpInfo(item.dvlpCoSn)}>
                          <ModeEdit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {isAddDvlpOpen && <PageAddDvlpModal isOpen={isAddDvlpOpen} setIsOpen={setIsAddDvlpOpen} type={type} />}
      {/* {isLoadDvlpOpen && <PagePrjcDvlpInfoDtlModal isOpen={isLoadDvlpOpen} setIsOpen={setIsLoadDvlpOpen} />} */}
    </>
  );
};

export default PageProjectRegDvlpInfoMain;
