import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDevDetail, putDvlpCo } from '@store/actions/counterpartyAction';
import { useSelector } from 'react-redux';
import { isValue, showToast, showToastError } from '@common/common';
import CompanyCode from './PageCounterPartyCode';
import DaumPostCode from 'component/DaumPostCode';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageDevDetail = ({ devId, dtlOpen, setDtlOpen, setIsSuccess, isSuccess }) => {
  const initialClientDetail = {
    dealYn: '',
    clntNm: '',
    coCd: '',
    zip: '',
    addr: '',
    daddr: '',
    telno: '',
    fxno: '',
    mngr: '',
    mngrTelno: '',
    mngrEmail: '',
    memo: '',
  };

  const userInfo = useSelector((state) => state.commonReducer.user);
  const [isCodeOpen, setIsCodeOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [updPop, setUpdPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [zipType, setZipType] = useState('');
  const [formData, setFormData] = useState(initialClientDetail);

  const dispatch = useDispatch();
  const [devDetail, setDevDetail] = useState(initialClientDetail);

  useEffect(() => {
    if (devId) {
      dispatch(
        getDevDetail({
          userSn: userInfo.userSn,
          userId: userInfo.userId,
          dvlpCoSn: devId,

          callback: (data) => {
            if (data.resultCode === 0) {
              setDevDetail(data.data);
            }
          },
        })
      );
    }
  }, [devId, dispatch, userInfo.userSn, userInfo.userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDevDetail((prevDevDetail) => ({
      ...prevDevDetail,
      [name]: value,
    }));
  };

  const handleUpdConfirm = () => {
    if (!isValue(devDetail.dvlpNm)) {
      return showToast('개발사명을 입력해주세요.');
    } else if (!isValue(devDetail.dealYn)) {
      return showToast('거래상태를 선택해주세요.');
    } else if (
      (isValue(devDetail.telno) && devDetail.telno.length < 9) ||
      (isValue(devDetail.mngrTelno) && devDetail.mngrTelno.length < 9) ||
      (isValue(devDetail.fxno) && devDetail.fxno.length < 9)
    ) {
      return showToast('전화번호 형식을 확인해주세요.');
    }

    setUpdPop(true);
  };

  const putSaveDvlp = () => {
    dispatch(
      putDvlpCo({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        dvlpCoSn: devId,
        coCd: devDetail.coCd,
        dvlpNm: devDetail.dvlpNm,
        zip: devDetail.zip,
        addr: devDetail.addr,
        daddr: devDetail.daddr,
        telno: devDetail.telno,
        fxno: devDetail.fxno,
        mngr: devDetail.mngr,
        mngrTelno: devDetail.mngrTelno,
        mngrEmail: devDetail.mngrEmail,
        memo: devDetail.memo,
        dealYn: devDetail.dealYn,
        callback: handlePutDvlpCoCallback,
      })
    );
  };

  const handlePutDvlpCoCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('개발사 정보가 수정되었습니다.');
      setDtlOpen(false);
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };

  const handleSetCompanyCode = (companyData) => {
    setDevDetail((prev) => ({
      ...prev,
      coCd: companyData.coCd,
      dvlpNm: companyData.coNm,
      telno: companyData.rprNo,
      fxno: companyData.faxNo,
      zip: companyData.zip,
      addr: companyData.addr,
      daddr: companyData.daddr,
      mngr: companyData.userNm,
      mngrTelno: companyData.mblNo,
      mngrEmail: companyData.mngrEmail,
      memo: companyData.memo,
    }));
    setIsOpen(false);
  };

  const handleChangeCode = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleZipSearch = (data) => {
    setDevDetail((prevDevDetail) => ({
      ...prevDevDetail,
      zip: data.zonecode,
      addr: data.address,
    }));
    setIsOpen(false);
  };

  const handleCodeOpen = () => {
    setIsCodeOpen(true);
  };

  const closeModal = () => {
    setIsCodeOpen(false);
  };

  const handleZipClick = (type) => {
    setZipType(type);
    setIsOpen(true);
  };

  // 저장 버튼 클릭 처리 함수
  // const handleSave = () => {
  //   //dispatch(savedevData(dev));
  //   close();
  // };

  return (
    <Dialog open={dtlOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
      <DialogTitle>
        <FormControl variant="outlined">
          <Select
            variant="outlined"
            size="small"
            displayEmpty
            fullWidth
            name="dealYn"
            value={devDetail.dealYn || ''}
            onChange={handleChange}
            sx={{ width: '150px', marginBottom: '10px' }}
          >
            <MenuItem value="">거래상태 선택</MenuItem>
            <MenuItem value="Y">거래 중</MenuItem>
            <MenuItem value="N">거래 종료</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="modal-tit2 rqd"
          size="medium"
          fullWidth
          name="dvlpNm"
          variant="standard"
          value={devDetail.dvlpNm || ''}
          placeholder="개발사명을 입력해주세요"
          onChange={handleChange}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={2}>
              <Typography variant="subtitle1">회사코드</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                size="small"
                fullWidth
                name="coCd"
                variant="standard"
                value={devDetail.coCd ? devDetail.coCd : ''}
                InputProps={{ readOnly: true }}
                onChange={handleChangeCode}
                onClick={handleCodeOpen}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={2}>
              <Typography variant="subtitle1">회사 주소</Typography>
            </Grid>
            <Grid container item md={8} spacing={2}>
              <Grid container item justifyContent={'space-between'} alignItems={'flex-end'} spacing={1}>
                <Grid item md={7}>
                  <TextField
                    variant="standard"
                    fullWidth
                    name="zip"
                    value={devDetail.zip ? devDetail.zip : ''}
                    onChange={handleChange}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item md={5}>
                  <Button className="btn" onClick={() => handleZipClick('zip')}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  size="small"
                  placeholder="기본주소"
                  name="addr"
                  fullWidth
                  value={devDetail.addr ? devDetail.addr : ''}
                  onChange={handleChange}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  variant="standard"
                  size="small"
                  placeholder="상세주소 입력"
                  name="daddr"
                  fullWidth
                  value={devDetail.daddr ? devDetail.daddr : ''}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">대표번호</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="telno"
                value={devDetail.telno ? devDetail.telno : ''}
                onChange={handleChange}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">팩스번호</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="fxno"
                value={devDetail.fxno ? devDetail.fxno : ''}
                onChange={handleChange}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">담당자</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="mngr"
                value={devDetail.mngr ? devDetail.mngr : ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">담당자 연락처</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="mngrTelno"
                value={devDetail.mngrTelno ? devDetail.mngrTelno : ''}
                onChange={handleChange}
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">담당자 이메일</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                name="mngrEmail"
                value={devDetail.mngrEmail ? devDetail.mngrEmail : ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} justifyContent={'space-between'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">메모</Typography>
            </Grid>
            <Grid item md={8}>
              <TextField multiline fullWidth rows={3} name="memo" value={devDetail.memo} onChange={handleChange} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCancelPop(true)} className="btn-white">
          취소
        </Button>
        <Button onClick={handleUpdConfirm} className="btn-navy">
          저장
        </Button>
      </DialogActions>
      {isOpen && <DaumPostCode isOpen={isOpen} setIsOpen={setIsOpen} handleZipSearch={handleZipSearch} />}
      {isCodeOpen && (
        <CompanyCode
          isOpen={isCodeOpen}
          setIsOpen={setIsCodeOpen}
          setCompanyCode={handleSetCompanyCode}
          close={closeModal}
        />
      )}
      {updPop && (
        <ConfirmPopUp
          open={updPop}
          close={setUpdPop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={putSaveDvlp}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setDtlOpen}
        />
      )}
    </Dialog>
  );
};

export default PageDevDetail;
