import { showToastError } from '@common/common';
import { Close, Search } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getPrjcGrpList } from '@store/actions/projectAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PageLoadGroupModal = ({ isOpen, setIsOpen, inputs, setInputs }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);
  const pUser = useSelector((state) => state.projectReducer.project);

  const [keyword, setKeyword] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    handleGetPrjcGrpList();
  }, [dispatch]);

  const handleGetPrjcGrpList = (newKeyword) => {
    dispatch(
      getPrjcGrpList({
        userId: user.userId,
        userSn: user.userSn,
        puserSn: pUser.puserSn,
        prjcMainSn: id,
        keyword: newKeyword,
        callback: handleGetPrjcGrpListCallback,
      })
    );
  };

  const handleGetPrjcGrpListCallback = (data) => {
    if (data.resultCode === 0) {
      setGroupList(data.data);
    } else {
      showToastError(data);
    }
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList = groupList.filter((data) => {
      const slsNmMatch = data.slsNm ? data.slsNm.toLowerCase().includes(newKeyword) : false;
      const prjcNmMatch = data.prjcNm ? data.prjcNm.includes(newKeyword) : false;
      return slsNmMatch || prjcNmMatch;
    });

    setFilteredList(filteredList);
  };

  const displayList = keyword ? filteredList : groupList;

  const handleSetGroupInfo = (sn, nm) => {
    setIsOpen(false);
    setInputs((prevInputs) => {
      const isGroupNameChanged = prevInputs.grpNm !== nm;

      return {
        ...prevInputs,
        prjcGrpSn: sn,
        grpNm: nm,
        prntTaskSn: isGroupNameChanged ? '' : prevInputs.prntTaskSn, // Reset tasknm if grpNm changed
        prntTaskNm: isGroupNameChanged ? '' : prevInputs.taskNm, // Reset tasknm if grpNm changed
      };
    });
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          그룹
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          업무 그룹을 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="그룹명을 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onKeyUp={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="list-type">
          {displayList.length > 0 ? (
            displayList.map((item) => (
              <Grid container key={item.prjcGrpSn}>
                <Grid item>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.grpNm}
                  </Typography>
                  <Typography variant="body2">{item.clntCd}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handleSetGroupInfo(item.prjcGrpSn, item.grpNm)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageLoadGroupModal;
