import { showToast } from '@common/common';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { delIssue } from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageProjectIssueDelete = (prop) => {
  const { deleteOpen, setDeleteOpen, resultData, handleIssue } = prop;

  const [integratedData, setIntegratedData] = useState([]);

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  useEffect(() => {
    if (Object.entries(resultData).length !== 0) {
      const excludeKeys = ['cnt', 'delBtnExpsYn'];

      const data = Object.entries(resultData)
        .filter(([key]) => !excludeKeys.includes(key))
        .map(([_, value]) => value)
        .flat();

      setIntegratedData(data);
    }
  }, []);

  const [checkedList, setCheckedList] = useState([]);
  const checkHandler = (e, value) => {
    const checked = e.target.checked;
    if (checked) {
      setCheckedList((prev) => [...prev, value]);

      return;
    }

    if (!checked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));
      return;
    }
  };

  const handleDelete = () => {
    dispatch(
      delIssue({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        issueSnList: checkedList,
        callback: fn_delResult,
      })
    );
  };

  const fn_delResult = (data) => {
    if (data.resultCode === 0) {
      showToast('이슈 ' + checkedList.length + '건이 삭제되었습니다.');
      setDeleteConfirm(false);
      setDeleteOpen(false);

      handleIssue();
    }
  };

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleConfirm = () => {
    if (checkedList.length > 0) {
      setDeleteConfirm(true);
    } else {
      showToast('삭제할 이슈를 선택해주세요.', 'error');
    }
  };

  return (
    <>
      <Dialog open={deleteOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
        <DialogTitle>
          <Typography className="modal-tit">이슈 삭제</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            내가 작성한 이슈만 삭제가 가능합니다.
          </Typography>
        </DialogTitle>
        <DialogContent>
          {integratedData.length > 0 ? (
            integratedData.map((item) => (
              <Grid container key={item.issueSn} alignItems={'center'} py={2} borderBottom={'1px solid #ccc'}>
                <Grid item md={2}>
                  <Checkbox
                    onChange={(e) => checkHandler(e, item.issueSn)}
                    disabled={item.isDelYN === 'N'}
                    color={item.isDelYN === 'N' ? 'error' : 'info'}
                  />
                </Grid>
                <Grid item md={10}>
                  <Box component={'div'}>
                    <Typography variant="body2" fontWeight={'bold'}>
                      {item.issueNm}
                    </Typography>
                    <Typography variant="body2">
                      {item.mngrList.join(', ')} / {item.chkList.join(', ')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              height={'100%'}
              alignContent={'center'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography variant="body1">등록된 이슈가 없습니다.</Typography>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={handleConfirm}>
            {checkedList.length}건 삭제
          </Button>
        </DialogActions>
      </Dialog>

      {deleteConfirm && (
        <ConfirmPopUp
          open={deleteConfirm}
          close={setDeleteConfirm}
          title={'선택한 이슈를 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다.'}
          btnNm1={'아니요'}
          btnNm2={'예'}
          btnEvent={handleDelete}
        />
      )}
    </>
  );
};

export default PageProjectIssueDelete;
