import { DatePickerRound, setDateFormat, showToast, showToastError } from '@common/common';
import { MoreVert } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { delPrjcEvent, postPrjcEvent, putPrjcEvent } from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { format, setDate, setHours, setMinutes } from 'date-fns';
import { ko } from 'date-fns/locale/ko';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
// import styled from 'styled-components';

// const StyledDatePicker = styled(DatePicker)`
//   && {
//     width: 100%;
//     padding: 5px;
//     border: 1px solid #ccc;
//     border-radius: 30px;
//     background: url(/calendar_icon.png) no-repeat center left 8px /20px !important;

//     padding-left: 30px !important;
//   }
// `;

const PageProjectCalendarPop = (prop) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const { openPopup, setOpenPopup, projectSn, saveResult, updateEvent } = prop;

  const [isAction, setIsAction] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [eventSave, setEventSave] = useState({
    prjcMainSn: projectSn,
    title: '',
    start: '',
    end: '',
    prjcContent: '',
    userSn: userInfo.userSn,
    userId: userInfo.userId,
    kebabYn: '',
  });

  useEffect(() => {
    if (updateEvent !== '') {
      setEventSave(updateEvent);
      setIsAction(true);
    }
  }, []);

  const handleOnchange = (e) => {
    const { name, value } = e.target;

    if (name === 'prjcContent' && value.length > 150) return;
    setEventSave((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fn_result = (data) => {
    if (data.resultCode === 0) {
      showToast('신규 이벤트가 등록 되었습니다.');
      setOpenPopup(false);
      saveResult();
    }
  };

  const sendSave = () => {
    console.log(eventSave.start);
    if (checkValue()) {
      dispatch(
        postPrjcEvent({
          ...eventSave,
          start: format(eventSave.start, 'yyyy-MM-dd HH:mm:ss'),
          end: format(eventSave.end, 'yyyy-MM-dd HH:mm:ss'),
          callback: fn_result,
        })
      );
    }
  };

  const checkValue = () => {
    if (eventSave.title === '') {
      showToast('이벤트명을 입력하세요.', 'error');
      return false;
    } else if (!(eventSave.start instanceof Date)) {
      showToast('시작일을 선택하세요.', 'error');
      return false;
    } else if (!(eventSave.end instanceof Date)) {
      showToast('종료일을 선택하세요.', 'error');
      return false;
    } else if (eventSave.start > eventSave.end) {
      showToast('시작일이 종료일보다 클 수 없습니다.', 'error');
      return false;
    } else if (eventSave.prjcContent === '') {
      showToast('내용을 입력하세요.', 'error');
      return false;
    }
    return true;
  };

  const handleCancle = () => {
    setOpenPopup(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const options = ['수정', '삭제'];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(eventSave);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deletePop, setDeletePop] = useState(false);
  const handleUpdate = (index) => {
    setAnchorEl(null);
    if (index === 0) {
      setIsAction(false);
      setIsUpdate(true);
    } else {
      setDeletePop(true);
    }
  };

  const fn_updateResult = (data) => {
    if (data.resultCode === 0) {
      showToast('이벤트가 수정 되었습니다.');
      setOpenPopup(false);
      saveResult();
    }
  };

  const sendUpdate = () => {
    if (checkValue()) {
      dispatch(
        putPrjcEvent({
          ...eventSave,
          start: format(eventSave.start, 'yyyy-MM-dd HH:mm:ss'),
          end: format(eventSave.end, 'yyyy-MM-dd HH:mm:ss'),
          callback: fn_updateResult,
        })
      );
    }
  };

  const fn_deleteResult = (data) => {
    if (data.resultCode === 0) {
      showToast('이벤트가 삭제 되었습니다.');
      setOpenPopup(false);
      saveResult();
    }
  };

  const handleDelete = () => {
    dispatch(
      delPrjcEvent({
        ...eventSave,
        callback: fn_deleteResult,
      })
    );
  };

  const handlePopup = () => {
    if (!isUpdate && isAction) {
      setOpenPopup(false);
    }
  };

  const [classNm, setClassNm] = useState('');
  useEffect(() => {
    if (!isUpdate && isAction) {
      setClassNm('');
    } else {
      setClassNm('rqd');
    }
  }, [isUpdate, isAction]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openPopup}
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '450px',
          },
        }}
        disableEnforceFocus
        onClose={handlePopup}
      >
        <DialogTitle>
          <Grid container>
            <Grid item md={6}>
              <TextField
                size="medium"
                fullWidth
                variant="standard"
                name="title"
                className={'modal-tit2 ' + classNm}
                placeholder="이벤트명을 입력해주세요."
                value={eventSave.title}
                onChange={handleOnchange}
                InputProps={{
                  readOnly: isAction,
                }}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={5} display={'flex'} alignItems={'center'} sx={{ justifyContent: 'end' }}>
              {eventSave.kebabYn === 'Y' && (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={option} onClick={() => handleUpdate(index)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </DialogTitle>
        <DialogContent>
          <Grid container padding={'0 10px;'}>
            <Grid container>
              <Grid item xs={2} sx={{ alignContent: 'center' }}>
                <Typography variant="subtitle1" className={classNm}>
                  기간
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={5}>
                    <DatePicker
                      locale={ko}
                      selected={eventSave.start}
                      onChange={(e) => setEventSave((prev) => ({ ...prev, start: e, end: '' }))}
                      startDate={eventSave.start}
                      dateFormat={'YYYY-MM-dd HH:mm'}
                      showTimeSelect
                      readOnly={isAction}
                      customInput={<DatePickerRound />}
                    />
                  </Grid>
                  <Grid item xs={1} textAlign="center" alignContent="center">
                    <span>~</span>
                  </Grid>
                  <Grid item xs={5}>
                    <DatePicker
                      locale={ko}
                      selected={eventSave.end}
                      onChange={(e) => setEventSave((prev) => ({ ...prev, end: e }))}
                      startDate={eventSave.end}
                      dateFormat={'YYYY-MM-dd HH:mm'}
                      showTimeSelect
                      minDate={eventSave.start}
                      minTime={
                        setDateFormat(eventSave.start) === setDateFormat(eventSave.end)
                          ? eventSave.start
                          : setHours(setMinutes(new Date(), 0), 0)
                      }
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      disabled={eventSave.start === '' ? true : false}
                      readOnly={isAction}
                      customInput={<DatePickerRound />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container my={2}>
              <Grid item xs={2}>
                <Typography variant="subtitle1" className={classNm}>
                  내용
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  multiline
                  fullWidth
                  rows={6}
                  name="prjcContent"
                  value={eventSave.prjcContent}
                  onChange={handleOnchange}
                  InputProps={{
                    readOnly: isAction,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancle()} className="btn-white" fullWidth hidden={!isUpdate && isAction}>
            취소
          </Button>
          {updateEvent === '' && (
            <Button className="btn-navy" fullWidth onClick={() => sendSave()} hidden={isAction}>
              등록
            </Button>
          )}
          {updateEvent !== '' && (
            <Button className="btn-navy" fullWidth onClick={() => sendUpdate()} hidden={!isUpdate}>
              저장
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'이벤트를 삭제하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다.'}
          color={'red'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDelete}
        />
      )}
    </>
  );
};

export default PageProjectCalendarPop;
