export const GET_DASHBOARD_INFO_D = 'GET_DASHBOARD_INFO_D';
export const GET_DASHBOARD_INFO_E = 'GET_DASHBOARD_INFO_E';

export const getDashboardInfoD = (payload) => {
  return { type: GET_DASHBOARD_INFO_D, payload };
};

export const getDashboardInfoE = (payload) => {
  return { type: GET_DASHBOARD_INFO_E, payload };
};
