import { Search } from '@mui/icons-material';
import { Box, Button, FormControl, InputAdornment, MenuItem, Select, TextField, Toolbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PageSalesRegModal from './PageSalesRegModal';
import PageSalesStepModal from './PageSalesStepModal';
import { useDispatch } from 'react-redux';
import { setCntrDtlInit } from '@store/actions/contractAction';
import { getSalesList, getStgList, getPrrtList } from '@store/actions/salesAction';
import { isComplHangul, showToast, showToastError } from '@common/common';
import { useSelector } from 'react-redux';
import PageSalesMainList from './PageSalesMainList';
import PageSalesDeleteModal from './PageSalesDeleteModal';
import PageSalesPrrtModal from './PageSalesPrrtModal';
import { SEARCH_TIME } from '@common/constants';
import _ from 'lodash';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageSalesMain = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [regOpen, setRegOpen] = useState(false);
  const [updOpen, setUpdOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [stepOpen, setStepOpen] = useState(false);
  const [prrtOpen, setPrrtOpen] = useState(false);
  const [isSuccessYn, setIsSuccessYn] = useState(false);
  const [slsStgSn, setSlsStgSn] = useState('');
  const [slsPrrtSn, setSlsPrrtSn] = useState('');
  const [salesData, setSalesData] = useState(null);
  const [stgList, setStgList] = useState([]);
  const [prrtList, setPrrtList] = useState([]);
  const [whatModal, setWhatModal] = useState('');
  const [dblReqCheck, setDblReqCheck] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);

  const [search, setSearch] = useState({
    searchType: 1,
    type: 1,
    keyword: '',
  });
  const [rows, setRows] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleGetSaleListCallback = (data) => {
    if (data.resultCode === 0) {
      setRows([]);

      let itemsObj = [];
      let list = [];

      console.log('data.data.stgList', data.data.stgList);
      console.log('data.data.prrtList', data.data.prrtList);
      setStgList(data.data.stgList);
      setPrrtList(data.data.prrtList);

      if (search.type == 1) {
        list = data.data.stgList;
      } else {
        list = data.data.prrtList;
      }

      list.forEach((item, index) => {
        let obj = {};
        obj.stg = item.stg;
        obj.prrt = item.prrt;
        obj.id =
          search.type === 1
            ? String(item.slsStgSn + '-' + '' + '-' + item.stg + '-' + '')
            : String('' + '-' + item.slsPrrtSn + '-' + '' + '-' + item.prrt);
        obj.nm = search.type === 1 ? String(item.stgNm) : String(item.prrtNm);
        obj.items = [];
        itemsObj.push(obj);
      });

      let res = data.data;
      itemTotalSetting(res.total, itemsObj);
    } else {
      showToastError(data.resultCode + ':' + data.resultMsg);
    }
  };

  const itemTotalSetting = (res, itemsObj) => {
    res.forEach((item, index) => {
      //console.log('item', item.stg);
      let obj = {};
      obj.id = String(item.salesSn + '-' + item.salesSn + '-' + item.stgIdx);
      obj.salesSn = String(item.salesSn);
      obj.slsNm = String(item.slsNm);
      obj.coNm = String(item.coNm);
      obj.payment = String(item.payment);
      obj.slsStgSn = String(item.slsStgSn);
      obj.slsPrrtSn = String(item.slsPrrtSn);
      obj.slsMngr = String(item.slsMngr);
      obj.slsDt = String(item.slsDt);
      obj.stgIdx = String(item.stgIdx);
      obj.prrtIdx = String(item.prrtIdx);
      obj.prrt = String(item.prrt);
      obj.prrtNm = String(item.prrtNm);
      obj.stg = String(item.stg);
      obj.stgNm = String(item.stgNm);
      obj.stgLog = String(item.stgLog);

      itemsObj.forEach((io, index) => {
        if (search.type == 1 && item.stg == io.stg) {
          io.items.push(obj);
        } else if (search.type == 2 && item.prrt == io.prrt) {
          io.items.push(obj);
        }
      });
    });

    setRows(itemsObj);
  };

  const itemSetting = (res) => {
    let itemsObj = {
      id: '',
      nm: '',
      items: [],
    };

    res.forEach((item, index) => {
      let obj = {};
      if (index == 0) {
        itemsObj.id = String(item.slsStgSn + '-' + item.slsPrrtSn + '-' + item.stg + '-' + item.prrt);
        itemsObj.nm = search.type === 1 ? String(item.stgNm) : String(item.prrtNm);
      }
      obj.id = String(item.salesSn + '-' + item.salesSn + '-' + item.stgIdx);
      obj.salesSn = String(item.salesSn);
      obj.slsNm = String(item.slsNm);
      obj.coNm = String(item.coNm);
      obj.payment = String(item.payment);
      obj.slsStgSn = String(item.slsStgSn);
      obj.slsPrrtSn = String(item.slsPrrtSn);
      obj.slsMngr = String(item.slsMngr);
      obj.slsDt = String(item.slsDt);
      obj.stgIdx = String(item.stgIdx);
      obj.prrtIdx = String(item.prrtIdx);
      obj.prrt = String(item.prrt);
      obj.prrtNm = String(item.prrtNm);
      obj.stg = String(item.stg);
      obj.stgNm = String(item.stgNm);
      obj.stgLog = String(item.stgLog);
      itemsObj.items.push(obj);
    });

    //console.log('itemsObjitemsObj', itemsObj);

    //setRows(prevList => [...prevList, itemsObj]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const debouncedDispatch = useCallback(
    _.debounce((newSearch) => {
      if (isComplHangul(newSearch.keyword)) {
        dispatch(
          getSalesList({
            ...newSearch,
            userId: user.userId,
            userSn: user.userSn,
            callback: handleGetSaleListCallback,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  useEffect(() => {
    if (!dblReqCheck) {
      setDblReqCheck(true);
      return;
    }
    debouncedDispatch(search);
  }, [debouncedDispatch]);

  useEffect(() => {
    dispatch(
      getSalesList({
        ...search,
        userId: user.userId,
        userSn: user.userSn,
        callback: handleGetSaleListCallback,
      })
    );
  }, [search, isSuccessYn]);

  const handleRegOpen = (what) => {
    setSalesData({});
    setWhatModal(what);
    setRegOpen(true);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleStepOpen = () => {
    setStepOpen(true);
  };

  const handlePrrtOpen = () => {
    setPrrtOpen(true);
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ minWidth: 150, mr: 1 }}>
            <FormControl size="small" fullWidth>
              <Select name="searchType" defaultValue={1} value={search.searchType} onChange={handleChange}>
                <MenuItem value={0}>전체</MenuItem>
                <MenuItem value={1}>프로젝트명</MenuItem>
                <MenuItem value={2}>의뢰/제안사명</MenuItem>
                <MenuItem value={3}>영업담당자명</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            placeholder="내용을 검색해주세요"
            autoComplete="off"
            className="inp-round"
            size="small"
            name="keyword"
            onKeyUp={handleChange}
            //disabled={srchType === '' || srchType === 4 ? true : false}
            // onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ minWidth: '300px', textAlign: 'center' }}>
          <FormControl size="small" fullWidth sx={{ mr: 1, width: '150px' }}>
            <Select name="type" defaultValue={1} value={search.type} onChange={handleChange}>
              <MenuItem value={1}>단계별 보기</MenuItem>
              <MenuItem value={2}>우선순위별 보기</MenuItem>
            </Select>
          </FormControl>
          <Button className="btn" sx={{ mr: 1, width: '110px' }} onClick={handleStepOpen}>
            단계 편집
          </Button>
          <Button className="btn" sx={{ mr: 1, width: '110px' }} onClick={handlePrrtOpen}>
            우선순위 편집
          </Button>
          <Button className="btn" sx={{ mr: 1 }} onClick={() => handleRegOpen('main')}>
            등록
          </Button>
          <Button className="btn-red" color="error" onClick={handleDeleteOpen}>
            삭제
          </Button>
        </Box>
      </Toolbar>

      <PageSalesMainList
        rows={rows}
        setRows={setRows}
        setUpdOpen={setUpdOpen}
        handleRegOpen={handleRegOpen}
        type={search.type}
        setSlsStgSn={setSlsStgSn}
        setSlsPrrtSn={setSlsPrrtSn}
        setSalesData={setSalesData}
      />

      {(regOpen || updOpen) && (
        <PageSalesRegModal
          regType={regOpen ? 'reg' : 'upd'}
          regOpen={regOpen ? regOpen : updOpen}
          setRegOpen={regOpen ? setRegOpen : setUpdOpen}
          setIsSuccessYn={setIsSuccessYn}
          isSuccessYn={isSuccessYn}
          type={search.type}
          slsStgSn={slsStgSn}
          slsPrrtSn={slsPrrtSn}
          stg={stgList}
          prrt={prrtList}
          salesData={salesData}
          whatModal={regOpen ? whatModal : 'detail'}
        />
      )}

      {stepOpen && (
        <PageSalesStepModal
          stepOpen={stepOpen}
          setStepOpen={setStepOpen}
          setIsSuccessYn={setIsSuccessYn}
          isSuccessYn={isSuccessYn}
        />
      )}

      {prrtOpen && (
        <PageSalesPrrtModal
          prrtOpen={prrtOpen}
          setPrrtOpen={setPrrtOpen}
          setIsSuccessYn={setIsSuccessYn}
          isSuccessYn={isSuccessYn}
        />
      )}

      {deleteOpen && (
        <PageSalesDeleteModal
          deleteOpen={deleteOpen}
          setDeleteOpen={setDeleteOpen}
          setIsSuccessYn={setIsSuccessYn}
          isSuccessYn={isSuccessYn}
        />
      )}

      {/* {updOpen && (
        <PageSalesRegModal type={'upd'} regOpen={updOpen} setRegOpen={setUpdOpen} setIsSuccessYn={setIsSuccessYn} />
      )} */}
    </>
  );
};

export default PageSalesMain;
