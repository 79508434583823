import React from 'react';
import PageSuccLayout from './PageSuccLayout';

const PageJoinSuccMain = () => {
  return (
    <PageSuccLayout
      title={'회원가입'}
      titleContent={'이 완료 되었습니다.'}
      succMsg={'로그인 후 편리하고 알찬 PMT 서비스를 이용해보세요!'}
    />
  );
};

export default PageJoinSuccMain;
