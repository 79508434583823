export const GET_CNTR_LIST = 'GET_CNTR_LIST';
export const GET_CNTR_HISTORY = 'GET_CNTR_HISTORY';

export const POST_CNTR = 'POST_CNTR';
export const PUT_CNTR = 'PUT_CNTR';
export const GET_CNTR_DTL = 'GET_CNTR_DTL';
// export const GET_SALES_NM_LIST = 'GET_SALES_NM_LIST';
// export const GET_SALES_DTL = 'GET_SALES_DTL';
export const GET_DVLP_CO_NM_LIST = 'GET_DVLP_CO_NM_LIST';
export const GET_DVLP_CO_DTL = 'GET_DVLP_CO_DTL';
export const GET_DEL_CNTR_LIST = 'GET_DEL_CNTR_LIST';
export const DEL_CNTR_USE_YN = 'DEL_CNTR_USE_YN';
export const DEL_CNTR_STTS = 'DEL_CNTR_STTS';

export const GET_CNTR_DTL_SUCCESS = 'GET_CNTR_DTL_SUCCESS';
export const SET_CNTR_DTL_INIT = 'SET_CNTR_DTL_INIT';

export const getCntrList = (payload) => {
  return { type: GET_CNTR_LIST, payload };
};

export const getCntrHistory = (payload) => {
  return { type: GET_CNTR_HISTORY, payload };
};

export const postCntr = (payload, callback) => {
  return { type: POST_CNTR, payload: { payload, callback } };
};

export const putCntr = (payload, callback) => {
  return { type: PUT_CNTR, payload: { payload, callback } };
};

export const getCntrDtl = (payload) => {
  return { type: GET_CNTR_DTL, payload };
};

export const getClntCoNmList = (payload) => {
  return { type: GET_CLNT_CO_NM_LIST, payload };
};

// export const getSalesNmList = (payload) => {
//   return { type: GET_SALES_NM_LIST, payload };
// };

// export const getSalesDtl = (payload) => {
//   return { type: GET_SALES_DTL, payload };
// };

export const getDvlpCoNmList = (payload) => {
  return { type: GET_DVLP_CO_NM_LIST, payload };
};

export const getDvlpCoDtl = (payload) => {
  return { type: GET_DVLP_CO_DTL, payload };
};

export const getCntrDtlSuccess = (payload) => {
  return { type: GET_CNTR_DTL_SUCCESS, payload };
};

export const setCntrDtlInit = (payload) => {
  return { type: SET_CNTR_DTL_INIT, payload };
};

export const getDelCntrList = (payload) => {
  return { type: GET_DEL_CNTR_LIST, payload };
};

export const delCntrUseYn = (payload) => {
  return { type: DEL_CNTR_USE_YN, payload };
};

export const delCntrStts = (payload) => {
  return { type: DEL_CNTR_STTS, payload };
};
