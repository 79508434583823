import { setTelFormat } from '@common/common';
import { Error, MoreVert, CalendarMonth } from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CallIcon from '@mui/icons-material/Call';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import ComputerIcon from '@mui/icons-material/Computer';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { delInptPrsn, putInptPrsnStop } from '@store/actions/projectAction';
import { useNavigate } from 'react-router-dom';
import PageProjectInptPrsnDetail from './PageProjectInptPrsnDetail';
import PageProjectInptPrsnUpdate from './PageProjectInptPrsnUpdate';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectInptPrsnList = ({ item, updateResult }) => {
  const userInfo = useSelector((state) => state.commonReducer.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [detailPopup, setDetailPopup] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);

  const options = ['투입정보 수정', '인력정보 수정', '철수(투입해제)'];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (idx) => {
    handleClose();
    if (idx === 0) {
      handleInptUpdate();
    } else if (idx === 1) {
      handleWrkfMove();
    } else if (idx === 2) {
      setDeletePopup(true);
    }
  };

  const handleInptUpdate = () => {
    setUpdatePopup(true);
  };

  const handleWrkfMove = () => {
    navigate('/mng/workforce', { state: { action: 'inptEvent', value: item.wrkfSn } });
  };

  const handleInptOut = () => {
    dispatch(
      putInptPrsnStop({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        inptPrsnSn: item.inptPrsnSn,
        callback: fn_outResult,
      })
    );
  };

  const fn_outResult = () => {
    setDeletePopup(false);
    setDeleteClearPopup(true);
  };

  const [backColor, setBackColor] = useState('#fff');

  useEffect(() => {
    if (new Date(item.inptEndDt) < new Date()) {
      setBackColor('rgba(0,0,0,0.1)');
    }
  }, [item]);

  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteClearPopup, setDeleteClearPopup] = useState(false);

  const handleClear = () => {
    setDeleteClearPopup(false);
    updateResult();
  };

  return (
    <>
      <Grid item xs={6} sm={6} md={6} onClick={() => setDetailPopup(true)}>
        <Card
          style={{
            userSelect: 'none',
            padding: '16px',
            margin: '16px 10px ',
            minHeight: '50px',
            backgroundColor: backColor,
            color: '#333',
            border: '1px solid lightgrey',
            borderRadius: '15px',
          }}
        >
          <CardContent>
            <Grid container justifyContent={'space-between'}>
              <Typography gutterBottom variant="subtitle1" fontWeight={'bold'} component="div">
                {item.userNm}
              </Typography>
              <Grid item display={'flex'} alignItems={'center'} onClick={(event) => event.stopPropagation()}>
                {userInfo.userSn === String(item.userSn) && (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVert />
                  </IconButton>
                )}
              </Grid>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
                onClick={(event) => event.stopPropagation()}
              >
                {options.map((option, idx) => {
                  if ((idx === 0 || idx === 2) && new Date(item.inptEndDt) < new Date()) {
                    return;
                  }

                  return (
                    <MenuItem key={option} onClick={() => handleOnClick(idx)}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <BusinessIcon />
                <Typography pl={2} variant="body2">
                  {item.coNm}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <PersonIcon />
                <Typography pl={2} variant="body2">
                  {item.rank}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <ComputerIcon />
                <Typography pl={2} variant="body2">
                  {item.position}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <InfoIcon />
                <Typography pl={2} variant="body2">
                  {item.dtlPosition}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <CallIcon />
                <Typography pl={2} variant="body2">
                  {setTelFormat(item.mblNo1, '-')}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <CalendarMonth />
                <Typography pl={2} variant="body2">
                  {item.inptStartDt} ~ {item.inptEndDt}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {detailPopup && (
        <PageProjectInptPrsnDetail
          detailPopup={detailPopup}
          setDetailPopup={setDetailPopup}
          inptPrsnSn={item.inptPrsnSn}
        />
      )}

      {updatePopup && (
        <PageProjectInptPrsnUpdate
          updatePopup={updatePopup}
          setUpdatePopup={setUpdatePopup}
          inptPrsnSn={item.inptPrsnSn}
          updateResult={updateResult}
        />
      )}

      {deletePopup && (
        <ConfirmPopUp
          open={deletePopup}
          close={setDeletePopup}
          title={'잔여 투입 기간이 있습니다.\n즉시 투입 해제 처리 하시겠습니까?'}
          btnNm1={'아니오'}
          btnNm2={'예'}
          btnEvent={handleInptOut}
        />
      )}
      {deleteClearPopup && (
        <ConfirmPopUp
          open={deleteClearPopup}
          close={setDeleteClearPopup}
          title={'투입 해제 처리 되었습니다.\n지급 목록을 확인해주세요.'}
          btnNm2={'확인'}
          btnEvent={handleClear}
        />
      )}
    </>
  );
};

export default PageProjectInptPrsnList;
