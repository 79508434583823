import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale/ko';
import { DatePickerRound, isValue, setNumberComma, showToast } from '@common/common';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PagePaymentRegDetailModal = (props) => {
  const { isOpen, setIsOpen, settlementAmount, setSettlementAmount, setSendData, sendData } = props;

  const [midlePay, setMidlePay] = useState('');
  const [totlaCost, setTotalCost] = useState('');
  const [inputData, setInputData] = useState('0');
  const [confirm, setConfirm] = useState(false);
  const [wrongDatePop, setWrongDatePop] = useState(false);
  const [checkBoxList, setCheckBoxList] = useState({
    fstBox: false,
    midBox: false,
    endBox: false,
  });

  const handleValues = (name, value, category) => {
    if (name === 'paymentDt' && (new Date(sendData.cntrStDt) > value || new Date(sendData.cntrEndDt) < value)) {
      setWrongDatePop(true);
      return;
    }

    setSettlementAmount((prev) => ({
      ...prev,
      amountList: prev.amountList.map((item) => (item.category === category ? { ...item, [name]: value } : item)),
    }));
  };

  useEffect(() => {
    const updatedDtlList = sendData.dtlList.map((item) => ({
      ...item,
      tgtList: item.tgtList
        ? item.tgtList.map((tgt) => ({
            ...tgt,
            // Initialize tgtList fields if needed
            day: tgt.day || '',
            startMonth: tgt.startMonth || '',
            cost: tgt.cost || '',
          }))
        : [], // If tgtList is undefined, initialize it as an empty array
    }));

    setSendData((prevSendData) => ({
      ...prevSendData,
      dtlList: updatedDtlList,
    }));
    // Initialize inputCost to zero
    let inputCost = BigInt(0);

    // Ensure amountList is an array before using map
    const amountList = settlementAmount.amountList || [];

    // Iterate over amountList and sum the costs
    amountList.forEach((e) => {
      // Convert e.cost to BigInt and add to inputCost
      inputCost += BigInt(e.cost || 0); // Use 0 as default if e.cost is undefined
    });

    setInputData(inputCost.toString());
    console.log(inputCost); // Or any other operation you want to perform with inputCost
  }, [settlementAmount]);

  useEffect(() => {
    setSettlementAmount((prev) => ({
      ...prev,
      amountList: [], // Reset amountList to an empty array
    }));
    setCheckBoxList({
      fstBox: false,
      midBox: false,
      endBox: false,
    });
  }, []);

  const handleCheckBox = (name) => {
    setCheckBoxList((prev) => ({
      ...prev,
      [name]: !checkBoxList[name],
    }));
  };

  const handleCalculList = () => {
    if (!checkBoxList.fstBox && !checkBoxList.midBox && !checkBoxList.endBox) {
      showToast('정산금을 선택해주세요.');
      return;
    }

    if (checkBoxList.midBox && midlePay === '') {
      showToast('중도금을 선택해주세요.');
      return;
    }

    setSettlementAmount((prev) => ({
      ...prev,
      amountList: [], // Reset amountList to an empty array
    }));

    const list = [];

    if (checkBoxList.fstBox) {
      list.push({
        name: '착수금',
        category: 2,
        clctDt: '',
        cost: '0',
      });
    }

    if (checkBoxList.midBox) {
      for (var i = 4; i <= midlePay; i++) {
        list.push({
          name: i - 3 + '차 중도금',
          category: i,
          clctDt: '',
          cost: '0',
        });
      }
    }

    if (checkBoxList.endBox) {
      list.push({
        name: '잔금',
        category: 3,
        clctDt: '',
        cost: '0',
      });
    }
    setSettlementAmount((prev) => ({
      ...prev,
      amountList: list,
    }));
    // setSettlementAmount(list);
  };

  const handleCost = (e, category) => {
    let inputValue = e.target.value
      .replace(/,/g, '')
      .replace(/^0+/, '')
      .replace(/[^0-9]+/g, '');

    if (inputValue === '' || /^0+$/.test(inputValue)) {
      inputValue = '0';
    }

    if (inputValue.length > 20) {
      inputValue = '99999999999999999999';
    }

    handleValues('cost', inputValue, category);
  };
  console.log(settlementAmount);
  const handlePaymentDetail = () => {
    let hasEmptyFields = false;

    if (!isValue(totlaCost)) {
      return showToast('총 지급액을 입력해주세요.');
    }

    settlementAmount.amountList.forEach((item) => {
      console.log(item.cost);
      if (item.cost == 0 || !isValue(item.paymentDt)) {
        hasEmptyFields = true;
      } else if (inputData !== totlaCost) {
        hasEmptyFields = true;
      }
    });

    // If there are any empty fields, return early
    if (hasEmptyFields) {
      showToast('입력한 지급 정보를 다시 확인해주세요.');
      return;
    }
    // Assuming dtlList is an array of objects with an 'index' property
    const existingIndices = new Set(sendData.dtlList.map((item) => item.index));

    setSendData((prevSendData) => {
      const updatedDtlList = [...prevSendData.dtlList];
      const indexToUpdate = parseInt(settlementAmount.index, 10);

      if (indexToUpdate >= 0 && indexToUpdate < updatedDtlList.length) {
        // 해당 인덱스의 항목이 존재하면 tgtList 업데이트
        updatedDtlList[indexToUpdate] = {
          ...updatedDtlList[indexToUpdate],
          tgtList: [...(updatedDtlList[indexToUpdate].tgtList || []), ...settlementAmount.amountList],
        };
      } else {
        // 해당 인덱스의 항목이 없으면 새 항목 추가
        while (updatedDtlList.length <= indexToUpdate) {
          updatedDtlList.push({
            index: updatedDtlList.length.toString(),
            tgtList: [],
          });
        }
        updatedDtlList[indexToUpdate] = {
          ...updatedDtlList[indexToUpdate],
          tgtList: settlementAmount.amountList,
        };
      }

      return {
        ...prevSendData,
        dtlList: updatedDtlList,
      };
    });
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
        <DialogTitle>
          <Typography className="modal-tit" fontWeight={'bold'}>
            지급 상세
          </Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            지급 상세 정보를 입력해주세요.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={'center'} mb={2}>
            <Grid item md={3}>
              <Typography variant="subtitle1" className="rqd">
                총 지급액
              </Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="totalCost"
                variant="standard"
                size="small"
                onChange={(e) => setTotalCost(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mr: 2 }}>
                      원
                    </InputAdornment>
                  ),
                }}
                // disabled={isValue(prjcSn) ? true : false}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item xs={6.5} display={'flex'} alignItems={'center'}>
                <Box
                  onClick={() => handleCheckBox('fstBox')}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Checkbox sx={{ padding: 0, mr: 1 }} checked={checkBoxList.fstBox} />
                  <Typography variant="subtitle2">착수금</Typography>
                </Box>
                <Box
                  onClick={() => handleCheckBox('midBox')}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Checkbox sx={{ padding: 0, mx: 1 }} checked={checkBoxList.midBox} />
                  <Typography variant="subtitle2">중도금</Typography>
                </Box>
                <Box
                  onClick={() => handleCheckBox('endBox')}
                  display={'flex'}
                  alignItems={'center'}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Checkbox sx={{ padding: 0, mx: 1 }} checked={checkBoxList.endBox} />
                  <Typography variant="subtitle2">잔금</Typography>
                </Box>
              </Grid>
              <Grid item xs={5.5} display={'flex'} alignItems={'center'}>
                <FormControl size="small" fullWidth disabled={!checkBoxList.midBox}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={checkBoxList.midBox ? midlePay : ''}
                    onChange={(e) => setMidlePay(e.target.value)}
                  >
                    <MenuItem value="">선택</MenuItem>
                    <MenuItem value={4}>1차 중도금</MenuItem>
                    <MenuItem value={5}>2차 중도금</MenuItem>
                    <MenuItem value={6}>3차 중도금</MenuItem>
                    <MenuItem value={7}>4차 중도금</MenuItem>
                    <MenuItem value={8}>5차 중도금</MenuItem>
                    <MenuItem value={9}>6차 중도금</MenuItem>
                    <MenuItem value={10}>7차 중도금</MenuItem>
                    <MenuItem value={11}>8차 중도금</MenuItem>
                    <MenuItem value={12}>9차 중도금</MenuItem>
                    <MenuItem value={13}>10차 중도금</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  className="btn"
                  sx={{
                    ml: 1,
                  }}
                  onClick={() => handleCalculList()}
                >
                  적용
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              {settlementAmount.amountList && settlementAmount.amountList.length > 0 && (
                <>
                  <Grid item md={12} mt={2}>
                    {settlementAmount.amountList.map((item) => (
                      <Grid container key={item.category} mb={1}>
                        <Grid item md={1}></Grid>
                        <Grid item md={2} display={'flex'} alignItems={'center'}>
                          <Typography variant="subtitle2">{item.name}</Typography>
                        </Grid>
                        <Grid item md={9} display={'flex'} alignItems={'center'}>
                          <Grid item md={6}>
                            {/* <DatePickerWrapper> */}
                            <DatePicker
                              locale={ko}
                              selected={item.paymentDt}
                              onChange={(e) => handleValues('paymentDt', e, item.category)}
                              dateFormat={'YYYY-MM-dd'}
                              customInput={<DatePickerRound placehol="YYYY-MM-DD" />}
                              popperPlacement="top"
                            />
                            {/* </DatePickerWrapper> */}
                          </Grid>
                          <Grid item md={6} mx={2}>
                            <TextField
                              className="inp-round"
                              fullWidth
                              size="small"
                              value={setNumberComma(item.cost)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ mr: 2 }}>
                                    원
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => handleCost(e, item.category)}
                              sx={{
                                '& .MuiInputBase-input': {
                                  textAlign: 'right',
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item md={12} display={'flex'} alignItems={'center'} my={1}>
                    <Grid item md={3} />
                    <Grid item md={9} display={'flex'} alignItems={'center'}>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" fontWeight={'bold'}>
                          총 지급액
                        </Typography>
                      </Grid>
                      <Grid item md={8} display={'flex'} justifyContent={'end'}>
                        <Typography variant="subtitle2">
                          {setNumberComma(totlaCost === '' ? '0' : totlaCost)} 원
                        </Typography>
                      </Grid>
                      <Grid item md={1} />
                    </Grid>
                  </Grid>
                  <Grid item md={12} display={'flex'} alignItems={'center'}>
                    <Grid item md={3} />
                    <Grid item md={9} display={'flex'} alignItems={'center'}>
                      <Grid item md={3}>
                        <Typography variant="subtitle2" fontWeight={'bold'}>
                          입력 금액
                        </Typography>
                      </Grid>
                      <Grid item md={8} display={'flex'} justifyContent={'end'}>
                        <Typography variant="subtitle2">{setNumberComma(inputData)}&nbsp;원</Typography>
                      </Grid>
                      <Grid item md={1} />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={handlePaymentDetail}>
            추가
          </Button>
        </DialogActions>
      </Dialog>
      {wrongDatePop && (
        <ConfirmPopUp
          open={wrongDatePop}
          close={setWrongDatePop}
          title={'지급일은 계약 기간 내의 날짜로 선택해주세요.'}
          comment={'이 프로젝트의 계약기간은\n' + sendData.cntrStDt + ' ~ ' + sendData.cntrEndDt + ' 입니다.'}
          btnNm2={'확인'}
        />
      )}
    </>
  );
};

export default PagePaymentRegDetailModal;
