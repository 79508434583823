import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

const PageProjectPositonModal = ({ isOpen, setIsOpen }) => {
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title" display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        프로젝트 포지션이란
        <IconButton onClick={() => setIsOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 40px 40px' }}>
        <Grid item>
          <Grid display={'flex'} alignItems={'center'}>
            <Typography sx={{ color: 'orange' }}>등록할 프로젝트에서 속한 포지션</Typography>
            <Typography> 입니다.</Typography>
          </Grid>
          <Typography>선택한 포지션에 따라프로젝트 관리에 대한 권한이 달라집니다.</Typography>
        </Grid>
        <Grid item mt={2}>
          <Typography fontWeight={'bold'}>개발사</Typography>
          <Typography>
            회사의 인력을 프로젝트에 투입하고, 지급정보를 등록할 수 있습니다. <br />
            프로젝트 관리 내 내 회사의 일정과 이슈 등을 등록 및 관리합니다.
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <Typography fontWeight={'bold'}>관리사</Typography>
          <Typography>
            개발에 직접 참여하지 않고 의뢰사와 여래 개발사 간의 관리 역할을 하게 됩니다.
            <br />
            프로젝트 관리 내 전체 일정을 확인하고 이슈를 등록할 수 있습니다.
          </Typography>
          <Typography color={'primary'}>
            프로젝트를 생성 시 개발사 회사코드를 입력하면 각 개발사 프로젝트 관리 화면에 자동으로 생성&연동되어, 각
            개발사가 프로젝틀르 생성하지 않아도 되며, 개발사별 전체 일정 흐름을 한번에 확인할 수 있습니다.
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <Typography fontWeight={'bold'}>개발&관리사</Typography>
          <Typography>
            관리사 겸 개발사로 참여하는 경우에 선택합니다.
            <br />
            관리사의 권한과, 개발사의 권한(내 횟사에 대한 인력/지급정보/일정/이슈 등에 대한 관리)을 모두 가지게 됩니다.
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <Typography fontWeight={'bold'}>개발&관리사&의뢰사</Typography>
          <Typography>
            회사 내에서 프로젝트의 모든 과정을 작업하는 경우 선택합니다.
            <br />
            관리사와 개발사의 권한을 모두 가지게 되며, 경우에 따라 개발사를 추가로 등록할 수 있습니다.
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageProjectPositonModal;
