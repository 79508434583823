import { DatePickerRound, ExampleCustomInput, isNumber, setNumberComma, showToast } from '@common/common';
import { FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography, Button } from '@mui/material';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { ko } from 'date-fns/locale/ko';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageCalculationMonth = ({ setSettlementAmount, sendData }) => {
  const [monthData, setMonthData] = useState({
    stYearMonth: '',
    endYearMonth: '',
    cost: '0',
    day: '',
  });

  const [dayRange, setDayRange] = useState([]);
  useEffect(() => {
    let range = [];
    for (let i = 1; i <= 31; i++) {
      if (i < 10) {
        i = '0' + i;
      }
      range.push(i + '');
    }
    setDayRange(range);
  }, []);

  useEffect(() => {
    setSettlementAmount([]);
    setMonthData({
      stYearMonth: '',
      endYearMonth: '',
      cost: '0',
      day: '',
    });
  }, [sendData]);

  const [confirm, setConfirm] = useState(false);
  const handleDateChange = (dates) => {
    if (sendData.cntrStDt === '' || sendData.cntrEndDt === null) {
      showToast('계약기간을 입력해 주세요.');
      return;
    }
    const [start, end] = dates;

    if (
      moment(sendData.cntrStDt, 'YYYYMM').format('YYYYMM') > moment(start, 'YYYYMM').format('YYYYMM') ||
      moment(sendData.cntrEndDt, 'YYYYMM').format('YYYYMM') < moment(start, 'YYYYMM').format('YYYYMM') ||
      (end !== null && moment(sendData.cntrEndDt, 'YYYYMM').format('YYYYMM') < moment(end, 'YYYYMM').format('YYYYMM'))
    ) {
      setConfirm(true);
      //   showToast('정산 기간은 계약 기간 내의 날짜로 선택해주세요.');
      return;
    }

    setMonthData({
      ...monthData,
      stYearMonth: start,
      endYearMonth: end,
    });
  };

  const handleChangeDay = (e) => {
    if (sendData.cntrStDt === '' || sendData.cntrEndDt === null) {
      showToast('계약기간을 입력해 주세요.');
      return;
    }
    setMonthData((prev) => ({
      ...prev,
      day: e.target.value,
    }));
  };

  const handleChange = (e) => {
    if (sendData.cntrStDt === '' || sendData.cntrEndDt === null) {
      showToast('계약기간을 입력해 주세요.');
      return;
    }

    let inputValue = e.target.value
      .replace(/,/g, '')
      .replace(/^0+/, '')
      .replace(/[^0-9]+/g, '');

    if (inputValue === '' || /^0+$/.test(inputValue)) {
      inputValue = '0';
    }

    if (inputValue.length > 20) {
      inputValue = '99999999999999999999';
    }

    setMonthData((prev) => ({
      ...prev,
      cost: inputValue,
    }));
  };

  useEffect(() => {
    setSettlementAmount([]);

    if (
      monthData.stYearMonth !== '' &&
      monthData.endYearMonth !== null &&
      monthData.cost !== '' &&
      monthData.day !== ''
    ) {
      const forRange =
        (monthData.endYearMonth.getFullYear() - monthData.stYearMonth.getFullYear()) * 12 +
        monthData.endYearMonth.getMonth() -
        monthData.stYearMonth.getMonth();

      let newDate = new Date(monthData.stYearMonth);
      const list = [];
      for (let i = 0; i <= forRange; i++) {
        const lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
        let monthDay = monthData.day;

        if (lastDay < monthData.day) {
          monthDay = lastDay;
        }

        let clctDt = new Date(newDate.setDate(monthDay));

        list.push({
          category: 1,
          clctDt: clctDt,
          cost: monthData.cost,
        });

        newDate.setMonth(newDate.getMonth() + 1);
      }
      setSettlementAmount(list);
    }
  }, [monthData]);

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item md={12} display={'flex'} alignItems={'center'}>
          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant="subtitle2">정산 기간</Typography>
          </Grid>
          <Grid item xs={7} display={'flex'} alignItems={'center'}>
            <DatePickerWrapper>
              <DatePicker
                locale={ko}
                selected={monthData.stYearMonth}
                onChange={handleDateChange}
                startDate={monthData.stYearMonth}
                endDate={monthData.endYearMonth}
                dateFormat={'YYYY-MM'}
                showMonthYearPicker
                selectsRange
                customInput={<DatePickerRound placehol="YYYY-MM ~ YYYY-MM" />}
              />
            </DatePickerWrapper>
          </Grid>
        </Grid>
        <Grid item md={12} display={'flex'} alignItems={'center'}>
          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant="subtitle2">월 정산일</Typography>
          </Grid>
          <Grid item xs={7} display={'flex'} alignItems={'center'}>
            <FormControl size="small" fullWidth>
              <Select fullWidth displayEmpty value={monthData.day} onChange={(e) => handleChangeDay(e)}>
                <MenuItem value={''}>선택</MenuItem>
                {dayRange.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item} 일
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={12} display={'flex'} alignItems={'center'}>
          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant="subtitle2">월 정산금</Typography>
          </Grid>
          <Grid item xs={7} display={'flex'} alignItems={'center'}>
            <TextField
              className="inp-round"
              fullWidth
              size="small"
              value={setNumberComma(monthData.cost)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="subtitle2" mr={2}>
                      원
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e)}
              sx={{
                '& .MuiInputBase-input': {
                  textAlign: 'right',
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {confirm && (
        <ConfirmPopUp
          open={confirm}
          close={setConfirm}
          title={'정산 기간은 계약 기간 내의 날짜로 선택해주세요.'}
          btnNm2={'확인'}
        />
      )}
    </>
  );
};

export default PageCalculationMonth;
