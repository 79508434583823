import { showToast, showToastError } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { getSalesNmList, delSales } from '@store/actions/salesAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageSalesDeleteModal = ({ deleteOpen, setDeleteOpen, setIsSuccessYn, isSuccessYn }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [delSalesList, setDelSalesList] = useState([]);
  const [deleteSnList, setDeleteSnList] = useState([]);
  const [deletePop, setDeletePop] = useState(false);

  useEffect(() => {
    dispatch(
      getSalesNmList({
        keyword: '',
        userId: user.userId,
        userSn: user.userSn,
        callback: handleGetSaleListCallback,
      })
    );
  }, []);

  const handleGetSaleListCallback = (data) => {
    if (data.resultCode === 0) {
      setDelSalesList(data.data.salesNmList);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg);
    }
  };

  const handleCheckChange = (e) => {
    if (e.target.checked) {
      setDeleteSnList((prevList) => [...prevList, e.target.value]);
    } else {
      setDeleteSnList((prevList) => prevList.filter((item) => item !== e.target.value));
    }
  };
  const handleDeleteCntr = () => {
    if (deleteSnList.length <= 0) {
      return showToast('삭제할 영업 건을 선택해주세요.');
    } else {
      setDeletePop(true);
    }
  };

  const handleDeleteTask = () => {
    dispatch(
      delSales({
        salesSnList: deleteSnList,
        userId: user.userId,
        userSn: user.userSn,
        callback: handelDelSalesCallback,
      })
    );
  };

  const handelDelSalesCallback = (data) => {
    if (data.resultCode === 0) {
      showToast(`업무 ${deleteSnList.length}건이 삭제되었습니다.`);
      setDeleteOpen(false);
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError(data);
    }
  };
  return (
    <>
      <Dialog open={deleteOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
        <DialogTitle
          id="scroll-dialog-title"
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ padding: '30px 40px' }}
        >
          <Box>
            <Typography variant="h6" fontWeight={'bold'}>
              영업 프로젝트 삭제
            </Typography>
            <Typography variant="body2">삭제할 영업 정보를 선택해주세요</Typography>
          </Box>
          <Close onClick={() => setDeleteOpen(false)} />
        </DialogTitle>
        <DialogContent sx={{ height: '380px' }}>
          {delSalesList ? (
            delSalesList.map((item, index) => (
              <Grid container key={item.salesSn} alignItems={'center'} py={2} borderBottom={'1px solid #ccc'}>
                <Grid item md={2}>
                  <Checkbox value={item.salesSn} onChange={handleCheckChange} />
                </Grid>
                <Grid item md={10}>
                  <Box component={'div'}>
                    <Typography variant="body2" fontWeight={'bold'}>
                      {item.slsNm}
                    </Typography>
                    <Typography variant="body2">{item.coNm}</Typography>
                  </Box>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid container alignItems={'center'} justifyContent={'center'} py={2}>
              <Box component={'div'}>
                <Typography variant="body2" fontWeight={'bold'}>
                  등록된 영업 프로젝트가 없습니다.
                </Typography>
              </Box>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button className="btn-white" onClick={() => setDeleteOpen(false)} sx={{ width: '100%' }}>
            취소
          </Button>
          <Button className="btn-navy" onClick={handleDeleteCntr} sx={{ width: '100%' }}>
            {deleteSnList.length}건 삭제
          </Button>
        </DialogActions>
      </Dialog>
      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'선택한 업무를 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없의며, 하위 업무가 있는 경우 함께 삭제됩니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDeleteTask}
        />
      )}
    </>
  );
};

export default PageSalesDeleteModal;
