import * as React from 'react';
import { useQuery } from '@common/common';
import PageSuccLayout from './PageSuccLayout';

export default function PageSearchIdSuccMain() {
  let query = useQuery();
  let userId = query.get('id');
  let userDt = query.get('dt');

  return (
    <PageSuccLayout 
      title={'아이디 찾기'} 
      titleContent={'가 완료되었습니다.'} 
      succMsg={`${userId}(${userDt})`} 
    />
  );
}
