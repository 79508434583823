import * as actions from "@actions/counterpartyAction";

const INIT_STATE = {
  clientInfo: {
    clientList: {
      resultCode: 0,
      resultMsg: "",
      pageNum: 0,
      pageSize: 0,
      totalCount: 0,
      data: [],
    },
  },
};

const counterpartyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.POST_CLIENT_SUCCESS:
      return {
        ...state,
        clientInfo: { ...state.clientInfo, clientList: action.payload },
      };
    case actions.POST_CLIENT_REG_SUCCESS:
      return {
        ...state,
        clientInfo: {
          ...state.clientInfo,
          clientList: {
            ...state.clientInfo.clientList,
            data: [...state.clientInfo.clientList.data, action.payload],
          },
        },
      };
    case actions.GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        clientInfo: { ...state.clientInfo, clientList: action.payload },
      };

    case actions.GET_DEV_DETAIL_SUCCESS:
      return {
        ...state,
        clientInfo: { ...state.clientInfo, clientList: action.payload },
      };

    default:
      return state;
  }
};

export default counterpartyReducer;
