import {
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Rating,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getPrjcDtl, putPrjcMainDelYn, putPrjcMainFvrtYn, setPrjcInfo } from '@store/actions/projectAction';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MoreVert } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import PageProjectCalendar from './PageProjectCalendar';
import PageProjectHome from './PageProjectHome';
import PageProjectTask from './PageProjectTask';
import PageProjectIssue from './PageProjectIssue';
import PageProjectInptPrsn from './PageProjectInptPrsn';
import { moveProject } from '@store/actions/commonAction';
import { showToast } from '@common/common';

const theme = createTheme();

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const PageProjectDetail = () => {
  // const { id } = useParams();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectInfo, setProjectInfo] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    dispatch(
      getPrjcDtl({
        prjcMainSn: id,
        userSn: user.userSn,
        userId: user.userId,
        callback: fn_projecInfo,
      })
    );
  }, [isSuccess]);

  const fn_projecInfo = (data) => {
    setProjectInfo(data.data);
    dispatch(
      setPrjcInfo({
        ownerYn: data.data.ownerYn,
        position: data.data.position,
        puserSn: data.data.puserSn,
      })
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const options = ['수정', '삭제'];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      navigate(`?tab=${newValue}&&type=B`, { replace: true });
    } else {
      navigate(`?tab=${newValue}`, { replace: true });
    }
  };

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab) {
      setValue(Number(tab));
    }
  }, [location]);

  const handleMenuItemClick = (option) => {
    setAnchorEl(null);

    if (option === '수정') {
      dispatch(moveProject(id));

      navigate(`/mng/project/upd`);
    } else {
      dispatch(
        putPrjcMainDelYn({
          userId: user.userId,
          userSn: user.userSn,
          prjcMainSn: id,
          delYn: 'Y',
          callback: handlePutPrjcMainDelYnCallback,
        })
      );
    }
  };

  const handlePutPrjcMainDelYnCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('프로젝트가 삭제 되었습니다.');
      navigate('/mng/project');
    } else {
      showToastError(data);
    }
  };

  const handleFrvtChange = (e, newValue) => {
    dispatch(
      putPrjcMainFvrtYn({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: id,
        fvrtYn: newValue === 1 ? 'Y' : 'N',
        callback: (data) => handlePutPrjcMainFvrtYnCallback(data, newValue),
      })
    );
  };

  const handlePutPrjcMainFvrtYnCallback = (data, newValue) => {
    if (data.resultCode === 0) {
      setIsSuccess(!isSuccess);
      showToast(`${newValue === 1 ? '프로젝트 즐겨찾기를 설정하였습니다.' : '프로젝트 즐겨찾기를 해제하였습니다.'}`);
    } else {
      showToastError(data);
    }
  };
  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <IconButton
          size="large"
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => navigate('/mng/project')}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {projectInfo && projectInfo.prjcNm}
        </Typography>
        <Grid item display={'flex'} alignItems={'center'}>
          <Rating
            name="simple-controlled"
            onChange={(e, newValue) => handleFrvtChange(e, newValue)}
            // value={item.fvrtYn === 'Y' ? 1 : 0}
            // onChange={(e, newValue) => handleFrvtChange(e, newValue, item.prjcMainSn)}
            max={1}
          />
          {user.userFlag === 'D' && projectInfo && projectInfo.ownerYn === 'Y' && (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
          )}
        </Grid>

        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={false} sx={{ padding: '0 24px 24px' }}>
          <Box>
            <Tabs className="tab" value={value} onChange={handleChange} aria-label="tabs">
              <Tab
                label="프로젝트 홈"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,

                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
              <Tab
                label="일정"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
              <Tab
                label="이슈"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
              <Tab
                label="캘린더"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
              <Tab
                label="투입인력"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 700,
                  },
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '&:focus': {
                    outline: 'none',
                    color: theme.palette.primary.dark,
                  },
                  '&:active': {
                    color: theme.palette.primary.dark,
                  },
                }}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <PageProjectHome projectSn={id} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PageProjectTask projectSn={id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PageProjectIssue projectSn={id} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PageProjectCalendar projectSn={id} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <PageProjectInptPrsn projectSn={id} />
            </TabPanel>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default PageProjectDetail;
