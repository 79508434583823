import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
} from '@mui/material/';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Lock } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getMyInfo, postLogin } from '@store/actions/userAction';
import { Navigate, useNavigate } from 'react-router-dom';
import { setUserInfo } from '@store/actions/commonAction';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { showToastMsg } from '@common/common';

const PageMypage = () => {
  const user = useSelector((state) => state.commonReducer.user);
  const theme = createTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const [inputs, setInputs] = useState({
    userId: user.userId,
    userPw: '',
  });

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      // handleMyInfoSubmit();
      handleLogin();
    }
  };

  const handleLogin = (e) => {
    // e.preventDefault();
    dispatch(
      postLogin({
        lgnId: inputs.userId,
        lgnPw: inputs.userPw,
        callback: fnHandleCallback,
      })
    );
  };

  const fnHandleCallback = (data, status) => {
    if (data.resultCode === 0) {
      dispatch(setUserInfo(data.data));
      navigate('/user/pageMypageEdit', { state: data.data });
    } else {
      setIsError(true);
    }
  };

  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems={'center'} padding={'16px 30px'} borderBottom={'1px solid lightgray'}>
        <Grid item>
          <ArrowBackIosIcon onClick={goBack} />
        </Grid>
        <Grid item>
          <Typography variant="h6" fontWeight={'bold'}>
            내 정보
          </Typography>
        </Grid>
      </Grid>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '500px',
          }}
        >
          <Typography variant="h6" fontWeight={'bold'}>
            <span style={{ color: theme.palette.primary.main }}>회원 정보</span> 확인
          </Typography>
          <Typography variant="subtitle1">회원님의 정보를 보호하기 위해 비밀번호를 다시 한번 확인합니다.</Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <FormControl component="fieldset" variant="standard">
              <Grid container className="login-inp">
                <Grid item xs={12}>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    type="username"
                    id="username"
                    name="userId"
                    value={inputs.userId}
                    onChange={handleChangeLogin}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="userPw"
                    name="userPw"
                    type="password"
                    value={inputs.userPw}
                    onChange={handleChangeLogin}
                    onKeyDown={handleOnKeyDown}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <Grid item xs={12} mt={1}>
              {isError && (
                <Typography color={'red'} lineHeight={'2'}>
                  비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.
                </Typography>
              )}
              <Typography color={'error'}></Typography>
            </Grid>
            <Button type="button" fullWidth sx={{ mt: 3, mb: 2 }} className="log-btn" onClick={handleLogin}>
              확인
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PageMypage;
