import { isComplHangul, showToast, showToastError } from '@common/common';
import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import { getPrjcMainList, putPrjcMainFvrtYn, setPrevStts, setPrjcDvlpInit } from '@store/actions/projectAction';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import PageProjectMainList from './PageProjectMainList';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { SEARCH_TIME } from '@common/constants';
import { moveProject } from '@store/actions/commonAction';
import PageProjectRequestRegModal from './PageProjectRequestRegModal';

const PageProjectMain = () => {
  const user = useSelector((state) => state.commonReducer.user);
  const prevStts = useSelector((state) => state.projectReducer.srchType);

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState({
    keyword: '',
    swtc: user.userFlag === 'E' ? 'I' : user.userFlag === 'C' ? 'C' : 'D',
    // swtc: 'D',
    stts: prevStts.stts === '' ? 1 : prevStts.stts === 3 ? '' : prevStts.stts,
    srchType: 0,
  });
  const [projectList0, setProjectList0] = useState([]);
  const [projectList1, setProjectList1] = useState([]);
  const [projectList2, setProjectList2] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [regOpen, setRegOpen] = useState(false);
  const [srchType, setSrchType] = useState(0);
  const [swtt, setSwtt] = useState('D');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = ['수정', '삭제'];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   dispatch(
  //     getPrjcMainList({
  //       ...search,
  //       srchType: srchType,
  //       userId: user.userId,
  //       userSn: user.userSn,
  //       userFlag: user.userFlag,
  //       callback: handleGetPrjcMainListCallback,
  //     })
  //   );
  // }, [isSuccess]);

  const handleSrchTypeChange = (e) => {
    setSrchType(e.target.value);
  };

  const debouncedDispatch = useCallback(
    _.debounce((newSearch) => {
      if (isComplHangul(newSearch.keyword)) {
        dispatch(
          getPrjcMainList({
            ...newSearch,
            userId: user.userId,
            userSn: user.userSn,
            userFlag: user.userFlag,
            callback: handleGetPrjcMainListCallback,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  useEffect(() => {
    debouncedDispatch(search);
  }, [debouncedDispatch, search, isSuccess]);

  const handleGetPrjcMainListCallback = (data) => {
    if (data.resultCode === 0) {
      setProjectList0(data.data.prjcSmpl0List);
      setProjectList1(data.data.prjcSmpl1List);
      setProjectList2(data.data.prjcSmpl2List);
    } else {
      showToastError(data);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFrvtChange = (e, newValue, sn) => {
    dispatch(
      putPrjcMainFvrtYn({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: sn,
        fvrtYn: newValue === 1 ? 'Y' : 'N',
        callback: handlePutPrjcMainFvrtYnCallback,
      })
    );
  };

  const handlePutPrjcMainFvrtYnCallback = (data) => {
    if (data.resultCode === 0) {
      setIsSuccess(!isSuccess);
      showToast('프로젝트 즐겨찾기 변경');
    } else {
      showToastError(data);
    }
  };

  const handleNewProject = () => {
    if (user.userFlag !== 'C') {
      dispatch(setPrjcDvlpInit());
      navigate('/mng/project/reg');
    } else {
      setRegOpen(true);
    }
  };

  //srchType change
  const handleSrchChange = (e, newValue) => {
    const { name, value } = e.target;
    setSwtt(newValue);
    console.log(value);
    if (name === 'stts') {
      dispatch(setPrevStts({ stts: value })); // name이 'stts'인 경우에만 value 전달
    } else {
      dispatch(setPrevStts({ stts: prevStts.stts })); // name이 'stts'가 아니면 null 전달 (또는 다른 처리)
    }
    setSearch((prevSearch) => ({
      ...prevSearch,
      stts: name === 'stts' ? (prevSearch.stts === 3 ? '' : value) : prevSearch.stts,
      srchType: srchType,
      [name]: name === 'stts' && value === 3 ? '' : value,
    }));
  };

  const handleMovePage = (data) => {
    dispatch(moveProject(data));

    navigate('/mng/project/detail');
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ minWidth: 150, mr: 1 }}>
            <FormControl size="small" fullWidth>
              <Select
                // defaultValue={0}
                value={srchType}
                onChange={handleSrchTypeChange}
                // onChange={handleSrchChange}
                name="srchType"
              >
                <MenuItem value={0}>프로젝트명</MenuItem>
                <MenuItem value={1}>의뢰사명</MenuItem>
                <MenuItem value={2}>개발사명</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            placeholder="내용을 검색해주세요"
            fullWidth
            className="inp-round"
            autoComplete="off"
            size="small"
            name="keyword"
            onKeyUp={handleSrchChange}
            // onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <FormControl size="small" sx={{ minWidth: 150, mr: 1 }}>
            <Select
              value={prevStts.stts === '' ? 1 : prevStts.stts}
              // onChange={handleSearchTypeChange}
              onChange={handleSrchChange}
              name="stts"
              fullWidth
            >
              <MenuItem value={3}>전체</MenuItem>
              <MenuItem value={1}>진행중</MenuItem>
              <MenuItem value={0}>진행예정</MenuItem>
              <MenuItem value={2}>진행완료</MenuItem>
            </Select>
          </FormControl>
          {user.userFlag !== 'E' && (
            <Button className="btn" fullWidth onClick={handleNewProject}>
              {user.userFlag === 'D' ? '프로젝트 신규 생성' : '프로젝트 등록'}
            </Button>
          )}
        </Box>
      </Toolbar>
      <Box p={2}>
        {user.userFlag === 'D' && (
          <Grid container borderBottom={1} borderColor={'divider'} pb={2}>
            <ToggleButtonGroup
              color="primary"
              value={search.swtc}
              className="radio-btn"
              exclusive
              onChange={handleSrchChange}
            >
              <ToggleButton value="D" aria-label="D" name="swtc">
                개발
              </ToggleButton>
              <ToggleButton value="M" aria-label="M" name="swtc">
                관리
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        {(search.stts === '' || search.stts === 1) && (
          <Grid container>
            <Grid item xs={12} md={12} py={2} display={'flex'}>
              <Typography variant="h6" fontWeight={'bold'} mr={3}>
                진행 중인 프로젝트
              </Typography>
              <Chip label={projectList1 ? projectList1.length : 0} variant="outlined" />
            </Grid>
          </Grid>
        )}
        <Grid container>
          {projectList1 &&
            projectList1.map((item, index) => (
              <PageProjectMainList
                key={item.prjcMainSn}
                item={item}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                movePage={() => handleMovePage(item.prjcMainSn)}
              />
            ))}
        </Grid>
        {(search.stts === '' || search.stts === 0) && (
          <Grid container>
            <Grid item xs={12} md={12} py={2} display={'flex'}>
              <Typography variant="h6" fontWeight={'bold'} mr={3}>
                진행 예정 프로젝트
              </Typography>
              <Chip label={projectList0 ? projectList0.length : 0} variant="outlined" />
            </Grid>
          </Grid>
        )}
        <Grid container>
          {projectList0 &&
            projectList0.map((item, index) => (
              <PageProjectMainList
                key={item.prjcMainSn}
                item={item}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                movePage={() => handleMovePage(item.prjcMainSn)}
              />
            ))}
        </Grid>
        {(search.stts === '' || search.stts === 2) && (
          <Grid container>
            <Grid item xs={12} md={12} py={2} display={'flex'}>
              <Typography variant="h6" fontWeight={'bold'} mr={3}>
                진행 완료 프로젝트
              </Typography>
              <Chip label={projectList2 ? projectList2.length : 0} variant="outlined" />
            </Grid>
          </Grid>
        )}
        <Grid container>
          {projectList2 &&
            projectList2.map((item, index) => (
              <PageProjectMainList
                key={item.prjcMainSn}
                prjcMainSn={item.prjcMainSn}
                item={item}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                movePage={() => handleMovePage(item.prjcMainSn)}
              />
            ))}
        </Grid>
      </Box>
      {regOpen && <PageProjectRequestRegModal isOpen={regOpen} setIsOpen={setRegOpen} />}
    </>
  );
};

export default PageProjectMain;
