import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast, showToastError } from '@common/common';
import { getBlngList, postBlng } from '@store/actions/wrkfAction';
import AddIcon from '@mui/icons-material/Add';

import ConfirmPopUp from 'component/ConfirmPopUp';

const PageWorkforceTeam = (props) => {
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();

  const [plusButton, setPlusButton] = useState(true);

  const { regTeamOpen, setRegTeamOpen, saves } = props;

  const [blngList, setBlngList] = useState();

  const [blngLength, setBlngLength] = useState();

  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    if (blngLength === 10) {
      setPlusButton(false);
    } else {
      setPlusButton(true);
    }
  }, [blngLength]);

  useEffect(() => {
    dispatch(
      getBlngList({
        userSn: userInfo.userSn,
        callback: fn_blngList,
      })
    );
  }, []);

  const fn_blngList = (data) => {
    setBlngList(data.data);
    setBlngLength(data.data.length);
  };

  const handlAddContent = () => {
    setBlngLength(blngLength + 1);

    const data = {
      blngSn: Math.random().toString(36).substring(2, 11),
      teamNm: '',
    };

    setBlngList((prev) => [...prev, data]);

    setData(data, 'I');
  };

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const deleteContent = (value) => {
    if (value.useWrkf === 'Y') {
      setConfirmMsg('해당 팀에 소속된 인력이 존재합니다.\n인력 삭제 후 다시 진행해주세요.');
      setDeleteConfirm(true);
    } else {
      setBlngList(blngList.filter((item) => item.blngSn !== value.blngSn));
      setBlngLength(blngLength - 1);

      if (value.useWrkf) {
        setData(value, 'D');
      } else {
        setSendData(sendData.filter((item) => item.blngSn !== value.blngSn));
      }
    }
  };
  const saveButton = () => {
    dispatch(postBlng(sendData, fn_saves));
  };

  const checkValue = () => {
    let isCheck = true;

    sendData.forEach((e) => {
      if (e.teamNm === '') {
        isCheck = false;
      }
    });

    return isCheck;
  };

  const fn_saves = (data) => {
    if (data.resultCode === 0) {
      saves();
      showToast('저장에 성공하였습니다.');
    } else {
      showToast('저장에 실패하였습니다.', 'error');
    }
  };

  const [saveConfirm, setSaveConfirm] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');

  const changeTeam = (item, e) => {
    item.teamNm = e.target.value;

    if (item.useWrkf) {
      setData(item, 'U');
    } else {
      setData(item, 'I');
    }
  };

  const setData = (data, type) => {
    const newBlng = {
      userId: userInfo.userId,
      userSn: userInfo.userSn,
      type: type,
      blngSn: data.blngSn,
      teamNm: data.teamNm,
    };

    setSendData((prev) => {
      const itemExists = prev.some((item) => item.blngSn === newBlng.blngSn);
      if (itemExists) {
        return prev.map((item) => (item.blngSn === newBlng.blngSn ? newBlng : item));
      } else {
        return [...prev, newBlng];
      }
    });
  };
  const [canclePopup, setCanclePopup] = useState(false);

  const handleSaveBtn = () => {
    if (!checkValue()) {
      setConfirmMsg('팀 이름을 입력해주세요.');
      setDeleteConfirm(true);
    } else if (!(sendData.length > 0)) {
      setConfirmMsg('변경사항이 존재하지 않습니다.');
      setDeleteConfirm(true);
    } else {
      setSaveConfirm(true);
    }
  };

  return (
    <>
      <Dialog open={regTeamOpen} fullWidth maxWidth={'xs'} disableEnforceFocus sx={{ minHeight: '500px' }}>
        <DialogTitle>
          <Typography className="modal-tit">소속 편집</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            최대 10개의 팀으로 구성할 수 있어요.
          </Typography>
        </DialogTitle>
        <DialogContent>
          {blngList &&
            blngList.map((item, idx) => (
              <Grid container className="inp-list" key={item.blngSn}>
                <Grid container>
                  <Grid item xs={11}>
                    {idx > 2 ? (
                      <TextField
                        defaultValue={item.teamNm}
                        placeholder="팀 이름을 입력해주세요"
                        size="small"
                        onChange={(e) => changeTeam(item, e)}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        defaultValue={item.teamNm}
                        placeholder="팀 이름을 입력해주세요"
                        size="small"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                      />
                    )}
                  </Grid>
                  {idx > 2 ? (
                    <DeleteForeverIcon onClick={() => deleteContent(item)} />
                  ) : (
                    <DeleteForeverIcon
                      sx={{
                        visibility: 'hidden',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            ))}

          {plusButton && <Button className="add" fullWidth onClick={handlAddContent} startIcon={<AddIcon />} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCanclePopup(true)} className="btn-white" fullWidth>
            취소
          </Button>
          <Button className="btn-navy" fullWidth onClick={() => handleSaveBtn()}>
            저장
          </Button>
        </DialogActions>
      </Dialog>
      {canclePopup && (
        <ConfirmPopUp
          open={canclePopup}
          close={setCanclePopup}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setRegTeamOpen}
        />
      )}
      {deleteConfirm && (
        <ConfirmPopUp open={deleteConfirm} close={setDeleteConfirm} title={confirmMsg} btnNm2={'확인'} />
      )}
      {saveConfirm && (
        <ConfirmPopUp
          open={saveConfirm}
          close={setSaveConfirm}
          title={'저장 하시겠습니까?'}
          btnNm1={'아니오'}
          btnNm2={'예'}
          btnEvent={saveButton}
        />
      )}
    </>
  );
};

export default PageWorkforceTeam;
