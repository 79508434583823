import { Close, Search } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getDvlpCoDtl, getDvlpCoNmList } from '@store/actions/contractAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageLoadDvlpModal = ({ isOpen, setIsOpen, inputs, setInputs }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    handleGetSalesNmList();
  }, [dispatch]);

  const handleGetSalesNmList = (newKeyword) => {
    dispatch(
      getDvlpCoNmList({
        userId: user.userId,
        userSn: user.userSn,
        keyword: newKeyword,
        callback: handleGetCntrNmListCallback,
      })
    );
  };

  const handleGetCntrNmListCallback = (data) => {
    if (data.resultCode === 0) {
      setProjectList(data.data.dvlpCoNmList);
    } else {
      showToastError(data);
    }
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList =
      projectList &&
      projectList.length > 0 &&
      projectList.filter((data) => {
        const dvlpNmMatch = data.dvlpNm ? data.dvlpNm.toLowerCase().includes(newKeyword) : false;
        const dvlpCdMatch = data.dvlpCd ? data.dvlpCd.toLowerCase().includes(newKeyword) : false;
        return dvlpNmMatch || dvlpCdMatch;
      });

    setFilteredList(filteredList);
  };
  const displayList = keyword ? filteredList : projectList;

  const handleGetProjectDetail = (dvlpSn) => {
    dispatch(
      getDvlpCoDtl({
        userId: user.userId,
        userSn: user.userSn,
        dvlpCoSn: dvlpSn,
        callback: handelGetProjectDetailCallback,
      })
    );
  };

  const handelGetProjectDetailCallback = (data) => {
    console.log(data);
    if (data.resultCode === 0) {
      setIsOpen(false);
      setInputs(data.data);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          개발사 가져오기
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          개발사 목록 중 정보를 가져올 개발사를 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="회사명/회사코드를 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="list-type">
          {displayList && displayList.length > 0 ? (
            displayList.map((item) => (
              <Grid container key={item.dvlpCoSn}>
                <Grid item>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.dvlpNm}
                  </Typography>
                  <Typography variant="body2">{item.dvlpCd}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handleGetProjectDetail(item.dvlpCoSn)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageLoadDvlpModal;
