import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { putPwEdit, putpwEdit } from '@store/actions/userAction';
import { isPassword, isValue, showToast } from '@common/common';

const PageMypagePwEdit = ({ pwOpen, setPwOpen }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [pwEdit, setPwEdit] = useState();
  const [isError, setIsError] = useState(false);

  const [errorMsg1, setErrorMsg1] = useState('');
  const [errorMsg2, setErrorMsg2] = useState('');
  const [errorMsg3, setErrorMsg3] = useState('');

  const [inputs, setInputs] = useState({
    userCurPw: '',
    userPw: '',
    userPwRe: '',
    //  userSn: user.userSn,
    //  userId: user.userId,
  });

  // 비밀번호 변경을 위한 핸들러
  const handlePwEdit = () => {
    if (!isValue(inputs.userCurPw) || !isValue(inputs.userPw) || !isValue(inputs.userPwRe)) {
      return showToast('입력하신 정보를 다시 확인해주세요. ');
    }
    console.log(inputs.userPw);

    if (!isPassword(inputs.userPw)) {
      return setErrorMsg1('새 비밀번호: 8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해주세요.');
    } else if (inputs.userPw !== inputs.userPwRe) {
      return setErrorMsg1('새 비밀번호 확인: 비밀번호가 일치하지 않습니다.');
    }

    dispatch(
      putPwEdit({
        userCurPw: inputs.userCurPw,
        userPw: inputs.userPw,
        userSn: user.userSn,
        userId: user.userId,
        callback: pwEditcallback,
      })
    );
  };

  const pwEditcallback = (data) => {
    if (data.resultCode === 0) {
      showToast('비밀번호가 성공적으로 변경되었습니다.');
      setPwOpen(false);
    } else {
      setIsError(true);
      showToast(data.resultMsg);
    }
  };

  useEffect(() => {
    console.log('pwOpen has changed:', pwOpen);
  }, [pwOpen]);

  const handleValid = (e) => {
    const { name, value } = e.target;
    setErrorMsg1('');
    setErrorMsg2('');
    setErrorMsg3('');
    if (name === 'userCurPw' && value !== user.userCurPw) {
      setErrorMsg1('현재 비밀번호: 현재 비밀번호와 일치하지 않습니다.');
    } else if (name === 'userPw' && !isPassword(inputs.userPw)) {
      setErrorMsg1('새 비밀번호: 8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해주세요.');
    } else if (name == 'userPwRe' && inputs.userPw !== inputs.userPwRe) {
      setErrorMsg1('새 비밀번호 확인: 비밀번호가 일치하지 않습니다.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let _value = '';

    if (name === 'opnDt') {
      _value = setOnlyNumber(value);
    } else {
      _value = value;
    }

    setInputs({
      ...inputs,
      [name]: _value,
    });
  };

  return (
    <>
      <Dialog open={pwOpen} fullWidth maxWidth="xs" disableEnforceFocus>
        <DialogTitle>
          <Button className="btn-close" onClick={() => setPwOpen(false)}>
            <CloseIcon className="icon" />
          </Button>
          <Typography className="modal-tit">비밀번호 변경</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            현재 비밀번호와 변경할 비밀번호를 입력해주세요
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container flexDirection={'column'} spacing={2}>
            <Grid container item alignItems={'center'} spacing={1}>
              <Grid item md={4}>
                <Typography variant="subtitle1">현재 비밀번호</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  className="inp-round"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleValid}
                  type="password"
                  name="userCurPw"
                  value={user.userCurPw}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems={'center'} spacing={1}>
              <Grid item md={4}>
                <Typography variant="subtitle1">새 비밀번호</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  className="inp-round"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleValid}
                  name="userPw"
                  type="password"
                  value={inputs.userPw}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems={'center'} spacing={1}>
              <Grid item md={4}>
                <Typography variant="subtitle1">새 비밀번호 확인</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  className="inp-round"
                  size="small"
                  fullWidth
                  id="pwd_cnf_val"
                  onChange={handleChange}
                  onBlur={handleValid}
                  name="userPwRe"
                  type="password"
                  value={inputs.userPwRe}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: 'gray', marginTop: '30px' }} />
        </DialogContent>
        <DialogActions>
          <Grid container padding={0} spacing={2}>
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
              {isValue(errorMsg1) && (
                <Typography variant="caption" color={'red'}>
                  {errorMsg1}
                </Typography>
              )}
              {isValue(errorMsg2) && (
                <Typography variant="caption" color={'red'}>
                  {errorMsg2}
                </Typography>
              )}
              {isValue(errorMsg3) && (
                <Typography variant="caption" color={'red'}>
                  {errorMsg3}
                </Typography>
              )}
              <Typography color={'error'}></Typography>
            </Grid>
            <Grid item xs={12} mt={1} paddingTop={0}>
              <Button onClick={handlePwEdit} className="btn-navy" sx={{ display: 'Block', margin: '0 auto' }}>
                저장
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PageMypagePwEdit;
