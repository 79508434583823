import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { moveIssue, moveProject } from '@store/actions/commonAction';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MainIssueTable = ({ issueData, issueCommentData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMoveIssue = (prjcSn, issueSn) => {
    console.log(prjcSn);
    dispatch(moveProject(prjcSn));
    dispatch(moveIssue(issueSn));
    navigate('/mng/project/detail/issue?type=B');
  };
  return (
    <Grid container px={2}>
      <Grid item sm={12} md={6}>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
            minHeight: '440px',
          }}
        >
          <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <Typography variant="body1" fontWeight={'500'}>
                  신규 이슈
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Grid item p={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={100}>프로젝트명</TableCell>
                    <TableCell width={100}>이슈명</TableCell>
                    <TableCell width={80} align="center">
                      작성자
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {issueData && issueData.length > 0 ? (
                    issueData.map((item, index) => (
                      <TableRow
                        key={item.issueSn}
                        onClick={() => handleMoveIssue(item.prjcMainSn, item.issueSn)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell
                          sx={{
                            maxWidth: '100px', // 원하는 최대 너비 설정
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.prjcNm}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: '100px', // 원하는 최대 너비 설정
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.issueNm}
                        </TableCell>
                        <TableCell align="center">{item.userNm}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center" sx={{ borderBottom: 0 }}>
                        <Typography>신규 이슈가 없습니다</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        <Grid
          item
          md={12}
          component={Card}
          variant="outlined"
          sx={{
            mt: 2,
            mr: 1,
            borderColor: 'primary',
            minHeight: '440px',
          }}
        >
          <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <Typography variant="body1" fontWeight={'500'}>
                  신규 이슈 코멘트
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <Grid item p={2}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={100} align="center">
                      이슈명
                    </TableCell>
                    <TableCell width={100} align="center">
                      코멘트
                    </TableCell>
                    <TableCell width={70} align="center">
                      작성자
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {issueCommentData && issueCommentData.length > 0 ? (
                    issueCommentData.map((item, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleMoveIssue(item.prjcMainSn, item.issueSn)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell
                          sx={{
                            maxWidth: '100px', // 원하는 최대 너비 설정
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.issueNm}
                        </TableCell>
                        <Tooltip title={item.content}>
                          <TableCell
                            sx={{
                              maxWidth: '100px', // 원하는 최대 너비 설정
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.content}
                          </TableCell>
                        </Tooltip>
                        <TableCell align="center">{item.userNm}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center" sx={{ borderBottom: 0 }}>
                        <Typography>신규 이슈 코멘트가 없습니다</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainIssueTable;
