import { DatePickerRound, setDateFormat, setNumberComma, showToast, showToastError } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getPaymentUpdateDtl, putPaymentList, putPaymentStop } from '@store/actions/paymentAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ko } from 'date-fns/locale/ko';
import DatePicker from 'react-datepicker';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PagePaymentMonthUpdateModal = (props) => {
  const { isOpen, setIsOpen, updateData, isSuccess, setIsSuccess, setIsDetailOpen } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [updateList, setUpdateList] = useState([]);
  const [sendData, setSendData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [inputData, setInputData] = useState('0');
  const [stopPop, setStopPop] = useState(false);
  const [updatePop, setUpdatePop] = useState(false);
  const [wrongDatePop, setWrongDatePop] = useState(false);

  useEffect(() => {
    dispatch(
      getPaymentUpdateDtl({
        userSn: user.userSn,
        userId: user.userId,
        paymentSn: updateData.paymentSn,
        tgtSn: updateData.tgtSn,
        tgtType: updateData.tgtType,
        callback: handleGetPaymentUpdateDtlCallback,
      })
    );
  }, []);

  useEffect(() => {
    let inputCost = BigInt(0);

    updateList.map((e) => {
      inputCost += BigInt(e.cost);
    });

    setInputData(inputCost.toString());
  }, [updateList]);

  const handleGetPaymentUpdateDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setUpdateList(data.data);
    } else {
      showToastError(data);
    }
  };

  const checkHandler = (e, paymentDtlSn) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setCheckedList((prev) => [...prev, paymentDtlSn]);

      return;
    }

    if (!isChecked && checkedList.includes(paymentDtlSn)) {
      setCheckedList(checkedList.filter((item) => item !== paymentDtlSn));

      return;
    }
  };

  const handleCost = (e, paymentDtlSn) => {
    let inputValue = e.target.value
      .replace(/,/g, '')
      .replace(/^0+/, '')
      .replace(/[^0-9]+/g, '');

    if (inputValue === '' || /^0+$/.test(inputValue)) {
      inputValue = '0';
    }

    if (inputValue.length > 20) {
      inputValue = '99999999999999999999';
    }

    handleValues('cost', inputValue, paymentDtlSn);
  };

  const handleValues = (name, value, paymentDtlSn) => {
    if (name === 'paymentDt' && (new Date(updateData.cntrStDt) > value || new Date(updateData.cntrEndDt) < value)) {
      setWrongDatePop(true);
      return;
    }

    setUpdateList((prev) => {
      // Map over the previous list to create a new list with updated data
      const newList = prev.map((item) => {
        if (item.paymentDtlSn === paymentDtlSn) {
          // Create a new object with updated values
          const updatedItem = {
            ...item,
            [name]: value,
            userId: user.userId,
            userSn: user.userSn,
          };

          // Update the sendData list
          const updatedSendData = sendData.filter((e) => e.paymentDtlSn !== paymentDtlSn);
          updatedSendData.push({
            paymentDtlSn: updatedItem.paymentDtlSn,
            paymentDt: setDateFormat(updatedItem.paymentDt, 'YYYY-MM-DD'),
            cost: updatedItem.cost,
          });

          setSendData(updatedSendData);
          return updatedItem;
        }
        return item;
      });

      return newList; // Return the updated list to update the state correctly
    });
  };

  const handlePaymentStopConfirm = () => {
    if (checkedList.length <= 0) {
      return showToast('중단할 지급 목록을 선택해주세요.');
    }
    setStopPop(true);
  };

  const handlePutPaymentStop = () => {
    dispatch(
      putPaymentStop({
        userSn: user.userSn,
        userId: user.userId,
        paymentDtlSnList: checkedList,
        callback: handlePutPaymentStopCallback,
      })
    );
  };

  const handlePutPaymentStopCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('지급 중단 처리 되었습니다.');
      setStopPop(false);
      setIsOpen(false);
      setIsSuccess(!isSuccess);
      setIsDetailOpen(false);
      setCheckedList([]);
    } else {
      showToastError(data);
    }
  };

  const handlePutPaymentList = () => {
    console.log(updateList);
    dispatch(
      putPaymentList({
        userSn: user.userSn,
        userId: user.userId,
        paymentList: updateList.map((item) => ({
          paymentDtlSn: item.paymentDtlSn,
          cost: Number(item.cost),
          paymentDt: setDateFormat(item.paymentDt, 'YYYY-MM-DD'),
        })),
        callback: handlePutPaymentListCallback,
      })
    );
  };

  const handlePutPaymentListCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('지급 정보가 수정되었습니다.');
      setIsOpen(false);
      setUpdatePop(false);
      setIsDetailOpen(false);
    } else {
      showToastError(data);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'sm'}
        disableEnforceFocus
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle>
          <Button className="btn-close">
            <Close onClick={() => setIsOpen(false)} />
          </Button>
          <Typography className="modal-tit">지급 수정</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            이미 중단되거나 완료된 항목은 중단 할 수 없습니다.
            <br />
            지급 기간 연장이나 고용 형태 수정은 신규 지급 등록으로만 가능합니다.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container mt={3}>
            <Grid item xs={12} borderBottom={'1px solid'} pb={2}>
              {updateList &&
                updateList.length > 0 &&
                updateList.map((item) => (
                  <Grid item xs={12} key={item.paymentDtlSn} display={'flex'} alignItems={'center'} mb={1}>
                    <Grid item xs={1}>
                      <Checkbox
                        disabled={item.paymentStts === '3' || item.paymentStts === '4'}
                        // sx={{
                        //   color: 'black',
                        // }}
                        checked={checkedList.includes(item.paymentDtlSn)}
                        onChange={(e) => checkHandler(e, item.paymentDtlSn)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>{item.categoryNm}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DatePicker
                        locale={ko}
                        selected={item.paymentDt}
                        onChange={(e) => handleValues('paymentDt', e, item.paymentDtlSn)}
                        dateFormat={'YYYY-MM-dd'}
                        customInput={<DatePickerRound placehol="YYYY-MM-DD" />}
                        disabled={item.paymentStts === '3' || item.paymentStts === '4'}
                      />
                    </Grid>
                    <Grid item xs={4} ml={1}>
                      <TextField
                        className="inp-round"
                        fullWidth
                        size="small"
                        value={setNumberComma(item.cost + '')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="subtitle2" mr={2}>
                                원
                              </Typography>
                            </InputAdornment>
                          ),
                          readOnly: item.paymentStts === '3' || item.paymentStts === '4',
                          // sx: {
                          //   backgroundColor: item.clctStts === '3' || item.clctStts === '4' ? 'lightgray' : '',
                          // },
                        }}
                        onChange={(e) => handleCost(e, item.paymentDtlSn)}
                        sx={{
                          '& .MuiInputBase-input': {
                            textAlign: 'right',
                          },
                        }}
                        name="cost"
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item md={12} display={'flex'} alignItems={'center'} my={1}>
              <Grid item md={3} />
              <Grid item md={9} display={'flex'} alignItems={'center'}>
                <Grid item md={5} display={'flex'} justifyContent={'end'}>
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    총 지급액
                  </Typography>
                </Grid>
                <Grid item md={7} display={'flex'} justifyContent={'end'}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    {/* {updat && setNumberComma(propData.cntrAmnt === '' ? '0' : resultData.cntrAmnt)} 원 */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} />
              <Grid item md={9} display={'flex'} alignItems={'center'}>
                <Grid item md={5} display={'flex'} justifyContent={'end'}>
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    입력 금액
                  </Typography>
                </Grid>
                <Grid item md={7} display={'flex'} justifyContent={'end'}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    {setNumberComma(inputData)} 원
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="btn-white" onClick={handlePaymentStopConfirm}>
            선택 중단
          </Button>
          <Button className="btn-navy" onClick={() => setUpdatePop(true)}>
            수정 저장
          </Button>
        </DialogActions>
      </Dialog>
      {stopPop && (
        <ConfirmPopUp
          open={stopPop}
          close={setStopPop}
          title={'선택한 정산 목록을 중단 하시겠습니까?'}
          comment={'중단 후에는 되돌릴 수 없으며,\n지급 정보를 수정한 경우 수정 저장후 중단 처리해주세요.'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handlePutPaymentStop}
        />
      )}
      {updatePop && (
        <ConfirmPopUp
          open={updatePop}
          close={setUpdatePop}
          title={'저장 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handlePutPaymentList}
        />
      )}
      {wrongDatePop && (
        <ConfirmPopUp
          open={wrongDatePop}
          close={setWrongDatePop}
          title={'지급일은 계약 기간 내의 날짜로 선택해주세요.'}
          comment={'이 프로젝트의 계약기간은\n' + updateData.cntrStDt + ' ~ ' + updateData.cntrEndDt + ' 입니다.'}
          btnNm2={'확인'}
        />
      )}
    </>
  );
};

export default PagePaymentMonthUpdateModal;
