import { ExampleCustomInput, setDateFormat, showToast, showToastError } from '@common/common';
import { Close, DragHandle, Percent } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getPrjcTaskDtl, putPrjcTask } from '@store/actions/projectAction';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Sortable from 'sortablejs';
import PageLoadGroupModal from './PageLoadGroupModal';
import PageLoadPrsnModal from './PageLoadPrsnModal';
import { ko } from 'date-fns/locale/ko';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectPrntTaskModal = ({ isOpen, setIsOpen, detailSn, isSuccess, setIsSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const pUser = useSelector((state) => state.projectReducer.project);

  const sortableContainerRef = useRef(null);
  const [originalPrsnList, setOriginalPrsnList] = useState([]);
  const [grpList, setGrpList] = useState([]);
  const [prrtLength, setPrrtLength] = useState();
  const [taskData, setTaskData] = useState();
  const [plusButton, setPlusButton] = useState(true);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [isPrsnOpen, setIsPrsnOpen] = useState(false);
  const [updPop, setUpdPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const sortableRef = useRef(null); // Sortable instance reference

  useEffect(() => {
    if (sortableContainerRef.current) {
      // Destroy existing Sortable instance if it exists
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }

      // Create a new Sortable instance
      sortableRef.current = new Sortable(sortableContainerRef.current, {
        handle: '.handle',
        animation: 150,
        //draggable: '.draggable-item:not(.fixed)',
        filter: '.fixed', // Class name of elements that should not be sortable
        preventOnFilter: false,
        onEnd: (event) => {
          const { oldIndex, newIndex } = event;
          console.log(grpList);
          if (oldIndex !== newIndex) {
            const updatedItems = [...grpList];
            console.log(updatedItems);
            const [movedItem] = updatedItems.splice(oldIndex, 1);
            updatedItems.splice(newIndex, 0, movedItem);

            setGrpList(updatedItems);
          }
        },
        onMove: (event) => {
          const { related, dragged } = event;
          const relatedIndex = Array.from(related.parentNode.children).indexOf(related);
          // Prevent drop to the first position

          return true;
        },
      });
    }

    if (prrtLength > 50) {
      setPlusButton(false);
    } else {
      setPlusButton(true);
    }
  }, [prrtLength, grpList, sortableContainerRef.current]);

  useEffect(() => {
    dispatch(
      getPrjcTaskDtl({
        userSn: user.userSn,
        userId: user.userId,
        puserSn: pUser.puserSn,
        prjcTaskSn: detailSn,
        callback: handleGetPrjcTaskDtlCallback,
      })
    );
  }, []);

  const handleGetPrjcTaskDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setOriginalPrsnList(data.data.prjcTaskPrsnList);
      setTaskData(data.data);
      setGrpList(data.data.prjcChildTaskList);
    } else {
      showToastError(data);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setTaskData({
      ...taskData,
      stDt: start,
      endDt: end,
    });
  };

  const handleTaskDataChange = (e) => {
    const { name, value } = e.target;

    setTaskData({
      ...taskData,
      [name]: value,
    });
  };

  const handleConfirmUpdPrjcTask = () => {
    if (taskData.prjcTaskPrsnList.length <= 0) {
      return showToast('업무담당자를 선택해주세요.');
    }

    setUpdPop(true);
  };

  const handleUpdPrjcTask = () => {
    dispatch(
      putPrjcTask({
        userSn: user.userSn,
        userId: user.userId,
        prjcTaskSn: detailSn,
        stts: taskData.stts,
        nm: taskData.taskNm,
        prjcGrpSn: taskData.prjcGrpSn,
        inptPrsnSnList: taskData.prjcTaskPrsnList.map((prsn) => prsn.inptPrsnSn),
        prjcChildTaskSnList: grpList.map((prjc) => prjc.prjcTaskSn),
        stDt: setDateFormat(taskData.stDt, 'YYYYMMDD'),
        endDt: setDateFormat(taskData.endDt, 'YYYYMMDD'),
        prgr: taskData.prgr,
        memo: taskData.memo,
        callback: handlePutPrjcTaskCallback,
      })
    );
  };

  const handlePutPrjcTaskCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('저장되었습니다.');
      setIsSuccess(!isSuccess);
      setIsOpen(false);
      setUpdPop(false);
    } else {
      showToastError(data);
    }
  };
  return (
    <>
      <Dialog open={isOpen} fullWidth scroll="paper" disableEnforceFocus>
        <DialogTitle id="scroll-dialog-title">
          {taskData && taskData.modiYn === 'N' && (
            <Button className="btn-close">
              <Close onClick={() => setIsOpen(false)} />
            </Button>
          )}
          <Chip label="상위" variant="outlined" />
          <TextField
            name="taskNm"
            variant="outlined"
            placeholder="업무명을 입력해주세요"
            onChange={handleTaskDataChange}
            value={taskData ? taskData.taskNm : ''}
            className="modal-tit2 rqd"
            fullWidth
            size="medium"
            InputProps={{ maxLength: 50, readOnly: taskData && taskData.modiYn === 'Y' ? false : true }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1" className="rqd">
                  상태
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Select
                  id="demo-simple-select"
                  variant="outlined"
                  size="small"
                  displayEmpty
                  fullWidth
                  name="stts"
                  //   sx={{ width: '150px', marginBottom: '10px' }}
                  defaultValue=""
                  onChange={handleTaskDataChange}
                  value={taskData ? taskData.stts : ''}
                  inputProps={{ readOnly: taskData && taskData.modiYn === 'N' ? true : false }}
                >
                  <MenuItem value="">상태선택</MenuItem>
                  <MenuItem value="0">대기</MenuItem>
                  <MenuItem value="1">진행</MenuItem>
                  <MenuItem value="2">피드백</MenuItem>
                  <MenuItem value="3">완료</MenuItem>
                  <MenuItem value="4">지연</MenuItem>
                  <MenuItem value="5">보류</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1" className="rqd">
                  그룹
                </Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="grpNm"
                  size="small"
                  variant="standard"
                  //   onClick={() => setIsGroupOpen(true)}
                  // onChange={handleInputChange}
                  // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                  onClick={() => (taskData && taskData.modiYn === 'Y' ? setIsGroupOpen(true) : undefined)}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  value={taskData ? taskData.grpNm : ''}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} display={'flex'} alignItems={'center'}>
                <Typography variant="subtitle1" className="rqd">
                  담당자
                </Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  variant="standard"
                  size="small"
                  //   onClick={() => setIsGroupOpen(true)}
                  // onChange={handleInputChange}
                  // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                  inputProps={{ readOnly: true }}
                  fullWidth
                  value={taskData?.prjcTaskPrsnList?.map((prsn) => prsn.userNm).join(', ') ?? ''}
                  onClick={() => (taskData && taskData.modiYn === 'Y' ? setIsPrsnOpen(true) : undefined)}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={3}>
                <Typography variant="subtitle1" className="rqd">
                  업무 기간
                </Typography>
              </Grid>
              <Grid item md={9} className="inp-date">
                <DatePicker
                  locale={ko}
                  selected={taskData && taskData.stDt}
                  onChange={handleDateChange}
                  startDate={taskData ? taskData.stDt : ''}
                  endDate={taskData ? taskData.endDt : ''}
                  dateFormat={'YYYY-MM-dd'}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  disabled={taskData && taskData.modiYn === 'Y' ? false : true}
                  // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={3}>
                <Typography variant="subtitle1">진행률</Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="prgr"
                  variant="standard"
                  size="small"
                  onChange={handleTaskDataChange}
                  value={taskData ? taskData.prgr : ''}
                  fullWidth
                  InputProps={{
                    readOnly: taskData && taskData.modiYn === 'N' ? true : false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Percent fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={12} display={'flex'}>
              <Grid item md={3}>
                <Typography variant="subtitle1">메모</Typography>
              </Grid>
              <Grid item md={9}>
                <TextField
                  name="memo"
                  onChange={handleTaskDataChange}
                  multiline
                  rows={4}
                  fullWidth
                  value={taskData ? taskData.memo : ''}
                  InputProps={{
                    readOnly: taskData && taskData.modiYn === 'N' ? true : false,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid container>
              <Typography variant="body1">하위 업무</Typography>
            </Grid>
            <TableContainer sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={80} align="center">
                      순서
                    </TableCell>
                    <TableCell>업무명</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody ref={sortableContainerRef}>
                  {taskData &&
                    taskData.prjcChildTaskList &&
                    taskData.prjcChildTaskList.length > 0 &&
                    taskData.prjcChildTaskList.map((item, index) => (
                      <TableRow key={item.prjcTaskSn} sx={{ cursor: 'pointer' }}>
                        <TableCell align="center">
                          <IconButton className={taskData && taskData.modiYn === 'Y' ? 'handle' : ''}>
                            <DragHandle />
                          </IconButton>
                        </TableCell>
                        <TableCell>{item.taskNm}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        {taskData && taskData.modiYn === 'Y' && (
          <DialogActions>
            <Button onClick={() => setCancelPop(true)} className="btn-white">
              취소
            </Button>
            <Button className="btn-navy" onClick={handleConfirmUpdPrjcTask}>
              저장
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {isGroupOpen && (
        <PageLoadGroupModal isOpen={isGroupOpen} setIsOpen={setIsGroupOpen} setInputs={setTaskData} inputs={taskData} />
      )}
      {isPrsnOpen && (
        <PageLoadPrsnModal
          isOpen={isPrsnOpen}
          setIsOpen={setIsPrsnOpen}
          setInputs={setTaskData}
          inputs={taskData}
          setOriginalPrsnList={setOriginalPrsnList}
          originalPrsnList={originalPrsnList}
        />
      )}
      {updPop && (
        <ConfirmPopUp
          open={updPop}
          close={setUpdPop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleUpdPrjcTask}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setIsOpen}
        />
      )}
    </>
  );
};

export default PageProjectPrntTaskModal;
