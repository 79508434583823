import { ArrowBackIosNew, HelpOutline } from '@mui/icons-material';
import { Box, Button, Card, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageProjectRegProjectInfoMain from './PageProjectRegProjectInfoMain';
import PageProjectRegClntInfoMain from './PageProjectRegClntInfoMain';
import PageProjectRegMngInfoMain from './PageProjectRegMngInfoMain';
import PageProjectRegDvlpInfoMain from './PageProjectRegDvlpInfoMain';
import PageProjectPositonModal from './PageProjectPositonModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getPrjcDtl,
  getPrjcDvlpSuccess,
  getUpdPrjcDvlpSuccess,
  putPrjcMain,
  setPrjcDvlpInit,
} from '@store/actions/projectAction';
import { useSelector } from 'react-redux';
import { setDateFormat, showToast, showToastError } from '@common/common';

import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectUpdMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);
  const dvlpData = useSelector((state) => state.projectReducer.projectDvlpInfo);

  const [selectedItemIndex, setSelectedItemIndex] = useState('');
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [regPop, setRegPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [projectInputs, setProjectInputs] = useState({});
  const [clntInputs, setClntInputs] = useState({});
  const [dvlpInputs, setDvlpInputs] = useState({});
  const params = useParams();
  const items = [
    {
      title: '개발사',
      value: '0',
    },
    {
      title: '관리사',
      value: '1',
    },
    {
      title: '개발&관리사',
      value: '2',
    },
    {
      title: '개발&관리&의뢰사',
      value: '3',
    },
  ];

  useEffect(() => {
    dispatch(setPrjcDvlpInit());
    dispatch(
      getPrjcDtl({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: id,
        callback: handleGetPrjcDtlCallback,
      })
    );
  }, [isSuccess]);

  const handleGetPrjcDtlCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      setSelectedItemIndex(data.data.type);
      setProjectInputs({
        prjcMainSn: data.data.prjcMainSn,
        stts: data.data.stts,
        prjcNm: data.data.prjcNm,
        cntrStDt: data.data.stDt,
        cntrEndDt: data.data.endDt,
        prjcDscr: data.data.prjcDscr,
      });

      setClntInputs({
        clntCoSn: data.data.clntCoSn,
        clntCoCd: data.data.clntCoCd,
        clntNm: data.data.clntCoNm,
        zip: data.data.clntZip,
        addr: data.data.clntAddr,
        daddr: data.data.clntDaddr,
        mngr: data.data.clntMngrNm,
        mngrTelno: data.data.clntMngrTelno,
        mngrEmail: data.data.clntMngrEmail,
      });
      setDvlpInputs(data.data.dvlpCoList[0]);
      dispatch(getUpdPrjcDvlpSuccess(data.data.dvlpCoList));
    } else {
      showToastError(data);
    }
  };

  const handleItemClick = (index, value) => {
    setSelectedItemIndex(value);
  };

  const handleHelpClick = () => {
    setIsHelpOpen(true);
  };

  const handleConfirmUpdProject = () => {
    if (dvlpData.length <= 0) {
      return showToast('개발사 정보를 입력해주세요.');
    }

    setRegPop(true);
  };

  const handleUpdProject = () => {
    dispatch(
      putPrjcMain({
        userId: user.userId,
        userSn: user.usersn,
        type: selectedItemIndex,
        stts: projectInputs.stts,
        prjcMainSn: projectInputs.prjcMainSn,
        prjcNm: projectInputs.prjcNm,
        prjcDscr: projectInputs.prjcDscr,
        stDt: setDateFormat(projectInputs.cntrStDt, 'YYYY-MM-DD'),
        endDt: setDateFormat(projectInputs.cntrEndDt, 'YYYY-MM-DD'),
        clntCoSn: clntInputs.clntCoSn,
        clntCoNm: clntInputs.clntNm,
        clntZip: clntInputs.zip,
        clntAddr: clntInputs.addr,
        clntDaddr: clntInputs.daddr,
        clntMngrNm: clntInputs.mngr,
        clntMngrTelno: clntInputs.mngrTelno,
        clntMngrEmail: clntInputs.clntMngrEmail,
        dvlpCoList: selectedItemIndex === '0' ? [dvlpInputs] : dvlpData,
        callback: handlePutPrjcMainCallback,
      })
    );
  };

  const handlePutPrjcMainCallback = (data) => {
    if (data.resultCode === 0) {
      setRegPop(false);
      showToast('프로젝트가 수정되었습니다.');
      navigate('/mng/project/detail');
      // setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton onClick={() => navigate('/mng/project')}>
            <ArrowBackIosNew />
          </IconButton>
          <Typography variant="h6">프로젝트 수정</Typography>
        </Box>
      </Toolbar>
      <Box py={2} px={5}>
        <Grid container borderBottom={1} borderColor={'divider'} pb={2}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography>프로젝트 포지션</Typography>
            </Grid>
            <Grid item>
              <IconButton sx={{ color: 'orange' }} onClick={handleHelpClick}>
                <HelpOutline />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            {items.map((item, index) => (
              <Grid key={index} item xs={12} sm={12} md={2} mr={2}>
                <Card
                  variant="outlined"
                  component={Button}
                  sx={{
                    p: 2,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    backgroundColor: selectedItemIndex === item.value ? '#deecfa' : undefined,
                    borderColor: selectedItemIndex === item.value ? 'primary.light' : 'primary',
                  }}
                >
                  <Box>
                    <Typography color="primary.light" variant="body2" fontWeight="bold">
                      {item.title}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <PageProjectRegProjectInfoMain projectInputs={projectInputs} setProjectInputs={setProjectInputs} />
        <PageProjectRegClntInfoMain clntInputs={clntInputs} setClntInputs={setClntInputs} prjcSn={id} />
        {selectedItemIndex === '0' ? (
          <PageProjectRegMngInfoMain dvlpInputs={dvlpInputs} setDvlpInputs={setDvlpInputs} prjcSn={id} />
        ) : (
          <PageProjectRegDvlpInfoMain />
        )}
        <Grid container justifyContent={'center'} my={2}>
          <Grid item md={2}>
            <Button variant="outlined" className="btn-white" onClick={() => setCancelPop(true)}>
              취소
            </Button>
          </Grid>
          <Grid item md={2}>
            <Button variant="outlined" className="btn-navy" onClick={handleConfirmUpdProject}>
              저장
            </Button>
          </Grid>
        </Grid>
      </Box>
      {isHelpOpen && <PageProjectPositonModal isOpen={isHelpOpen} setIsOpen={setIsHelpOpen} />}
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'저장하시겠습니까?'}
          comment={'개발사 목록 삭제 시 해당 개발사와의\n프로젝트 연결등이 끊어질 수 있습니다.'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleUpdProject}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={() => navigate('/mng/project')}
        />
      )}
    </>
  );
};

export default PageProjectUpdMain;
