import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast, showToastError, isNumber, isValue } from '@common/common';
import { getPrrtList, getChkPrrtDel, putPrrtList } from '@store/actions/salesAction';
import AddIcon from '@mui/icons-material/Add';
import Sortable from 'sortablejs';
import { AccountCircle, Lock, DragHandle } from '@mui/icons-material';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageSalesPrrtModal = ({ prrtOpen, setPrrtOpen, setIsSuccessYn, isSuccessYn }) => {
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();
  const [plusButton, setPlusButton] = useState(true);
  const [prrtList, setPrrtList] = useState();
  const [prrtLength, setPrrtLength] = useState();
  const [regPop, setRegPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);
  const sortableContainerRef = useRef(null);
  const sortableRef = useRef(null); // Sortable instance reference
  const [arrPrrt, setArrPrrt] = useState([]);

  useEffect(() => {
    if (sortableContainerRef.current) {
      // Destroy existing Sortable instance if it exists
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }

      // Create a new Sortable instance
      sortableRef.current = new Sortable(sortableContainerRef.current, {
        handle: '.handle',
        animation: 150,
        //draggable: '.draggable-item:not(.fixed)',
        filter: '.fixed', // Class name of elements that should not be sortable
        preventOnFilter: false,
        onEnd: (event) => {
          const { oldIndex, newIndex } = event;

          if (oldIndex === 0 || newIndex === 0) {
            return;
          }

          if (oldIndex !== newIndex) {
            const updatedItems = [...prrtList];
            const [movedItem] = updatedItems.splice(oldIndex, 1);
            updatedItems.splice(newIndex, 0, movedItem);
            setPrrtList(updatedItems);
          }
        },
        onMove: (event) => {
          const { related, dragged } = event;
          const relatedIndex = Array.from(related.parentNode.children).indexOf(related);
          // Prevent drop to the first position
          if (relatedIndex === 0) {
            return false;
          }

          return true;
        },
      });
    }

    if (prrtLength > 5) {
      setPlusButton(false);
    } else {
      setPlusButton(true);
    }
  }, [prrtLength, prrtList, sortableContainerRef.current]);

  const getPrrtListHandler = () => {
    dispatch(
      getPrrtList({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: getPrrtListCallback,
      })
    );
  };

  useEffect(() => {
    getPrrtListHandler();
  }, []);

  const getPrrtListCallback = (data) => {
    if (data.resultCode === 0) {
      setPrrtList(data.data.prrtList);
      setPrrtLength(data.data.prrtList.length);
    } else {
      showToast(data);
    }
  };

  const handlAddContent = () => {
    console.log('prrtLength', prrtLength);
    setPrrtLength(prrtLength + 1);
    const data = {
      slsPrrtSn: Math.random().toString(36).substring(2, 11),
      prrt: prrtLength,
      prrtNm: '',
    };

    setPrrtList((prev) => [...prev, data]);

    //setData(data);
  };

  const deleteContent = (value) => {
    if (!isNumber(value.slsPrrtSn)) {
      setPrrtList(prrtList.filter((item) => item.slsPrrtSn !== value.slsPrrtSn));
      setPrrtLength(prrtLength - 1);
    } else {
      dispatch(
        getChkPrrtDel({
          slsPrrtSn: value.slsPrrtSn,
          userSn: userInfo.userSn,
          userId: userInfo.userId,
          callback: (data) => {
            if (data.resultCode === 0) {
              setPrrtList(prrtList.filter((item) => item.slsPrrtSn !== value.slsPrrtSn));
              setPrrtLength(prrtLength - 1);
              //setSendData(sendData.filter((item) => item.slsPrrtSn !== value.slsPrrtSn));
            } else {
              showToast('해당 우선순위에 속한 프로젝트가 존재합니다. 프로젝트 삭제 후 다시 진행해주세요.');
            }
          },
        })
      );
    }
  };

  const handlerSaveConfirm = () => {
    //onsole.log('prrtLength', prrtLength);
    //console.log('sendData', prrtList, sendData);

    const tempArrPrrt = [];
    let arrPrrt = [];
    let objPrrt = {};
    let isSave = true;
    prrtList.map((item, index) => {
      if (!isNumber(item.slsPrrtSn)) item.slsPrrtSn = '';
      if (!isValue(item.prrtNm)) {
        isSave = false;
      }
      objPrrt = {
        slsPrrtSn: item.slsPrrtSn,
        prrt: index + 1,
        prrtNm: item.prrtNm,
      };
      tempArrPrrt.push(objPrrt);

      setArrPrrt(tempArrPrrt);
    });

    if (isSave) {
      setRegPop(true);
    } else {
      showToast('우선순위 이름을 입력해주세요.');
    }
  };

  const handleSave = () => {
    dispatch(
      putPrrtList({
        prrtList: arrPrrt,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: putPrrtListCallback,
      })
    );
  };

  const putPrrtListCallback = (data, status) => {
    if (data.resultCode === 0) {
      showToast('우선순위가 수정 되었습니다.');
      setPrrtOpen(!prrtOpen);
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError('등록에 실패하였습니다.');
    }
  };

  const changePrrt = (item, e) => {
    item.prrtNm = e.target.value;
    //setData(item);
  };

  // const setData = (data) => {
  //   const newPrrt = {
  //     userId: userInfo.userId,
  //     userSn: userInfo.userSn,
  //     slsPrrtSn: data.slsPrrtSn,
  //     prrt: data.prrt,
  //     prrtNm: data.prrtNm
  //   };

  //   setSendData((prev) => {
  //     const itemExists = prev.some((item) => item.prrt === newPrrt.prrt);
  //     if (itemExists) {
  //       return prev.map((item) =>
  //         item.prrt === newPrrt.prrt ? newPrrt : item
  //       );
  //     } else {
  //       return [...prev, newPrrt];
  //     }
  //   });
  // };

  return (
    <>
      <Dialog open={prrtOpen} fullWidth maxWidth={'xs'} disableEnforceFocus sx={{ minHeight: '500px' }}>
        <DialogTitle>
          <Typography className="modal-tit">우선순위 편집</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            우선순위 위치 이동 시 영업 프로젝트도 함께 이동되며,
            <br />
            영업프로젝트가 있는 우선순위는 삭제할 수 없어요. (최대 5개 추가 가능)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid ref={sortableContainerRef}>
            {prrtList &&
              prrtList.map((item, index) => (
                <Grid container className="inp-list" key={item.slsPrrtSn}>
                  <Grid container>
                    <Grid item md={11}>
                      <TextField
                        defaultValue={item.prrtNm}
                        placeholder="우선순위 이름을 입력해주세요"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        className={index === 0 ? 'fixed' : ''}
                        onChange={(e) => changePrrt(item, e)}
                        InputProps={{
                          maxLength: 15,
                          readOnly: index === 0,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              {...(index === 0 ? { sx: { visibility: 'hidden' } } : { className: 'handle' })}
                            >
                              <DragHandle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {index !== 0 ? <DeleteForeverIcon onClick={() => deleteContent(item)} /> : ''}
                  </Grid>
                </Grid>
              ))}
          </Grid>
          {plusButton && <Button className="add" fullWidth onClick={handlAddContent} startIcon={<AddIcon />} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelPop(true)} className="btn-white" fullWidth>
            취소
          </Button>
          <Button className="btn-navy" fullWidth onClick={handlerSaveConfirm}>
            저장
          </Button>
        </DialogActions>
      </Dialog>
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleSave}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={() => setPrrtOpen(false)}
        />
      )}
    </>
  );
};

export default PageSalesPrrtModal;
