import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageLoadDvlpModal from './PageLoadDvlpModal';
import { isValue } from '@common/common';
import DaumPostCode from 'component/DaumPostCode';

const PageProjectRegMngInfoMain = ({ dvlpInputs, setDvlpInputs, prjcSn }) => {
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [isMngOpen, setIsMngOpne] = useState(false);
  console.log(dvlpInputs);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setDvlpInputs({
      ...dvlpInputs,
      [name]: value,
    });
  };

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsPostOpen(false);
    setDvlpInputs({
      ...dvlpInputs,
      zip: zonecode,
      addr: address,
    });
  };
  return (
    <>
      <Grid container borderBottom={1} borderColor={'divider'}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} mt={2}>
          <Grid item>
            <Typography variant="subtitle1">관리사 정보</Typography>
          </Grid>
          {!isValue(prjcSn) && (
            <Grid>
              <Button className="btn-orange" onClick={() => setIsMngOpne(true)}>
                정보 가져오기
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container p={2}>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                회사코드
              </Grid>
              <Grid item md={4}>
                <TextField
                  name="coCd"
                  variant="standard"
                  fullWidth
                  value={dvlpInputs.coCd ? dvlpInputs.coCd : ''}
                  onChange={handleInputChange}
                  // disabled={isValue(prjcSn) ? true : false}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                <Typography variant="subtitle1" className="rqd">
                  회사명
                </Typography>
              </Grid>
              <Grid item md={10}>
                <TextField
                  name="dvlpNm"
                  variant="standard"
                  fullWidth
                  value={dvlpInputs.dvlpNm ? dvlpInputs.dvlpNm : ''}
                  onChange={handleInputChange}
                  inputProps={{ readOnly: isValue(prjcSn) ? true : false }}
                  // disabled={isValue(prjcSn) ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                회사 주소
              </Grid>
              <Grid item md={10} display={'flex'} alignItems={'center'}>
                <Grid item md={3} mr={2}>
                  <TextField
                    name="zip"
                    variant="standard"
                    // disabled
                    fullWidth
                    placeholder="우편번호"
                    value={dvlpInputs.zip ? dvlpInputs.zip : ''}
                    onChange={handleInputChange}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item md={3}>
                  <Button type="button" className="btn" onClick={() => setIsPostOpen(true)}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={2}></Grid>
            <Grid item md={10} display={'flex'} alignItems={'center'}>
              <TextField
                name="addr"
                variant="standard"
                fullWidth
                placeholder="기본주소"
                value={dvlpInputs.addr ? dvlpInputs.addr : ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={2}></Grid>
            <Grid item md={10} display={'flex'} alignItems={'center'}>
              <TextField
                name="daddr"
                variant="standard"
                fullWidth
                placeholder="상세주소"
                value={dvlpInputs.daddr ? dvlpInputs.daddr : ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                담당자
              </Grid>
              <Grid item md={5}>
                <TextField
                  name="mngr"
                  variant="standard"
                  fullWidth
                  value={dvlpInputs.mngr ? dvlpInputs.mngr : ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                담당자 연락처
              </Grid>
              <Grid item md={5}>
                <TextField
                  name="mngrTelno"
                  variant="standard"
                  fullWidth
                  placeholder="숫자만 입력"
                  value={dvlpInputs.mngrTelno ? dvlpInputs.mngrTelno : ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                담당자 이메일
              </Grid>
              <Grid item md={5}>
                <TextField
                  name="mngrEmail"
                  variant="standard"
                  fullWidth
                  value={dvlpInputs.mngrEmail ? dvlpInputs.mngrEmail : ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isPostOpen && <DaumPostCode isOpen={isPostOpen} setIsOpen={setIsPostOpen} handleZipSearch={handleZipSearch} />}

      {isMngOpen && (
        <PageLoadDvlpModal isOpen={isMngOpen} setIsOpen={setIsMngOpne} inputs={dvlpInputs} setInputs={setDvlpInputs} />
      )}
    </>
  );
};

export default PageProjectRegMngInfoMain;
