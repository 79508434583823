import * as actions from '@actions/projectAction';

const INIT_STATE = {
  projectType: '',
  projectInfo: {},
  projectDvlpInfo: [],
  project: {
    ownerYn: '',
    position: '',
    puserSn: '',
  },
  srchType: {
    stts: '',
    swtc: '',
  },
  successYn: false,
};

const projectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.SET_PRJC_DVLP_INIT:
      return {
        ...INIT_STATE,
        srchType: state.srchType, // Keep the current value of stts
      };
    case actions.SET_PRJC_DVLP_DTL_INIT:
      return {
        ...state,
        projectInfo: { ...state.contractInfo, ...action.payload },
      };
    case actions.GET_PRJC_DVLP_DTL_SUCCESS:
      const updatedProjectInfo = state.projectDvlpInfo.filter((item) => item.dvlpCoSn === action.payload);
      return {
        ...state,
        projectInfo: { ...state.projectInfo, ...updatedProjectInfo[0] },
      };
    case actions.GET_PRJC_DVLP_SUCCESS:
      return {
        ...state,
        projectDvlpInfo: [...state.projectDvlpInfo, action.payload],
      };
    case actions.GET_UPD_PRJC_DVLP_SUCCESS:
      return {
        ...state,
        projectDvlpInfo: [...state.projectDvlpInfo, ...action.payload],
      };
    case actions.UPDATE_PRJC_DVLP:
      return {
        ...state,
        projectDvlpInfo: state.projectDvlpInfo.map((item) =>
          item.dvlpCoSn === action.payload.dvlpCoSn ? action.payload : item
        ),
      };
    case actions.DELETE_PRJC_DVLP_INFO:
      console.log(action.payload);
      return {
        ...state,
        projectDvlpInfo: state.projectDvlpInfo.filter((item) => item.dvlpCoSn !== action.payload),
      };
    case actions.SET_PRJC_INFO:
      return {
        ...state,
        project: { ...state.project, ...action.payload },
      };

    case actions.SET_PREV_STTS:
      return {
        ...state,
        srchType: { ...state.project, ...action.payload },
      };
    default:
      return state;
  }
};

export default projectReducer;
