import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography, Box, Card, CardContent } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PageSuccLayout from './PageSuccLayout';

const defaultTheme = createTheme();

export default function PageSearchPwSuccMain() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <PageSuccLayout
      title={'비밀번호 찾기'}
      titleContent={'가 완료되었습니다.'}
      succMsg={`휴대폰으로 임시 비밀번호를 전송하였습니다.\n안전을 위해 로그인 후 즉시 비밀번호를 변경해주세요.`}
    />
  );
}
