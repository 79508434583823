import { showToast } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { putIssueStts } from '@store/actions/projectAction';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const PageProjectIssueStateModal = (prop) => {
  const { statePopup, setStatePopup, ingStateNm, ingStateNum, issueSn, stateSave } = prop;

  const user = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();

  const [sendData, setSendData] = useState({
    userId: user.userId,
    userSn: user.userSn,
    issueSn,
    stts: '',
  });

  const fn_StateResult = (data) => {
    if (data.resultCode === 0) {
      setStatePopup(false);
      stateSave();
    } else {
      showToast('진행상태 변경에 실패하였습니다', 'error');
    }
  };

  const handleSaveState = () => {
    if (sendData.stts === '') {
      showToast('수정할 진행 상태를 선택해주세요.', 'error');
    } else if (sendData.stts === ingStateNum) {
      showToast('현재 상태와 동일합니다.', 'error');
    } else {
      dispatch(
        putIssueStts({
          ...sendData,
          callback: fn_StateResult,
        })
      );
    }
  };

  return (
    <>
      <Dialog open={statePopup} fullWidth maxWidth={'xs'} disableEnforceFocus>
        <DialogTitle>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Typography className="modal-tit" fontWeight={'bold'}>
                진행 상태 변경
              </Typography>
              <Typography color={'grey'} variant="body2" mt={1}>
                변경할 진행 상태를 선택해주세요
              </Typography>
            </Box>
            <Close onClick={() => setStatePopup(false)} sx={{ cursor: 'pointer' }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">현재 진행 상태 </Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip label={ingStateNm} sx={{ width: '100%', justifyContent: 'flex-start' }} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">수정할 진행 상태 </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth>
                <Select
                  fullWidth
                  defaultValue=""
                  displayEmpty
                  onChange={(e) =>
                    setSendData((prev) => ({
                      ...prev,
                      stts: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">선택</MenuItem>
                  <MenuItem value={1}>신규</MenuItem>
                  <MenuItem value={2}>처리중</MenuItem>
                  <MenuItem value={3}>지연</MenuItem>
                  <MenuItem value={4}>협의중</MenuItem>
                  <MenuItem value={5}>철회</MenuItem>
                  <MenuItem value={6}>완료</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="btn-navy" onClick={handleSaveState}>
            적용
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PageProjectIssueStateModal;
