import { Search } from '@mui/icons-material';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { getCoDtl, getClntNmList } from '@store/actions/counterpartyAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { showToast } from '@common/common';

const PageCompanyCode = ({ isOpen, setIsOpen, setCompanyCode, inputs, setInputs }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [clntList, setClntList] = useState([]);
  const [keyword, setKeyword] = useState('');

  const handleSearchChange = (e) => {
    // handleGetClntNmList();
    setKeyword(e.target.value);
  };

  // useEffect(() => {
  //   handleGetClntNmList();
  // }, [dispatch, keyword]);

  const handlEnter = (event) => {
    if (event.key === 'Enter') {
      handleGetClntNmList();
    }
  };

  const handleGetClntNmList = () => {
    // if (keyword.length === 6) {
    dispatch(
      getClntNmList({
        //coCd: keyword,
        userId: user.userId,
        userSn: user.userSn,
        keyword,
        callback: handleGetClntNmListCallback,
      })
    );
    // } else {
    //   showToast('회사코드는 6자리입니다.');
    // }
  };

  const handleGetClntNmListCallback = (data) => {
    if (data.resultCode === 0) {
      //console.log(data);
      setClntList(data.data);
    } else {
      // showToastError(data);
    }
  };

  const handleGetCompanyDetail = (userSn) => {
    dispatch(
      getCoDtl({
        userId: user.userId,
        userSn: userSn,

        callback: handleGetCompanyDetailCallback,
      })
    );
  };

  const handleGetCompanyDetailCallback = (data) => {
    console.log(data);
    if (data.resultCode === 0) {
      setCompanyCode(data.data);
      setIsOpen(false);
      // setInputs({
      //   ...inputs,
      //   clntcoNm: data.data.coNm,
      //   clntcoCd: data.data.coCd,
      //   clntrprNo: data.data.rprNo,
      //   clntfaxNo: data.data.faxNo,
      //   clntZip: data.data.zip,
      //   clntAddr: data.data.addr,
      //   clntDaddr: data.data.daddr,
      //   clntMngrNm: data.data.userNm,
      //   clntMngrTelno: data.data.mngrTelno,
      //   clntMngrEmail: data.data.mngrEmail,
      //   memo: data.data.memo,
      // });
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'550px'} disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close" onClick={() => setIsOpen(false)}>
          <CloseIcon className="icon" />
        </Button>
        <Typography className="modal-tit">회사명</Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          회사명를 입력하면, 회사 기본 정보가 자동 삽입됩니다.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container flexDirection={'column'} spacing={2}>
          <Grid item spacing={2} container justifyContent={'space-between'}>
            <Grid item md={9.5}>
              <TextField
                className="inp-round"
                onChange={handleSearchChange}
                fullWidth
                required
                placeholder="회사명을 입력해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onKeyUp={(e) => handlEnter(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={2.5}>
              <Button className="srh-btn" onClick={handleGetClntNmList}>
                검색
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container className="list-type" sx={{ overflowY: 'hidden' }}>
          {clntList.length > 0 && clntList !== '' ? (
            clntList.map((item) => (
              <Grid key={item.userSn} container>
                <Grid item>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {item.coNm}
                  </Typography>
                  <Typography variant="body2">
                    {item.rprNm} / {item.addr}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    className="btn-orange"
                    onClick={() => {
                      handleGetCompanyDetail(item.userSn);
                      // setCompanyCode(item.userSn);
                      setIsOpen(false);
                    }}
                  >
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색 결과가 없습니다</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageCompanyCode;
