import { convertFomData, ExampleCustomInput, setDateFormat, showToast } from '@common/common';
import { Backup } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ko } from 'date-fns/locale/ko';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import PageProjectIssuePrsnModal from './PageProjectIssuePrsnModal';
import { postIssue } from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectIssueReg = (prop) => {
  const { regOpen, setRegOpen, projectSn, handleIssue } = prop;

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [sendData, setSendData] = useState({
    type: '',
    stts: '',
    url: '',
    content: '',
    issueStartDt: '',
    issueEndDt: '',
    mngPrsnSnList: [],
    chkPrsnSnList: [],
    issueNm: '',
    prjcMainSn: projectSn,
    userSn: userInfo.userSn,
    userId: userInfo.userId,
  });

  const [files, setFiles] = useState([]);
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    droppedFiles.forEach((file) => {
      file.id = Math.random().toString(36).substring(2, 11);
    });
    setFiles([...files, ...droppedFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((file) => {
      file.id = Math.random().toString(36).substring(2, 11);
    });
    setFiles([...files, ...selectedFiles]);
  };

  const handleDeleteFile = (value) => {
    console.log(value);
    setFiles(files.filter((item) => item.id !== value));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setSendData((prev) => ({
      ...prev,
      issueStartDt: start,
      issueEndDt: end,
    }));
  };

  const downloadFile = (file) => {
    const downloadUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    else if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(1)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  };

  const handleChangeEvent = (e) => {
    const { name, value } = e.target;

    if (name === 'url' && value.length > 150) return;
    setSendData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [mngPrsnPopup, setMngPrsnPopup] = useState(false);
  const [chkPrsnPopup, setChkPrsnPopup] = useState(false);

  const [mngList, setMngList] = useState([]);
  const [chkList, setChkList] = useState([]);
  const handleMngPrsn = (data) => {
    console.log(data);
    setMngPrsnPopup(false);
    setMngList(data);
    setSendData((prev) => ({
      ...prev,
      mngPrsnSnList: data.map((item) => item.inptPrsnSn),
    }));
  };

  const handleChkPrsn = (data) => {
    console.log(data);
    setChkPrsnPopup(false);
    setChkList(data);
    setSendData((prev) => ({
      ...prev,
      chkPrsnSnList: data.map((item) => item.inptPrsnSn),
    }));
  };

  const saveIssue = () => {
    console.log(sendData);

    dispatch(
      postIssue(
        convertFomData({
          regIssueRequest: {
            ...sendData,
            issueStartDt: setDateFormat(sendData.issueStartDt, 'YYYYMMDD'),
            issueEndDt: setDateFormat(sendData.issueEndDt, 'YYYYMMDD'),
          },
          files: files,
        }),
        fn_issue
      )
    );
  };

  const fn_issue = (data) => {
    if (data.resultCode === 0) {
      handleIssue();
      showToast('신규 이슈가 등록 되었습니다.');
      setConfirmPop(false);
      setRegOpen(false);
    }
  };

  const isCheckValid = () => {
    if (sendData.type === '') {
      showToast('분류를 선택해주세요', 'error');
      return false;
    } else if (sendData.stts === '') {
      showToast('진행상태를 선택해주세요', 'error');
      return false;
    } else if (sendData.issueNm === '') {
      showToast('이슈명을 입력해주세요', 'error');
      return false;
    } else if (sendData.mngPrsnSnList.length < 1) {
      showToast('담당자를 선택해주세요', 'error');
      return false;
    } else if (sendData.chkPrsnSnList.length < 1) {
      showToast('검수자를 선택해주세요', 'error');
      return false;
    } else if (sendData.issueStartDt === '' || sendData.issueEndDt === '') {
      showToast('처리 기간을 선택해주세요', 'error');
      return false;
    } else if (sendData.content === '') {
      showToast('내용을 입력해주세요', 'error');
      return false;
    }

    return true;
  };

  const [confirmPop, setConfirmPop] = useState(false);
  const [canclePopup, setCanclePopup] = useState(false);

  return (
    <>
      <Dialog open={regOpen} fullWidth maxWidth={'md'} disableEnforceFocus>
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <FormControl size="small" sx={{ minWidth: 150, mr: 1 }}>
                <Select fullWidth displayEmpty value={sendData.type} name="type" onChange={(e) => handleChangeEvent(e)}>
                  <MenuItem value="">분류 선택</MenuItem>
                  <MenuItem value="1">기획</MenuItem>
                  <MenuItem value="2">디자인</MenuItem>
                  <MenuItem value="3">개발</MenuItem>
                  <MenuItem value="4">협업</MenuItem>
                  <MenuItem value="5">기타</MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 150, mr: 1 }}>
                <Select fullWidth displayEmpty value={sendData.stts} name="stts" onChange={(e) => handleChangeEvent(e)}>
                  <MenuItem value="">진행상태 선택</MenuItem>
                  <MenuItem value="1">신규</MenuItem>
                  <MenuItem value="2">처리중</MenuItem>
                  <MenuItem value="3">지연</MenuItem>
                  <MenuItem value="4">협의중</MenuItem>
                  <MenuItem value="5">철회</MenuItem>
                  <MenuItem value="6">완료</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="medium"
                fullWidth
                variant="standard"
                name="issueNm"
                className="modal-tit2 rqd"
                placeholder="이슈명을 입력해주세요."
                onChange={(e) => handleChangeEvent(e)}
                value={sendData.issueNm}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
              <Grid container alignItems={'center'}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" className="rqd">
                    담당자
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    onClick={() => setMngPrsnPopup(true)}
                    value={mngList.length > 0 ? mngList.map((item) => item.userNm).join(', ') : ''}
                    InputProps={{
                      readOnly: true,
                      sx: {
                        '& input': {
                          cursor: 'pointer',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
              <Grid container alignItems={'center'}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" className="rqd">
                    검수자
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    onClick={() => setChkPrsnPopup(true)}
                    value={chkList.length > 0 ? chkList.map((item) => item.userNm).join(', ') : ''}
                    InputProps={{
                      readOnly: true,
                      sx: {
                        '& input': {
                          cursor: 'pointer',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
              <Grid container alignItems={'center'}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" className="rqd">
                    처리 기간
                  </Typography>
                </Grid>
                <Grid item xs={9} className="inp-date">
                  <DatePicker
                    locale={ko}
                    selected={sendData.issueStartDt}
                    onChange={handleDateChange}
                    startDate={sendData.issueStartDt}
                    endDate={sendData.issueEndDt}
                    dateFormat={'YYYY-MM-dd'}
                    selectsRange
                    customInput={<ExampleCustomInput />}
                    // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 3 }}>
              <Grid container alignItems={'center'}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">관련 URL</Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    name="url"
                    value={sendData.url}
                    onChange={(e) => handleChangeEvent(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">관련 첨부파일</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Box component="section" className="file-Box" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <Backup />
                    <Typography variant="body2">첨부할 파일을 끌어다 놓거나</Typography>
                    <Button component="label">
                      찾아보기
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
              <Grid container my={2} margin={0} alignItems={'center'}>
                <Grid item xs={2} />
                <Grid item xs={9} alignItems={'center'}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      justifyItems: 'start',
                    }}
                  >
                    {files &&
                      files.map((file) => (
                        <Box
                          key={file.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="button"
                            display="block"
                            sx={{ textTransform: 'none', cursor: 'pointer' }}
                            onClick={() => downloadFile(file)}
                            gutterBottom
                          >
                            {file.oriFileNm || file.name} ({formatFileSize(file.fileSize || file.size)})
                          </Typography>
                          <IconButton
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                            onClick={() => handleDeleteFile(file.id)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle1" className="rqd">
                    내용
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    multiline
                    fullWidth
                    rows={5}
                    name="content"
                    value={sendData.content}
                    onChange={(e) => handleChangeEvent(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCanclePopup(true)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={() => (isCheckValid() ? setConfirmPop(true) : '')}>
            등록
          </Button>
        </DialogActions>
      </Dialog>
      {canclePopup && (
        <ConfirmPopUp
          open={canclePopup}
          close={setCanclePopup}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setRegOpen}
        />
      )}
      {mngPrsnPopup && (
        <PageProjectIssuePrsnModal
          open={mngPrsnPopup}
          close={setMngPrsnPopup}
          titleNm={'담당자'}
          projectSn={projectSn}
          getInptPrsnSn={handleMngPrsn}
          prsnList={mngList}
        />
      )}
      {chkPrsnPopup && (
        <PageProjectIssuePrsnModal
          open={chkPrsnPopup}
          close={setChkPrsnPopup}
          titleNm={'검수자'}
          projectSn={projectSn}
          getInptPrsnSn={handleChkPrsn}
          prsnList={chkList}
        />
      )}
      {confirmPop && (
        <ConfirmPopUp
          open={confirmPop}
          close={setConfirmPop}
          title={'등록하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'등록'}
          btnEvent={saveIssue}
        />
      )}
    </>
  );
};

export default PageProjectIssueReg;
