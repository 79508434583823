import * as actions from '@actions/mainAction';

const INIT_STATE = {
  user: {
    userId: '',
    userSn: '',
    userNm: '',
    userFlag: '',
    token: '',
    dvlpCoSn: '',
    authList: [],
    authMap: [],
    coNm: '',
  },
  notifyCount: 0,
  boolPopup: false,
  loading: false,
  projectSn: '',
  issueSn: '',
};

const mainReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default mainReducer;
