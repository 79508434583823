import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import authSetup from '@common/authSetup';

const PrivateRoute = ({ pathname, isAuthenticated, children, isAuth }) => {
  const { A10, B10, C10, D10, E10, F10, G10, H10, I10 } = useSelector(authSetup);

  let isShow = false;
  if (pathname == '/' && A10) {
    isShow = true;
  } else if (pathname == '/calendar' && B10) {
    isShow = true;
  } else if (pathname == '/mng/sales' && C10) {
    isShow = true;
  } else if (pathname == '/mng/contract' && D10) {
    isShow = true;
  } else if (pathname == '/mng/project' && E10) {
    isShow = true;
  } else if (pathname == '/mng/workforce' && F10) {
    isShow = true;
  } else if (pathname == '/mng/caculation' && G10) {
    isShow = true;
  } else if (pathname == '/mng/payment' && H10) {
    isShow = true;
  } else if (pathname == '/mng/counterparty' && I10) {
    isShow = true;
  } else {
    if (
      pathname != '/' &&
      pathname != '/calendar' &&
      pathname != '/mng/sales' &&
      pathname != '/mng/contract' &&
      pathname != '/mng/project' &&
      pathname != '/mng/workforce' &&
      pathname != '/mng/payment' &&
      pathname != '/mng/counterparty'
    ) {
      isShow = true;
    }
  }

  if (!isAuthenticated || !isShow) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;
