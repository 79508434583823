import { setDateFormat } from '@common/common';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import {
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { moveIssue } from '@store/actions/commonAction';
import { differenceInDays } from 'date-fns';
import { ko } from 'date-fns/locale/ko';
import { Gantt, ViewMode } from 'gantt-task-react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledGantt = styled.div`
  ._1KJ6x {
    display: none;
  }
  ._2dZTy {
    fill: white !important;
  }
`;

const PageProjectIssueChart = (prop) => {
  const { resultData, projectSn } = prop;

  const [view, setView] = useState(ViewMode.Day);

  const [tasks, setTasks] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const taskHeadColumns = ['25%', '15%', '15%', '10%', '13%', '13%', '9%'];
  const taskBodyColumns = ['25%', '15%', '15%', '10%', '13%', '13%', '9%'];

  useEffect(() => {
    if (Object.entries(resultData).length !== 0) {
      let arrTasks = [];
      console.log(resultData);
      const excludeKeys = ['cnt', 'delBtnExpsYn'];

      const data = Object.fromEntries(Object.entries(resultData).filter(([key]) => !excludeKeys.includes(key)));

      console.log(data);

      let planArr = [];
      let designArr = [];
      let developArr = [];
      let cooperationArr = [];
      let otherArr = [];

      const today = new Date();
      const plan = {
        id: 'plan',
        name: '',
        name2: '기획',
        type: 'project',
        taskType: 0,
        start: today,
        end: today,
        progress: 0,
        hideChildren: false,
      };

      const design = {
        id: 'design',
        name: '',
        name2: '디자인',
        type: 'project',
        taskType: 0,
        start: today,
        end: today,
        progress: 0,
        hideChildren: false,
      };

      const develop = {
        id: 'develop',
        name: '',
        name2: '개발',
        type: 'project',
        taskType: 0,
        start: today,
        end: today,
        progress: 0,
        hideChildren: false,
      };

      const cooperation = {
        id: 'cooperation',
        name: '',
        name2: '협업',
        type: 'project',
        taskType: 0,
        start: today,
        end: today,
        progress: 0,
        hideChildren: false,
      };

      const other = {
        id: 'other',
        name: '',
        name2: '기타',
        type: 'project',
        taskType: 0,
        start: today,
        end: today,
        progress: 0,
        hideChildren: false,
      };

      data.one.forEach((item, index) => {
        const start = new Date(setDateFormat(item.issueStartDt, 'YYYY-MM-DD'));
        const end = new Date(setDateFormat(item.issueEndDt, 'YYYY-MM-DD'));
        const today = new Date();

        let objTask = {
          id: '' + index,
          name: item.issueNm,
          issueSn: item.issueSn,
          chkNm: item.chkList.length > 1 ? item.chkList[0] + '외 ' + (item.chkList.length - 1) + '명' : item.chkList[0],
          mngrNm:
            item.mngrList.length > 1 ? item.mngrList[0] + '외 ' + (item.mngrList.length - 1) + '명' : item.mngrList[0],
          stts: item.stts,

          start: start,
          end: end,
          difference: Math.abs(differenceInDays(start, end)),
          taskType: 1,
          styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
          progress: Math.max(0, Math.min(100, ((today - start) / (end - start)) * 100)),
          type: 'task',
          isDisabled: true,
          project:
            item.type === 1
              ? 'plan'
              : item.type === 2
              ? 'design'
              : item.type === 3
              ? 'develop'
              : item.type === 4
              ? 'cooperation'
              : 'other',
        };

        if (item.type === 1) {
          planArr.push(objTask);
        } else if (item.type === 2) {
          designArr.push(objTask);
        } else if (item.type === 3) {
          developArr.push(objTask);
        } else if (item.type === 4) {
          cooperationArr.push(objTask);
        } else if (item.type === 5) {
          otherArr.push(objTask);
        }
      });

      arrTasks.push(plan);
      if (planArr.length > 0) {
        arrTasks.push(...planArr);
      }

      arrTasks.push(design);
      if (designArr.length > 0) {
        arrTasks.push(...designArr);
      }

      arrTasks.push(develop);
      if (developArr.length > 0) {
        arrTasks.push(...developArr);
      }

      arrTasks.push(cooperation);
      if (cooperationArr.length > 0) {
        arrTasks.push(...cooperationArr);
      }

      arrTasks.push(other);
      if (otherArr.length > 0) {
        arrTasks.push(...otherArr);
      }

      setTasks(arrTasks);
    }
  }, [resultData]);

  const onDateChange = (task) => {
    console.log('task', task);
    const updatedTasks = tasks.map((t) => (t.id === task.id ? task : t));
    setTasks(updatedTasks);
  };

  const tooltipContent = (tasks) => {
    if (tasks.task.taskType !== 0) {
      return (
        <div className="Tooltip">
          <b>{tasks.task.name}</b>
          <p>시작일 : {setDateFormat(tasks.task.start, 'YYYY-MM-DD')}</p>
          <p>완료일 : {setDateFormat(tasks.task.end, 'YYYY-MM-DD')}</p>
        </div>
      );
    }
  };

  const onExpanderClick = (task) => {
    const hideChildren = task.taskType === 0 ? !task.hideChildren : !task.hideSubChildren;

    const updatedTasks = tasks.map((t) => {
      if (t.id === task.id) {
        return task.taskType === 0
          ? { ...t, hideChildren: !t.hideChildren }
          : { ...t, hideSubChildren: !t.hideSubChildren };
      }
      return t;
    });

    const finalTasks = updatedTasks.map((t) => {
      if (task.taskType === 0 && t.project === task.id) {
        return { ...t, hide: hideChildren };
      }
      return t;
    });

    setTasks(finalTasks);
  };

  const taskFilter = tasks.filter((task) => !task.hide);

  const taskListHeader = () => {
    return (
      <Table className="table-dash" sx={{ width: '800px' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: taskHeadColumns[0] }} align="center">
              이슈명(총 {tasks.length - 5}건)
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[1] }} align="center">
              담당자
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[2] }} align="center">
              검수자
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[3] }} align="center">
              상태
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[4] }} align="center">
              시작일
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[5] }} align="center">
              완료일
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[6] }} align="center">
              기간
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    );
  };

  const handleMoveDetail = (value) => {
    dispatch(moveIssue(value));
    navigate('/mng/project/detail/issue?type=C');
  };

  const taskListTable = ({ tasks }) => {
    return (
      <div>
        <Table className="table-dash">
          <TableBody>
            {tasks.map((item, index) => {
              let arrow = '';
              let pading = '';
              let style = {};

              const handleArrowClick = (e) => {
                e.stopPropagation();
                onExpanderClick(item);
              };

              if (item.taskType === 0) {
                pading = '0px !important';
                style = { fontSize: '.6rem', padding: '.15rem .2rem 0', cursor: 'pointer' };

                arrow = item.hideChildren ? (
                  <IconButton onClick={handleArrowClick}>
                    <ArrowRight fontSize="medium" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleArrowClick}>
                    <ArrowDropDown fontSize="medium" />
                  </IconButton>
                );
              } else {
                pading = '70px !important';
                style = {};
              }

              return (
                <TableRow
                  key={index}
                  onClick={() => {
                    if (item.taskType !== 0) {
                      return handleMoveDetail(item.issueSn);
                    }
                  }}
                  sx={{ cursor: item.taskType !== 0 ? 'pointer' : 'default' }}
                >
                  {item.taskType === 0 ? (
                    <TableCell sx={{ width: taskBodyColumns[0], paddingLeft: pading }}>
                      <span style={style} onClick={() => onExpanderClick(item)}>
                        {arrow}
                      </span>
                      {item.name2}
                    </TableCell>
                  ) : (
                    <TableCell sx={{ width: taskBodyColumns[0], paddingLeft: pading }}>{item.name}</TableCell>
                  )}
                  <TableCell sx={{ width: taskBodyColumns[1] }} align="center">
                    {item.mngrNm}
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[2] }} align="center">
                    {item.chkNm}
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[3] }} align="center">
                    {item.taskType !== 0 && (
                      <Chip
                        label={
                          item.stts === 1
                            ? '신규'
                            : item.stts === 2
                            ? '처리중'
                            : item.stts === 3
                            ? '지연'
                            : item.stts === 4
                            ? '협의중'
                            : item.stts === 5
                            ? '철회'
                            : '완료'
                        }
                        color={
                          item.stts === 1
                            ? 'primary'
                            : item.stts === 2
                            ? 'success'
                            : item.stts === 3
                            ? 'warning'
                            : item.stts === 4
                            ? 'secondary'
                            : item.stts === 5
                            ? 'error'
                            : 'default'
                        }
                      />
                    )}
                  </TableCell>

                  <TableCell sx={{ width: taskBodyColumns[4] }} align="center">
                    <div>{item.taskType !== 0 && setDateFormat(item.start, 'YYYY-MM-DD')}</div>
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[5] }} align="center">
                    <div>{item.taskType !== 0 && setDateFormat(item.end, 'YYYY-MM-DD')}</div>
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[6] }} align="center">
                    <div>{item.difference}</div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <>
      <Grid container>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {tasks.length > 0 && (
            <StyledGantt>
              <Gantt
                tasks={taskFilter}
                TaskListHeader={taskListHeader}
                TaskListTable={taskListTable}
                viewMode={view}
                locale="ko"
                onDateChange={onDateChange}
                TooltipContent={tooltipContent}
                onExpanderClick={onExpanderClick}
                rowHeight={50.5}
              />
            </StyledGantt>
          )}
        </Container>
      </Grid>
    </>
  );
};

export default PageProjectIssueChart;
