import React, { useCallback, useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Chip,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EventNoteIcon from '@mui/icons-material/EventNote';
import MainProjectList from './MainProjectList';
import MainIssueTable from './MainIssueTable';
import { useDispatch } from 'react-redux';
import { getDashboardInfo, getDashboardInfoD, getDashboardInfoE } from '@store/actions/mainAction';
import { showToastError } from '@common/common';
import { useSelector } from 'react-redux';
import MainSalesRecognitionChart from './MainSalesRecognitionChart';
import { MainSalesData } from './MainSalesData';

export default function Main() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  const [resultData, setResultData] = useState([]);

  useEffect(() => {
    if (user.userFlag === 'D' || user.userFlag === 'C') {
      dispatch(
        getDashboardInfoD({
          userSn: user.userSn,
          userId: user.userId,
          userFlag: user.userFlag,
          callback: getDashboardInfoCallback,
        })
      );
    } else {
      dispatch(
        getDashboardInfoE({
          userSn: user.userSn,
          userId: user.userId,
          callback: getDashboardInfoCallback,
        })
      );
    }
  }, []);

  const getDashboardInfoCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data.data.dataList);
      setResultData(data.data);
    } else {
      showToastError(data);
    }
  };

  return (
    <>
      <MainProjectList data={resultData.dataList} />
      {user.userFlag !== 'E' && (
        <MainIssueTable issueData={resultData.issueList} issueCommentData={resultData.commentList} />
      )}
      {user.userFlag === 'D' && (
        <>
          <MainSalesRecognitionChart resultData={resultData} />
          <MainSalesData resultData={resultData} />
        </>
      )}
    </>
  );
}
