import { setBrdtFormat, setDateFormat, showToast } from '@common/common';
import { Close, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { getInptPrsnList } from '@store/actions/projectAction';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';

const PageProjectIssuePrsnModal = (prop) => {
  const { titleNm, open, close, projectSn, getInptPrsnSn, prsnList } = prop;
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();

  const [allData, setAllData] = useState([]);

  const [filterData, setFilterData] = useState([]);

  const fn_searchResult = (data) => {
    if (data.resultCode === 0) {
      if (data.data.length > 0) {
        let datas = [];

        data.data.forEach((e) => {
          if (e.inptEndDt > setDateFormat(new Date(), 'YYYY-MM-DD')) {
            datas.push(e);
          }
        });

        let sortData = datas.sort((a, b) => new Date(b.inptEndDt) - new Date(a.inptEndDt));
        setAllData(sortData);

        if (prsnList.length > 0) {
          sortData = sortData.filter((option) => !prsnList.some((item) => option.inptPrsnSn === item.inptPrsnSn));
          setSaveList(prsnList);
        }

        setFilterData(sortData);
      }
    }
  };

  useEffect(() => {
    dispatch(
      getInptPrsnList({
        prjcMainSn: projectSn,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        useType: 2,
        pageNum: 0,
        callback: fn_searchResult,
      })
    );
  }, []);

  const [saveList, setSaveList] = useState([]);

  const handleInpt = (event, prsn) => {
    setSaveList((prev) => {
      const itemExists = prev.some((item) => item.inptPrsnSn === prsn.inptPrsnSn);
      if (!itemExists) {
        setAutocompleteKey((auto) => auto + 1);
        return [...prev, prsn];
      } else {
        return [...prev];
      }
    });
  };

  useEffect(() => {
    setFilterData(allData.filter((option) => !saveList.some((item) => option.inptPrsnSn === item.inptPrsnSn)));
  }, [saveList]);

  const handleDeletePrsn = (value) => {
    console.log(value);
    setSaveList(saveList.filter((item) => item.inptPrsnSn !== value));
  };

  const handleSavePrsn = () => {
    if (saveList.length > 0) {
      getInptPrsnSn(saveList);
    } else {
      showToast(titleNm + '를 선택해주세요.');
    }
  };

  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
        <DialogTitle>
          <Button className="btn-close">
            <Close onClick={() => close(false)} />
          </Button>
          <Typography className="modal-tit" fontWeight={'bold'}>
            {titleNm}
          </Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            담당자 선택 후 적용 버튼을 눌러주세요.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {filterData.length === 0 ? (
              <TextField
                fullWidth
                size="small"
                disabled
                value="데이터가 없습니다"
                className="inp-round"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Autocomplete
                key={autocompleteKey}
                id="combo-box-demo"
                options={filterData}
                fullWidth
                value={selectedOption}
                onChange={handleInpt}
                getOptionLabel={(option) =>
                  `${option.userNm} ${option.gender} (${option.age}세) / ${option.position} / ${option.rank}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="담당자 이름을 검색해주세요"
                    className="inp-round"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={option.inptPrsnSn || `${option.userNm}-${option.age}-${option.position}-${option.rank}`}
                  >
                    {`${option.userNm} ${option.gender} (${option.age}세) / ${option.position} / ${option.rank}`}
                  </li>
                )}
              />
            )}
          </Grid>
          <Grid container>
            {saveList.length > 0 &&
              saveList.map((item) => (
                <Grid key={item.inptPrsnSn} item display={'flex'} alignItems={'center'} mx={1}>
                  <Typography>{item.userNm}</Typography>
                  <IconButton color="error" onClick={() => handleDeletePrsn(item.inptPrsnSn)}>
                    <Close />
                  </IconButton>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'center'}>
            <Grid item md={5}>
              <Button className="btn-navy" onClick={() => handleSavePrsn()} sx={{ width: '100%' }}>
                적용
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PageProjectIssuePrsnModal;
