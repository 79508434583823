import { Add, Apartment, Book, BusinessCenter, CalendarMonth, Computer, Paid, Search } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, IconButton, Typography, Box, Chip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { putDnd, getSalesLog, getSalesDtl } from '@store/actions/salesAction';
import { setNumberComma, showToast, showToastError, setDateFormat, isValue } from '@common/common';
import { SALES_STEP_COLORS, SALES_PRRT_COLORS } from '@common/constants';
import { useSelector } from 'react-redux';
import PageSalesHistory from './PageSalesHistory';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { format, parse } from 'date-fns';

const PageSalesMainList = ({ rows, setRows, setUpdOpen, handleRegOpen, type, setSlsStgSn, setSlsPrrtSn, setSalesData }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  console.log('rows', rows);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [historyData, setHistoryData] = useState('');
   const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event, salesSn) => {
    setHistoryData('');
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    dispatch(
      getSalesLog({
        salesSn: salesSn,
        userId: user.userId,
        userSn: user.userSn,
        callback: handleGetCntrHistoryCallback,
      })
    );
  };

  const handleGetCntrHistoryCallback = (data) => {
    if (data.resultCode === 0) {
      setHistoryData(data);
    } else {
      //showToast(data.resultCode + ':' + data.resultMsg);
    }
  };

  const handleSalesDetail = (salesSn) => {
    dispatch(
      getSalesDtl({
        userId: user.userId,
        userSn: user.userSn,
        salesSn,
        callback: handleGetSalesDtlCallback,
      })
    );
  };

  const handleGetSalesDtlCallback = (data) => {
    if (data.resultCode === 0) {
      const processedData = Object.entries(data.data).map(([key, value]) => {

        if(key === 'slsDt' && isValue(value)) {
          const date = parse(value, 'yyyyMMdd', new Date());
          value = format(date, 'yyyy-MM-dd');
        }
        return [key, value];
      });
      setSalesData(Object.fromEntries(processedData));
      setUpdOpen(true);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let draggableId = result.draggableId;
    let splitId = draggableId.split('-');
    let destinationId = type === 1 ? result.destination.droppableId.split('-')[0] : result.destination.droppableId.split('-')[1];

    const sourceRowId = result.source.droppableId;
    const destinationRowId = result.destination.droppableId;
    const sourceRowIndex = rows.findIndex((row) => row.id === sourceRowId);
    const destinationRowIndex = rows.findIndex((row) => row.id === destinationRowId);

    // If dragged within the same row
    if (sourceRowId === destinationRowId) {
      const reorderedItems = Array.from(rows[sourceRowIndex].items);
      const [movedItem] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, movedItem);

      const updatedRows = [...rows];
      updatedRows[sourceRowIndex] = { ...rows[sourceRowIndex], items: reorderedItems };

      setRows(updatedRows);
    } else {
      // If dragged to a different row
      const movedItem = rows[sourceRowIndex].items[result.source.index];
      const updatedSourceItems = Array.from(rows[sourceRowIndex].items);
      updatedSourceItems.splice(result.source.index, 1);

      const updatedDestinationItems = Array.from(rows[destinationRowIndex].items);
      updatedDestinationItems.splice(result.destination.index, 0, movedItem);

      const updatedRows = [...rows];
      updatedRows[sourceRowIndex] = { ...rows[sourceRowIndex], items: updatedSourceItems };
      updatedRows[destinationRowIndex] = { ...rows[destinationRowIndex], items: updatedDestinationItems };

      setRows(updatedRows);
    }

    dispatch(
      putDnd({
        salesSn: splitId[0],
        userSn: user.userSn,
        sn: destinationId,
        userId: user.userId,
        idx: result.destination.index + 1,
        type: type,
        callback: (data) => {
          if(data.resultCode === 0) {
          } else {
            showToastError(data);
          }
        },
      })
    );
  };

  const scrollContainerRef = useRef(null);

  const buttonClick = () =>{
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 5000; // Adjust this value as needed
    }
  }

  const clickRegOpen = (id) => {
    let arrId = id.split('-');
    setSlsStgSn(arrId[0]);
    setSlsPrrtSn(arrId[1])
    handleRegOpen('list');
  }

  return (
    <Grid style={{ overflowX: 'auto' }} mt={2} ref={scrollContainerRef}>
      <div style={{  paddingLeft: '10px', paddingRight: '10px', width: 'max-content' }} > 
        <DragDropContext onDragEnd={handleDragEnd}>
          <Grid container spacing={1}> 
            {rows &&
              rows.length > 0 &&
              rows.map((row, index) => (
                <Grid key={index} item style={{ width: '355px', marginTop: '0px', marginRight: '24px', marginLeft: '24px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      borderBottom: 1,
                      //borderColor: SALES_STEP_COLORS[index % SALES_STEP_COLORS.length],
                      borderWidth: 'medium',
                    }}
                    style={{
                      borderColor: type === 1
                      ? SALES_STEP_COLORS[index % SALES_STEP_COLORS.length]
                      : type === 2
                      ? SALES_PRRT_COLORS[index % SALES_PRRT_COLORS.length]
                      : '#ffffff'
                    }}
                    p={1}
                  >
                    <Typography variant="h6" fontWeight={'bold'}>
                      {row.nm}
                    </Typography>
                    <Chip label={row.items.length} variant="outlined"/>
                  </Box>    
                  <Droppable key={index} droppableId={row.id}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          minHeight: '0px',
                          padding: '26px 0px 5px 0px',
                          width: '100%',
                        }}>
                        {row.items.map((item, idx) => ( 
                          <Draggable key={idx} draggableId={item.id + '-' + index+idx} index={idx}>
                            {(provided) => (
                              <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                elevation={3}
                                style={{
                                  userSelect: 'none',
                                  padding: '16px',
                                  minHeight: '50px',
                                  backgroundColor: '#fff',
                                  color: '#333',
                                  border: '1px solid lightgrey',
                                  marginBottom : '16px', 
                                  padding : '16px', 
                                  borderRadius : '15px !important',
                                  ...provided.draggableProps.style,
                                }}
                                onClick={() => handleSalesDetail(item.salesSn)}>
                                <CardContent>
                                  <Grid container justifyContent={'space-between'}>
                                    <Chip label={type === 1 ? item.prrtNm : item.stgNm} variant="outlined" sx={{ 
                                      borderColor: 
                                      type === 1
                                        ? SALES_PRRT_COLORS[item.prrt - 1]
                                        : type === 2
                                        ? SALES_STEP_COLORS[item.stg - 1]
                                        : '#ffffff' // 기본값                            
                                    }}
                                    />
                                    <IconButton onClick={(e) => handleClick(e, item.salesSn)}>
                                      <Book sx={{ color: '#ffb300' }} />
                                    </IconButton>
                                    <PageSalesHistory
                                      anchorEl={anchorEl}
                                      id={id}
                                      open={open}
                                      handleClose={handleClose}
                                      data={historyData}
                                    />
                                  </Grid>

                                  <Grid container>
                                    <Typography gutterBottom component="div" sx={{fontSize : '1rem', marginBottom : '0.7rem', fontWeight : '700'}}>
                                      {item.slsNm}
                                    </Typography>
                                  </Grid>
                                  <Grid container>
                                    <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                      <Apartment />
                                      <Typography pl={2} variant="body2">
                                        {item.coNm}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} mb={1}>
                                      <Paid />
                                      <Typography pl={2} variant="body2" noWrap title={'30,0000000'}>
                                        {setNumberComma(item.payment)}&nbsp;원
                                      </Typography>
                                    </Grid>
                                    <Grid item md={12} display={'flex'} alignItems={'center'} mb={1}>
                                      <CalendarMonth />
                                      <Typography pl={2} variant="body2">
                                      {setDateFormat(item.slsDt, 'YYYY-MM-DD')}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'}>
                                      <BusinessCenter />
                                      <Typography pl={2} variant="body2">
                                        {item.slsMngr === '' ? '-' : item.slsMngr}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Grid item md={12} my={2}>
                      <Button variant="outlined" fullWidth 
                      onClick={() => clickRegOpen(row.id)}
                      sx={{ 
                        margin: 'none',
                        padding: '10px 15px',
                        borderRadius: '15px',
                        borderColor: 'darkgrey', 
                        color: '#6a6a6a',
                        }} color="inherit">
                        <Add />
                      </Button>
                    </Grid>                   
                </Grid>
              ))}
          </Grid>
        </DragDropContext>
      </div>
    </Grid>
  );
};

export default PageSalesMainList;
