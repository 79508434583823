import { showToast, showToastError } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { delCntrUseYn, getDelCntrList } from '@store/actions/contractAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageContractDeleteModal = ({ deleteOpen, setDeleteOpen, setIsSuccessYn, isSuccessYn }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [deletePop, setDeletePop] = useState(false);
  const [delCntrList, setDelCntrList] = useState([]);
  const [deleteSnList, setDeleteSnList] = useState([]);

  useEffect(() => {
    dispatch(
      getDelCntrList({
        userId: user.userId,
        userSn: user.userSn,
        callback: handleGetDelCntrListCallback,
      })
    );
  }, []);

  const handleGetDelCntrListCallback = (data) => {
    if (data.resultCode === 0) {
      setDelCntrList(data.data.cntrSmplList);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg);
    }
  };

  const handleCheckChange = (e) => {
    if (e.target.checked) {
      setDeleteSnList((prevList) => [...prevList, e.target.value]);
    } else {
      setDeleteSnList((prevList) => prevList.filter((item) => item !== e.target.value));
    }
  };

  const handleConfirmDeleteCntr = () => {
    if (deleteSnList.length <= 0) {
      return showToast('삭제할 계약 정보를 선택해주세요.');
    }

    setDeletePop(true);
  };
  const handleDeleteCntr = () => {
    dispatch(
      delCntrUseYn({
        cntrSnList: deleteSnList,
        userId: user.userId,
        userSn: user.userSn,
        callback: handelDelCntrUseYnCallback,
      })
    );
  };

  const handelDelCntrUseYnCallback = (data) => {
    if (data.resultCode === 0) {
      showToast(`${deleteSnList.length}건이 삭제되었습니다.`);
      setDeleteOpen(false);
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError(data);
    }
  };
  return (
    <Dialog open={deleteOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title">
        <Button className="btn-close">
          <Close onClick={() => setDeleteOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          계약 프로젝트 삭제
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          삭제할 계약 정보를 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ height: '380px', padding: '0 40px' }}>
        {delCntrList ? (
          delCntrList.map((item, index) => (
            <Grid container key={item.cntrSn} alignItems={'center'} py={2} borderBottom={'1px solid #ccc'}>
              <Grid item md={2}>
                <Checkbox value={item.cntrSn} onChange={handleCheckChange} />
              </Grid>
              <Grid item md={10}>
                <Box component={'div'}>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.prjcNm}
                  </Typography>
                  <Typography variant="body2">{item.clntCoNm}</Typography>
                </Box>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid container key={item.cntrSn} alignItems={'center'} justifyContent={'center'} py={2}>
            <Box component={'div'}>
              <Typography variant="body2" fontWeight={'bold'}>
                등록된 계약 프로젝트가 없습니다.
              </Typography>
            </Box>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button className="btn-white" onClick={() => setDeleteOpen(false)} sx={{ width: '100%' }}>
          취소
        </Button>
        <Button className="btn-navy" onClick={handleConfirmDeleteCntr} sx={{ width: '100%' }}>
          {deleteSnList.length}건 삭제
        </Button>
      </DialogActions>
      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'선택한 프로젝트를 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없으며,\n정산 정보도 함께 삭제됩니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDeleteCntr}
        />
      )}
    </Dialog>
  );
};

export default PageContractDeleteModal;
