export const GET_SALES_LIST = 'GET_SALES_LIST';
export const GET_STG_LIST = 'GET_STG_LIST';
export const GET_PRRT_LIST = 'GET_PRRT_LIST';
export const POST_SALES = 'POST_SALES';
export const DEL_SALES = 'DEL_SALES';
export const GET_SALES_DTL = 'GET_SALES_DTL';
export const GET_SALES_NM_LIST = 'GET_SALES_NM_LIST';
export const PUT_DND = 'PUT_DND';
export const PUT_PRRT_LIST = 'PUT_PRRT_LIST';
export const PUT_SALES_DTL = 'PUT_SALES_DTL';
export const GET_CHK_STG_DEL = 'GET_CHK_STG_DEL';
export const GET_CHK_PRRT_DEL = 'GET_CHK_PRRT_DEL';
export const PUT_STG_LIST = 'PUT_STG_LIST';
export const GET_SALES_LOG = 'GET_SALES_LOG';
export const GET_CLNT_CO_NM_LIST = 'GET_CLNT_CO_NM_LIST';
export const GET_SALES_DTL_SUCCESS = 'GET_SALES_DTL_SUCCESS';
export const TEST_PRJC_TASK_LIST = 'TEST_PRJC_TASK_LIST';

export const getSalesList = (payload) => {
  return { type: GET_SALES_LIST, payload };
};

export const getStgList = (payload) => {
  return { type: GET_STG_LIST, payload };
};

export const getPrrtList = (payload) => {
  return { type: GET_PRRT_LIST, payload };
};

export const postSales = (payload) => {
  return { type: POST_SALES, payload };
};

export const delSales = (payload) => {
  return { type: DEL_SALES, payload };
};

export const getSalesDtl = (payload) => {
  return { type: GET_SALES_DTL, payload };
};

export const getSalesNmList = (payload) => {
  return { type: GET_SALES_NM_LIST, payload };
};

export const putDnd = (payload) => {
  return { type: PUT_DND, payload };
};

export const putPrrtList = (payload) => {
  return { type: PUT_PRRT_LIST, payload };
};

export const putSalesDtl = (payload) => {
  return { type: PUT_SALES_DTL, payload };
};

export const getChkStgDel = (payload) => {
  return { type: GET_CHK_STG_DEL, payload };
};

export const getChkPrrtDel = (payload) => {
  return { type: GET_CHK_PRRT_DEL, payload };
};

export const putStgList = (payload) => {
  return { type: PUT_STG_LIST, payload };
};

export const getSalesLog = (payload) => {
  return { type: GET_SALES_LOG, payload };
};

export const getClntCoNmList = (payload) => {
  return { type: GET_CLNT_CO_NM_LIST, payload };
};

export const getSalesDtlSuccess = (payload) => {
  return { type: GET_SALES_DTL_SUCCESS, payload };
};

export const testPrjcTaskList = (payload) => {
  return { type: TEST_PRJC_TASK_LIST, payload };
};


