import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import DaumPostcode from 'react-daum-postcode';
import React from 'react';
import { Close } from '@mui/icons-material';

const DaumPostCode = ({ isOpen, setIsOpen, handleZipSearch }) => {
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title">
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          우편번호 검색
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DaumPostcode onComplete={handleZipSearch} />
      </DialogContent>
    </Dialog>
  );
};

export default DaumPostCode;
