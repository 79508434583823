import { setDateFormat, showToast, showToastError } from '@common/common';
import {
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { getPrjcTaskList, getPrjcTaskPrsnDtl, putPrjcTask } from '@store/actions/projectAction';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import PageProjectPrntTaskModal from './PageProjectPrntTaskModal';
import { Gantt, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale/ko';
import PageProjectGrpTaskModal from './PageProjectGrpTaskModal';
import PageProjectChldTaskModal from './PageProjectChldTaskModal';
import PageLoadDirectPrsnModal from './PageLoadDirectPrsnModal';
import PageProjectTaskUpdProgressModal from './PageProjectTaskUpdProgressModal';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import styled from 'styled-components';
import moment from 'moment';
import { debounce } from 'lodash';

const StyledGantt = styled.div`
  ._2dZTy {
    fill: #ffffff;
  }
`;

const PageProjectTaskGanttChart = ({ isSuccess, setIsSuccess, id, puserSn }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const pUser = useSelector((state) => state.projectReducer.project);
  // const { id } = useParams();
  const [taskList, setTaskList] = useState([]);
  const [detailSn, setDetailSn] = useState('');
  const [isGrpTaskOpen, setIsGrpTaskOpen] = useState(false);
  const [isPrntTaskOpen, setIsPrntTaskOpen] = useState(false);
  const [isChldTaskOpen, setIsChldTaskOpen] = useState(false);
  const [isPrsnOpen, setIsPrsnOpen] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [prsnData, setPrsnData] = useState({});
  const [view, setView] = useState(ViewMode.Day);

  const taskHeadColumns = ['25%', '12.5%', '12.5%', '10%', '8%', '12.5%', '12.5%', '12.5%'];
  const taskBodyColumns = ['25%', '12.5%', '12.5%', '10%', '8%', '12.5%', '12.5%', '12.5%'];

  useEffect(() => {
    if (pUser && pUser.puserSn) {
      dispatch(
        getPrjcTaskList({
          userSn: user.userSn,
          userId: user.userId,
          puserSn: pUser.puserSn,
          prjcMainSn: id,
          prjcGrpSn: 0,
          callback: hadnleGetPrjcTaskListCallback,
        })
      );
    }

    const selected = document.querySelector('._2k9Ys');
    if (selected) {
      selected.style.marginLeft = '850px';
    }
  }, [isSuccess, pUser]);

  const handlePrjcTaskDetail = (type, grpSn, taskSn) => {
    // setIsPrntTaskOpen(true);
    if (type === 0) {
      setIsGrpTaskOpen(true);
      setDetailSn(grpSn);
    } else if (type === 1) {
      setIsPrntTaskOpen(true);
      setDetailSn(taskSn);
    } else {
      setIsChldTaskOpen(true);
      setDetailSn(taskSn);
    }
  };
  // useEffect(() => {
  //   dispatch(
  //     testPrjcTaskList({
  //       userSn: 1,
  //       userId: 'admin',
  //       prjcMainSn: 24,
  //       dvlpCoSn: 0,
  //       prjcGrpSn: 0,
  //       callback: handletestPrjcTaskListCallback,
  //     })
  //   );

  //   const selected = document.querySelector('._2k9Ys');
  //   if (selected) {
  //     selected.style.marginLeft = '500px';
  //   }
  // }, []);

  const hadnleGetPrjcTaskListCallback = (data) => {
    let arrTasks = [];
    if (data.resultCode === 0) {
      let res = data.data;
      let arrStDt = '';
      let arrEndDt = '';
      let prjcGrpSn = 0;
      let grpIndex = 0;

      res.forEach((item, index) => {
        arrStDt = item.stDt.split('-');
        arrEndDt = item.endDt.split('-');
        let objTask = {};

        objTask = {
          id: '' + index,
          name: item.taskNm,
          prjcTaskSn: item.prjcTaskSn,
          prjcGrpSn: item.prjcGrpSn,
          dvlpNm: item.dvlpNm,
          prsnNm: item.prsnCnt - 1 > 0 ? item.prsnNm + '외 ' + (item.prsnCnt - 1) + '명' : item.prsnNm,
          stts: item.stts,
          progress: item.prgr,
          prntTaskSn: item.prntTaskSn,

          start: new Date(parseInt(arrStDt[0], 10), parseInt(arrStDt[1], 10) - 1, parseInt(arrStDt[2], 10)),
          end: new Date(parseInt(arrEndDt[0], 10), parseInt(arrEndDt[1], 10) - 1, parseInt(arrEndDt[2], 10)),
          prd: item.prd,
          displayOrder: index + 1,
          taskType: item.type,
          modiYn: item.modiYn,
          isDisabled: item.modiYn === 'N' ? true : false,
          styles: {
            progressColor: '#ffbb54',
            progressSelectedColor: '#ff9e0d',
          },
        };

        if (item.type == 0) {
          objTask.hideChildren = false;
          objTask.type = 'project';
          objTask.className = 'type-0';
        } else if (item.type == 1) {
          objTask.hideSubChildren = false;
          objTask.project = '' + grpIndex;
          objTask.type = 'task';
          objTask.className = 'type-1';
        } else {
          objTask.project = '' + grpIndex;
          objTask.type = 'task';
          objTask.className = 'type-2';
        }

        if (item.prjcGrpSn !== prjcGrpSn) grpIndex = index;
        if (item.prjcGrpSn != prjcGrpSn) prjcGrpSn = item.prjcGrpSn;

        arrTasks.push(objTask);
      });

      setTasks(arrTasks);
    } else {
      showToastError(data.resultCode + ':' + data.resultMsg);
    }
  };

  const [prjcTaskSn, setPrjcTaskSn] = useState('');
  const handlePrsnDetail = (prjcTaskSn) => {
    setPrjcTaskSn(prjcTaskSn);
    dispatch(
      getPrjcTaskPrsnDtl({
        userSn: user.userSn,
        userId: user.userId,
        puserSn: pUser.puserSn,
        prjcTaskSn: prjcTaskSn,
        callback: handleGetPrjcTaskPrsnDtlCallback,
      })
    );
  };

  const [originalPrsnList, setOriginalPrsnList] = useState([]);
  const handleGetPrjcTaskPrsnDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setPrsnData({
        ...prsnData,
        prjcTaskPrsnList: data.data,
      });
      setIsPrsnOpen(true);
    } else {
      showToastError(data);
    }
  };

  const [isPrgrOpen, setIsPrgrOpen] = useState(false);
  const [oriPrgr, setOriPrgr] = useState('');
  const handlePrgrDetail = (prgr, prjcTaskSn) => {
    setIsPrgrOpen(true);
    setOriPrgr(prgr);
    setPrjcTaskSn(prjcTaskSn);
  };

  const taskListHeader = () => {
    return (
      <Table className="table-dash" sx={{ width: '850px' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: taskHeadColumns[0] }} align="center">
              업무명
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[1] }} align="center">
              개발사
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[2] }} align="center">
              담당자
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[3] }} align="center">
              상태
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[4] }} align="center">
              진행률
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[5] }} align="center">
              시작일
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[6] }} align="center">
              완료일
            </TableCell>
            <TableCell sx={{ width: taskHeadColumns[7] }} align="center">
              기간
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    );
  };

  const onDateChange = debounce((task) => {
    const updatedTasks = tasks.map((t) => (t.id === task.id ? task : t));
    setTasks(updatedTasks);

    dispatch(
      putPrjcTask({
        userSn: user.userSn,
        userId: user.userId,
        prjcTaskSn: task.prjcTaskSn,
        stDt: setDateFormat(task.start, 'YYYY-MM-DD'),
        endDt: setDateFormat(task.end, 'YYYY-MM-DD'),
        callback: handlePutPrjcDateTaskCallbacktest,
      })
    );
  }, 300);

  const handleDateChange = (task, date, dateType) => {
    const formattedDate = setDateFormat(date, 'YYYY-MM-DD');

    // 날짜 타입에 따라 'stdt'와 'enddt' 설정
    const isStartDate = dateType === 'stDt';
    const startDate = isStartDate ? formattedDate : setDateFormat(task.start, 'YYYY-MM-DD');
    const endDate = isStartDate ? setDateFormat(task.end, 'YYYY-MM-DD') : formattedDate;

    if (moment(startDate).isSameOrBefore(endDate)) {
      const updatedTask = { ...task, [dateType]: date };
      const updatedTasks = tasks.map((t) => (t.id === task.id ? updatedTask : t));
      setTasks(updatedTasks);

      dispatch(
        putPrjcTask({
          userSn: user.userSn,
          userId: user.userId,
          prjcTaskSn: task.prjcTaskSn,
          [dateType]: setDateFormat(date, 'YYYY-MM-DD'),
          callback: handlePutPrjcDateTaskCallback,
        })
      );
    } else {
      showToast('시작일을 종료일 보다 이전의 날짜를 선택해주세요.');
    }
  };

  const handlePutPrjcDateTaskCallbacktest = (data) => {
    if (data.resultCode === 0) {
      showToast('기간이 수정되었습니다.');
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };
  const handlePutPrjcDateTaskCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('기간이 수정되었습니다.');
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };

  const [updSttsIndex, setUpdSttsIndex] = useState(null);
  const [updPrgrIndex, setUpdPrgrIndex] = useState(null);

  const handleCellClick = (index) => {
    setUpdSttsIndex(index);
  };

  const handleSttsChange = (prjcTaskSn, value) => {
    dispatch(
      putPrjcTask({
        userSn: user.userSn,
        userId: user.userId,
        prjcTaskSn: prjcTaskSn,
        stts: value,
        callback: handlePutPrjcTaskCallback,
      })
    );
  };

  const handlePutPrjcTaskCallback = (data) => {
    if (data.resultCode === 0) {
      showToast(`상태가 저장되었습니다.`);
      setIsSuccess(!isSuccess);
      setUpdSttsIndex(null);
    } else {
      showToastError(data);
    }
  };

  const taskListTable = ({ tasks }) => {
    const [taskstest, setTasks] = useState(tasks);

    const getFontColor = (task, dateType) => {
      const taskType1EndDate = tasks.find((item) => item.taskType === 1 && item.prjcTaskSn === task.prntTaskSn)?.end; // taskType이 1인 아이템의 enddt 찾기

      const taskType1StartDate = tasks.find(
        (item) => item.taskType === 1 && item.prjcTaskSn === task.prntTaskSn
      )?.start; // taskType이 1인 아이템의 enddt 찾기

      if (task.taskType === 2) {
        if (dateType === 'end' && taskType1EndDate && moment(task.end).isAfter(moment(taskType1EndDate))) {
          return 'red'; // 시작일이 완료일보다 이후인 경우
        }
        if (dateType === 'start' && taskType1StartDate && moment(task.start).isBefore(moment(taskType1StartDate))) {
          return 'red'; // 완료일이 시작일보다 이후인 경우
        }
      }
      return 'black'; // 기본 색상
    };

    return (
      <div>
        <Table className="table-dash">
          <TableBody>
            {tasks.map((item, index) => {
              let arrow = '';
              let pading = '';
              let style = {};
              const handleArrowClick = (e) => {
                e.stopPropagation();
                onExpanderClick(item);
              };

              if (item.taskType === 0) {
                arrow = item.hideChildren ? (
                  <IconButton onClick={handleArrowClick}>
                    <ArrowRight />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleArrowClick}>
                    <ArrowDropDown />
                  </IconButton>
                );
              } else if (item.taskType === 1)
                arrow = item.hideSubChildren ? (
                  <IconButton onClick={handleArrowClick}>
                    <ArrowRight />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleArrowClick}>
                    <ArrowDropDown />
                  </IconButton>
                );
              if (item.taskType === 1) {
                pading = '10px !important';
                style = { fontSize: '.6rem', padding: '.15rem .2rem 0', cursor: 'pointer' };
              } else if (item.taskType === 2) {
                pading = '70px !important';
                style = {};
              } else {
                pading = '0px !important';
                style = { fontSize: '.6rem', padding: '.15rem .2rem 0', cursor: 'pointer' };
              }

              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: item.taskType === 0 ? '#f5f5f5' : 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <TableCell
                    sx={{ width: taskBodyColumns[0], paddingLeft: pading }}
                    onClick={() => handlePrjcTaskDetail(item.taskType, item.prjcGrpSn, item.prjcTaskSn)}
                  >
                    {(item.taskType === 0 || item.taskType === 1) && (
                      <span onClick={() => onExpanderClick(item)} style={style}>
                        {arrow}
                      </span>
                    )}
                    {item.name}
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[1] }} align="center">
                    {item.dvlpNm}
                  </TableCell>
                  <Tooltip title={item.prsnNm}>
                    <TableCell
                      sx={{ width: taskBodyColumns[2] }}
                      align="center"
                      onClick={
                        user.userFlag !== 'C' && item.prsnNm !== '' && item.modiYn === 'Y'
                          ? () => handlePrsnDetail(item.prjcTaskSn)
                          : undefined
                      }
                    >
                      {item.prsnNm}
                    </TableCell>
                  </Tooltip>
                  <TableCell
                    sx={{ width: taskBodyColumns[3] }}
                    align="center"
                    onClick={() => (item.modiYn === 'Y' ? handleCellClick(index) : undefined)}
                    // onBlur={() => setUpdSttsIndex(null)}
                  >
                    {updSttsIndex !== index
                      ? item.stts !== '' && (
                          <Chip
                            sx={{ width: '70px' }}
                            label={
                              item.stts === '0'
                                ? '대기'
                                : item.stts === '1'
                                ? '진행'
                                : item.stts === '2'
                                ? '피드백'
                                : item.stts === '3'
                                ? '완료'
                                : item.stts === '4'
                                ? '지연'
                                : '보류'
                            }
                            color={
                              item.stts === '0'
                                ? 'primary'
                                : item.stts === '1'
                                ? 'success'
                                : item.stts === '2'
                                ? 'warning'
                                : item.stts === '3'
                                ? 'secondary'
                                : item.stts === '4'
                                ? 'error'
                                : 'default'
                            }
                          />
                        )
                      : item.stts !== '' && (
                          <FormControl size="small" fullWidth>
                            <Select
                              name="stts"
                              defaultValue={item.stts}
                              onBlur={() => setUpdSttsIndex(null)}
                              onChange={(e) => handleSttsChange(item.prjcTaskSn, e.target.value)}
                              // onChange={handleSrchTypeChange}
                            >
                              <MenuItem value="">상태선택</MenuItem>
                              <MenuItem value="0">대기</MenuItem>
                              <MenuItem value="1">진행</MenuItem>
                              <MenuItem value="2">피드백</MenuItem>
                              <MenuItem value="3">완료</MenuItem>
                              <MenuItem value="4">지연</MenuItem>
                              <MenuItem value="5">보류</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                  </TableCell>

                  <TableCell
                    sx={{ width: taskBodyColumns[4] }}
                    align="center"
                    onClick={() =>
                      item.modiYn === 'Y' && item.taskType !== 0
                        ? handlePrgrDetail(item.progress, item.prjcTaskSn)
                        : undefined
                    }
                    // onBlur={() => handlePrgrChange(item.prjcTaskSn)}
                  >
                    {updPrgrIndex === index ? (
                      <TextField
                        name="prgr"
                        variant="standard"
                        value={item.progress}
                        fullWidth
                        // inputRef={inputRef}
                      />
                    ) : (
                      item.progress
                    )}
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[5] }} align="center">
                    <div>
                      {item.taskType === 0 ? (
                        setDateFormat(item.start, 'YYYY-MM-DD')
                      ) : (
                        <DatePicker
                          className="datepicker"
                          variant="standard"
                          locale={ko}
                          selected={item.start}
                          onChange={(date) => handleDateChange(item, date, 'stDt')}
                          startDate={item.start}
                          dateFormat={'yyyy-MM-dd'}
                          customInput={<input style={{ textAlign: 'center', color: getFontColor(item, 'start') }} />}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[6] }} align="center">
                    <div>
                      {item.taskType === 0 ? (
                        setDateFormat(item.end, 'YYYY-MM-DD')
                      ) : (
                        <DatePicker
                          className="datepicker"
                          variant="standard"
                          locale={ko}
                          selected={item.end}
                          onChange={(date) => handleDateChange(item, date, 'endDt')}
                          startDate={item.end}
                          dateFormat={'yyyy-MM-dd'}
                          customInput={<input style={{ textAlign: 'center', color: getFontColor(item, 'end') }} />}
                        />
                      )}{' '}
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: taskBodyColumns[7] }} align="center">
                    {item.prd}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  const tooltipContent = (tasks) => {
    return (
      <div className="Tooltip">
        <b>{tasks.task.name}</b>
        <p>{setDateFormat(tasks.task.start, 'YYYY-MM-DD')}</p>
        <p>{setDateFormat(tasks.task.end, 'YYYY-MM-DD')}</p>
      </div>
    );
  };

  const onExpanderClick = (task) => {
    const hideChildren = task.taskType === 0 ? !task.hideChildren : !task.hideSubChildren;

    const updatedTasks = tasks.map((t) => {
      if (t.id === task.id) {
        return task.taskType === 0
          ? { ...t, hideChildren: !t.hideChildren }
          : { ...t, hideSubChildren: !t.hideSubChildren };
      }
      return t;
    });

    const finalTasks = updatedTasks.map((t) => {
      if (task.taskType === 0 && t.project === task.id) {
        return { ...t, hide: hideChildren };
      } else if (task.taskType === 1 && t.prntTaskSn === task.prjcTaskSn) {
        return { ...t, hide: hideChildren };
      }
      return t;
    });

    setTasks(finalTasks);
  };

  const taskFilter = tasks.filter((task) => !task.hide);

  const handleSetPrsnInfo = () => {
    if (prsnData.prjcTaskPrsnList.length <= 0) {
      return showToast('업무 담당자를 선택해주세요.');
    }

    dispatch(
      putPrjcTask({
        userSn: user.userSn,
        userId: user.userId,
        prjcTaskSn: prjcTaskSn,
        inptPrsnSnList: prsnData.prjcTaskPrsnList.map((prsn) => prsn.inptPrsnSn),
        callback: (data) => handlePutModalPrjcTaskCallback(data, 'prsn'),
      })
    );
  };

  const handleSetPrgr = () => {
    dispatch(
      putPrjcTask({
        userSn: user.userSn,
        userId: user.userId,
        prjcTaskSn: prjcTaskSn,
        prgr: oriPrgr,
        callback: (data) => handlePutModalPrjcTaskCallback(data, 'prgr'),
      })
    );
  };

  const handlePutModalPrjcTaskCallback = (data, type) => {
    if (data.resultCode === 0) {
      showToast(`${type === 'prsn' ? '담당자가' : '진행률이'} 수정되었습니다.`);
      type === 'prsn' ? setIsPrsnOpen(false) : setIsPrgrOpen(false);
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };

  return (
    <Grid container>
      <Container maxWidth="xl" sx={{ padding: '0 !important' }}>
        {tasks.length > 0 && (
          <StyledGantt>
            <Gantt
              tasks={taskFilter}
              TaskListHeader={taskListHeader}
              TaskListTable={taskListTable}
              viewMode={view}
              viewDate={moment()}
              locale="ko"
              rowHeight={50.5}
              onDateChange={onDateChange}
              TooltipContent={tooltipContent}
              onExpanderClick={onExpanderClick}
            />
          </StyledGantt>
        )}
      </Container>
      {isGrpTaskOpen && (
        <PageProjectGrpTaskModal
          isOpen={isGrpTaskOpen}
          setIsOpen={setIsGrpTaskOpen}
          detailSn={detailSn}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
        />
      )}
      {isPrntTaskOpen && (
        <PageProjectPrntTaskModal
          isOpen={isPrntTaskOpen}
          setIsOpen={setIsPrntTaskOpen}
          detailSn={detailSn}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
        />
      )}
      {isChldTaskOpen && (
        <PageProjectChldTaskModal
          isOpen={isChldTaskOpen}
          setIsOpen={setIsChldTaskOpen}
          detailSn={detailSn}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
        />
      )}
      {isPrsnOpen && (
        <PageLoadDirectPrsnModal
          isOpen={isPrsnOpen}
          setIsOpen={setIsPrsnOpen}
          setInputs={setPrsnData}
          inputs={prsnData}
          onClick={handleSetPrsnInfo}
        />
      )}
      {isPrgrOpen && (
        <PageProjectTaskUpdProgressModal
          isOpen={isPrgrOpen}
          setIsOpen={setIsPrgrOpen}
          oriPrgr={oriPrgr}
          setOriPrgr={setOriPrgr}
          onClick={handleSetPrgr}
        />
      )}
    </Grid>
  );
};

export default PageProjectTaskGanttChart;
