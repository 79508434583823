import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast, showToastError, isNumber } from "@common/common";
import { getStgList, getChkStgDel, putStgList } from "@store/actions/salesAction";
import AddIcon from "@mui/icons-material/Add";
import Sortable from 'sortablejs';
import { AccountCircle, Lock, DragHandle } from '@mui/icons-material';

const TimerModal = ({ timerOpen, setTimerOpen, resetTimehandler }) => {
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();

 
  useEffect(() => {
 
    
  }, []);

  const handleSave = () => {

  };




  return (
    <Dialog
      open={timerOpen}
      fullWidth
      maxWidth={"xs"}
      disableEnforceFocus
      sx={{ minHeight: "500px" }}
      >
      <DialogTitle>
        <Typography className="modal-tit">시간 연장</Typography>
        {/* <Typography color={"grey"} variant="body2" mt={1}>
          시간 연장
        </Typography> */}
      </DialogTitle>
      <DialogContent>
        <Grid>
          시간을 연장 하시겠습니까?
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setTimerOpen(false)}
          className="btn-white"
          fullWidth
        >
          닫기
        </Button>
        <Button className="btn-navy" fullWidth onClick={resetTimehandler}>
          연장하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimerModal;
