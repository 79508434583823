import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageCounterPartyClient from './PageCounterPartyClient';
import PageCounterPartyDev from './PageCounterPartyDev';
const theme = createTheme();
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const PageCounterPartyMain = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false}>
        <Box>
          <Tabs className="tab" value={value} onChange={handleChange} aria-label="tabs">
            <Tab
              label="의뢰사관리"
              sx={{
                fontSize: 16,
                fontWeight: 500,

                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                },
                '&:hover': {
                  color: theme.palette.primary.dark,
                },
                '&:focus': {
                  outline: 'none',
                  color: theme.palette.primary.dark,
                },
                '&:active': {
                  color: theme.palette.primary.dark,
                },
              }}
            />
            <Tab
              label="개발사관리"
              sx={{
                fontSize: 16,
                fontWeight: 500,
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                },
                '&:hover': {
                  color: theme.palette.primary.dark,
                },
                '&:focus': {
                  outline: 'none',
                  color: theme.palette.primary.dark,
                },
                '&:active': {
                  color: theme.palette.primary.dark,
                },
              }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <PageCounterPartyClient />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PageCounterPartyDev />
          </TabPanel>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PageCounterPartyMain;
