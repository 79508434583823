import { isValue, showToast, showToastError } from '@common/common';
import styled from '@emotion/styled';
import { Close, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getInptPrsnList } from '@store/actions/projectAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PageLoadPrsnModal = ({ isOpen, setIsOpen, inputs, setInputs, setOriginalPrsnList, originalPrsnList }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [prsnList, setPrsnList] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState(0);

  useEffect(() => {
    dispatch(
      getInptPrsnList({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: id,
        useType: 2,
        callback: handleGetInptPrsnListCallback,
      })
    );
  }, [prsnList]);

  const handleGetInptPrsnListCallback = (data) => {
    if (data.resultCode === 0) {
      // setOptions(data.data);
      if (inputs.prjcTaskPrsnList && inputs.prjcTaskPrsnList.length > 0) {
        // prjcTaskPrsnList가 존재하고 비어있지 않은 경우, 필터링된 옵션을 설정
        const filteredOptions = data.data.filter(
          (option) => !inputs.prjcTaskPrsnList.some((prsn) => prsn.inptPrsnSn === option.inptPrsnSn)
        );
        setOptions(filteredOptions);
      } else {
        // prjcTaskPrsnList가 없거나 비어있는 경우, 모든 데이터를 설정
        setOptions(data.data);
      }
    } else {
      showToastError(data);
    }
  };

  const handleOptionSelected = (event, newValue) => {
    if (newValue) {
      // 선택된 옵션을 options에서 제거
      setOptions((prevOptions) => prevOptions.filter((option) => option.inptPrsnSn !== newValue.inptPrsnSn));

      // 선택된 옵션을 prsnList에 추가
      // setPrsnList((prevSetList) => [...prevSetList, newValue]);
      setInputs((prevInputs) => ({
        ...prevInputs,
        prjcTaskPrsnList: [...(prevInputs.prjcTaskPrsnList || []), newValue],
      }));
      // Autocomplete 컴포넌트 리셋
      setAutocompleteKey((prevKey) => prevKey + 1);
    }
  };

  const handleDeletePrsn = (prsnSn) => {
    setPrsnList((prevPrsnList) => prevPrsnList.filter((prsn) => prsn.inptPrsnSn !== prsnSn));
    setInputs((prevInputs) => ({
      ...prevInputs,
      prjcTaskPrsnList: prevInputs.prjcTaskPrsnList.filter((prsn) => prsn.inptPrsnSn !== prsnSn),
    }));
  };

  const handleSetPrsnInfo = () => {
    console.log(inputs.prjcTaskPrsnList);
    if (
      prsnList === '' ||
      !isValue(inputs.prjcTaskPrsnList) ||
      (inputs && Object.keys(inputs).length <= 0) ||
      (Array.isArray(inputs.prjcTaskPrsnList) && inputs.prjcTaskPrsnList.length <= 0)
    ) {
      return showToast('담당자를 선택해주세요.');
    }
    setIsOpen(false);
    setInputs({
      ...inputs,
      // prjcTaskPrsnList: prsnList,
    });
    // setOriginalPrsnList([...inputs.prjcTaskPrsnList]);
    setOriginalPrsnList([...(Array.isArray(inputs.prjcTaskPrsnList) ? inputs.prjcTaskPrsnList : [])]);
  };

  const handlePrsnClose = () => {
    setIsOpen(false);
    setInputs((prevInputs) => ({
      ...prevInputs,
      prjcTaskPrsnList: originalPrsnList,
    }));
  };
  console.log(inputs);
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={handlePrsnClose} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          업무 담당자
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          담당자 선택 후 적용 버튼을 눌러주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {options.length === 0 ? (
            <TextField
              fullWidth
              className="inp-round"
              size="small"
              disabled
              value="데이터가 없습니다"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Autocomplete
              key={autocompleteKey}
              id="combo-box-demo"
              options={options}
              fullWidth
              value={selectedOption}
              onChange={handleOptionSelected}
              getOptionLabel={(option) =>
                `${option.userNm} ${option.gender} (${option.age}세) / ${option.position} / ${option.rank}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className="inp-round"
                  size="small"
                  placeholder="담당자 이름을 검색해주세요"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.inptPrsnSn || `${option.userNm}-${option.age}-${option.position}-${option.rank}`}
                >
                  {`${option.userNm} ${option.gender} (${option.age}세) / ${option.position} / ${option.rank}`}
                </li>
              )}
            />
          )}
        </Grid>
        <Grid container>
          {inputs.prjcTaskPrsnList &&
            inputs.prjcTaskPrsnList.length > 0 &&
            inputs.prjcTaskPrsnList.map((item) => (
              <Grid key={item.inptPrsnSn} item display={'flex'} alignItems={'center'} mx={1}>
                <Typography variant="body2">{item.userNm}</Typography>
                <IconButton onClick={() => handleDeletePrsn(item.inptPrsnSn)}>
                  <Close fontSize="small" />
                </IconButton>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'center'}>
          <Grid item md={5}>
            <Button className="btn-navy" onClick={handleSetPrsnInfo} sx={{ width: '100%' }}>
              적용
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PageLoadPrsnModal;
