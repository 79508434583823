import { isComplHangul, showToast, showToastError } from '@common/common';
import { SEARCH_TIME } from '@common/constants';
import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getDvlpCoNmList, getSalesDtl, getSalesNmList } from '@store/actions/salesAction';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

function PageLoadProjectModal({ isOpen, setIsOpen, inputs, setInputs, setPrevMemo }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    handleGetSalesNmList();
  }, [dispatch]);

  const handleGetSalesNmList = (newKeyword) => {
    dispatch(
      getSalesNmList({
        userId: user.userId,
        userSn: user.userSn,
        keyword: newKeyword,
        callback: handleGetSalesNmListCallback,
      })
    );
  };

  const handleGetSalesNmListCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      setProjectList(data.data.salesNmList);
    } else {
      showToastError(data);
    }
  };

  const debouncedDispatch = useCallback(
    _.debounce((newKeyword) => {
      if (isComplHangul(newKeyword)) {
        handleGetSalesNmList(newKeyword);
      }
    }, SEARCH_TIME)
  );

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList =
      projectList &&
      projectList.length > 0 &&
      projectList.filter((data) => {
        const slsNmMatch = data.slsNm ? data.slsNm.toLowerCase().includes(newKeyword) : false;
        const coNmMatch = data.coNm ? data.coNm.toLowerCase().includes(newKeyword) : false;
        return slsNmMatch || coNmMatch;
      });

    setFilteredList(filteredList);
  };
  const displayList = keyword ? filteredList : projectList;

  const handleGetProjectDetail = (salesSn) => {
    dispatch(
      getSalesDtl({
        userId: user.userId,
        userSn: user.userSn,
        salesSn: salesSn,
        callback: handleGetProjectDetailCallback,
      })
    );
  };

  const handleGetProjectDetailCallback = (data) => {
    console.log(data);
    if (data.resultCode === 0) {
      setIsOpen(false);
      setInputs({
        ...inputs,
        prjcNm: data.data.slsNm,
        slsMngrNm: data.data.slsMngr,
        cntrAmnt: data.data.payment,
        clntCoNm: data.data.coNm,
        clntZip: data.data.zip,
        clntAddr: data.data.addr,
        clntDaddr: data.data.daddr,
        clntMngrNm: data.data.mngr,
        clntMngrTelno: data.data.mngrTelno,
        clntMngrEmail: data.data.mngrEmail,
        memoSnList: data.data.memo !== '' ? data.data.memo.map((item) => item.salesMemoSn) : '',
      });
      setPrevMemo(data.data.memo);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          가져오기
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          정보를 가져올 영업 목록을 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="프로젝트를 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onKeyUp={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="list-type" sx={{ height: '350px' }}>
          {displayList && displayList.length > 0 ? (
            displayList.map((item) => (
              <Grid container key={item.salesSn}>
                <Grid item>
                  <Typography variant="body1" fontWeight={'500'}>
                    {item.slsNm}
                  </Typography>
                  <Typography variant="body2">{item.coNm}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handleGetProjectDetail(item.salesSn)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PageLoadProjectModal;
