import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '@actions/projectAction';
import api from '@api/apiProject';

function* getPrjcMainList({ payload }) {
  const { status, data } = yield call(api.getPrjcMainList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcMainFvrtYn({ payload }) {
  const { status, data } = yield call(api.putPrjcMainFvrtYn, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getCntrNmList({ payload }) {
  const { status, data } = yield call(api.getCntrNmList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcEventList({ payload }) {
  const { status, data } = yield call(api.getPrjcEventList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcDtl({ payload }) {
  const { status, data } = yield call(api.getPrjcDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postPrjc({ payload }) {
  const { status, data } = yield call(api.postPrjc, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcMain({ payload }) {
  const { status, data } = yield call(api.putPrjcMain, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcMainDelYn({ payload }) {
  const { status, data } = yield call(api.putPrjcMainDelYn, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcMainInfo({ payload }) {
  const { status, data } = yield call(api.getPrjcMainInfo, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcGrpList({ payload }) {
  const { status, data } = yield call(api.getPrjcGrpList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcGrpDtl({ payload }) {
  const { status, data } = yield call(api.getPrjcGrpDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postPrjcGrp({ payload }) {
  const { status, data } = yield call(api.postPrjcGrp, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcGrp({ payload }) {
  const { status, data } = yield call(api.putPrjcGrp, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrntPrjcTaskList({ payload }) {
  const { status, data } = yield call(api.getPrntPrjcTaskList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postPrjcTask({ payload }) {
  const { status, data } = yield call(api.postPrjcTask, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcTaskInfo({ payload }) {
  const { status, data } = yield call(api.getPrjcTaskInfo, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcTaskList({ payload }) {
  const { status, data } = yield call(api.getPrjcTaskList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcTaskNmList({ payload }) {
  const { status, data } = yield call(api.getPrjcTaskNmList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcTaskDtl({ payload }) {
  const { status, data } = yield call(api.getPrjcTaskDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcTask({ payload }) {
  const { status, data } = yield call(api.putPrjcTask, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcTasklist({ payload }) {
  const { status, data } = yield call(api.putPrjcTaskList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcTaskPrsnDtl({ payload }) {
  const { status, data } = yield call(api.getPrjcTaskPrsnDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postPrjcEvent({ payload }) {
  const { status, data } = yield call(api.postPrjcEvent, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putPrjcEvent({ payload }) {
  const { status, data } = yield call(api.putPrjcEvent, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* delPrjcEvent({ payload }) {
  const { status, data } = yield call(api.delPrjcEvent, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getInptPrsnList({ payload }) {
  const { status, data } = yield call(api.getInptPrsnList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postInptPrsn({ payload }) {
  const { status, data } = yield call(api.postInptPrsn, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* delInptPrsn({ payload }) {
  const { status, data } = yield call(api.delInptPrsn, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getInptPrsnDtl({ payload }) {
  const { status, data } = yield call(api.getInptPrsnDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getInptPrsnUpdDtl({ payload }) {
  const { status, data } = yield call(api.getInptPrsnUpdDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putInptPrsn({ payload }) {
  const { status, data } = yield call(api.putInptPrsn, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getIssueList({ payload }) {
  const { status, data } = yield call(api.getIssueList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postIssue({ payload }) {
  const { status, data } = yield call(api.postIssue, payload);
  if (status === 200) payload.callback(data);
}

function* delIssue({ payload }) {
  const { status, data } = yield call(api.delIssue, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getIssueDtl({ payload }) {
  const { status, data } = yield call(api.getIssueDtl, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postIssueCmt({ payload }) {
  const { status, data } = yield call(api.postIssueCmt, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putIssueStts({ payload }) {
  const { status, data } = yield call(api.putIssueStts, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putIssue({ payload }) {
  const { status, data } = yield call(api.putIssue, payload);
  if (status === 200) payload.callback(data);
}

function* putInptPrsnStop({ payload }) {
  const { status, data } = yield call(api.putInptPrsnStop, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postPrjcTaskListDownload({ payload }) {
  const { status, data } = yield call(api.postPrjcTaskListDownload, { payload: payload });
  payload.callback(data, status);
}

function* getInptPrsnCheck({ payload }) {
  const { status, data } = yield call(api.getInptPrsnCheck, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* getPrjcMainNmList({ payload }) {
  const { status, data } = yield call(api.getPrjcMainNmList, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* postClntPrjc({ payload }) {
  const { status, data } = yield call(api.postClntPrjc, { payload: payload });
  if (status === 200) payload.callback(data);
}

function* putClntPrjc({ payload }) {
  const { status, data } = yield call(api.putClntPrjc, { payload: payload });
  if (status === 200) payload.callback(data);
}

// watcher
function* watchUser() {
  yield takeLatest(actions.GET_PRJC_MAIN_LIST, getPrjcMainList);
  yield takeLatest(actions.PUT_PRJC_MAIN_FVRT_YN, putPrjcMainFvrtYn);
  yield takeLatest(actions.GET_PRJC_EVENT_LIST, getPrjcEventList);
  yield takeLatest(actions.GET_PRJC_DTL, getPrjcDtl);
  yield takeLatest(actions.GET_CNTR_NL_LIST, getCntrNmList);
  yield takeLatest(actions.POST_PRJC_EVENT, postPrjcEvent);
  yield takeLatest(actions.POST_PRJC, postPrjc);
  yield takeLatest(actions.PUT_PRJC_MAIN, putPrjcMain);
  yield takeLatest(actions.PUT_PRJC_MAIN_DEL_YN, putPrjcMainDelYn);
  yield takeLatest(actions.GET_PRJC_MAIN_INFO, getPrjcMainInfo);
  yield takeLatest(actions.GET_PRJC_GRP_LIST, getPrjcGrpList);
  yield takeLatest(actions.GET_PRJC_GRP_DTL, getPrjcGrpDtl);
  yield takeLatest(actions.POST_PRJC_GRP, postPrjcGrp);
  yield takeLatest(actions.PUT_PRCJ_GRP, putPrjcGrp);
  yield takeLatest(actions.GET_PRNT_PRJC_TASK_LIST, getPrntPrjcTaskList);
  yield takeLatest(actions.POST_PRJC_TASK, postPrjcTask);
  yield takeLatest(actions.GET_PRJC_TASK_INFO, getPrjcTaskInfo);
  yield takeLatest(actions.GET_PRJC_TASK_LIST, getPrjcTaskList);
  yield takeLatest(actions.GET_PRJC_TASK_NM_LIST, getPrjcTaskNmList);
  yield takeLatest(actions.GET_PRJC_TASK_DTL, getPrjcTaskDtl);
  yield takeLatest(actions.PUT_PRCJ_TASK, putPrjcTask);
  yield takeLatest(actions.PUT_PRCJ_TASK_LIST, putPrjcTasklist);
  yield takeLatest(actions.GET_PRJC_TASK_PRSN_DTL, getPrjcTaskPrsnDtl);
  yield takeLatest(actions.PUT_PRJC_EVENT, putPrjcEvent);
  yield takeLatest(actions.DEL_PRJC_EVENT, delPrjcEvent);
  yield takeLatest(actions.GET_INPT_PRSN_LIST, getInptPrsnList);
  yield takeLatest(actions.POST_INPT_PRSN, postInptPrsn);
  yield takeLatest(actions.DEL_INPT_PRSN, delInptPrsn);
  yield takeLatest(actions.GET_INPT_PRSN_DTL, getInptPrsnDtl);
  yield takeLatest(actions.GET_INPT_PRSN_UPD_DTL, getInptPrsnUpdDtl);
  yield takeLatest(actions.PUT_INPT_PRSN, putInptPrsn);
  yield takeLatest(actions.GET_ISSUE_LIST, getIssueList);
  yield takeLatest(actions.POST_ISSUE, postIssue);
  yield takeLatest(actions.DEL_ISSUE, delIssue);
  yield takeLatest(actions.GET_ISSUE_DTL, getIssueDtl);
  yield takeLatest(actions.POST_ISSUE_CMT, postIssueCmt);
  yield takeLatest(actions.PUT_ISSUE_STTS, putIssueStts);
  yield takeLatest(actions.PUT_ISSUE, putIssue);
  yield takeLatest(actions.PUT_INPT_PRSN_STOP, putInptPrsnStop);
  yield takeLatest(actions.POST_PRJC_TASK_LIST_EXCEL, postPrjcTaskListDownload);
  yield takeLatest(actions.GET_INPT_PRSN_CHECK, getInptPrsnCheck);
  yield takeLatest(actions.GET_PRJC_MAIN_NM_LIST, getPrjcMainNmList);
  yield takeLatest(actions.POST_CLNT_PRJC, postClntPrjc);
  yield takeLatest(actions.PUT_CLNT_PRJC, putClntPrjc);
}

export default function* projectSaga() {
  yield all([fork(watchUser)]);
}
