import { DatePickerRound, ExampleCustomInput, setDateFormat, setNumberComma, showToast } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { putClctList, putClctStop } from '@store/actions/calculationAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { ko } from 'date-fns/locale/ko';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PageCalculationListUpdate = ({ updatePopup, setUpdatePopup, resultData, updateProcess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [stopConfirm, setStopConfirm] = useState(false);

  const [sendData, setSendData] = useState([]);

  const [propData, setPropData] = useState(resultData);

  const [inputData, setInputData] = useState('0');

  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    let inputCost = BigInt(0);

    propData.clctDtlList.map((e) => {
      inputCost += BigInt(e.cost);
    });

    setInputData(inputCost.toString());
  }, [propData]);

  const handleCost = (e, clctDtlSn) => {
    let inputValue = e.target.value
      .replace(/,/g, '')
      .replace(/^0+/, '')
      .replace(/[^0-9]+/g, '');

    if (inputValue === '' || /^0+$/.test(inputValue)) {
      inputValue = '0';
    }

    if (inputValue.length > 20) {
      inputValue = '99999999999999999999';
    }

    handleValues('cost', inputValue, clctDtlSn);
  };

  const [confirm, setConfirm] = useState(false);
  const handleValues = (name, value, clctDtlSn) => {
    if (name === 'clctDt' && (new Date(propData.cntrStDt) > value || new Date(propData.cntrEndDt) < value)) {
      setConfirm(true);
      return;
    }

    setPropData((prev) => {
      const dataList = prev.clctDtlList.map((item) => {
        if (item.clctDtlSn === clctDtlSn) {
          const changeData = { ...item, [name]: value, userId: user.userId, userSn: user.userSn };

          const dataList = [
            {
              clctDtlSn: changeData.clctDtlSn,
              clctDt: setDateFormat(changeData.clctDt, 'YYYY-MM-DD'),
              cost: changeData.cost,
            },
          ];

          sendData.map((e) => {
            if (e.clctDtlSn !== clctDtlSn) {
              dataList.push(e);
            }
          });

          setSendData(dataList);

          return changeData;
        } else {
          return item;
        }
      });

      return { ...prev, clctDtlList: dataList };
    });
  };

  const fn_updateResult = (data) => {
    if (data.resultCode === 0) {
      setUpdateConfirm(false);
      setUpdatePopup(false);
      showToast('정산 정보가 수정되었습니다.');
      updateProcess();
    }
  };

  const [alertConfirm, setAlertConfirm] = useState(false);

  const handleClctUpdate = () => {
    dispatch(putClctList(sendData, fn_updateResult));
  };

  const checkHandler = (e, clctDtlSn) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setCheckedList((prev) => [...prev, clctDtlSn]);

      return;
    }

    if (!isChecked && checkedList.includes(clctDtlSn)) {
      setCheckedList(checkedList.filter((item) => item !== clctDtlSn));

      return;
    }
  };

  const fn_stopResult = (data) => {
    if (data.resultCode === 0) {
      setUpdateConfirm(false);
      setUpdatePopup(false);
      showToast('정산중단 처리 되었습니다.');
      updateProcess();
    }
  };

  const handleClctStop = () => {
    dispatch(
      putClctStop({
        userId: user.userId,
        userSn: user.userSn,
        clctDtlSnList: checkedList,
        callback: fn_stopResult,
      })
    );
  };

  const handleAlert = () => {
    if (checkedList.length > 0) {
      setStopConfirm(true);
    } else {
      setAlertConfirm(true);
    }
  };

  const [updateCheckConfirm, setUpdateCheckConfirm] = useState(false);

  const handleUpdate = () => {
    if (inputData === resultData.cntrAmnt) {
      setUpdateConfirm(true);
    } else {
      setUpdateCheckConfirm(true);
    }
  };

  return (
    <>
      <Dialog
        open={updatePopup}
        fullWidth
        maxWidth={'sm'}
        disableEnforceFocus
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogTitle sx={{ padding: '30px 40px !important', pb: '0px !important' }}>
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h5" fontWeight={'bold'} mb={1}>
                  정산 수정
                </Typography>
                <Typography color={'grey'} variant="body2">
                  정산을 중단하시려면 목록을 선택 후 선택 중단 버튼을 눌러주세요. <br />
                  이미 중단되거나 완료된 항목은 중단 할 수 없습니다.
                </Typography>
              </Box>
              <Close onClick={() => setUpdatePopup(false)} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container mt={3}>
            <Grid item xs={12} borderBottom={'1px solid'} pb={2}>
              {propData &&
                propData.clctDtlList.map((item) => (
                  <Grid item xs={12} key={item.clctDtlSn} display={'flex'} alignItems={'center'} mb={1}>
                    <Grid item xs={1}>
                      <Checkbox
                        disabled={item.clctStts === '3' || item.clctStts === '4'}
                        sx={{
                          color: 'black',
                        }}
                        checked={checkedList.includes(item.clctDtlSn)}
                        onChange={(e) => checkHandler(e, item.clctDtlSn)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>{item.categoryNm}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <DatePickerWrapper>
                        <DatePicker
                          locale={ko}
                          selected={item.clctDt}
                          onChange={(e) => handleValues('clctDt', e, item.clctDtlSn)}
                          dateFormat={'YYYY-MM-dd'}
                          customInput={<DatePickerRound placehol="YYYY-MM-DD" />}
                          disabled={item.clctStts === '3' || item.clctStts === '4'}
                        />
                      </DatePickerWrapper>
                    </Grid>
                    <Grid item xs={4} ml={2}>
                      <TextField
                        className="inp-round"
                        fullWidth
                        size="small"
                        value={setNumberComma(item.cost + '')}
                        disabled={item.clctStts === '3' || item.clctStts === '4'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="subtitle2" mr={2}>
                                원
                              </Typography>
                            </InputAdornment>
                          ),
                          // readOnly: item.clctStts === '3' || item.clctStts === '4',
                          // sx: {
                          //   backgroundColor: item.clctStts === '3' || item.clctStts === '4' ? 'lightgray' : '',
                          // },
                        }}
                        onChange={(e) => handleCost(e, item.clctDtlSn)}
                        sx={{
                          '& .MuiInputBase-input': {
                            textAlign: 'right',
                          },
                        }}
                        name="cost"
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item md={12} display={'flex'} alignItems={'center'} my={1}>
              <Grid item md={3} />
              <Grid item md={9} display={'flex'} alignItems={'center'}>
                <Grid item md={5} display={'flex'} justifyContent={'end'}>
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    계약 금액
                  </Typography>
                </Grid>
                <Grid item md={7} display={'flex'} justifyContent={'end'}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    {propData && setNumberComma(propData.cntrAmnt === '' ? '0' : resultData.cntrAmnt)} 원
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} display={'flex'} alignItems={'center'}>
              <Grid item md={3} />
              <Grid item md={9} display={'flex'} alignItems={'center'}>
                <Grid item md={5} display={'flex'} justifyContent={'end'}>
                  <Typography variant="subtitle2" fontWeight={'bold'}>
                    입력 금액
                  </Typography>
                </Grid>
                <Grid item md={7} display={'flex'} justifyContent={'end'}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    {setNumberComma(inputData)} 원
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="btn-white" onClick={() => handleAlert()}>
            선택 중단
          </Button>
          <Button className="btn-navy" onClick={() => handleUpdate()}>
            수정 저장
          </Button>
        </DialogActions>
      </Dialog>

      {stopConfirm && (
        <ConfirmPopUp
          open={stopConfirm}
          close={setStopConfirm}
          title={'선택한 정산 목록을 중단 하시겠습니까?'}
          comment={'중단 후에는 되돌릴 수 없으며,\n정산 정보를 수정한 경우 수정 저장 후 중단 처리해주세요.'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handleClctStop}
        />
      )}

      {updateConfirm && (
        <ConfirmPopUp
          open={updateConfirm}
          close={setUpdateConfirm}
          title={'저장 하시겠습니까?'}
          btnNm1={'아니오'}
          btnNm2={'예'}
          btnEvent={handleClctUpdate}
        />
      )}

      {confirm && (
        <ConfirmPopUp
          open={confirm}
          close={setConfirm}
          title={'정산일은 계약 기간 내의 날짜로 선택해주세요.'}
          comment={'이 프로젝트의 계약기간은\n' + propData.cntrStDt + ' ~ ' + propData.cntrEndDt + ' 입니다.'}
          btnNm2={'확인'}
        />
      )}
      {alertConfirm && (
        <ConfirmPopUp
          open={alertConfirm}
          close={setAlertConfirm}
          title={'중단할 정산 목록을 선택해주세요.'}
          btnNm2={'확인'}
        />
      )}
      {updateCheckConfirm && (
        <ConfirmPopUp
          open={updateCheckConfirm}
          close={setUpdateCheckConfirm}
          title={'계약금액과 입력금액이 서로 다릅니다.\n정산 정보를 수정 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handleClctUpdate}
        />
      )}
    </>
  );
};

export default PageCalculationListUpdate;
