import { Box, Button, Chip, Container, Grid, IconButton, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PageProjectIssueBoardList from './PageProjectIssueBoardList';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const PageProjectIssueBoard = ({ resultData }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    touchMove: false,
    arrows: false,
  };

  const [isNext, setIsNext] = useState(true);

  const sliderRef = useRef(null);

  const goToNext = () => {
    sliderRef.current.slickNext();
    setIsNext(false);
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
    setIsNext(true);
  };

  return (
    <>
      <Grid container height={'100%'}>
        <Grid item xs={12}>
          <Slider ref={sliderRef} {...settings}>
            <Grid container mt={2} className="custom-slider">
              <Grid item xs={12} className="custom-slider">
                <Grid item xs={0.2}></Grid>
                <Grid item xs={11.9} md={3}>
                  <Container>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: 1,
                        borderColor: '#6f6f6f',
                        borderWidth: 'medium',
                      }}
                      p={1}
                    >
                      <Typography variant="h6" fontWeight={'bold'}>
                        신규
                      </Typography>
                      <Chip label={resultData.one ? resultData.one.length : 0} variant="outlined" />
                    </Box>
                    <PageProjectIssueBoardList issueList={resultData.one} />
                  </Container>
                </Grid>
                <Grid item xs={11.9} md={3}>
                  <Container>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: 1,
                        borderColor: '#6f6f6f',
                        borderWidth: 'medium',
                      }}
                      p={1}
                    >
                      <Typography variant="h6" fontWeight={'bold'}>
                        처리중
                      </Typography>
                      <Chip label={resultData.two ? resultData.two.length : 0} variant="outlined" />
                    </Box>
                    <PageProjectIssueBoardList issueList={resultData.two} />
                  </Container>
                </Grid>
                <Grid item xs={11.9} md={3}>
                  <Container>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: 1,
                        borderColor: '#6f6f6f',
                        borderWidth: 'medium',
                      }}
                      p={1}
                    >
                      <Typography variant="h6" fontWeight={'bold'}>
                        지연
                      </Typography>
                      <Chip label={resultData.three ? resultData.three.length : 0} variant="outlined" />
                    </Box>
                    <PageProjectIssueBoardList issueList={resultData.three} />
                  </Container>
                </Grid>
                <Grid item xs={11.9} md={3}>
                  <Container>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: 1,
                        borderColor: '#6f6f6f',
                        borderWidth: 'medium',
                      }}
                      p={1}
                    >
                      <Typography variant="h6" fontWeight={'bold'}>
                        협의중
                      </Typography>
                      <Chip label={resultData.four ? resultData.four.length : 0} variant="outlined" />
                    </Box>
                    <PageProjectIssueBoardList issueList={resultData.four} />
                  </Container>
                </Grid>
                <Grid item xs={0.2}>
                  {isNext && (
                    <IconButton
                      disableRipple
                      sx={{
                        pt: 2,
                        width: '100%',
                        '& .MuiSvgIcon-root': {
                          transform: 'scaleY(3) scaleX(2)',
                        },
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        color: '#6f6f6f',
                      }}
                      onClick={goToNext}
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container mt={2} className="custom-slider">
              <Grid item xs={12} className="custom-slider">
                <Grid item xs={0.2}>
                  {!isNext && (
                    <IconButton
                      disableRipple
                      sx={{
                        width: '100%',
                        pt: 2,
                        '& .MuiSvgIcon-root': {
                          transform: 'scaleY(3) scaleX(2)',
                        },
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        color: '#6f6f6f',
                      }}
                      onClick={goToPrev}
                    >
                      <KeyboardDoubleArrowLeftIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={11.9} md={3}>
                  <Container>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: 1,
                        borderColor: '#6f6f6f',
                        borderWidth: 'medium',
                      }}
                      p={1}
                    >
                      <Typography variant="h6" fontWeight={'bold'}>
                        철회
                      </Typography>
                      <Chip label={resultData.five ? resultData.five.length : 0} variant="outlined" />
                    </Box>
                    <PageProjectIssueBoardList issueList={resultData.five} />
                  </Container>
                </Grid>
                <Grid item xs={11.9} md={3}>
                  <Container>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: 1,
                        borderColor: '#6f6f6f',
                        borderWidth: 'medium',
                      }}
                      p={1}
                    >
                      <Typography variant="h6" fontWeight={'bold'}>
                        완료
                      </Typography>
                      <Chip label={resultData.six ? resultData.six.length : 0} variant="outlined" />
                    </Box>
                    <PageProjectIssueBoardList issueList={resultData.six} />
                  </Container>
                </Grid>
                <Grid item xs={0.2}></Grid>
              </Grid>
            </Grid>
          </Slider>
        </Grid>
      </Grid>
    </>
  );
};

export default PageProjectIssueBoard;
