import { Add, Apartment, Book, BusinessCenter, CalendarMonth, Computer, Paid, Search } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageContractRegModal from './PageContractRegModal';
import { useDispatch } from 'react-redux';
import { getCntrDtl, getCntrDtlSuccess, getCntrHistory, setContractData } from '@store/actions/contractAction';
import { setNumberComma, showToast, showToastError } from '@common/common';
import { useSelector } from 'react-redux';
import PageContractHistory from './PageContractHistory';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

const PageContractMainList = ({ searchList, setUpdOpen }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const [historyData, setHistoryData] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event, cntrSn) => {
    console.log(event.currentTarget);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    dispatch(
      getCntrHistory({
        cntrSn: cntrSn,
        userId: user.userId,
        userSn: user.userSn,
        callback: handleGetCntrHistoryCallback,
      })
    );
  };

  const handleGetCntrHistoryCallback = (data) => {
    if (data.resultCode === 0) {
      setHistoryData(data);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg);
    }
  };

  const handleCntrDetail = (cntrSn) => {
    console.log('test');
    dispatch(
      getCntrDtl({
        userId: user.userId,
        userSn: user.userSn,
        cntrSn,
        callback: handleGetCntrDtlCallback,
      })
    );
  };

  const handleGetCntrDtlCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      dispatch(getCntrDtlSuccess(data.data));
      setUpdOpen(true);
    } else {
      showToast(data.resultCode + ':' + data.resultMsg);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <DragDropContext style={{ margin: '0px', padding: '0px' }}>
      <Grid container spacing={1} sx={{ paddingTop: '26px' }}>
        {searchList &&
          searchList.length > 0 &&
          searchList.map((item, index) => (
            <Grid key={item.cntrSn} item xs={12} sm={12} md={12}>
              <Droppable droppableId={item.cntrSn}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      minHeight: '200px',
                      // padding: '26px 0px 5px 0px',
                      width: '100%',
                    }}
                  >
                    <Draggable key={item.cntrSn} draggableId={item.cntrSn} index={index} isDragDisabled>
                      {(provided) => (
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          elevation={3}
                          style={{
                            userSelect: 'none',
                            padding: '16px',
                            margin: '0 0 16px 0',
                            minHeight: '50px',
                            backgroundColor: '#fff',
                            color: '#333',
                            border: '1px solid lightgrey',
                            ...provided.draggableProps.style,
                          }}
                          onClick={() => handleCntrDetail(item.cntrSn)}
                        >
                          <CardContent>
                            <Grid container justifyContent={'space-between'}>
                              <Typography gutterBottom variant="subtitle1" fontWeight={'bold'} component="div">
                                {item.prjcNm}
                              </Typography>
                              <IconButton onClick={(e) => handleClick(e, item.cntrSn)}>
                                <Book sx={{ color: '#ffb300' }} />
                              </IconButton>
                              <PageContractHistory
                                anchorEl={anchorEl}
                                id={id}
                                open={open}
                                handleClose={handleClose}
                                data={historyData}
                              />
                            </Grid>

                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                <Apartment />
                                <Typography pl={2} variant="body2">
                                  {item.clntCoNm}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} mb={1}>
                                <Paid />
                                <Typography pl={2} variant="body2" noWrap title={'30,0000000'}>
                                  {setNumberComma(item.cntrAmnt)}&nbsp;원
                                </Typography>
                              </Grid>
                              <Grid item md={12} display={'flex'} alignItems={'center'} mb={1}>
                                <CalendarMonth />
                                <Typography pl={2} variant="body2">
                                  {item.cntrStDt} ~ {item.cntrEndDt}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'}>
                                <BusinessCenter />
                                <Typography pl={2} variant="body2">
                                  {item.slsMngrNm === '' ? '-' : item.slsMngrNm}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Grid>
          ))}
      </Grid>
    </DragDropContext>
  );
};

export default PageContractMainList;
