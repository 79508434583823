import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography, Box, Checkbox, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { JOIN_TERMS_1, JOIN_TERMS_2, JOIN_TERMS_3 } from '@common/constants';
import { isValue } from '@common/common';
import PageJoinRequestLayout from './PageJoinRequestLayout';

const defaultTheme = createTheme();

export default function PageJoinTermsMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allChecked, setAllChecked] = useState(false);
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [terms3, setTerms3] = useState(false);

  const userFlag = useSelector((state) => state.userReducer.userJoin.userFlag);

  const handleAllChange = (event) => {
    setAllChecked(event.target.checked);
    setTerms1(event.target.checked);
    setTerms2(event.target.checked);
    setTerms3(event.target.checked);
  };

  useEffect(() => {
    if (!isValue(userFlag)) {
      navigate('/joinType');
    }
  }, []);

  const handleTerms1Change = (event) => {
    setTerms1(event.target.checked);
    console.log('event', event.target.checked, terms2, terms3);
    if (event.target.checked && terms2 && terms3) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const handleTerms2Change = (event) => {
    setTerms2(event.target.checked);
    if (terms1 && event.target.checked && terms3) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const handleTerms3Change = (event) => {
    setTerms3(event.target.checked);
    if (terms1 && terms2 && event.target.checked) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const handleNext = () => {
    if (userFlag == 'E') {
      navigate('/joinAccount');
    } else {
      navigate('/joinBiz');
    }
  };

  return (
    <PageJoinRequestLayout>
      <Box noValidate>
        {/* <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
          회원 가입
        </Typography> */}

        <Typography>
          <Checkbox checked={allChecked} onChange={handleAllChange} />
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            전체 동의하기 확인
          </Box>
        </Typography>
        <Typography sx={{ paddingLeft: 5 }}>약관1, 약관2, 약관3에 대해 모두 동의합니다.</Typography>

        <Typography component="div" sx={{ textAlign: 'left', mt: 3 }}>
          <Checkbox checked={terms1} onChange={handleTerms1Change} />
          <span style={{ color: 'blue' }}>[필수]</span>약관
          <br />
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            defaultValue={JOIN_TERMS_1}
            fullWidth
            InputProps={{
              style: { fontSize: '14px' },
            }}
          />
        </Typography>

        <Typography component="div" sx={{ textAlign: 'left', mt: 3 }}>
          <Checkbox checked={terms2} onChange={handleTerms2Change} />
          <span style={{ color: 'blue' }}>[필수]</span>약관
          <br />
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            defaultValue={JOIN_TERMS_2}
            fullWidth
            InputProps={{
              style: { fontSize: '14px' },
            }}
          />
        </Typography>

        <Typography component="div" sx={{ textAlign: 'left', mt: 3 }}>
          <Checkbox checked={terms3} onChange={handleTerms3Change} />
          <span style={{ color: 'blue' }}>[필수]</span>약관
          <br />
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            defaultValue={JOIN_TERMS_3}
            fullWidth
            InputProps={{
              style: { fontSize: '14px' },
            }}
          />
        </Typography>

        <Button type="button" fullWidth variant="contained" sx={{ mt: 5 }} onClick={handleNext} disabled={!allChecked}>
          다음
        </Button>
      </Box>
    </PageJoinRequestLayout>
  );
}
