import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageContractRegModal from './PageContractRegModal';
import { useDispatch } from 'react-redux';
import { getCntrList, setCntrDtlInit } from '@store/actions/contractAction';
import { isComplHangul, showToast } from '@common/common';
import { useSelector } from 'react-redux';
import PageContractMainList from './PageContractMainList';
import PageContractDeleteModal from './PageContractDeleteModal';
import _ from 'lodash';
import { SEARCH_TIME } from '@common/constants';

const PageContractMain = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [regOpen, setRegOpen] = useState(false);
  const [updOpen, setUpdOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isSuccessYn, setIsSuccessYn] = useState(false);
  const [search, setSearch] = useState({});
  const [searchList0, setSearchList0] = useState([]);
  const [searchList1, setSearchList1] = useState([]);
  const [searchList2, setSearchList2] = useState([]);
  const [searchList3, setSearchList3] = useState([]);
  const [srchType, setSrchType] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    dispatch(
      getCntrList({
        ...search,
        srchType: srchType,
        userId: user.userId,
        userSn: user.userSn,
        callback: handleGetCntrListCallback,
      })
    );
  }, [isSuccessYn]);

  const handleSearchTypeChange = (e) => {
    const { name, value } = e.target;

    setSearch((prevSearch) => ({
      ...prevSearch,
      srchType: srchType,
      [name]: value,
    }));
  };

  const handleSrchTypeChange = (e) => {
    setSrchType(e.target.value);
  };

  const debouncedDispatch = useCallback(
    _.debounce((newSearch) => {
      if (isComplHangul(newSearch.keyword)) {
        dispatch(
          getCntrList({
            ...newSearch,
            userId: user.userId,
            userSn: user.userSn,
            callback: handleGetCntrListCallback,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  useEffect(() => {
    debouncedDispatch(search);
  }, [debouncedDispatch, search]);

  const handleGetCntrListCallback = (data) => {
    if (data.resultCode === 0) {
      setSearchList0(data.data.cntrMain0List);
      setSearchList1(data.data.cntrMain1List);
      setSearchList2(data.data.cntrMain2List);
      setSearchList3(data.data.cntrMain3List);
    } else {
      showToast(data);
    }
  };

  const handleRegOpen = () => {
    setRegOpen(true);
    dispatch(setCntrDtlInit());
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ minWidth: 150, mr: 1 }}>
            <FormControl size="small" fullWidth>
              <Select
                name="searchType"
                defaultValue={0}
                // onChange={handleSearchTypeChange}
                onChange={handleSrchTypeChange}
              >
                <MenuItem value={0}>프로젝트명</MenuItem>
                <MenuItem value={1}>의뢰사명</MenuItem>
                <MenuItem value={3}>영업담당자명</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            placeholder="내용을 검색해주세요"
            className="inp-round"
            fullWidth
            autoComplete="off"
            size="small"
            name="keyword"
            onKeyUp={handleSearchTypeChange}
            // onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button className="btn dispSv" sx={{ mr: 1 }} onClick={handleRegOpen}>
            등록
          </Button>
          <Button className="btn-red dispRm" onClick={handleDeleteOpen}>
            삭제
          </Button>
        </Box>
      </Toolbar>
      <Grid container mt={2}>
        <Grid item xs={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: '#6f6f6f',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                계약 준비중
              </Typography>
              <Chip label={searchList0.length} variant="outlined" />
            </Box>
            <PageContractMainList searchList={searchList0} setSearchList={setSearchList0} setUpdOpen={setUpdOpen} />
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: 'primary.main',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                계약 진행중
              </Typography>
              <Chip label={searchList1.length} variant="outlined" />
            </Box>
            <PageContractMainList searchList={searchList1} setSearchList={setSearchList1} setUpdOpen={setUpdOpen} />
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: 'success.main',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                계약 종료
              </Typography>
              <Chip label={searchList2.length} variant="outlined" />
            </Box>
            <PageContractMainList searchList={searchList2} setSearchList={setSearchList2} setUpdOpen={setUpdOpen} />
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: 'error.main',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                계약 중단
              </Typography>
              <Chip label={searchList3.length} variant="outlined" />
            </Box>
            <PageContractMainList searchList={searchList3} setSearchList={setSearchList3} setUpdOpen={setUpdOpen} />
          </Container>
        </Grid>
      </Grid>
      {(regOpen || updOpen) && (
        <PageContractRegModal
          type={regOpen ? 'reg' : 'upd'}
          regOpen={regOpen ? regOpen : updOpen}
          setRegOpen={regOpen ? setRegOpen : setUpdOpen}
          setIsSuccessYn={setIsSuccessYn}
          isSuccessYn={isSuccessYn}
        />
      )}
      {deleteOpen && (
        <PageContractDeleteModal
          deleteOpen={deleteOpen}
          setDeleteOpen={setDeleteOpen}
          setIsSuccessYn={setIsSuccessYn}
          isSuccessYn={isSuccessYn}
        />
      )}
      {/* {updOpen && (
        <PageContractRegModal type={'upd'} regOpen={updOpen} setRegOpen={setUpdOpen} setIsSuccessYn={setIsSuccessYn} />
      )} */}
    </>
  );
};

export default PageContractMain;
