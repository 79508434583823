import { Box, Chip, Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import PageCalculationBoardList from './PageCalculationBoardList';

const PageCalculationBoard = ({ resultStats, updateProcess }) => {
  return (
    <>
      <Grid container height={'100%'} mt={2}>
        <Grid item xs={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: '#6f6f6f',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                정산 예정
              </Typography>
              <Chip label={resultStats.scheduled ? resultStats.scheduled.length : '0'} variant="outlined" />
            </Box>
            <PageCalculationBoardList resultData={resultStats.scheduled} updateProcess={updateProcess} />
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: '#6f6f6f',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                정산 지연
              </Typography>
              <Chip label={resultStats.delayed ? resultStats.delayed.length : '0'} variant="outlined" />
            </Box>
            <PageCalculationBoardList resultData={resultStats.delayed} updateProcess={updateProcess} />
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: '#6f6f6f',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                정산 완료
              </Typography>
              <Chip label={resultStats.completed ? resultStats.completed.length : '0'} variant="outlined" />
            </Box>
            <PageCalculationBoardList resultData={resultStats.completed} updateProcess={updateProcess} />
          </Container>
        </Grid>
        <Grid item xs={12} md={3}>
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                borderBottom: 1,
                borderColor: '#6f6f6f',
                borderWidth: 'medium',
              }}
              p={1}
            >
              <Typography variant="h6" fontWeight={'bold'}>
                정산 중단
              </Typography>
              <Chip label={resultStats.suspended ? resultStats.suspended.length : '0'} variant="outlined" />
            </Box>
            <PageCalculationBoardList resultData={resultStats.suspended} updateProcess={updateProcess} />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default PageCalculationBoard;
