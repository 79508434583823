import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getSalesNmList } from '@store/actions/contractAction';
import { getCntrNmList } from '@store/actions/projectAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageLoadProjectModal = ({ isOpen, setIsOpen, inputs, setInputs, title, subTitle }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    handleGetSalesNmList();
  }, [dispatch]);

  const handleGetSalesNmList = (newKeyword) => {
    dispatch(
      getCntrNmList({
        userId: user.userId,
        userSn: user.userSn,
        keyword: newKeyword,
        callback: handleGetCntrNmListCallback,
      })
    );
  };

  const handleGetCntrNmListCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      setProjectList(data.data.cntrNmList);
    } else {
      showToastError(data);
    }
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList =
      projectList &&
      projectList.length > 0 &&
      projectList.filter((data) => {
        const slsNmMatch = data.slsNm ? data.slsNm.toLowerCase().includes(newKeyword) : false;
        const prjcNmMatch = data.prjcNm ? data.prjcNm.includes(newKeyword) : false;
        return slsNmMatch || prjcNmMatch;
      });

    setFilteredList(filteredList);
  };
  const displayList = keyword ? filteredList : projectList;

  const handleGetProjectDetail = (cntrSn) => {
    const projectData = projectList.find((data) => data.cntrSn === cntrSn);
    setIsOpen(false);

    setInputs({
      ...projectData,
      cntrYN: 'Y',
    });
  };
  console.log(inputs);
  const handleGetProjectDetailCallback = (data) => {
    console.log(data);
    if (data.resultCode === 0) {
      setIsOpen(false);
      setInputs({
        ...inputs,
        prjcNm: data.data.slsNm,
        slsMngrNm: data.data.slsMngr,
        cntrAmnt: data.data.payment,
        memo: data.data.memo,
        clntCoNm: data.data.coNm,
        clntZip: data.data.zip,
        clntAddr: data.data.addr,
        clntDaddr: data.data.daddr,
        clntMngrNm: data.data.mngr,
        clntMngrTelno: data.data.mngrTelno,
        clntMngrEmail: data.data.mngrEmail,
      });
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          {title}
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          {subTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="프로젝트를 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onKeyUp={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="list-type">
          {displayList.length > 0 ? (
            displayList.map((item) => (
              <Grid container key={item.cntrSn}>
                <Grid item>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.prjcNm}
                  </Typography>
                  <Typography variant="body2">{item.clntCoNm}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handleGetProjectDetail(item.cntrSn)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageLoadProjectModal;
