import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/mainAction';
import api from '@api/apiMain';

function* getDashboardInfoD({ payload }) {
  const { status, data } = yield call(api.getDashboardInfoD, { payload: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

function* getDashboardInfoE({ payload }) {
  const { status, data } = yield call(api.getDashboardInfoE, { payload: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

// watcher
function* watchCommon() {
  // yield takeLatest(actions.SET_USER_INFO, postUserInfo);
  yield takeLatest(actions.GET_DASHBOARD_INFO_D, getDashboardInfoD);
  yield takeLatest(actions.GET_DASHBOARD_INFO_E, getDashboardInfoE);
}

export default function* mainSaga() {
  yield all([fork(watchCommon)]);
}
