export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_STATE = 'SET_USER_STATE';
export const POST_USER_MNATHR = 'POST_USER_MNATHR';
export const POST_USER_MNATHR_SUCCESS = 'POST_USER_MNATHR_SUCCESS';
export const SET_USER_INIT = 'SET_USER_INIT';
export const GET_NOTIFY_COUNT = 'GET_NOTIFY_COUNT';
export const GET_NOTIFY_COUNT_SUCCESS = 'GET_NOTIFY_COUNT_SUCCESS';
export const SET_LOADING = 'SET_LOADING';
export const GET_FONT_SIZE = 'GET_FONT_SIZE';
export const GET_SYSTEM_MNG = 'GET_SYSTEM_MNG';
export const SET_SYSTEM_MNG = 'SET_SYSTEM_MNG';
export const SET_BOOL_POPUP = 'SET_BOOL_POPUP';
export const RESET_STATE = 'RESET_STATE';
export const MOVE_PROJECT = 'MOVE_PROJECT';
export const MOVE_ISSUE = 'MOVE_ISSUE';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const SET_ALARM_CNT = 'SET_ALARM_CNT';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';

export const setBoolPopup = (payload) => {
  return { type: SET_BOOL_POPUP, payload };
};

export const setUserInit = (payload) => {
  return { type: SET_USER_INIT, payload };
};

export const setUserInfo = (payload) => {
  return { type: SET_USER_INFO, payload };
};

export const setUserState = (payload) => {
  return { type: SET_USER_STATE, payload };
};

export const setLoading = (payload) => {
  return { type: SET_LOADING, payload };
};

export const resetState = (payload) => {
  return { type: RESET_STATE, payload };
};

export const moveProject = (payload) => {
  return { type: MOVE_PROJECT, payload };
};

export const moveIssue = (payload) => {
  return { type: MOVE_ISSUE, payload };
};

export const getTokenSuccess = (payload) => {
  return { type: GET_TOKEN_SUCCESS, payload };
};

export const setAlarmCnt = (payload) => {
  return { type: SET_ALARM_CNT, payload };
};

export const setSearchType = (payload) => {
  return { type: SET_SEARCH_TYPE, payload };
};
