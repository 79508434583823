import { ExampleCustomInput, isValue, setDateFormat, showToast, showToastError } from '@common/common';
import { Close, Percent } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale/ko';
import { useDispatch } from 'react-redux';
import { getPrjcTaskDtl, putPrjcTask } from '@store/actions/projectAction';
import { useSelector } from 'react-redux';
import PageLoadGroupModal from './PageLoadGroupModal';
import PageLoadPrsnModal from './PageLoadPrsnModal';
import ConfirmPopUp from 'component/ConfirmPopUp';
import PageLoadPrntTaskModal from './PageLoadPrntTaskModal';

const PageProjectChldTaskModal = ({ isOpen, setIsOpen, detailSn, isSuccess, setIsSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const pUser = useSelector((state) => state.projectReducer.project);

  const [taskData, setTaskData] = useState();
  const [originalPrsnList, setOriginalPrsnList] = useState([]);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [isPrntTaskOpen, setIsPrntTaskOpen] = useState(false);
  const [isPrsnOpen, setIsPrsnOpen] = useState(false);
  const [updPop, setUpdPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);

  useEffect(() => {
    dispatch(
      getPrjcTaskDtl({
        userSn: user.userSn,
        userId: user.userId,
        puserSn: pUser.puserSn,
        prjcTaskSn: detailSn,
        callback: handleGetPrjcTaskDtlCallback,
      })
    );
  }, []);

  const handleGetPrjcTaskDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setOriginalPrsnList(data.data.prjcTaskPrsnList);
      setTaskData(data.data);
    } else {
      showToastError(data);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setTaskData({
      ...taskData,
      stDt: start,
      endDt: end,
    });
  };

  const handleTaskDataChange = (e) => {
    const { name, value, checked, type } = e.target;
    const inputValue = type === 'checkbox' ? checked : name === 'prgr' && value > 100 ? 100 : value;

    setTaskData({
      ...taskData,
      [name]: inputValue,
    });
  };
  const handleConfirmUpdPrjcTask = () => {
    console.log(taskData);
    if (!isValue(taskData.prntTaskSn) && !taskData.check) {
      return showToast('상위업무를 선택해주세요.');
    } else if (taskData.prjcTaskPrsnList.length <= 0) {
      return showToast('업무담당자를 선택해주세요.');
    }
    setUpdPop(true);
  };

  const handleUpdPrjcTask = () => {
    dispatch(
      putPrjcTask({
        userSn: user.userSn,
        userId: user.userId,
        prjcTaskSn: detailSn,
        stts: taskData.stts,
        nm: taskData.taskNm,
        inptPrsnSnList: taskData.prjcTaskPrsnList.map((prsn) => prsn.inptPrsnSn),
        prjcGrpSn: taskData.prjcGrpSn,
        prntTaskSn: taskData.prntTaskSn,
        stDt: setDateFormat(taskData.stDt, 'YYYYMMDD'),
        endDt: setDateFormat(taskData.endDt, 'YYYYMMDD'),
        prgr: taskData.prgr,
        memo: taskData.memo,
        callback: handlePutPrjcTaskCallback,
      })
    );
  };

  const handlePutPrjcTaskCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('저장되었습니다.');
      setIsSuccess(!isSuccess);
      setIsOpen(false);
      setUpdPop(false);
    } else {
      showToastError(data);
    }
  };
  return (
    <Dialog open={isOpen} fullWidth scroll="paper" disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title">
        {taskData && taskData.modiYn === 'N' && (
          <Button className="btn-close">
            <Close onClick={() => setIsOpen(false)} />
          </Button>
        )}
        <Chip label="하위" variant="outlined" />
        <TextField
          name="taskNm"
          variant="outlined"
          placeholder="업무명을 입력해주세요"
          InputProps={{ maxLength: 50 }}
          onChange={handleTaskDataChange}
          value={taskData ? taskData.taskNm : ''}
          className="modal-tit2 rqd"
          fullWidth
          size="medium"
          inputProps={{ readOnly: taskData && taskData.modiYn === 'N' ? true : false }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container my={2}>
          <Grid item md={12} display={'flex'} alignItems={'center'}>
            <Grid item md={3} display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" className="rqd">
                상태
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Select
                id="demo-simple-select"
                variant="outlined"
                size="small"
                displayEmpty
                fullWidth
                name="stts"
                //   sx={{ width: '150px', marginBottom: '10px' }}
                defaultValue=""
                onChange={handleTaskDataChange}
                value={taskData ? taskData.stts : ''}
                inputProps={{ readOnly: taskData && taskData.modiYn === 'N' ? true : false }}
              >
                <MenuItem value="">상태선택</MenuItem>
                <MenuItem value="0">대기</MenuItem>
                <MenuItem value="1">진행</MenuItem>
                <MenuItem value="2">피드백</MenuItem>
                <MenuItem value="3">완료</MenuItem>
                <MenuItem value="4">지연</MenuItem>
                <MenuItem value="5">보류</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid item md={12} display={'flex'} alignItems={'center'}>
            <Grid item md={3} display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" className="rqd">
                그룹
              </Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="grpNm"
                variant="standard"
                //   onClick={() => setIsGroupOpen(true)}
                // onChange={handleInputChange}
                // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                fullWidth
                size="small"
                value={taskData ? taskData.grpNm : ''}
                onClick={() => (taskData && taskData.modiYn === 'Y' ? setIsGroupOpen(true) : undefined)}
                inputProps={{ readOnly: true }}
                // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={1} justifyContent={'space-between'}>
          <Grid item md={12} display={'flex'} alignItems={'center'}>
            <Grid item md={3} display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" className="rqd">
                상위 업무
              </Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="cntrAmnt"
                size="small"
                variant="standard"
                inputProps={{ readOnly: true }}
                // onChange={handleInputChange}
                value={taskData && taskData.prntTaskNm && !taskData.check ? taskData.prntTaskNm : ''}
                fullWidth
                onClick={() => (taskData && taskData.modiYn === 'Y' ? setIsPrntTaskOpen(true) : undefined)}
                // disabled={taskData.check ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid item md={12} display={'flex'} alignItems={'center'}>
            <Grid item md={3} display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" className="rqd">
                담당자
              </Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="standard"
                //   onClick={() => setIsGroupOpen(true)}
                // onChange={handleInputChange}
                // value={inputs.cntrAmnt ? setNumberComma(inputs.cntrAmnt) : ''}
                onClick={() => (taskData && taskData.modiYn === 'Y' ? setIsPrsnOpen(true) : undefined)}
                inputProps={{ readOnly: true }}
                fullWidth
                size="small"
                value={taskData?.prjcTaskPrsnList?.map((prsn) => prsn.userNm).join(', ') ?? ''}
                // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant="subtitle1" className="rqd">
                업무 기간
              </Typography>
            </Grid>
            <Grid item md={9} className="inp-date">
              <DatePicker
                locale={ko}
                selected={taskData && taskData.stDt}
                onChange={handleDateChange}
                startDate={taskData ? taskData.stDt : ''}
                endDate={taskData ? taskData.endDt : ''}
                dateFormat={'YYYY-MM-dd'}
                selectsRange
                customInput={<ExampleCustomInput />}
                disabled={taskData && taskData.modiYn === 'N' ? true : false}
                // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">진행률</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="prgr"
                variant="standard"
                onChange={handleTaskDataChange}
                value={taskData ? taskData.prgr : ''}
                fullWidth
                size="small"
                InputProps={{
                  readOnly: taskData && taskData.modiYn === 'N' ? true : false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid item md={12} display={'flex'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">메모</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="memo"
                onChange={handleTaskDataChange}
                multiline
                rows={4}
                fullWidth
                value={taskData ? taskData.memo : ''}
                InputProps={{
                  readOnly: taskData && taskData.modiYn === 'N' ? true : false,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {taskData && taskData.modiYn === 'Y' && (
        <DialogActions>
          <Button onClick={() => setCancelPop(true)} className="btn-white">
            취소
          </Button>
          <Button className="btn-navy" onClick={handleConfirmUpdPrjcTask}>
            저장
          </Button>
        </DialogActions>
      )}
      {isGroupOpen && (
        <PageLoadGroupModal isOpen={isGroupOpen} setIsOpen={setIsGroupOpen} setInputs={setTaskData} inputs={taskData} />
      )}
      {isPrntTaskOpen && (
        <PageLoadPrntTaskModal
          isOpen={isPrntTaskOpen}
          setIsOpen={setIsPrntTaskOpen}
          setInputs={setTaskData}
          inputs={taskData}
        />
      )}
      {isPrsnOpen && (
        <PageLoadPrsnModal
          isOpen={isPrsnOpen}
          setIsOpen={setIsPrsnOpen}
          setInputs={setTaskData}
          inputs={taskData}
          setOriginalPrsnList={setOriginalPrsnList}
          originalPrsnList={originalPrsnList}
        />
      )}
      {updPop && (
        <ConfirmPopUp
          open={updPop}
          close={setUpdPop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleUpdPrjcTask}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setIsOpen}
        />
      )}
    </Dialog>
  );
};

export default PageProjectChldTaskModal;
