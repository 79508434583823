import { showToast, showToastError } from '@common/common';
import { Close, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { getDvlpCoNmList } from '@store/actions/contractAction';
import { getWrkfList } from '@store/actions/wrkfAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageLoadTargetModal = (props) => {
  const { isOpen, setIsOpen, sendData, setSendData, setOriginalPrsnList, originalPrsnList } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [type, setType] = useState(1);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [prsnList, setPrsnList] = useState([]);

  useEffect(() => {
    if (type === 1) {
      dispatch(
        getWrkfList({
          userSn: user.userSn,
          userId: user.userId,
          callback: handleGetWrkfListCallback,
        })
      );
    } else {
      dispatch(
        getDvlpCoNmList({
          userSn: user.userSn,
          userId: user.userId,
          callback: handleGetWrkfListCallback,
        })
      );
    }
  }, [type]);

  const handleSrchTypeChange = (e) => {
    const value = e.target.value;

    setType(value);
  };

  const handleGetWrkfListCallback = (data) => {
    if (data.resultCode === 0) {
      // setOptions(data.data);
      if (sendData.dtlList && sendData.dtlList.length > 0) {
        // prjcTaskPrsnList가 존재하고 비어있지 않은 경우, 필터링된 옵션을 설정
        const filteredOptions =
          type === 1
            ? data.data.filter((option) => !sendData.dtlList.some((prsn) => prsn.wrkfSn === option.wrkfSn))
            : data.data.dvlpCoNmList.filter(
                (option) => !sendData.dtlList.some((prsn) => prsn.dvlpCoSn === option.dvlpCoSn)
              );
        setOptions(filteredOptions);
      } else {
        // prjcTaskPrsnList가 없거나 비어있는 경우, 모든 데이터를 설정
        setOptions(type === 1 ? data.data : data.data.dvlpCoNmList);
      }
    } else {
      showToastError(data);
    }
  };

  const handleTargetClose = () => {
    setIsOpen(false);
    // setInputs((prevInputs) => ({
    //   ...prevInputs,
    //   prjcTaskPrsnList: originalPrsnList,
    // }));
  };

  const handleOptionSelected = (event, newValue) => {
    console.log(newValue);
    if (newValue) {
      // 선택된 옵션을 options에서 제거
      setOptions((prevOptions) =>
        prevOptions.filter((option) =>
          type === 1 ? option.wrkfSn !== newValue.wrkfSn : option.dvlpCoSn !== newValue.dvlpCoSn
        )
      );

      const updatedNewValue = {
        ...newValue,
        type: type, // Add the type property
      };

      // 선택된 옵션을 prsnList에 추가
      // setPrsnList((prevSetList) => [...prevSetList, newValue]);
      setPrsnList((prevPrsnList) => [...prevPrsnList, updatedNewValue]);
      // Autocomplete 컴포넌트 리셋
      setAutocompleteKey((prevKey) => prevKey + 1);
    }
  };
  console.log(sendData);
  const handleDeletePrsn = (wrkfSn) => {
    console.log(wrkfSn);
    setPrsnList((prevInputs) => ({
      ...prevInputs,
      dtlList: prevInputs.dtlList.filter((prsn) => prsn.wrkfSn !== wrkfSn && prsn.dvlpCoSn !== wrkfSn),
    }));
    // setSendData((prevInputs) => ({
    //   ...prevInputs,
    //   dtlList: prevInputs.dtlList.filter((prsn) => prsn.wrkfSn !== wrkfSn && prsn.dvlpCoSn !== wrkfSn),
    // }));
  };

  const handleSetPrsnInfo = () => {
    console.log(prsnList);
    if (Object.keys(prsnList).length <= 0 || prsnList.length <= 0) {
      return showToast('지급대상을 선택해주세요.');
    }
    setIsOpen(false);
    setSendData((prevSendData) => ({
      ...prevSendData,
      dtlList: [...(prevSendData.dtlList || []), ...prsnList],
    }));
    setOriginalPrsnList([...prsnList]);
  };

  const handlePrsnClose = () => {
    setIsOpen(false);
    setSendData((prevInputs) => ({
      ...prevInputs,
      dtlList: originalPrsnList,
    }));
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={handlePrsnClose} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          지급 대상 선택
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          선택 후 적용 버튼을 눌러주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={3} mr={1}>
            <FormControl size="small" fullWidth>
              <Select displayEmpty value={type} onChange={handleSrchTypeChange}>
                <MenuItem value={1}>인력</MenuItem>
                <MenuItem value={2}>개발사</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={8}>
            {options.length === 0 ? (
              <TextField
                fullWidth
                className="inp-round"
                size="small"
                disabled
                value="데이터가 없습니다"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Autocomplete
                key={autocompleteKey}
                id="combo-box-demo"
                options={options}
                fullWidth
                value={selectedOption}
                onChange={handleOptionSelected}
                getOptionLabel={(option) =>
                  `${option.userNm} ${option.gender} (${option.age}세) / ${option.position} / ${option.rank}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className="inp-round"
                    size="small"
                    placeholder="담당자 이름을 검색해주세요"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) =>
                  type === 1 ? (
                    <li
                      {...props}
                      key={option.wrkfSn || `${option.userNm}-${option.age}-${option.teamNm}-${option.rank}`}
                    >
                      {`${option.userNm} ${option.gender} (${option.age}세) / ${option.teamNm} / ${option.rank}`}
                    </li>
                  ) : (
                    <li {...props} key={option.dvlpCoSn || `${option.dvlpNm}-${option.coCd}`}>
                      {`${option.dvlpNm} ${option.coCd} `}
                    </li>
                  )
                }
              />
            )}
          </Grid>
          <Grid container>
            {prsnList &&
              prsnList.length > 0 &&
              prsnList.map((item, index) => (
                <Grid key={index} item display={'flex'} alignItems={'center'} mx={1}>
                  <Typography>{item.userNm || item.dvlpNm}</Typography>
                  <IconButton color="error" onClick={() => handleDeletePrsn(item.wrkfSn || item.dvlpCoSn)}>
                    <Close />
                  </IconButton>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'center'}>
          <Button className="btn-navy" onClick={handleSetPrsnInfo}>
            적용
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PageLoadTargetModal;
