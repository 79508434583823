import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageLoadClntModal from './PageLoadClntModal';
import DaumPostCode from 'component/DaumPostCode';
import { isValue } from '@common/common';

const PageProjectRegClntInfoMain = ({ clntInputs, setClntInputs, prjcSn }) => {
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [isClntOpen, setIsClntOpen] = useState(false);

  console.log(prjcSn);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setClntInputs({
      ...clntInputs,
      [name]: value,
    });
  };

  const handleZipSearch = (data) => {
    const { address, zonecode } = data;
    setIsPostOpen(false);
    setClntInputs({
      ...clntInputs,
      zip: zonecode,
      addr: address,
    });
  };
  return (
    <>
      <Grid container borderBottom={1} borderColor={'divider'}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} mt={2}>
          <Grid item>
            <Typography variant="subtitle1">의뢰사 정보</Typography>
          </Grid>
          {!isValue(prjcSn) && (
            <Grid>
              <Button className="btn-orange" onClick={() => setIsClntOpen(true)}>
                정보 가져오기
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container p={2}>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                회사코드
              </Grid>
              <Grid item md={4}>
                <TextField
                  name="clntCd"
                  variant="standard"
                  size="small"
                  fullWidth
                  value={clntInputs.clntCd ? clntInputs.clntCd : ''}
                  onChange={handleInputChange}
                  inputProps={{ readOnly: true }}
                  // disabled={isValue(prjcSn) ? true : false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                <Typography variant="subtitle1" className="rqd">
                  회사명
                </Typography>
              </Grid>
              <Grid item md={10}>
                <TextField
                  name="clntNm"
                  variant="standard"
                  size="small"
                  fullWidth
                  value={clntInputs.clntNm ? clntInputs.clntNm : ''}
                  onChange={handleInputChange}
                  // disabled={isValue(prjcSn) ? true : false}
                  inputProps={{ readOnly: isValue(prjcSn) ? true : false }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                회사 주소
              </Grid>
              <Grid item md={10} display={'flex'} alignItems={'center'}>
                <Grid item md={3} mr={2}>
                  <TextField
                    name="zip"
                    variant="standard"
                    size="small"
                    fullWidth
                    placeholder="우편번호"
                    value={clntInputs.zip ? clntInputs.zip : ''}
                    onChange={handleInputChange}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item md={3}>
                  <Button type="button" className="btn" onClick={() => setIsPostOpen(true)}>
                    우편번호 검색
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={2}></Grid>
            <Grid item md={10} display={'flex'} alignItems={'center'}>
              <TextField
                name="addr"
                variant="standard"
                size="small"
                fullWidth
                placeholder="기본주소"
                value={clntInputs.addr ? clntInputs.addr : ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid item md={2}></Grid>
            <Grid item md={10} display={'flex'} alignItems={'center'}>
              <TextField
                name="daddr"
                variant="standard"
                size="small"
                fullWidth
                placeholder="상세주소"
                value={clntInputs.daddr ? clntInputs.daddr : ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                담당자
              </Grid>
              <Grid item md={5}>
                <TextField
                  name="mngr"
                  variant="standard"
                  size="small"
                  fullWidth
                  value={clntInputs.mngr ? clntInputs.mngr : ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                담당자 연락처
              </Grid>
              <Grid item md={5}>
                <TextField
                  name="mngrTelno"
                  variant="standard"
                  size="small"
                  fullWidth
                  placeholder="숫자만 입력"
                  value={clntInputs.mngrTelno ? clntInputs.mngrTelno : ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item md={2}>
                담당자 이메일
              </Grid>
              <Grid item md={5}>
                <TextField
                  name="mngrEmail"
                  variant="standard"
                  size="small"
                  fullWidth
                  value={clntInputs.mngrEmail ? clntInputs.mngrEmail : ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isPostOpen && <DaumPostCode isOpen={isPostOpen} setIsOpen={setIsPostOpen} handleZipSearch={handleZipSearch} />}
      {isClntOpen && (
        <PageLoadClntModal
          isOpen={isClntOpen}
          setIsOpen={setIsClntOpen}
          inputs={clntInputs}
          setInputs={setClntInputs}
        />
      )}
    </>
  );
};

export default PageProjectRegClntInfoMain;
