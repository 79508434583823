import { showToast, showToastError } from '@common/common';
import { Close, Search } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getPrntPrjcTaskList } from '@store/actions/projectAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PageLoadPrntTaskModal = ({ isOpen, setIsOpen, inputs, setInputs }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const id = useSelector((state) => state.commonReducer.projectSn);

  const [keyword, setKeyword] = useState('');
  const [taskList, setTaskList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    console.log(inputs.prjcGrpSn);
    if (inputs.prjcGrpSn !== undefined) {
      handleGetPrntTaskList();
    } else {
      return showToast('그룹을 선택해주세요');
    }
  }, [dispatch]);

  const handleGetPrntTaskList = (newKeyword) => {
    dispatch(
      getPrntPrjcTaskList({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: id,
        prjcGrpSn: inputs.prjcGrpSn,
        keyword: newKeyword,
        callback: handleGetPrntTaskListCallback,
      })
    );
  };

  const handleGetPrntTaskListCallback = (data) => {
    if (data.resultCode === 0) {
      setTaskList(data.data);
    } else {
      showToastError(data);
    }
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList = taskList.filter((data) => {
      const slsNmMatch = data.slsNm ? data.slsNm.toLowerCase().includes(newKeyword) : false;
      const prjcNmMatch = data.prjcNm ? data.prjcNm.includes(newKeyword) : false;
      return slsNmMatch || prjcNmMatch;
    });

    setFilteredList(filteredList);
  };

  const displayList = keyword ? filteredList : taskList;

  const handleSetTaskInfo = (sn, nm) => {
    setIsOpen(false);
    setInputs({
      ...inputs,
      prntTaskNm: nm,
      prntTaskSn: sn,
    });
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          상위 업무
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          상위 업무를 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="업무명을 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onKeyUp={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="list-type">
          {displayList.length > 0 ? (
            displayList.map((item) => (
              <Grid container key={item.prjcTaskSn}>
                <Grid item>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.taskNm}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handleSetTaskInfo(item.prjcTaskSn, item.taskNm)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageLoadPrntTaskModal;
