import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { ko } from 'date-fns/locale/ko';
import PageLoadProjectModal from './PageLoadProjectModal';
import { isValue, setDateFormat } from '@common/common';

const PageProjectRegProjectInfoMain = ({ projectInputs, setProjectInputs }) => {
  const [isProjectOpen, setIsProjectOpen] = useState(false);

  console.log(projectInputs);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      variant="standard"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
    />
  ));

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProjectInputs({
      ...projectInputs,
      [name]: value,
    });
  };

  //날짜 change
  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setProjectInputs({
      ...projectInputs,
      cntrStDt: start,
      cntrEndDt: end,
    });
  };

  console.log(projectInputs.stts);
  return (
    <>
      <Grid container borderBottom={1} borderColor={'divider'}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} mt={2}>
          <Grid item>
            <Typography variant="subtitle1">프로젝트 정보</Typography>
          </Grid>
          <Grid>
            <Button className="btn-orange" onClick={() => setIsProjectOpen(true)}>
              정보 가져오기
            </Button>
          </Grid>
        </Grid>
        <Grid container p={2}>
          <Grid container>
            <Grid container alignItems={'center'}>
              <Grid item sm={12} md={2}>
                <Typography variant="subtitle1" className="rqd">
                  진행 상태
                </Typography>
              </Grid>
              <Grid item sm={5} md={2}>
                <FormControl size="small" fullWidth>
                  <Select
                    name="stts"
                    value={isValue(projectInputs.stts) ? projectInputs.stts : 1}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={1}>진행 중</MenuItem>
                    <MenuItem value={0}>진행 예정</MenuItem>
                    <MenuItem value={2}>진행 완료</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item sm={12} md={2}>
                <Typography variant="subtitle1" className="rqd">
                  개발 기간
                </Typography>
              </Grid>
              <Grid item sm={12} md={4}>
                <Box className="inp-date">
                  <DatePicker
                    locale={ko}
                    selected={projectInputs.cntrStDt}
                    onChange={handleDateChange}
                    startDate={projectInputs.cntrStDt}
                    endDate={projectInputs.cntrEndDt}
                    dateFormat={'YYYY-MM-dd'}
                    selectsRange
                    customInput={<ExampleCustomInput />}
                    // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item sm={12} md={2}>
                <Typography variant="subtitle1" className="rqd">
                  프로젝트명
                </Typography>
              </Grid>
              <Grid item sm={12} md={10}>
                <TextField
                  name="prjcNm"
                  variant="standard"
                  size="small"
                  fullWidth
                  onChange={handleInputChange}
                  value={projectInputs.prjcNm ? projectInputs.prjcNm : ''}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container my={2}>
            <Grid container alignItems={'center'}>
              <Grid item sm={12} md={2}>
                상세설명
              </Grid>
              <Grid item sm={12} md={10}>
                <TextField
                  name="prjcDscr"
                  variant="standard"
                  size="small"
                  fullWidth
                  multiline
                  onChange={handleInputChange}
                  value={projectInputs.prjcDscr ? projectInputs.prjcDscr : ''}
                  inputProps={{ maxLength: 150 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isProjectOpen && (
        <PageLoadProjectModal
          isOpen={isProjectOpen}
          setIsOpen={setIsProjectOpen}
          inputs={projectInputs}
          setInputs={setProjectInputs}
          title={'가져오기'}
          subTitle={'계약 프로젝트 목록 중 정보를 가져올 항목을 선택해주세요.'}
        />
      )}
    </>
  );
};

export default PageProjectRegProjectInfoMain;
