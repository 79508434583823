import * as actions from '@actions/salesAction';

const INIT_STATE = {
  salesInfo: {
    slsStgSn: '',
    stgNm: '',
    slsPrrtSn: '',
    prrtNm: '',
    slsNm: '',
    coNm: '',
    zip: '',
    addr: '',
    daddr: '',
    mngr: '',
    mngrTelno: '',
    mngrEmail: '',
    payment: '',
    slsMngr: '',
    slsDt: '',
    memo: '',
    coCd: '',
  },
  successYn: false,
};

const salesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.GET_SALES_DTL_SUCCESS:
      return {
        ...state,
        salesInfo: { ...state.salesInfo, ...action.payload },
      };    
    default:
      return state;
  }
};

export default salesReducer;
