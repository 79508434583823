export const POST_LOGIN = 'POST_LOGIN';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_SIGN_UP = 'POST_SIGN_UP';
export const GET_SEARCH_ID = 'GET_SEARCH_ID';
export const GET_SEARCH_PW = 'POST_SEARCH_PW';
export const GET_MBL_AUTH = 'GET_MBL_AUTH';
export const GET_MBL_CNFR = 'GET_MBL_CNFR';
export const SET_USER_JOIN = 'SET_USER_JOIN';
export const GET_BSN_INF = 'GET_BSN_INF';
export const POST_USER_REG = 'POST_USER_REG';
export const POST_CO_REG = 'POST_CO_REG';
export const GET_MY_INFO = 'GET_MY_INFO';
export const PUT_PW_EDIT = 'PUT_PW_EDIT';
export const PUT_TEL_EDIT = 'PUT_TEL_EDIT';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_ALARM_LIST = 'GET_ALARM_LIST';
export const POST_BRNO_OVRLP_CHECK = 'POST_BRNO_OVRLP_CHECK';

export const postLogin = (payload) => {
  return { type: POST_LOGIN, payload };
};

export const postLoginSuccess = (payload) => {
  return { type: POST_LOGIN_SUCCESS, payload };
};

export const postSignUp = (payload) => {
  return { type: POST_SIGN_UP, payload };
};

export const getSearchId = (payload) => {
  return { type: GET_SEARCH_ID, payload };
};

export const getSearchPw = (payload) => {
  return { type: GET_SEARCH_PW, payload };
};

export const getMblAuth = (payload) => {
  return { type: GET_MBL_AUTH, payload };
};

export const getMblCnfr = (payload) => {
  return { type: GET_MBL_CNFR, payload };
};

export const setUserJoin = (payload) => {
  return { type: SET_USER_JOIN, payload };
};

export const getBsnInf = (payload) => {
  return { type: GET_BSN_INF, payload };
};

export const postCoReg = (payload) => {
  return { type: POST_CO_REG, payload };
};

export const postUserReg = (payload) => {
  return { type: POST_USER_REG, payload };
};

// 내 정보
export const getMyInfo = (payload) => {
  return { type: GET_MY_INFO, payload };
};

export const putPwEdit = (payload) => {
  return { type: PUT_PW_EDIT, payload };
};

export const putTelEdit = (payload) => {
  return { type: PUT_TEL_EDIT, payload };
};

export const getToken = (payload) => {
  return { type: GET_TOKEN, payload };
};

export const getAlarmList = (payload) => {
  return { type: GET_ALARM_LIST, payload };
};

export const postBrnoCheck = (payload) => {
  return { type: POST_BRNO_OVRLP_CHECK, payload };
};


