import { all, fork, call, takeLatest, put } from 'redux-saga/effects';

import * as actions from '@actions/wrkfAction';
import api from '@api/apiWorkforce';

function* getWrkfList({ payload }) {
    const { status, data } = yield call(api.getWrkfList, { payload: payload });
    if (status === 200) payload.callback(data, status);
}

function* getBlngList({ payload }) {
    const { status, data } = yield call(api.getBlngList, { payload: payload });
    if (status === 200) payload.callback(data, status);
}

function* delWrkfList({ payload }) {
    const { status, data } = yield call(api.delWrkfList, { payload: payload });
    if (status === 200) payload.callback(data, status);
}

function* postBlng(action) {
    const {payload, callback} = action.payload;
    const { status, data } = yield call(api.postBlng, { payload: payload });
    if (status === 200) callback(data, status);
}

function* postWrkf({ payload }) {
    
    const { status, data } = yield call(api.postWrkf, payload);
    if (status === 200) payload.callback(data, status);
}

function* getSchUserInfoByCode({ payload }) {
    const { status, data } = yield call(api.getSchUserInfoByCode, { payload: payload });
    if (status === 200) payload.callback(data, status);
}

function* getWrkfDtl({ payload }) {
    const { status, data } = yield call(api.getWrkfDtl, { payload: payload });
    if (status === 200) payload.callback(data, status);
}

function* putWrkf({ payload }) {
    const { status, data } = yield call(api.putWrkf, payload);
    if (status === 200) payload.callback(data, status);
}


function* watchUser() {
    yield takeLatest(actions.GET_WRKF_LIST, getWrkfList);
    yield takeLatest(actions.GET_BLNG_LIST, getBlngList);
    yield takeLatest(actions.DEL_WRKF_LIST, delWrkfList);
    yield takeLatest(actions.POST_BLNG, postBlng);
    yield takeLatest(actions.POST_WRKF, postWrkf);
    yield takeLatest(actions.GET_SCH_INFO_CODE, getSchUserInfoByCode);
    yield takeLatest(actions.GET_WRKF_DTL, getWrkfDtl);
    yield takeLatest(actions.PUT_WRKF, putWrkf);
}


export default function* wrkfSaga() {
    yield all([fork(watchUser)]);
  }