export const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST';
export const PUT_PAYMENT_PROCESS = 'PUT_PAYMENT_PROCESS';
export const DEL_PAYMENT_PROCESS = 'DEL_PAYMENT_PROCESS';
export const GET_PAYMENT_MONTH = 'GET_PAYMENT_MONTH';
export const GET_PAYMENT_AGREEMENT = 'GET_PAYMENT_AGREEMENT';
export const GET_PAYMENT_COMP_DTL = 'GET_PAYMENT_COMP_DTL';
export const GET_PAYMENT_STATISTICS = 'GET_PAYMENT_STATISTICS';
export const GET_PAYMENT_UPDATE_DTL = 'GET_PAYMENT_UPDATE_DTL';
export const PUT_PAYMENT_STOP = 'PUT_PAYMENT_STOP';
export const PUT_PAYMENT_LIST = 'PUT_PAYMENT_LIST';
export const POST_PAYMENT = 'POST_PAYMENT';

export const getPaymentList = (payload) => {
  return { type: GET_PAYMENT_LIST, payload };
};

export const putPaymentProcess = (payload) => {
  return { type: PUT_PAYMENT_PROCESS, payload };
};

export const delPaymentProcess = (payload) => {
  return { type: DEL_PAYMENT_PROCESS, payload };
};

export const getPaymentMonth = (payload) => {
  return { type: GET_PAYMENT_MONTH, payload };
};

export const getPaymentAgreement = (payload) => {
  return { type: GET_PAYMENT_AGREEMENT, payload };
};

export const getPaymentCompDtl = (payload) => {
  return { type: GET_PAYMENT_COMP_DTL, payload };
};

export const getPaymentStatistics = (payload) => {
  return { type: GET_PAYMENT_STATISTICS, payload };
};

export const getPaymentUpdateDtl = (payload) => {
  return { type: GET_PAYMENT_UPDATE_DTL, payload };
};

export const putPaymentStop = (payload) => {
  return { type: PUT_PAYMENT_STOP, payload };
};

export const putPaymentList = (payload) => {
  return { type: PUT_PAYMENT_LIST, payload };
};

export const postPayment = (payload) => {
  return { type: POST_PAYMENT, payload };
};
