import * as actions from '@actions/userAction';

const INIT_STATE = {
  userInfo: {
    userGet: {
      userId: '',
      userType: '',
    },
  },
  userJoin: {
    userFlag: '',
    bsnRegNo: '',
    rprNm: '',
    opnDt: '',
    coNm: '',
    rprNo: '',
    faxNo: '',
    zip: '',
    addr: '',
    daddr: '',
    userId: '',
    userPw: '',
    userNm: '',
    brdt: '',
    mblNo: ''
  }
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.POST_LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };
      case actions.SET_USER_JOIN:
        return {
          ...state,
          userJoin: { ...state.userJoin, ...action.payload },
        };
    default:
      return state;
  }
};

export default userReducer;
