import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Typography, ToggleButtonGroup, ToggleButton, Grid, Box, TextField, Chip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSearchId, getMblAuth, getMblCnfr } from '@store/actions/userAction';
import { CUSTOMER_CENTER_PHONE_NUMBER } from '@common/constants';
import { isValue, isNumberExact, showToast, showToastError, isMobile, isComplHangul } from '@common/common';
import { AccessTime } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const defaultTheme = createTheme();

export default function PageSearchIdMain() {
  const [inputs, setInputs] = useState({
    userNm: '',
    brdt: '',
    mblNo: '',
    authNo: '',
    authResNo: '',
    userSex: 'M',
    isAuth: false,
    uuid: '',
    isAuthCompl: false,
    userId: '',
    regDt: '',
  });

  const userFlag = useSelector((state) => state.userReducer.userJoin.userFlag);
  const limitTime = 180;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [timer, setTimer] = useState(limitTime);
  const [intervalId, setIntervalId] = useState(null); // interval ID 저장할 상태

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleTimer = () => {
    const time = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(time);
          setAuth(false);
          setInputs({
            ...inputs,
            uuid: '',
            authNo: '',
            authResNo: '',
          });
        }
        return prevTimer - 1;
      });
    }, 1000);
    setIntervalId(time); // interval ID를 상태에 저장
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSuccRequest = () => {
    if (!isValue(inputs.userNm)) {
      return showToast('이름을 입력해주세요');
    } else if (!isNumberExact(inputs.brdt, 8)) {
      return showToast('생년월일 8자리를 입력해주세요.');
    } else if (!isMobile(inputs.mblNo)) {
      return showToast('휴대폰번호 11자리를 입력해주세요.', 'warning', '0.9em');
    }

    dispatch(
      getSearchId({
        userNm: inputs.userNm,
        brdt: inputs.brdt,
        mblNo: inputs.mblNo,
        userSex: inputs.userSex,
        userFlag: userFlag,
        callback: handleSuccCallback,
      })
    );
  };

  const handleSuccCallback = (data, status) => {
    if (data.resultCode === 0) {
      navigate(`/searchIdSucc?id=${data.data.userId}&dt=${data.data.regDt}`);
    } else {
      showToastError(data);
    }
  };

  const handleGenderChange = (event, newValue) => {
    if (newValue !== null) {
      setInputs({
        ...inputs,
        userSex: newValue,
      });
    }
  };

  const handleAuthCallback = (data, status) => {
    console.log('handleAuthCallback', data, data.data.authNo);
    if (data.resultCode === 0) {
      setInputs({
        ...inputs,
        uuid: data.data.uuid,
        authResNo: data.data.authNo,
        isAuth: true,
      });
      handleTimer();
      setAuth(!auth);
      setTimer(limitTime);
    } else {
      showToastError(data);
    }
  };

  const handleAuthRequest = () => {
    if (!isValue(inputs.userNm)) {
      return showToast('이름을 입력해주세요');
    } else if (!isNumberExact(inputs.brdt, 8)) {
      return showToast('생년월일 8자리를 입력해주세요.');
    } else if (!isMobile(inputs.mblNo)) {
      return showToast('휴대폰번호 11자리를 입력해주세요.', 'warning', '0.9em');
    } else {
      resetInterval();
      setInputs({
        ...inputs,
        uuid: '',
        authNo: '',
        authResNo: '',
        isAuth: false,
        isAuthCompl: false,
      });
      dispatch(
        getMblAuth({
          mblNo: inputs.mblNo,
          regIp: '192.169.0.72',
          callback: handleAuthCallback,
        })
      );
    }
  };

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null); // interval ID 초기화
    }
  };

  const handleComplCollback = (data, status) => {
    if (data.resultCode === 0) {
      resetInterval();
      setInputs({
        ...inputs,
        uuid: '',
        authResNo: '',
        isAuth: false,
        isAuthCompl: true,
      });
    } else {
      showToastError(data);
    }
  };

  const handleComplRequest = () => {
    if (!isMobile(inputs.mblNo)) {
      return showToast('휴대폰번호 11자리를 입력해주세요.', 'warning', '0.9em');
    } else if (!inputs.isAuth) {
      return showToast('휴대폰번호 인증을 해주세요.', 'warning');
    } else if (!isNumberExact(inputs.authNo, 6)) {
      return showToast('인증번호 숫자 6자리를 입력해주세요.', 'warning');
    } else if (inputs.authNo != inputs.authResNo) {
      return showToast('유효한 인증번호가 아닙니다.', 'warning');
    } else {
      dispatch(
        getMblCnfr({
          uuid: inputs.uuid,
          authNo: inputs.authNo,
          callback: handleComplCollback,
        })
      );
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // 너비를 100%로 설정
          }}
        >
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
              <img src="/logo.png" style={{ width: '150px' }} />
            </Box>
            <Typography variant="h6">아이디 찾기</Typography>
          </Box>
          <Box noValidate sx={{ mt: 1 }}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color={'primary'} fontWeight={'bold'}>
                계정 정보
              </Typography>
              <Typography fontWeight={'bold'}>&nbsp;확인</Typography>
            </Box>
            <Typography variant="body2"> 가입 시 입력한 계정 정보를 입력하고 휴대폰 인증을 진행해주세요</Typography>

            <TextField
              sx={{ mt: 3 }}
              margin="normal"
              required
              fullWidth
              placeholder="이름"
              autoComplete="off"
              autoFocus
              name="userNm"
              value={inputs.userNm}
              onChange={handleChange}
            />
            <Grid container spacing={2} alignItems="center">
              {/* TextField */}
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  fullWidth
                  margin="normal"
                  required
                  placeholder="생년월일 (YYYYMMDD 형식의 숫자 8자리)"
                  name="brdt"
                  autoComplete="off"
                  value={inputs.brdt}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <ToggleButtonGroup
                  sx={{ mt: 1 }}
                  color="primary"
                  value={inputs.userSex}
                  exclusive
                  onChange={handleGenderChange}
                  aria-label="Gender"
                  fullWidth
                >
                  <ToggleButton value="M">남자</ToggleButton>
                  <ToggleButton value="F">여자</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  placeholder="휴대폰번호 (‘-’ 제외 숫자 11자리)"
                  name="mblNo"
                  autoComplete="off"
                  onChange={handleChange}
                  value={inputs.mblNo}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Chip
                          icon={<AccessTime />}
                          label={formatTime(timer)}
                          variant="outlined"
                          sx={{ ml: 1, display: !inputs.isAuth ? 'none' : '' }}
                        />
                        <Button
                          type="button"
                          fullWidth
                          variant="outlined"
                          sx={{ width: '40%' }}
                          onClick={handleAuthRequest}
                          disabled={auth}
                        >
                          인증번호
                        </Button>
                      </>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={4}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, height: 50 }}
                  onClick={handleAuthRequest}
                  disabled={auth}
                >
                  인증번호
                </Button>
              </Grid> */}
            </Grid>

            <Grid container spacing={2} alignItems="center" sx={{ display: !auth ? 'none' : '' }}>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  placeholder="인증번호 숫자 6자리"
                  name="authNo"
                  autoComplete="off"
                  value={inputs.authNo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, height: 50 }}
                  onClick={handleComplRequest}
                  disabled={!auth}
                >
                  인증
                </Button>
              </Grid>
            </Grid>

            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSuccRequest}
              disabled={!inputs.isAuthCompl}
            >
              아이디 찾기 완료
            </Button>
          </Box>
        </Box>
        <Typography
          sx={{ mt: 4, mb: 4 }}
          variant="body2"
          color="text.secondary"
          align="center"
          dangerouslySetInnerHTML={{
            __html:
              '휴대폰 인증이 불가능한 경우 고객센터에 문의해주세요.<br>PMT 고객센터 ' + CUSTOMER_CENTER_PHONE_NUMBER,
          }}
        />
      </Container>
    </ThemeProvider>
  );
}
