import { setDateFormat, setNumberComma } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getClctDtl } from '@store/actions/calculationAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageCalculationCompleteDetail = ({ isDetailOpen, setIsDetailOpen, clctSn }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [resultData, setResultData] = useState({});

  const fn_resultData = (data) => {
    if (data.resultCode === 0) {
      setResultData(data.data);
    }
  };
  useEffect(() => {
    dispatch(
      getClctDtl({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        pageType: 3,
        clctSn,
        callback: fn_resultData,
      })
    );
  }, []);

  return (
    <>
      <Dialog open={isDetailOpen} fullWidth maxWidth={'sm'} disableEnforceFocus>
        <DialogTitle>
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h5" fontWeight={'bold'} mb={1}>
                  {resultData.prjcNm}
                </Typography>
                <Typography color={'grey'} variant="body2">
                  {resultData.cntrStDt} ~ {resultData.cntrEndDt}
                </Typography>
              </Box>
              <Close onClick={() => setIsDetailOpen(false)} sx={{ cursor: 'pointer' }} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} display={'flex'}>
              <Grid item xs={2}>
                <Typography>정산 주체</Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  size="small"
                  fullWidth
                  variant="standard"
                  value={
                    // resultData && resultData.coCd !== ''
                    //   ? resultData.coNm + ' (' + resultData.coCd + ')'
                    //   : resultData.coNm
                    resultData && resultData.coNm
                  }
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
          <TableContainer sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>정산 유형</TableCell>
                  <TableCell>정산 예정일</TableCell>
                  <TableCell>정산 처리일시</TableCell>
                  <TableCell sx={{ textAlign: 'end' }}>지급액</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(resultData).length > 0 && resultData.clctDtlList.length > 0 ? (
                  resultData.clctDtlList.map((item) => (
                    <TableRow key={item.clctDtlSn}>
                      <TableCell>{item.categoryNm}</TableCell>
                      <TableCell>{item.clctDt}</TableCell>
                      <TableCell>{setDateFormat(item.clctRealDttm, 'YYYY-MM-DD HH:mm:ss')}</TableCell>
                      <TableCell sx={{ textAlign: 'end' }}>{setNumberComma(String(item.cost))} 원</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      검색결과가 없습니다.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default PageCalculationCompleteDetail;
