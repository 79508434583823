import * as actions from '@actions/contractAction';

const INIT_STATE = {
  contractInfo: {
    prjcNm: '',
    clntCoNm: '',
    clntZip: '',
    clntAddr: '',
    clntDaddr: '',
    clntMngrNm: '',
    clntMngrTelno: '',
    clntMngrEmail: '',
    slsMngrNm: '',
    rqstWork: '',
    memo: '',
    cntrAmnt: '',
  },
  successYn: false,
};

const contractReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.SET_CNTR_DTL_INIT:
      return INIT_STATE;
    case actions.GET_CNTR_DTL_SUCCESS:
      return {
        ...state,
        contractInfo: { ...state.contractInfo, ...action.payload },
      };
    default:
      return state;
  }
};

export default contractReducer;
