import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  InputAdornment,
  Box,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Tooltip,
  Checkbox,
  Container,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import PagiNation from 'component/pagination';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { getBlngList, getWrkfList, delWrkfList } from '@store/actions/wrkfAction';
import PageWorkforceTeam from './PageWorkforceTeam';
import { isComplHangul, setTelFormat, showToast, showToastError } from '@common/common';
import PageWorkfoceSave from './PageWorkfoceSave';
import PageWorkforceDetail from './PageWorkforceDetail';
import { SEARCH_TIME } from '@common/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageWorkforceMain = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [wrkfInfoList, setWrkfInfoList] = useState([]);
  const [blngList, setBlngList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [regTeamOpen, setRegTeamOpen] = useState(false);
  const [regWrkfSaveOpen, setRegWrkfSaveOpen] = useState(false);

  const [searchValues, setSearchValues] = useState({
    searchType: 'name',
    searchValue: '',
  });

  const [selectValues, setSelectValues] = useState({
    userSn: userInfo.userSn,
    userId: userInfo.userId,
    pageSize: 20,
    pageNum: 1,
    blngSn: '0',
    rank: '-1',
  });

  useEffect(() => {
    onGetWrkfList();
  }, [selectValues]);

  useEffect(() => {
    onGetBlngList();
  }, []);

  useEffect(() => {
    if (location.state?.action === 'inptEvent') {
      handleDetailPopup(location.state.value);

      navigate(location.pathname, { replace: true, state: {} });
    }
  }, []);

  const onGetBlngList = () => {
    dispatch(
      getBlngList({
        userSn: userInfo.userSn,
        callback: fn_blngList,
      })
    );
  };
  const fn_blngList = (data) => {
    setBlngList(data);
  };

  const onGetWrkfList = () => {
    const typeToBlng = selectValues.blngSn === '0' ? '' : selectValues.blngSn;
    const typeToRank = selectValues.rank === '-1' ? '' : selectValues.rank;
    setAllChecked(false);
    setCheckedList([]);

    dispatch(
      getWrkfList({
        ...selectValues,
        ...searchValues,
        blngSn: typeToBlng,
        rank: typeToRank,
        callback: fn_wrkfList,
      })
    );
  };

  const useStateDefault = () => {
    setIsFirstRender(true);

    setSearchValues({
      searchType: 'name',
      searchValue: '',
    });

    setSelectValues({
      userSn: userInfo.userSn,
      userId: userInfo.userId,
      pageSize: 20,
      pageNum: 1,
      blngSn: '0',
      rank: '-1',
    });
  };

  const [totalCount, setTotalCount] = useState(0);

  const fn_wrkfList = (data) => {
    if (data.resultCode === 0) {
      setWrkfInfoList(data.data);
      setTotalCount(data.totalCount);
    } else {
      setWrkfInfoList([]);
    }
  };

  const returnPage = (event) => {
    setSelectValues((prev) => ({
      ...prev,
      pageNum: event,
    }));
  };

  const checkedItemHandler = (value, isChecked) => {
    setAllChecked(false);

    if (isChecked) {
      setCheckedList((prev) => [...prev, value]);

      return;
    }

    if (!isChecked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));

      return;
    }

    return;
  };

  const checkHandler = (e, value) => {
    checkedItemHandler(value, e.target.checked);
  };

  const onDelWrkfList = () => {
    dispatch(
      delWrkfList({
        userSn: userInfo.userSn,
        wrlfSnList: checkedList,
        callback: fn_delWrkfList,
      })
    );
  };

  const fn_delWrkfList = (data) => {
    if (data.resultCode === 0) {
      useStateDefault();
      setConfirmDelete(false);

      showToast('인력 정보가 삭제되었습니다.');
    } else {
      showToastError('인력 정보 삭제에 실패하였습니다.');
    }
  };

  const saveTeam = () => {
    setRegTeamOpen(false);

    useStateDefault();
    onGetBlngList();
  };

  const handleSaveWrkf = () => {
    setRegWrkfSaveOpen(false);

    useStateDefault();
  };

  const handleModiWrkf = () => {
    setIsDetailOpen(false);
    useStateDefault();
  };

  const debouncedDispatch = useCallback(
    _.debounce((newSearch, selectValues) => {
      const typeToBlng = selectValues.blngSn === '0' ? '' : selectValues.blngSn;
      const typeToRank = selectValues.rank === '-1' ? '' : selectValues.rank;
      if (isComplHangul(newSearch.searchValue)) {
        dispatch(
          getWrkfList({
            ...selectValues,
            ...newSearch,
            blngSn: typeToBlng,
            rank: typeToRank,
            callback: fn_wrkfList,
          })
        );
      }
    }, SEARCH_TIME),
    [dispatch]
  );

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setCheckedList([]);
    debouncedDispatch(searchValues, selectValues);
  }, [debouncedDispatch, searchValues.searchValue]);

  const handleSplitKywr = (key) => {
    const kywr = key.split(',');

    const plusMessage = kywr.length > 1 ? '외 ' + (kywr.length - 1) + '건' : '';

    return kywr[0] + plusMessage;
  };

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailSn, setDetailSn] = useState('');
  const handleDetailPopup = (value) => {
    setIsDetailOpen(true);
    setDetailSn(value);
  };

  const [allChecked, setAllChecked] = useState(false);

  const handleAllCheck = () => {
    setAllChecked(!allChecked);

    if (!allChecked) {
      wrkfInfoList.map((item) => {
        setCheckedList((prev) => [...prev, item.wrkfSn]);
      });
    } else {
      setCheckedList([]);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setSearchValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnChangeSelect = (event) => {
    const { name, value } = event.target;

    setSelectValues((prev) => ({
      ...prev,
      pageNum: 1,
      [name]: value,
    }));
  };

  const renderProjectCell = (project) => {
    if (!project) {
      return ''; // 값이 없을 때 빈 값을 반환
    }

    const projectArray = project.split(','); // 쉼표로 구분된 문자열을 배열로 변환

    if (projectArray.length === 1) {
      return projectArray[0]; // 값이 1개일 때 그 값을 반환
    }

    return `${projectArray[0]} 외 ${projectArray.length - 1}건`; // 값이 여러 개일 때 "x건"으로 표시
  };

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [alertConfirm, setAlertConfirm] = useState(false);
  const handleDelete = () => {
    if (checkedList.length > 0) {
      setConfirmDelete(true);
    } else {
      setAlertConfirm(true);
    }
  };

  return (
    <>
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          justifyContent: 'space-between',
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ minWidth: 150, mr: 1 }}>
            <FormControl size="small" fullWidth>
              <Select
                id="demo-simple-select"
                name="searchType"
                value={searchValues.searchType}
                onChange={(e) => handleOnChange(e)}
              >
                <MenuItem value="name">이름</MenuItem>
                <MenuItem value="age">나이</MenuItem>
                <MenuItem value="cpNm">회사</MenuItem>
                <MenuItem value="mblNo">연락처</MenuItem>
                <MenuItem value="email">이메일</MenuItem>
                <MenuItem value="project">프로젝트명</MenuItem>
                <MenuItem value="keyWord">키워드</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TextField
            required
            placeholder="내용을 검색해주세요"
            autoComplete="off"
            className="inp-round"
            size="small"
            name="searchValue"
            onKeyUp={(e) => handleOnChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button sx={{ mr: 1 }} className="btn" onClick={() => setRegTeamOpen(true)}>
            소속 편집
          </Button>
          <Button className="btn" sx={{ mr: 1 }} onClick={() => setRegWrkfSaveOpen(true)}>
            등록
          </Button>
          <Button className="btn-red" onClick={() => handleDelete()}>
            삭제
          </Button>
        </Box>
      </Toolbar>
      <Container
        sx={{
          width: '100%',
          maxWidth: '100% !important',
          paddingLeft: '40px !important',
          paddingRight: '40px !important',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Toolbar
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" fontWeight={'bold'}>
            총 {totalCount} 명
          </Typography>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select onChange={(e) => handleOnChangeSelect(e)} value={selectValues.blngSn} name="blngSn">
                <MenuItem value="0">소속 전체</MenuItem>
                {blngList.data &&
                  blngList.data.map((i) => (
                    <MenuItem value={i.blngSn} key={i.blngSn}>
                      {i.teamNm}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select value={selectValues.rank} name="rank" onChange={(e) => handleOnChangeSelect(e)}>
                <MenuItem value="-1">직급 전체</MenuItem>
                <MenuItem value="0">인턴</MenuItem>
                <MenuItem value="1">사원</MenuItem>
                <MenuItem value="2">주임</MenuItem>
                <MenuItem value="3">대리</MenuItem>
                <MenuItem value="4">과장</MenuItem>
                <MenuItem value="5">차장</MenuItem>
                <MenuItem value="6">부장</MenuItem>
                <MenuItem value="7">이사</MenuItem>
                <MenuItem value="8">상무</MenuItem>
                <MenuItem value="9">전무</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
              <Select
                labelId="wrkf-size"
                onChange={(e) => handleOnChangeSelect(e)}
                name="pageSize"
                value={selectValues.pageSize}
              >
                <MenuItem value={20}>20건</MenuItem>
                <MenuItem value={50}>50건</MenuItem>
                <MenuItem value={100}>100건</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Toolbar>
        <TableContainer sx={{ pb: '10px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: 0.5, width: '5%' }}>
                  <Checkbox checked={allChecked} onChange={handleAllCheck} disabled={!wrkfInfoList.length > 0} />
                </TableCell>
                <TableCell sx={{ padding: 0.5, width: '5%' }}>NO</TableCell>
                <TableCell sx={{ padding: 0.5, width: '8%' }}>이름</TableCell>
                <TableCell sx={{ padding: 0.5, width: '3%' }}>성별</TableCell>
                <TableCell sx={{ padding: 0.5, width: '5%' }}>나이</TableCell>
                <TableCell sx={{ padding: 0.5, width: '15%' }}>회사</TableCell>
                <TableCell sx={{ padding: 0.5, width: '10%' }}>소속</TableCell>
                <TableCell sx={{ padding: 0.5, width: '5%' }}>직급</TableCell>
                <TableCell sx={{ padding: 0.5, width: '10%' }}>연락처</TableCell>
                <TableCell sx={{ padding: 0.5, width: '15%' }}>이메일</TableCell>
                <TableCell sx={{ padding: 0.5, width: '10%' }}>투입 프로젝트</TableCell>
                <TableCell sx={{ padding: 0.5, width: '9%' }}>키워드</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wrkfInfoList ? (
                wrkfInfoList.map((item, index) => (
                  <TableRow key={item.wrkfSn} onClick={() => handleDetailPopup(item.wrkfSn)} sx={{ cursor: 'pointer' }}>
                    <TableCell sx={{ padding: 0.5 }} onClick={(event) => event.stopPropagation()}>
                      <Checkbox
                        checked={checkedList.includes(item.wrkfSn)}
                        onChange={(e) => checkHandler(e, item.wrkfSn)}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>
                      {index + (selectValues.pageNum - 1) * selectValues.pageSize + 1}
                    </TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.userNm}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.gender}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.age + '세'}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.cpNm}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.teamNm}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.rank}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{setTelFormat(item.mblNo1, '-')}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{item.email}</TableCell>
                    <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{renderProjectCell(item.project)}</TableCell>
                    <Tooltip title={item.kywr}>
                      <TableCell sx={{ padding: 0.5, whiteSpace: 'nowrap' }}>{handleSplitKywr(item.kywr)}</TableCell>
                    </Tooltip>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    검색결과가 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PagiNation
          totalItems={totalCount}
          itemCountPerPage={selectValues.pageSize}
          currentPage={selectValues.pageNum}
          returnPage={returnPage}
        />
      </Container>
      {regTeamOpen && <PageWorkforceTeam regTeamOpen={regTeamOpen} setRegTeamOpen={setRegTeamOpen} saves={saveTeam} />}
      {regWrkfSaveOpen && (
        <PageWorkfoceSave
          regWrkfSaveOpen={regWrkfSaveOpen}
          setRegWrkfSaveOpen={setRegWrkfSaveOpen}
          teamList={blngList.data}
          resultSaveWrkf={handleSaveWrkf}
        />
      )}
      {isDetailOpen && (
        <PageWorkforceDetail
          isDetailOpen={isDetailOpen}
          setIsDetailOpen={setIsDetailOpen}
          teamList={blngList.data}
          resultModiWrkf={handleModiWrkf}
          detailSn={detailSn}
        />
      )}
      {confirmDelete && (
        <ConfirmPopUp
          open={confirmDelete}
          close={setConfirmDelete}
          title={'선택한 인력을 삭제하시겠습니까?'}
          comment={
            '삭제 시 해당 인력의 프로젝트 투입 정보 및 지급 정보를 비롯한 관련 모든 정보가 삭제되며,\n삭제 후에는 되돌릴 수 없습니다.'
          }
          btnNm1={'아니요'}
          btnNm2={'예'}
          btnEvent={onDelWrkfList}
        />
      )}
      {alertConfirm && (
        <ConfirmPopUp
          open={alertConfirm}
          close={setAlertConfirm}
          title={'삭제할 항목을 선택해주세요.'}
          btnNm2={'확인'}
        />
      )}
    </>
  );
};

export default PageWorkforceMain;
