import { downloadAPI, fileAPI, sendAPI } from '@axios/index';

/* 프로젝트 관리 */
const getPrjcMainList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-main-list`, params, payload);
};

const putPrjcMainFvrtYn = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-main-fvrt-yn`, params, payload);
};

const getCntrNmList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-cntr-nm-list`, params, payload);
};

const getPrjcEventList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-event-list`, params, payload);
};

const getPrjcDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-dtl`, params, payload);
};

const postPrjc = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-prjc`, params, payload);
};

const putPrjcMain = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-main`, params, payload);
};

const putPrjcMainDelYn = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-main-del-yn`, params, payload);
};

const getPrjcMainInfo = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-main-info`, params, payload);
};

const getPrjcGrpList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-grp-list`, params, payload);
};

const getPrjcGrpDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-grp-dtl`, params, payload);
};

const postPrjcGrp = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-prjc-grp`, params, payload);
};

const putPrjcGrp = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-grp`, params, payload);
};

const getPrntPrjcTaskList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prnt-prjc-task-list`, params, payload);
};

const postPrjcTask = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-prjc-task`, params, payload);
};

const getPrjcTaskInfo = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-task-info`, params, payload);
};

const getPrjcTaskList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-task-list`, params, payload);
};

const getPrjcTaskNmList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-task-nm-list`, params, payload);
};

const getPrjcTaskDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-task-dtl`, params, payload);
};

const putPrjcTask = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-task`, params, payload);
};

const putPrjcTaskList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-task-list`, params, payload);
};

const getPrjcTaskPrsnDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-task-prsn-dtl`, params, payload);
};

const postPrjcEvent = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-prjc-event`, params, payload);
};

const putPrjcEvent = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-prjc-event`, params, payload);
};

const delPrjcEvent = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-prjc-event`, params, payload);
};

const getInptPrsnList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-inpt-prsn-list`, params, payload);
};

const postInptPrsn = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-inpt-prsn`, params, payload);
};

const delInptPrsn = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-inpt-prsn`, params, payload);
};

const getInptPrsnDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-inpt-prsn-dtl`, params, payload);
};

const getInptPrsnUpdDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-inpt-prsn-upd-dtl`, params, payload);
};

const putInptPrsn = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-inpt-prsn`, params, payload);
};

const getIssueList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-issue-list`, params, payload);
};

const postIssue = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/api/post-issue`, params, payload);
};

const delIssue = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-issue`, params, payload);
};

const getIssueDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-issue-dtl`, params, payload);
};

const postIssueCmt = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-issue-cmt`, params, payload);
};

const putIssueStts = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-issue-stts`, params, payload);
};

const putIssue = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/api/put-issue`, params, payload);
};

const putInptPrsnStop = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-inpt-prsn-stop`, params, payload);
};

const postPrjcTaskListDownload = ({ params = {}, payload = {} }) => {
  return downloadAPI('POST', `/api/get-prjc-task-list-excel`, params, payload);
};

const getInptPrsnCheck = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-inpt-prsn-check`, params, payload);
};

const getPrjcMainNmList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-prjc-main-nm-list`, params, payload);
};

const postClntPrjc = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/post-clnt-prjc`, params, payload);
};

const putClntPrjc = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/put-clnt-prjc`, params, payload);
};

const api = {
  getPrjcMainList,
  putPrjcMainFvrtYn,
  getCntrNmList,
  getPrjcEventList,
  getPrjcDtl,
  postPrjc,
  putPrjcMain,
  putPrjcMainDelYn,
  getPrjcMainInfo,
  getPrjcGrpList,
  getPrjcGrpDtl,
  postPrjcGrp,
  putPrjcGrp,
  getPrntPrjcTaskList,
  postPrjcTask,
  getPrjcTaskInfo,
  getPrjcTaskList,
  getPrjcTaskNmList,
  getPrjcTaskDtl,
  putPrjcTask,
  putPrjcTaskList,
  getPrjcTaskPrsnDtl,
  postPrjcEvent,
  putPrjcEvent,
  delPrjcEvent,
  getInptPrsnList,
  postInptPrsn,
  delInptPrsn,
  getInptPrsnDtl,
  getInptPrsnUpdDtl,
  putInptPrsn,
  getIssueList,
  postIssue,
  delIssue,
  getIssueDtl,
  postIssueCmt,
  putIssueStts,
  putIssue,
  putInptPrsnStop,
  postPrjcTaskListDownload,
  getInptPrsnCheck,
  getPrjcMainNmList,
  postClntPrjc,
  putClntPrjc,
};

export default api;
