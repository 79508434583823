import { Box, Container, CssBaseline, Typography, createTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

const PageJoinRequestLayout = ({ children, title, titleBlack, content }) => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%', // 너비를 100%로 설정
          }}
        >
          <Box display={'flex'} alignItems={'center'} mb={3}>
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
              <img src="/logo.png" style={{ width: '150px' }} />
            </Box>
            <Typography variant="h6">회원가입</Typography>
          </Box>
          <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color={'primary'} fontWeight={'bold'}>
                {title}
              </Typography>
              <Typography fontWeight={'bold'}>&nbsp;{titleBlack}</Typography>
            </Box>
            <Typography>{content}</Typography>
            {children}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PageJoinRequestLayout;
