import { Box, Button, Card, Grid, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageProjectTaskRegModal from './PageProjectTaskRegModal';
import PageProjectTaskUpdGroupModal from './PageProjectTaskUpdGroupModal';
import PageProjectTaskGanttChart from './PageProjectTaskGanttChart';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPrjcTaskInfo, postPrjcTaskListDownload } from '@store/actions/projectAction';
import { useParams } from 'react-router-dom';
import { failDownloadExcel, postDownloadExcel, showToastError } from '@common/common';
import PageProjectTaskDelModal from './PageProjectTaskDelModal';

const PageProjectTask = ({ projectSn }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const pUser = useSelector((state) => state.projectReducer.project);
  const id = projectSn;

  const [isRegOpen, setIsRegOpen] = useState(false);
  const [isUpdGroupOpen, setIsUpdGroupOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [taskInfo, setTaskInfo] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    dispatch(
      getPrjcTaskInfo({
        userSn: user.userSn,
        userId: user.userId,
        prjcMainSn: id,
        callback: handleGetPrjcTaskInfoCallback,
      })
    );
  }, [isSuccess]);

  const handleGetPrjcTaskInfoCallback = (data) => {
    if (data.resultCode === 0) {
      setTaskInfo(data.data);
    } else {
      showToastError(data);
    }
  };

  const handleExcelDownload = () => {
    dispatch(
      postPrjcTaskListDownload({
        userSn: user.userSn,
        userId: user.userId,
        puserSn: pUser.puserSn,
        prjcMainSn: id,
        prjcGrpSn: 0,
        callback: handlePostPrjcTaskListDownloadCallback,
      })
    );
  };

  const handlePostPrjcTaskListDownloadCallback = (data, status) => {
    if (status === 200) {
      postDownloadExcel(data, '업무 목록');
    } else {
      failDownloadExcel(data);
    }
  };

  return (
    <>
      <Grid item md={12} p={2}>
        <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Grid
            container
            component={Card}
            variant="outlined"
            sx={{
              borderColor: 'primary',
              mb: 2,
            }}
          >
            <Grid container textAlign={'center'} py={2}>
              <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Grid item md={12}>
                  <Typography>대기중 업무</Typography>
                </Grid>
                <Grid item md={12} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'}>
                    {taskInfo && taskInfo.taskStts0}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Grid item md={12}>
                  <Typography>진행중 업무</Typography>
                </Grid>
                <Grid item md={12} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'}>
                    {taskInfo && taskInfo.taskStts1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Grid item md={12}>
                  <Typography>피드백 업무</Typography>
                </Grid>
                <Grid item md={12} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'}>
                    {taskInfo && taskInfo.taskStts2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Grid item md={12}>
                  <Typography>지연된 업무</Typography>
                </Grid>
                <Grid item md={12} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'}>
                    {taskInfo && taskInfo.taskStts4}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item p={2} md={2} sx={{ borderRight: 1, borderColor: 'divider' }}>
                <Grid item md={12}>
                  <Typography>보류된 업무</Typography>
                </Grid>
                <Grid item md={12} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'}>
                    {taskInfo && taskInfo.taskStts5}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item p={2} md={2}>
                <Grid item md={12}>
                  <Typography>완료된 업무</Typography>
                </Grid>
                <Grid item md={12} justifyContent={'center'}>
                  <Typography variant="h6" color={'primary'}>
                    {taskInfo && taskInfo.taskStts3}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <Grid container justifyContent={'space-between'} p={2}>
          <Grid item>
            <Button className="btn" onClick={handleExcelDownload}>
              다운로드
            </Button>
          </Grid>
          {user.userFlag !== 'C' && (
            <Grid item display={'flex'} alignItems={'center'}>
              <Button className="btn dispSv" sx={{ mr: 1 }} onClick={() => setIsRegOpen(true)}>
                업무 등록
              </Button>
              {/* <Button className="btn" sx={{ mr: 1 }}>
              엑셀 등록
              </Button>  */}
              <Button className="btn" sx={{ mr: 1 }} onClick={() => setIsUpdGroupOpen(true)}>
                그룹 편집
              </Button>
              <Button className="btn-red" onClick={() => setIsDeleteOpen(true)}>
                삭제
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <PageProjectTaskGanttChart id={projectSn} isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
        </Grid>
      </Grid>
      {isRegOpen && (
        <PageProjectTaskRegModal
          isOpen={isRegOpen}
          setIsOpen={setIsRegOpen}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
        />
      )}
      {isUpdGroupOpen && (
        <PageProjectTaskUpdGroupModal
          isOpen={isUpdGroupOpen}
          setIsOpen={setIsUpdGroupOpen}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
        />
      )}
      {isDeleteOpen && (
        <PageProjectTaskDelModal
          isOpen={isDeleteOpen}
          setIsOpen={setIsDeleteOpen}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
        />
      )}
    </>
  );
};

export default PageProjectTask;
