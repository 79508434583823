import {
  Box,
  Button,
  Chip,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { delIssue, getIssueDtl, postIssueCmt } from '@store/actions/projectAction';
import { downloadFileDB, setDateFormat, showToast } from '@common/common';
import { MoreVert } from '@mui/icons-material';
import PageProjectIssueStateModal from './PageProjectIssueStateModal';
import PageProjectIssueReg from './PageProjectIssueReg';
import PageProjectIssueUpdate from './PageProjectIssueUpdate';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageProjectIssueDetail = () => {
  const navigate = useNavigate();
  const commonReducer = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();

  const [issueResult, setIssueResult] = useState({
    issueSn: '',
    issueNm: '',
    type: '',
    stts: '',
    typeNm: '',
    sttsNm: '',
    userNm: '',
    coNm: '',
    url: '',
    cnt: '',
    content: '',
    mngrList: [],
    chkList: [],
    fileSnList: [],
    cmtList: [],
    kebabExpsYn: '',
    issueStartDt: '',
    issueEndDt: '',
    regDttm: '',
  });
  const fn_issueResult = (data) => {
    if (data.resultCode === 0) {
      setIssueResult(data.data);
    }
  };

  const getIssueDetail = () => {
    dispatch(
      getIssueDtl({
        issueSn: commonReducer.issueSn,
        userFlag: commonReducer.user.userFlag,
        userId: commonReducer.user.userId,
        userSn: commonReducer.user.userSn,
        callback: fn_issueResult,
      })
    );
  };

  useEffect(() => {
    getIssueDetail();
  }, []);

  const downloadFile = (file) => {
    downloadFileDB(file.fileSn, commonReducer.issueSn, 'issue', file.oriFileNm);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
    else if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(1)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const options = ['상태변경', '수정', '삭제'];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (idx) => {
    handleClose();
    if (idx === 0) {
      handleChangeState();
    } else if (idx === 1) {
      handleUpdateIssue();
    } else if (idx === 2) {
      handleDeleteIssue();
    }
  };

  const [confirmDelete, setConfirmDelte] = useState(false);
  const handleDeleteIssue = () => {
    setConfirmDelte(true);
  };

  const [value, setValue] = useState('');

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');
    if (type) {
      setValue(type);
    }
  }, [location]);

  const fn_deleteResult = (data) => {
    if (data.resultCode === 0) {
      showToast('이슈가 삭제 되었습니다.');
      setConfirmDelte(true);
      navigate('/mng/project/detail?tab=2&&type=' + value);
    }
  };

  const deleteIssue = () => {
    dispatch(
      delIssue({
        userId: commonReducer.user.userId,
        userSn: commonReducer.user.userSn,
        issueSnList: [commonReducer.issueSn],
        callback: fn_deleteResult,
      })
    );
  };

  const [statePopup, setStatePopup] = useState(false);
  const handleChangeState = () => {
    setStatePopup(true);
  };

  const [updateIssuePopup, setUpdateIssuePopup] = useState(false);
  const handleUpdateIssue = () => {
    setUpdateIssuePopup(true);
  };

  const [saveComment, setSaveComment] = useState({
    issueSn: commonReducer.issueSn,
    userId: commonReducer.user.userId,
    userSn: commonReducer.user.userSn,
    content: '',
  });

  const fn_cmtResult = (data) => {
    if (data.resultCode === 0) {
      setSaveComment((prev) => ({
        ...prev,
        content: '',
      }));
      getIssueDetail();
    }
  };

  const handleSave = () => {
    dispatch(
      postIssueCmt({
        ...saveComment,
        callback: fn_cmtResult,
      })
    );
  };

  const handleOnchange = (e) => {
    if (e.target.value.length > 500) return;

    setSaveComment((prev) => ({
      ...prev,
      content: e.target.value,
    }));
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => navigate('/mng/project/detail?tab=2&&type=' + value)}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
          <Chip label={issueResult.typeNm} variant="outlined" sx={{ borderColor: 'red', mr: 2, fontWeight: 'bold' }} />
          <Chip
            label={issueResult.sttsNm}
            variant="outlined"
            sx={{ borderColor: '#307bff', mr: 2, fontWeight: 'bold' }}
          />
          <Typography gutterBottom variant="h6" fontWeight={'bold'} component="div" sx={{ marginBottom: '0' }}>
            {issueResult.issueNm}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography mr={2}>조회 {issueResult.cnt}</Typography>
          {issueResult.kebabExpsYn === 'Y' ? (
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>

              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
                onClick={(event) => event.stopPropagation()}
              >
                {options.map((option, idx) => {
                  return (
                    <MenuItem key={option} onClick={() => handleOnClick(idx)}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          ) : (
            <Button sx={{ visibility: 'hidden' }} />
          )}
        </Box>
      </Toolbar>
      <Grid container mt={2}>
        <Grid item xs={12} mx={3}>
          <Grid
            container
            border={'1px solid lightgrey'}
            sx={{
              borderRadius: '15px',
            }}
          >
            <Grid item xs={12} padding={'24px'}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={1}>
                  <Typography>
                    {issueResult.content.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={'bold'}>담당자 </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    {issueResult.mngrList.length > 0 ? issueResult.mngrList.map((item) => item.userNm).join(', ') : ''}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={'bold'}>검수자 </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    {issueResult.chkList.length > 0 ? issueResult.chkList.map((item) => item.userNm).join(', ') : ''}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={'bold'}>처리 기간 </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>
                    {setDateFormat(issueResult.issueStartDt, 'YYYY-MM-DD')} ~{' '}
                    {setDateFormat(issueResult.issueEndDt, 'YYYY-MM-DD')}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={'bold'}>관련 URL </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{issueResult.url}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontWeight={'bold'}>관련 첨부파일 </Typography>
                </Grid>
                <Grid item xs={10} alignItems={'center'}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      justifyItems: 'start',
                    }}
                  >
                    {issueResult.fileSnList.length > 0 &&
                      issueResult.fileSnList.map((file) => (
                        <Box
                          key={file.fileSn}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="button"
                            display="block"
                            sx={{ textTransform: 'none', cursor: 'pointer' }}
                            onClick={() => downloadFile(file)}
                            gutterBottom
                          >
                            {file.oriFileNm || file.name} ({formatFileSize(file.fileSize || file.size)})
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={3} mb={2}>
        <Grid item xs={12} mx={3}>
          <Grid
            container
            border={'1px solid lightgrey'}
            sx={{
              borderRadius: '10px',
            }}
            mb={1}
          >
            {issueResult.cmtList.length > 0 ? (
              <Grid item xs={12} padding={2}>
                <Grid container alignItems={'center'}>
                  {issueResult.cmtList.length > 0 &&
                    issueResult.cmtList.map((item) => (
                      <Grid key={item.issueCommentSn} item xs={12} my={2}>
                        <Typography color={'darkgray'} mb={1}>
                          {setDateFormat(item.regDttm, 'YYYY-MM-DD HH:mm:ss')} {item.userNm}
                        </Typography>
                        <Typography color={'black'}>{item.content}</Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            <Grid item xs={12} padding={'24px'}>
              <Grid container alignItems={'end'}>
                <Grid item xs={11} pr={'16px'}>
                  <TextField
                    multiline
                    fullWidth
                    rows={2}
                    onChange={(e) => handleOnchange(e)}
                    value={saveComment.content}
                    placeholder="남길 코멘트를 입력해주세요."
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button className="btn-navy" onClick={handleSave}>
                    등록
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'end'}>
            <Typography color={'darkgray'}>
              {setDateFormat(issueResult.regDttm, 'YYYY-MM-DD HH:mm:ss')} {issueResult.userNm}/{issueResult.coNm}{' '}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {statePopup && (
        <PageProjectIssueStateModal
          statePopup={statePopup}
          setStatePopup={setStatePopup}
          ingStateNm={issueResult.sttsNm}
          ingStateNum={issueResult.stts}
          issueSn={issueResult.issueSn}
          stateSave={getIssueDetail}
        />
      )}
      {updateIssuePopup && (
        <PageProjectIssueUpdate
          updateIssuePopup={updateIssuePopup}
          setUpdateIssuePopup={setUpdateIssuePopup}
          handleDetail={getIssueDetail}
          issueDetail={issueResult}
          issueSn={issueResult.issueSn}
          projectSn={commonReducer.projectSn}
        />
      )}
      {confirmDelete && (
        <ConfirmPopUp
          open={confirmDelete}
          close={setConfirmDelte}
          title={'삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다.'}
          btnNm1={'아니요'}
          btnNm2={'예'}
          btnEvent={deleteIssue}
        />
      )}
    </>
  );
};

export default PageProjectIssueDetail;
