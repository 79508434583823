import { combineReducers } from 'redux';
import commonReducer from './commonReducer';
import userReducer from './userReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // session storage
import counterpartyReducer from './counterpartyReducer';
import salesReducer from './salesReducer';
import contractReducer from './contractReducer';
import projectReducer from './projectReducer';
import mainReducer from './mainReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['commonReducer'],
};

const rootReducer = combineReducers({
  commonReducer,
  userReducer,
  counterpartyReducer,
  salesReducer,
  contractReducer,
  projectReducer,
  mainReducer,
});

export default persistReducer(persistConfig, rootReducer);
