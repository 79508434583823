import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageCalculationStat from './PageCalculationStat';
import PageCalculationBoard from './PageCalculationBoard';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getClctBoard, getClctStats } from '@store/actions/calculationAction';

const PageCalculationStatistic = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.commonReducer.user);

  const [toggleValue, setToggleValue] = useState('S');

  const [years, setYears] = useState([]);

  useEffect(() => {
    const toDay = new Date();

    const toDayYear = toDay.getFullYear();

    let yearList = [];
    for (let i = toDayYear; i <= toDayYear + 5; i++) {
      yearList.push(i);
    }

    setYears(yearList);
  }, []);

  const [resultStats, setResultStats] = useState({});
  const [resultBoard, setResultBoard] = useState({});
  const [selectYear, setSelectYear] = useState('');
  useEffect(() => {
    if (toggleValue === 'S') {
      dispatch(
        getClctStats({
          userSn: userInfo.userSn,
          callback: fn_resultData,
        })
      );
    } else if (toggleValue === 'B') {
      getBoard();
    }
  }, [toggleValue, selectYear]);

  const fn_resultData = (data) => {
    if (data.resultCode === 0) {
      if (toggleValue === 'S') {
        setResultStats(data.data);
      } else if (toggleValue === 'B') {
        setResultBoard(data.data);
      }
    }
  };

  const getBoard = () => {
    dispatch(
      getClctBoard({
        userSn: userInfo.userSn,
        searchYear: selectYear,
        callback: fn_resultData,
      })
    );
  };

  return (
    <>
      <Toolbar>
        <Grid
          container
          borderBottom={1}
          borderColor={'divider'}
          pb={2}
          pt={2}
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={toggleValue}
            className="radio-btn"
            exclusive
            onChange={(e) => setToggleValue(e.target.value)}
          >
            <ToggleButton value="S" aria-label="S">
              Stats
            </ToggleButton>
            <ToggleButton value="B" aria-label="B">
              Board
            </ToggleButton>
          </ToggleButtonGroup>
          <Box visibility={toggleValue !== 'B' ? 'hidden' : 'visible'}>
            <FormControl size="small" sx={{ minWidth: 150, mr: 1 }}>
              <Select fullWidth displayEmpty value={selectYear} onChange={(e) => setSelectYear(e.target.value)}>
                <MenuItem value="">전체</MenuItem>
                {years.length > 0 &&
                  years.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Toolbar>
      {toggleValue === 'S' && <PageCalculationStat resultStats={resultStats} />}
      {toggleValue === 'B' && <PageCalculationBoard resultStats={resultBoard} updateProcess={getBoard} />}
    </>
  );
};

export default PageCalculationStatistic;
