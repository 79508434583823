import { setDateFormat } from '@common/common';
import { Chip, Grid, Popover, Typography } from '@mui/material';
import { getCntrHistory } from '@store/actions/contractAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageContractHistory = ({ anchorEl, id, open, handleClose, data }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        '.MuiPopover-paper': {
          minWidth: '350px',
          padding: '20px',
          borderRadius: '15px',
          boxShadow:
            '0px 5px 5px -3px rgba(0, 0, 0, 0.01), 0px 8px 5px 1px rgba(0, 0, 0, 0.02), 0px 3px 5px 2px rgba(0, 0, 0, 0.02)',
        },
      }}
    >
      <Typography variant="body1" color={'gray'}>
        HISTORY
      </Typography>
      {data &&
        data.data.cntrHistoryList.map((item, index) => (
          <Grid container key={index} alignItems={'center'} justifyContent={'space-between'} pt={2}>
            <Grid item md={4}>
              {item.stts === '0' ? (
                <Chip label="계약준비중" variant="outlined" sx={{ minWidth: '90px' }} />
              ) : item.stts === '1' ? (
                <Chip label="계약진행중" color="primary" variant="outlined" sx={{ minWidth: '90px' }} />
              ) : item.stts === '2' ? (
                <Chip label="계약종료" color="success" variant="outlined" sx={{ minWidth: '90px' }} />
              ) : (
                <Chip label="계약중단" color="error" variant="outlined" sx={{ minWidth: '90px' }} />
              )}
            </Grid>
            <Grid item md={7}>
              <Typography variant="body2">
                {setDateFormat(item.regDttm, 'YYYY-MM-DD HH:mm')} {item.regNm}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Popover>
  );
};

export default PageContractHistory;
