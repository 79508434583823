import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast, showToastError, isNumber, isValue } from '@common/common';
import { getStgList, getChkStgDel, putStgList } from '@store/actions/salesAction';
import AddIcon from '@mui/icons-material/Add';
import Sortable from 'sortablejs';
import { AccountCircle, Lock, DragHandle } from '@mui/icons-material';
import ConfirmPopUp from 'component/ConfirmPopUp';

const PageSalesStepModal = ({ stepOpen, setStepOpen, setIsSuccessYn, isSuccessYn }) => {
  const userInfo = useSelector((state) => state.commonReducer.user);
  const dispatch = useDispatch();
  const [plusButton, setPlusButton] = useState(true);
  const [stgList, setStgList] = useState();
  const [stgLength, setStgLength] = useState();
  const [cancelPop, setCancelPop] = useState(false);
  const [regPop, setRegPop] = useState(false);
  const sortableContainerRef = useRef(null);
  const sortableRef = useRef(null); // Sortable instance reference
  const [arrStg, setArrStg] = useState([]);

  useEffect(() => {
    if (sortableContainerRef.current) {
      // Destroy existing Sortable instance if it exists
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }

      // Create a new Sortable instance
      sortableRef.current = new Sortable(sortableContainerRef.current, {
        handle: '.handle',
        animation: 150,
        //draggable: '.draggable-item:not(.fixed)',
        filter: '.fixed', // Class name of elements that should not be sortable
        preventOnFilter: false,
        onEnd: (event) => {
          const { oldIndex, newIndex } = event;

          if (oldIndex === 0 || newIndex === 0) {
            return;
          }

          if (oldIndex !== newIndex) {
            const updatedItems = [...stgList];
            const [movedItem] = updatedItems.splice(oldIndex, 1);
            updatedItems.splice(newIndex, 0, movedItem);
            setStgList(updatedItems);
          }
        },
        onMove: (event) => {
          const { related, dragged } = event;
          const relatedIndex = Array.from(related.parentNode.children).indexOf(related);
          // Prevent drop to the first position
          if (relatedIndex === 0) {
            return false;
          }

          return true;
        },
      });
    }

    if (stgLength > 9) {
      setPlusButton(false);
    } else {
      setPlusButton(true);
    }
  }, [stgLength, stgList, sortableContainerRef.current]);

  const getStgListHandler = () => {
    dispatch(
      getStgList({
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: getStgListCallback,
      })
    );
  };

  useEffect(() => {
    getStgListHandler();
  }, []);

  const getStgListCallback = (data) => {
    if (data.resultCode === 0) {
      console.log('data.data', data.data.stgList);
      setStgList(data.data.stgList);
      setStgLength(data.data.stgList.length);
      console.log('stgLenth', stgLength);
    } else {
      showToast(data);
    }
  };

  const handlAddContent = () => {
    console.log('stgLength', stgLength);
    setStgLength(stgLength + 1);
    const data = {
      slsStgSn: Math.random().toString(36).substring(2, 11),
      stg: stgLength,
      stgNm: '',
    };

    setStgList((prev) => [...prev, data]);

    //setData(data);
  };

  const deleteContent = (value) => {
    if (!isNumber(value.slsStgSn)) {
      setStgList(stgList.filter((item) => item.slsStgSn !== value.slsStgSn));
      setStgLength(stgLength - 1);
    } else {
      dispatch(
        getChkStgDel({
          slsStgSn: value.slsStgSn,
          userSn: userInfo.userSn,
          userId: userInfo.userId,
          callback: (data) => {
            if (data.resultCode === 0) {
              setStgList(stgList.filter((item) => item.slsStgSn !== value.slsStgSn));
              setStgLength(stgLength - 1);
              //setSendData(sendData.filter((item) => item.slsStgSn !== value.slsStgSn));
            } else {
              showToast('해당 단계에 속한 영업 프로젝트가 존재합니다. 프로젝트 삭제 후 다시 진행해주세요.');
            }
          },
        })
      );
    }
  };

  const handleSaveConfirm = () => {
    //onsole.log('stgLength', stgLength);
    //console.log('sendData', stgList, sendData);

    const tempArrStg = [];
    let arrStg = [];
    let objStg = {};
    let isSave = true;
    stgList.map((item, index) => {
      if (!isNumber(item.slsStgSn)) item.slsStgSn = '';
      if (!isValue(item.stgNm)) {
        isSave = false;
      }
      objStg = {
        slsStgSn: item.slsStgSn,
        stg: index + 1,
        stgNm: item.stgNm,
      };
      tempArrStg.push(objStg);

      setArrStg(tempArrStg);
    });

    if (isSave) {
      setRegPop(true);
    } else {
      showToast('단계 이름을 입력해주세요.');
    }
  };

  const handleSave = () => {
    dispatch(
      putStgList({
        stgList: arrStg,
        userSn: userInfo.userSn,
        userId: userInfo.userId,
        callback: putStgListCallback,
      })
    );
  };

  const putStgListCallback = (data, status) => {
    if (data.resultCode === 0) {
      showToast('[영업관리] 내 단계가 수정 되었습니다.');
      setStepOpen(!stepOpen);
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError('등록에 실패하였습니다.');
    }
  };

  const changeStg = (item, e) => {
    item.stgNm = e.target.value;
    //setData(item);
  };

  // const setData = (data) => {
  //   const newStg = {
  //     userId: userInfo.userId,
  //     userSn: userInfo.userSn,
  //     slsStgSn: data.slsStgSn,
  //     stg: data.stg,
  //     stgNm: data.stgNm
  //   };

  //   setSendData((prev) => {
  //     const itemExists = prev.some((item) => item.stg === newStg.stg);
  //     if (itemExists) {
  //       return prev.map((item) =>
  //         item.stg === newStg.stg ? newStg : item
  //       );
  //     } else {
  //       return [...prev, newStg];
  //     }
  //   });
  // };

  return (
    <>
      <Dialog open={stepOpen} fullWidth maxWidth={'xs'} disableEnforceFocus sx={{ minHeight: '500px' }}>
        <DialogTitle>
          <Typography className="modal-tit">단계 편집</Typography>
          <Typography color={'grey'} variant="body2" mt={1}>
            단계 위치 이동 시 영업 프로젝트도 함께 이동되며,
            <br />
            영업프로젝트가 있는 단계는 삭제할 수 없어요. (최대 10단계로 구성 가능)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid ref={sortableContainerRef}>
            {stgList &&
              stgList.map((item, index) => (
                <Grid container className="inp-list" key={item.slsStgSn}>
                  <Grid container>
                    <Grid item md={11}>
                      <TextField
                        defaultValue={item.stgNm}
                        placeholder="단계 이름을 입력해주세요"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        className={index === 0 ? 'fixed' : ''}
                        onChange={(e) => changeStg(item, e)}
                        InputProps={{
                          maxLength: 15,
                          readOnly: index === 0,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              {...(index === 0 ? { sx: { visibility: 'hidden' } } : { className: 'handle' })}
                            >
                              <DragHandle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {index !== 0 ? <DeleteForeverIcon onClick={() => deleteContent(item)} /> : ''}
                  </Grid>
                </Grid>
              ))}
          </Grid>
          {plusButton && <Button className="add" fullWidth onClick={handlAddContent} startIcon={<AddIcon />} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelPop(true)} className="btn-white" fullWidth>
            취소
          </Button>
          <Button className="btn-navy" fullWidth onClick={handleSaveConfirm}>
            저장
          </Button>
        </DialogActions>
      </Dialog>
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={() => setStepOpen(false)}
        />
      )}
      {regPop && (
        <ConfirmPopUp
          open={regPop}
          close={setRegPop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleSave}
        />
      )}
    </>
  );
};

export default PageSalesStepModal;
