import { Close, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { getClientDetail } from '@store/actions/counterpartyAction';
import { getCntrNmList } from '@store/actions/projectAction';
import { getClntCoNmList } from '@store/actions/salesAction';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageLoadClntModal = ({ isOpen, setIsOpen, inputs, setInputs }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const [keyword, setKeyword] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    handleGetSalesNmList();
  }, [dispatch]);

  const handleGetSalesNmList = (newKeyword) => {
    dispatch(
      getClntCoNmList({
        userId: user.userId,
        userSn: user.userSn,
        keyword: newKeyword,
        callback: handleGetCntrNmListCallback,
      })
    );
  };

  const handleGetCntrNmListCallback = (data) => {
    if (data.resultCode === 0) {
      setProjectList(data.data.clntCoNmList);
    } else {
      showToastError(data);
    }
  };

  const handleSearchChange = (e) => {
    const newKeyword = e.target.value.toLowerCase();
    setKeyword(newKeyword);

    const filteredList =
      projectList &&
      projectList.length > 0 &&
      projectList.filter((data) => {
        const slsNmMatch = data.slsNm ? data.slsNm.toLowerCase().includes(newKeyword) : false;
        const prjcNmMatch = data.prjcNm ? data.prjcNm.includes(newKeyword) : false;
        return slsNmMatch || prjcNmMatch;
      });

    setFilteredList(filteredList);
  };
  const displayList = keyword ? filteredList : projectList;

  const handleGetProjectDetail = (clntSn) => {
    dispatch(
      getClientDetail({
        userId: user.userId,
        userSn: user.userSn,
        clntCoSn: clntSn,
        callback: handelGetClntDetailCallback,
      })
    );
  };

  const handelGetClntDetailCallback = (data) => {
    console.log(data);
    if (data.resultCode === 0) {
      setIsOpen(false);
      setInputs({
        ...inputs,
        clntCd: data.data.coCd,
        clntNm: data.data.clntNm,
        zip: data.data.zip,
        addr: data.data.addr,
        daddr: data.data.daddr,
        mngr: data.data.mngr,
        mngrTelno: data.data.mngrTelno,
        mngrEmail: data.data.mngrEmail,
      });
    } else {
      showToast(data.resultCode + ':' + data.resultMsg, 'error');
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} scroll="paper" disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          의뢰사 가져오기
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          의뢰사 목록 중 정보를 가져올 의뢰사를 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                className="inp-round"
                placeholder="회사명/회사코드를 검색해주세요."
                autoComplete="off"
                size="small"
                name="keyword"
                onKeyUp={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className="list-type">
          {displayList && displayList.length > 0 ? (
            displayList.map((item) => (
              <Grid container key={item.clntCoSn}>
                <Grid item>
                  <Typography variant="body2" fontWeight={'bold'}>
                    {item.clntNm}
                  </Typography>
                  <Typography variant="body2">{item.clntCd}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Button className="btn-orange" onClick={() => handleGetProjectDetail(item.clntCoSn)}>
                    선택
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item md={12}>
              <Typography textAlign={'center'}>검색결과가 없습니다.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PageLoadClntModal;
