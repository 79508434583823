import { showToast, showToastError } from '@common/common';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { delCntrStts } from '@store/actions/contractAction';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const PageContractSttsChagneModal = ({ isOpen, setIsOpen, setRegOpen, setIsSuccessYn, isSuccessYn }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const contractData = useSelector((state) => state.contractReducer.contractInfo);

  const handleDeleteCntrStts = () => {
    dispatch(
      delCntrStts({
        userId: user.userId,
        userSn: user.userSn,
        stts: '3',
        cntrSnList: [contractData.cntrSn],
        callback: handleDelCntrSttsCallback,
      })
    );
  };

  const handleDelCntrSttsCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('계약중단 처리 되었습니다.');
      setIsOpen(false);
      setRegOpen(false);
      setIsSuccessYn(!isSuccessYn);
    } else {
      showToastError(data);
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth={'xs'} disableEnforceFocus>
      <DialogTitle>
        <Button className="btn-close">
          <Close onClick={() => setIsOpen(false)} />
        </Button>
        <Typography className="modal-tit" fontWeight={'bold'}>
          계약 중단
        </Typography>
        <Typography color={'grey'} variant="body2" mt={1}>
          계약과 함께 중단할 정산 목록을 선택해주세요.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems={'center'} py={2}>
          <Grid item md={6}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="착수금" />
          </Grid>
          <Grid item md={6} textAlign={'end'}>
            <Typography variant="body2">10,000,000원</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-around'}>
          <Grid item md={5}>
            <Button className="btn-white" onClick={() => setIsOpen(false)} sx={{ width: '100%' }}>
              취소
            </Button>
          </Grid>
          <Grid item md={5}>
            <Button className="btn-navy" onClick={handleDeleteCntrStts} sx={{ width: '100%' }}>
              확인
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PageContractSttsChagneModal;
