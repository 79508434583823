import { DateRange, Search } from '@mui/icons-material';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import PagiNation from 'component/pagination';
import React, { useEffect, useState } from 'react';
import PagePaymentReg from './PagePaymentReg';
import { useDispatch } from 'react-redux';
import { delPaymentProcess, getPaymentList, putPaymentProcess } from '@store/actions/paymentAction';
import { useSelector } from 'react-redux';
import { DatePickerRound, setNumberComma, showToast, strToPriceFormat } from '@common/common';
import ConfirmPopUp from 'component/ConfirmPopUp';
import { ko } from 'date-fns/locale/ko';
import DatePicker from 'react-datepicker';
import PagePaymentListMonthDetailModal from './PagePaymentListMonthDetailModal';
import PagePaymentListAgreementDetailModal from './PagePaymentListAgreementDetailModal';

const PagePaymentList = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);

  const [activePage, setActivePage] = useState(1);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [clientList, setClientList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [search, setSearch] = useState({
    pageNum: 1,
    pageSize: 20,
    keyword: '',
    searchType: 1,
    stDt: '',
    endDt: '',
    paymentStts: 1,
  });
  const [allChecked, setAllChecked] = useState(false);
  const [regOpen, setRegOpen] = useState(false);
  const [processPop, setProcessPop] = useState(false);
  const [delProcessPop, setDelProcessPop] = useState(false);
  const [isSuceess, setIsSuccess] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getPaymentList({
        ...search,
        userSn: user.userSn,
        userId: user.userId,
        callback: handleGetPaymentListCallback,
      })
    );
    console.log(search);
  }, [search, isSuceess]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;

    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const returnPage = (event) => {
    setSearch((prev) => ({
      ...prev,
      pageNum: event,
    }));
  };

  const handleGetPaymentListCallback = (data) => {
    if (data.resultCode === 0) {
      console.log(data);
      setPaymentList(data);
    } else {
      showToast(data);
    }
  };

  const handleAllCheck = () => {
    setAllChecked(!allChecked);

    if (!allChecked) {
      paymentList.data.map((item) => {
        setCheckedList((prev) => [...prev, item.paymentDtlSn]);
      });
    } else {
      setCheckedList([]);
    }
  };

  const checkedItemHandler = (value, isChecked) => {
    setAllChecked(false);

    if (isChecked) {
      setCheckedList((prev) => [...prev, value]);

      return;
    }

    if (!isChecked && checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));

      return;
    }

    return;
  };

  const checkHandler = (e, value) => {
    checkedItemHandler(value, e.target.checked);
  };

  const handlePaymentProcessConfirm = () => {
    if (checkedList.length <= 0) {
      return showToast('지급 처리 할 지급목록을 선택해주세요.');
    }
    setProcessPop(true);
  };

  const handlePaymentProcess = () => {
    dispatch(
      putPaymentProcess({
        userSn: user.userSn,
        userId: user.userId,
        paymentDtlSnList: checkedList,
        callback: handlePaymentProcessCallback,
      })
    );
  };

  const handlePaymentProcessCallback = (data) => {
    if (data.resultCode === 0) {
      showToast(
        checkedList.length + '건의 지급목록이 지급 처리 되었습니다. 처리된 목록은 지급 내역 메뉴에서 확인 가능합니다.'
      );
      setProcessPop(false);
      setIsSuccess(!isSuceess);
      setCheckedList([]);
      setAllChecked(false);
    } else {
      showToast(data);
    }
  };

  const handleDelProcessConfirm = () => {
    if (checkedList.length <= 0) {
      return showToast('삭제할 지급 목록을 선택해주세요.');
    }

    setDelProcessPop(true);
  };

  const handleDelPaymentProcess = () => {
    dispatch(
      delPaymentProcess({
        userSn: user.userSn,
        userId: user.userId,
        paymentDtlSnList: checkedList,
        callback: handleDelPaymentProcessCallback,
      })
    );
  };

  const handleDelPaymentProcessCallback = (data) => {
    if (data.resultCode === 0) {
      setDelProcessPop(false);
      setIsSuccess(!isSuceess);
      showToast(checkedList.length + '건의 지급 목록이 삭제되었습니다.');
    } else {
      showToast(data);
    }
  };

  const handleDtlClick = (detailSn, category) => {
    setIsDetailOpen(true);
    setPaymentDetail({
      sn: detailSn,
      category: category,
    });
    console.log(detailSn);
  };

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} pb={2}>
        <Grid item md={6} display={'flex'} alignItems={'center'}>
          <Grid item mr={1}>
            <FormControl size="small" fullWidth>
              <Select
                id="demo-simple-select"
                variant="outlined"
                name="searchType"
                sx={{ width: '120px' }}
                defaultValue={1}
                onChange={handleSearchChange}
              >
                <MenuItem value={1}>지급대상</MenuItem>
                <MenuItem value={2}>회원코드(회사코드)</MenuItem>
                <MenuItem value={3}>프로젝트명</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item mr={1}>
            <TextField
              className="inp-round srh"
              required
              placeholder="내용을 검색해주세요"
              autoComplete="off"
              size="small"
              fullWidth
              name="keyword"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <DatePicker
              locale={ko}
              selected={search.stDt}
              // onChange={handleDateChange}
              startDate={search.stDt}
              endDate={search.end}
              dateFormat={'YYYY-MM-dd'}
              selectsRange
              customInput={<DatePickerRound placehol="YYYY-MM-DD ~ YYYY-MM-DD" />}
              // isClearable={searchDay.clctStDt !== ''}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <Grid item mr={1}>
            <Button className="btn" onClick={handlePaymentProcessConfirm}>
              지급 처리
            </Button>
          </Grid>
          <Grid item mr={1}>
            <Button className="btn" onClick={() => setRegOpen(true)}>
              등록
            </Button>
          </Grid>
          <Grid item mr={1}>
            <Button className="btn-red" onClick={handleDelProcessConfirm}>
              삭제
            </Button>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <Select
                id="demo-simple-select"
                variant="outlined"
                displayEmpty
                defaultValue={1}
                sx={{ width: '120px' }}
                name="paymentStts"
                onChange={handleSearchChange}
              >
                <MenuItem value={1}>지연+예정</MenuItem>
                <MenuItem value={2}>지급 지연</MenuItem>
                <MenuItem value={3}>지급 예정</MenuItem>
                <MenuItem value={4}>지급 중단</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <Select
                variant="outlined"
                id="demo-simple-select"
                displayEmpty
                inputProps={{
                  name: 'pageSize',
                }}
                defaultValue="20"
                onChange={handleSearchChange}
              >
                <MenuItem value={20}>20건</MenuItem>
                <MenuItem value={50}>50건</MenuItem>
                <MenuItem value={100}>100건</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 1, width: '5%' }}>
                <Checkbox checked={allChecked} onChange={handleAllCheck} />
              </TableCell>
              <TableCell sx={{ padding: 1, width: '5%' }}>구분</TableCell>
              <TableCell sx={{ padding: 1, width: '20%' }}>프로젝트</TableCell>
              <TableCell sx={{ padding: 1, width: '20%' }}>지급 대상</TableCell>
              <TableCell sx={{ padding: 1, width: '10%' }}>고용 형태</TableCell>
              <TableCell sx={{ padding: 1, width: '10%' }}>지급 종류</TableCell>
              <TableCell sx={{ padding: 1, width: '10%' }}>지급 예정일</TableCell>
              <TableCell sx={{ padding: 1, width: '10%', textAlign: 'end' }}>지급액</TableCell>
              <TableCell sx={{ padding: 1, width: '10%', textAlign: 'end' }}>실지급액</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paymentList.data && paymentList.data.length > 0 ? (
              paymentList.data.map((item, index) => (
                <TableRow
                  key={item.paymentDtlSn}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleDtlClick(item.paymentSn, item.category)}
                >
                  <TableCell sx={{ padding: 1 }} onClick={(event) => event.stopPropagation()}>
                    <Checkbox
                      checked={checkedList.includes(item.paymentDtlSn)}
                      onChange={(e) => checkHandler(e, item.paymentDtlSn)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 1, color: item.paymentSttsNm === '지연' && 'red' }}>
                    {item.paymentSttsNm}
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.prjcNm}</TableCell>
                  <TableCell sx={{ padding: 1 }}>
                    <Typography variant="body2">{item.tgtNm}</Typography>
                    <Typography variant="body2">{item.coCd}</Typography>
                  </TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.emplTypeNm}</TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.categoryNm}</TableCell>
                  <TableCell sx={{ padding: 1 }}>{item.paymentDt}</TableCell>
                  <TableCell sx={{ padding: 1, textAlign: 'end' }}>
                    {setNumberComma(String(item.cost))}&nbsp;원
                  </TableCell>
                  <TableCell sx={{ padding: 1, textAlign: 'end' }}>
                    {setNumberComma(String(item.realCost))}&nbsp;원
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  검색결과가 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PagiNation
        totalItems={paymentList && paymentList.totalCount}
        itemCountPerPage={search.pageSize}
        currentPage={search.pageNum}
        returnPage={returnPage}
      />
      {regOpen && (
        <PagePaymentReg isOpen={regOpen} setIsOpen={setRegOpen} isSuceess={isSuceess} setIsSuccess={setIsSuccess} />
      )}
      {processPop && (
        <ConfirmPopUp
          open={processPop}
          close={setProcessPop}
          title={'선택한 지급 목록을 지급 처리 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={handlePaymentProcess}
        />
      )}
      {delProcessPop && (
        <ConfirmPopUp
          open={delProcessPop}
          close={setDelProcessPop}
          title={'선택한 지급 목록을 삭제 하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDelPaymentProcess}
        />
      )}
      {isDetailOpen &&
        (paymentDetail.category === 1 ? (
          <PagePaymentListMonthDetailModal
            isOpen={isDetailOpen}
            setIsOpen={setIsDetailOpen}
            paymentDtl={paymentDetail}
            isSuceess={isSuceess}
            setIsSuccess={setIsSuccess}
          />
        ) : (
          <PagePaymentListAgreementDetailModal
            isOpen={isDetailOpen}
            setIsOpen={setIsDetailOpen}
            paymentDtl={paymentDetail}
            isSuceess={isSuceess}
            setIsSuccess={setIsSuccess}
          />
        ))}
    </>
  );
};

export default PagePaymentList;
