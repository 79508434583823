import { setDateFormat } from '@common/common';
import { Chip, Grid, Typography } from '@mui/material';
import { moveIssue, moveProject } from '@store/actions/commonAction';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MainProjectListMyIssue = ({ project, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMoveIssue = (prjcSn, issueSn) => {
    console.log(issueSn);
    dispatch(moveProject(prjcSn));
    dispatch(moveIssue(issueSn));
    navigate('/mng/project/detail/issue?type=B');
  };
  return (
    <Grid
      container
      p={2}
      sx={{
        maxHeight: '300px', // 원하는 최대 높이 설정
        overflowY: 'auto', // 수직 스크롤을 허용
      }}
    >
      {project && Array.isArray(index === 0 ? project.myIssue1 : project.myIssue2) ? (
        (index === 0 ? project.myIssue1 : project.myIssue2).map((item, index) => (
          <Grid
            item
            key={index}
            display={'flex'}
            alignItems={'center'}
            sm={12}
            md={12}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              py: 1,
            }}
            onClick={() => handleMoveIssue(project.prjcMainSn, item.issueSn)}
          >
            <Grid item md={2}>
              <Chip
                label={
                  item.stts === 0
                    ? '신규'
                    : item.stts === 1
                    ? '처리중'
                    : item.stts === 2
                    ? '협의중'
                    : item.stts === 3
                    ? '완료'
                    : item.stts === 4
                    ? '지연'
                    : '철회'
                }
                color={
                  item.stts === 0
                    ? 'primary'
                    : item.stts === 1
                    ? 'success'
                    : item.stts === 2
                    ? 'warning'
                    : item.stts === 3
                    ? 'secondary'
                    : item.stts === 4
                    ? 'error'
                    : 'default'
                }
                size="small"
              />
            </Grid>
            <Grid item md={5}>
              <Typography variant="body2">{item.issueNm}</Typography>
            </Grid>
            <Grid item md={5}>
              <Typography variant="body2">
                {setDateFormat(item.issueStartDt, 'YYYY-MM-DD')} ~ {setDateFormat(item.issueEndDt, 'YYYY-MM-DD')}
              </Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item md={12} display={'flex'} justifyContent={'center'}>
          <Typography textAlign={'center'}>
            {index === 0 ? '예정된 담당 이슈가 없습니다.' : '예정된 검수 이슈가 없습니다.'}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MainProjectListMyIssue;
