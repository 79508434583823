import { showToast, showToastError } from '@common/common';
import {
  AccountCircle,
  Apartment,
  Book,
  BusinessCenter,
  CalendarMonth,
  Computer,
  Error,
  MoreVert,
  Paid,
  Search,
  Tag,
} from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, LinearProgress, Menu, MenuItem, Rating, Typography } from '@mui/material';
import { moveProject } from '@store/actions/commonAction';
import {
  getPrjcDtl,
  getPrjcDvlpDtlSuccess,
  putPrjcMainDelYn,
  putPrjcMainFvrtYn,
  setPrjcDtl,
  setPrjcInfo,
  setPrjcType,
} from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PageProjectMainList = ({ prjcMainSn, item, isSuccess, setIsSuccess, movePage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deletePop, setDeletePop] = useState(false);
  const [deletePrjcSn, setDeletePrjcSn] = useState('');

  const user = useSelector((state) => state.commonReducer.user);

  const options = ['수정', '삭제'];
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option, prjcSn) => {
    setAnchorEl(null);

    if (option === '수정') {
      dispatch(moveProject(prjcSn));

      navigate(`/mng/project/upd`);
    } else {
      setDeletePop(true);
      setDeletePrjcSn(prjcSn);
    }
  };

  const handleDelete = () => {
    dispatch(
      putPrjcMainDelYn({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: deletePrjcSn,
        delYn: 'Y',
        callback: handlePutPrjcMainDelYnCallback,
      })
    );
  };

  const handlePutPrjcMainDelYnCallback = (data) => {
    if (data.resultCode === 0) {
      showToast('프로젝트가 삭제 되었습니다.');
      setIsSuccess(!isSuccess);
    } else {
      showToastError(data);
    }
  };

  const handleFrvtChange = (e, newValue, sn) => {
    dispatch(
      putPrjcMainFvrtYn({
        userId: user.userId,
        userSn: user.userSn,
        prjcMainSn: sn,
        fvrtYn: newValue === 1 ? 'Y' : 'N',
        callback: (data) => handlePutPrjcMainFvrtYnCallback(data, newValue),
      })
    );
  };

  const handlePutPrjcMainFvrtYnCallback = (data, newValue) => {
    if (data.resultCode === 0) {
      setIsSuccess(!isSuccess);
      showToast(`${newValue === 1 ? '프로젝트 즐겨찾기를 설정하였습니다.' : '프로젝트 즐겨찾기를 해제하였습니다.'}`);
    } else {
      showToastError(data);
    }
  };

  return (
    <>
      <Grid item xs={6} sm={6} md={6} onClick={movePage}>
        <Card
          style={{
            userSelect: 'none',
            padding: '16px',
            margin: '16px 10px ',
            minHeight: '50px',
            backgroundColor: '#fff',
            color: '#333',
            border: '1px solid lightgrey',
            borderRadius: '15px',
          }}
        >
          <CardContent>
            <Grid container justifyContent={'space-between'}>
              <Typography gutterBottom variant="subtitle1" fontWeight={'bold'} component="div">
                {item.prjcNm}
              </Typography>
              <Grid item display={'flex'} alignItems={'center'} onClick={(event) => event.stopPropagation()}>
                <Rating
                  value={item.fvrtYn === 'Y' ? 1 : 0}
                  onChange={(e, newValue) => handleFrvtChange(e, newValue, item.prjcMainSn)}
                  max={1}
                />
                {item.modiYn === 'Y' && (
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVert />
                  </IconButton>
                )}
              </Grid>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
                onClick={(event) => event.stopPropagation()}
              >
                {options.map((option) => (
                  <MenuItem key={option} onClick={() => handleMenuItemClick(option, item.prjcMainSn)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <CalendarMonth />
                <Typography pl={2} variant="body2">
                  {item.stDt} ~ {item.endDt}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <Tag />
                <Typography pl={2} variant="body2" noWrap>
                  {item.prjcCd}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <Apartment />
                <Typography pl={2} variant="body2">
                  {item.clntCoNm}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'} mb={1}>
                <Computer />
                <Typography pl={2} variant="body2">
                  {item.prjcDvlpCnt > 1 ? `${item.prjcDvlpNm} 외 ${Number(item.prjcDvlpCnt) - 1} 건` : item.prjcDvlpNm}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'}>
                <AccountCircle />
                <Typography pl={2} variant="body2">
                  {item.prjcDvlpCnt}&nbsp;명
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} display={'flex'} alignItems={'center'}>
                <Error />
                <Typography pl={2} variant="body2">
                  {item.isssueCnt}&nbsp;건
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={2}>
                <LinearProgress variant="determinate" value={item.rate} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {deletePop && (
        <ConfirmPopUp
          open={deletePop}
          close={setDeletePop}
          title={'삭제하시겠습니까?'}
          comment={'삭제 후에는 되돌릴 수 없습니다.'}
          btnNm1={'취소'}
          btnNm2={'삭제'}
          btnEvent={handleDelete}
        />
      )}
    </>
  );
};

export default PageProjectMainList;
