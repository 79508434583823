import { ExampleCustomInput, showToast, showToastError } from '@common/common';
import { Close, DragHandle, Percent } from '@mui/icons-material';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getPrjcGrpDtl, putPrjcGrp } from '@store/actions/projectAction';
import ConfirmPopUp from 'component/ConfirmPopUp';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sortable from 'sortablejs';
import { ko } from 'date-fns/locale/ko';

const PageProjectGrpTaskModal = ({ isOpen, setIsOpen, detailSn, isSuccess, setIsSuccess }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.commonReducer.user);
  const sortableContainerRef = useRef(null);
  const id = useSelector((state) => state.commonReducer.projectSn);

  const sortableRef = useRef(null); // Sortable instance reference
  const [taskData, setTaskData] = useState();
  const [prrtLength, setPrrtLength] = useState();
  const [grpList, setGrpList] = useState([]);
  const [updPop, setUpdPop] = useState(false);
  const [cancelPop, setCancelPop] = useState(false);

  useEffect(() => {
    if (sortableContainerRef.current) {
      // Destroy existing Sortable instance if it exists
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }

      // Create a new Sortable instance
      sortableRef.current = new Sortable(sortableContainerRef.current, {
        handle: '.handle',
        animation: 150,
        //draggable: '.draggable-item:not(.fixed)',
        filter: '.fixed', // Class name of elements that should not be sortable
        preventOnFilter: false,
        onEnd: (event) => {
          const { oldIndex, newIndex } = event;
          console.log(grpList);
          if (oldIndex !== newIndex) {
            const updatedItems = [...grpList];
            console.log(updatedItems);
            const [movedItem] = updatedItems.splice(oldIndex, 1);
            updatedItems.splice(newIndex, 0, movedItem);

            setGrpList(updatedItems);
          }
        },
        onMove: (event) => {
          const { related, dragged } = event;
          const relatedIndex = Array.from(related.parentNode.children).indexOf(related);
          // Prevent drop to the first position

          return true;
        },
      });
    }
  }, [prrtLength, grpList, sortableContainerRef.current]);

  useEffect(() => {
    dispatch(
      getPrjcGrpDtl({
        userSn: user.userSn,
        userId: user.userId,
        prjcMainSn: id,
        prjcGrpSn: detailSn,
        callback: getPrjcGrpDtlCallback,
      })
    );
  }, []);

  const getPrjcGrpDtlCallback = (data) => {
    if (data.resultCode === 0) {
      setTaskData(data.data);
      setGrpList(data.data.prjPrntTaskList);
    } else {
      showToastError(data);
    }
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;

    setTaskData({
      ...taskData,
      stDt: start,
      endDt: end,
    });
  };

  const handleTaskDataChange = (e) => {
    const { name, value } = e.target;

    setTaskData({
      ...taskData,
      [name]: value,
    });
  };

  const handleUpdPrjcGrp = () => {
    dispatch(
      putPrjcGrp({
        userSn: user.userSn,
        userId: user.userId,
        prjcGrpSn: detailSn,
        grpNm: taskData.grpNm,
        memo: taskData.memo,
        prjcPrntTaskSnList: grpList.map((prjc) => prjc.prjcTaskSn),
        callback: handleUpdPrjcGrpCallback,
      })
    );
  };

  const handleUpdPrjcGrpCallback = (data) => {
    if (data.resultCode === 0) {
      setUpdPop(false);
      setIsOpen(false);
      setIsSuccess(!isSuccess);
      showToast('그룹이 수정 되었습니다.');
    } else {
      showToastError(data);
    }
  };

  return (
    <Dialog open={isOpen} fullWidth scroll="paper" disableEnforceFocus>
      <DialogTitle id="scroll-dialog-title">
        <Chip label="그룹" variant="outlined" />
        <TextField
          name="grpNm"
          variant="outlined"
          placeholder="업무명을 입력해주세요"
          InputProps={{ maxLength: 50 }}
          onChange={handleTaskDataChange}
          value={taskData ? taskData.grpNm : ''}
          className="modal-tit2 rqd"
          fullWidth
          size="medium"
        />
      </DialogTitle>
      <DialogContent>
        <Grid container my={2}>
          <Grid container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant="subtitle1" className="rqd">
                업무 기간
              </Typography>
            </Grid>
            <Grid item md={9} className="inp-date">
              <DatePicker
                locale={ko}
                selected={taskData && taskData.stDt}
                onChange={handleDateChange}
                startDate={taskData ? taskData.stDt : ''}
                endDate={taskData ? taskData.endDt : ''}
                dateFormat={'YYYY-MM-dd'}
                selectsRange
                disabled={true}
                customInput={<ExampleCustomInput />}
                // disabled={contractData.stts === '2' || contractData.stts === '3' ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid container alignItems={'center'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">진행률</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="rate"
                variant="standard"
                onChange={handleTaskDataChange}
                value={taskData ? taskData.rate : ''}
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container my={2}>
          <Grid item md={12} display={'flex'}>
            <Grid item md={3}>
              <Typography variant="subtitle1">메모</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                name="memo"
                onChange={handleTaskDataChange}
                multiline
                rows={4}
                fullWidth
                value={taskData ? taskData.memo : ''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container mt={5}>
          <Grid>
            <Typography variant="subtitle1">상위 업무</Typography>
            <Typography variant="subtitle2" color={'grey'}>
              상위 업무 순서 이동 시 해당 업무에 속한 하위 업무도 함께 이동됩니다.
            </Typography>
          </Grid>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={80} align="center">
                    순서
                  </TableCell>
                  <TableCell>업무명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={sortableContainerRef}>
                {taskData &&
                  taskData.prjPrntTaskList &&
                  taskData.prjPrntTaskList.length > 0 &&
                  taskData.prjPrntTaskList.map((item, index) => (
                    <TableRow key={item.prjcTaskSn} sx={{ cursor: 'pointer' }}>
                      <TableCell align="center">
                        <IconButton className="handle">
                          <DragHandle />
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.taskNm}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCancelPop(true)} className="btn-white">
          취소
        </Button>
        <Button className="btn-navy" onClick={() => setUpdPop(true)}>
          저장
        </Button>
      </DialogActions>
      {updPop && (
        <ConfirmPopUp
          open={updPop}
          close={setUpdPop}
          title={'저장하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'저장'}
          btnEvent={handleUpdPrjcGrp}
        />
      )}
      {cancelPop && (
        <ConfirmPopUp
          open={cancelPop}
          close={setCancelPop}
          title={'작성중인 내용이 저장 되지 않습니다.\n취소 하시겠습니까?'}
          btnNm1={'취소'}
          btnNm2={'확인'}
          btnEvent={setIsOpen}
        />
      )}
    </Dialog>
  );
};

export default PageProjectGrpTaskModal;
