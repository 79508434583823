export const GET_WRKF_LIST = 'GET_WRKF_LIST';

export const GET_BLNG_LIST = 'GET_BLNG_LIST';

export const DEL_WRKF_LIST = 'DEL_WRKF_LIST';

export const POST_BLNG = 'POST_BLNG';

export const POST_WRKF = 'POST_WRKF';

export const GET_SCH_INFO_CODE = 'GET_SCH_INFO_CODE';

export const GET_WRKF_DTL = 'GET_WRKF_DTL';

export const PUT_WRKF = 'PUT_WRKF';

export const getWrkfList = (payload) => {
    return { type: GET_WRKF_LIST, payload };
};

export const getBlngList = (payload) => {
    return { type: GET_BLNG_LIST, payload };
};

export const delWrkfList = (payload) => {
    return { type: DEL_WRKF_LIST, payload };
};

export const postBlng = (payload, callback) => {
    return { type: POST_BLNG, payload:{ payload, callback} };
};

export const postWrkf = (payload, callback) => {
    return { type: POST_WRKF, payload:{ payload, callback} };
};

export const getSchUserInfoByCode = (payload) => {
    return { type: GET_SCH_INFO_CODE, payload };
};

export const getWrkfDtl = (payload) => {
    return { type: GET_WRKF_DTL, payload };
};

export const putWrkf = (payload, callback) => {
    return { type: PUT_WRKF, payload:{ payload, callback} };
};