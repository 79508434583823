import { fileAPI, sendAPI } from '@axios/index';

/* 계약 관리 */
const getCntrList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-cntr-list`, params, payload);
};

const getCntrHistory = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-cntr-history`, params, payload);
};

const postCntr = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/api/post-cntr`, params, payload);
};

const putCntr = ({ params = {}, payload = {} }) => {
  return fileAPI('POST', `/api/put-cntr`, params, payload);
};

const getCntrDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-cntr-dtl`, params, payload);
};

const getSalesNmList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sales-nm-list`, params, payload);
};

const getSalesDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-sales-dtl`, params, payload);
};

const getDvlpCoNmList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-dvlp-co-nm-list`, params, payload);
};

const getDvlpCoDtl = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-dvlp-co-dtl`, params, payload);
};

const getDelCntrList = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/get-del-cntr-list`, params, payload);
};

const delCntrUseYn = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-cntr-use-yn`, params, payload);
};

const delCntrStts = ({ params = {}, payload = {} }) => {
  return sendAPI('POST', `/api/del-cntr-stts`, params, payload);
};

const api = {
  getCntrList,
  getCntrHistory,
  postCntr,
  putCntr,
  getCntrDtl,
  getSalesNmList,
  getSalesDtl,
  getDvlpCoNmList,
  getDvlpCoDtl,
  getDelCntrList,
  delCntrUseYn,
  delCntrStts,
};

export default api;
