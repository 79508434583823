import { Chip, Grid, Typography } from '@mui/material';
import { moveProject } from '@store/actions/commonAction';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MainProjectListMyTask = ({ project, index }) => {
  console.log(index);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMoveTask = (sn) => {
    dispatch(moveProject(sn));
    navigate('/mng/project/detail?tab=1');
  };
  return (
    <Grid
      container
      p={2}
      sx={{
        maxHeight: '300px', // 원하는 최대 높이 설정
        overflowY: 'auto', // 수직 스크롤을 허용
      }}
    >
      {project && Array.isArray(index === 0 ? project.myTask1 : project.myTask2) ? (
        (index === 0 ? project.myTask1 : project.myTask2).map((item, index) => (
          <Grid
            item
            key={item.prjcTaskSn}
            display={'flex'}
            alignItems={'center'}
            sm={12}
            md={12}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              py: 1,
            }}
            onClick={() => handleMoveTask(project.prjcMainSn)}
          >
            <Grid item md={2}>
              <Chip
                label={
                  item.stts === 0
                    ? '대기'
                    : item.stts === 1
                    ? '진행'
                    : item.stts === 2
                    ? '피드백'
                    : item.stts === 3
                    ? '완료'
                    : item.stts === 4
                    ? '지연'
                    : '보류'
                }
                color={
                  item.stts === 0
                    ? 'primary'
                    : item.stts === 1
                    ? 'success'
                    : item.stts === 2
                    ? 'warning'
                    : item.stts === 3
                    ? 'secondary'
                    : item.stts === 4
                    ? 'error'
                    : 'default'
                }
                size="small"
              />
            </Grid>
            <Grid item md={5}>
              <Typography variant="body2">{item.nm}</Typography>
            </Grid>
            <Grid item md={5}>
              <Typography variant="body2">
                {item.stDt} ~ {item.endDt}
              </Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item md={12} display={'flex'} justifyContent={'center'}>
          <Typography textAlign={'center'}>
            {index === 0 ? '예정된 담당 업무가 없습니다.' : '지연된 담당 업무가 없습니다.'}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MainProjectListMyTask;
