import {
  all,
  fork,
  call,
  put,
  //take,
  takeLatest,
  //select,
} from 'redux-saga/effects';

import * as actions from '@actions/commonAction';
import api from '@api/api';

// function* postUserInfo({ payload }) {
//   const { status, data } = yield call(api.postUserInfo, { payload });
//   if (status === 200) {
//     // 성공
//   }
// }

function* postSetting({ payload }) {
  const { status, data } = yield call(api.postSetting, { params: payload });
  if (status === 200) {
    payload.callback(data);
  }
}

// watcher
function* watchCommon() {
  // yield takeLatest(actions.SET_USER_INFO, postUserInfo);
  yield takeLatest(actions.SET_USER_STATE, postSetting);
}

export default function* commonSaga() {
  yield all([fork(watchCommon)]);
}
