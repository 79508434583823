import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Apartment, CalendarMonth, Paid } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { setNumberComma } from '@common/common';
import PageCalculationListDetail from './PageCalculationListDetail';

const PageCalculationBoardList = ({ resultData, updateProcess }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailSn, setDetailSn] = useState('');
  const handleDetailPopup = (value) => {
    setIsDetailOpen(true);
    setDetailSn(value);
  };

  const handleUpdate = () => {
    setIsDetailOpen(false);
    updateProcess();
  };

  return (
    <>
      <DragDropContext>
        <Grid container spacing={1}>
          {resultData &&
            resultData.length > 0 &&
            resultData.map((item, index) => (
              <Grid key={item.clctDtlSn} item xs={12} sm={12} md={12} onClick={() => handleDetailPopup(item.clctSn)}>
                <Droppable droppableId={item.clctDtlSn + ''}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        minHeight: '200px',
                        padding: '26px 0px 5px 0px',
                        width: '100%',
                      }}
                    >
                      <Draggable key={item.clctDtlSn} draggableId={item.clctDtlSn + ''} index={index} isDragDisabled>
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            elevation={3}
                            style={{
                              userSelect: 'none',
                              padding: '16px',
                              margin: '0 0 8px 0',
                              minHeight: '50px',
                              backgroundColor: '#fff',
                              color: '#333',
                              border: '1px solid lightgrey',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <CardContent>
                              <Grid container>
                                <Typography gutterBottom variant="subtitle1" fontWeight={'bold'} component="div">
                                  {item.prjcNm}
                                </Typography>
                              </Grid>

                              <Grid container>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                  <Apartment />
                                  <Typography pl={2} variant="body2">
                                    {item.coNm}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                  <CurrencyExchangeIcon />
                                  <Typography pl={2} variant="body2">
                                    {item.categoryNm}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                  <Paid />
                                  <Typography pl={2} variant="body2">
                                    {setNumberComma(String(item.cost))} 원
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} display={'flex'} alignItems={'center'} my={1}>
                                  <CalendarMonth />
                                  <Typography pl={2} variant="body2">
                                    {item.clctDt}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        )}
                      </Draggable>
                    </div>
                  )}
                </Droppable>
              </Grid>
            ))}
        </Grid>
      </DragDropContext>
      {isDetailOpen && (
        <PageCalculationListDetail
          isDetailOpen={isDetailOpen}
          setIsDetailOpen={setIsDetailOpen}
          clctSn={detailSn}
          updateProcess={handleUpdate}
        />
      )}
    </>
  );
};

export default PageCalculationBoardList;
